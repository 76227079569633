import { Space, Button, Table, message, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import Add from "./add";
import Totop from "../../shared/TopUp";
import { getMerchantsApi } from "../../../KIWE-V2/Services/merchantApi";
import Edit from "./edit";

const CreateMerchant = () => {
  const [merchantsRewards, setMerchantsRewards] = useState([]);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [
    isEditMerchantRewardModalVisible,
    setIsEditMerchantRewardModalVisible,
  ] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [record, setRecord] = useState(null);

  const [loading, setLoading] = useState(false);
  const handleGetMerchants = async (page) => {
    try {
      setLoading(true);
      const response = await getMerchantsApi(page);
      setMerchantsRewards(response.data);
      setTotalPages(response.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    handleGetMerchants(page);
  }, [page]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "Merchant Avatar",
      dataIndex: "avatar",
      key: "avatar",

      render: (txt, record) => (
        <>
          {record?.merchant && record?.merchant.avatar ? (
            <Avatar src={record?.merchant.avatar} size="large" />
          ) : (
            <Avatar size="large" icon={<UserOutlined />} />
          )}
        </>
      ),
    },
    {
      title: "Merchant Name",
      dataIndex: "MerchantName",
      key: "MerchantName",
      render: (txt, record) => (
        <>
          <span>{record?.merchant ? record?.merchant.name : "-"}</span>
        </>
      ),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (txt, record) => (
        <>
          <span>{new Date(txt)?.toLocaleDateString()}</span>
        </>
      ),
      sorter: (a, b) => new Date(a?.expiryDate) - new Date(b?.expiryDate),
    },
    {
      title: "Cashback %",
      dataIndex: "cashbackPercentage",
      key: "cashbackPercentage",
      sorter: (a, b) => a.cashbackPercentage - b.cashbackPercentage,
    },
    {
      title: "Discount %",
      dataIndex: "discountPercentage",
      key: "discountPercentage",
      sorter: (a, b) => a.discountPercentage - b.discountPercentage,
    },
    {
      title: "Min Amount Spent",
      dataIndex: "minAmountToSpend",
      key: "minAmountToSpend",
      render: (txt, record) => (
        <>
          <span>
            {typeof record?.minAmountToSpend === "number"
              ? record?.minAmountToSpend
              : "-"}
          </span>
        </>
      ),
      sorter: (a, b) => a?.minAmountToSpend - b?.minAmountToSpend,
    },
    {
      title: "Max Cashback Amount",
      dataIndex: "maxCashbackAmount",
      key: "maxCashbackAmount",
      render: (txt, record) => (
        <>
          <span>
            {typeof record?.maxCashbackAmount === "number"
              ? record?.maxCashbackAmount
              : "-"}
          </span>
        </>
      ),
      sorter: (a, b) => a?.maxCashbackAmount - b?.maxCashbackAmount,
    },
    {
      title: "Cashback %",
      dataIndex: "cashbackPercentage",
      key: "cashbackPercentage",
      render: (txt, record) => (
        <>
          <span>
            {typeof record?.cashbackPercentage === "number"
              ? record?.cashbackPercentage
              : "-"}
          </span>
        </>
      ),
      sorter: (a, b) => a?.cashbackPercentage - b?.cashbackPercentage,
    },
    {
      title: "Locked",
      dataIndex: "active",
      key: "active",
      render: (txt, record) => (
        <>
          <span>{record?.active ? "UNLOCKED" : "LOCKED"}</span>
        </>
      ),
    },
    {
      title: "Level",
      dataIndex: "levelId",
      key: "levelId",
      render: (txt, record) => (
        <>
          <span>{typeof record?.level ? record?.level.name : "-"}</span>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (txt, record) => (
        <>
          <Button
            type="primary"
            className="btn edit"
            style={{ margin: ".5rem" }}
            onClick={() => {
              showEditMerchantRewardModal();
              setRecord(record);
            }}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];
  const showEditMerchantRewardModal = () => {
    setIsEditMerchantRewardModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "flex-end" }}
        >
          <h2>Merchant Reward</h2>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal();
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {merchantsRewards.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={merchantsRewards}
              rowKey="id"
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: page,
                onChange: (page) => {
                  setPage(page);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No Merchant Rewards Records Found.
            </div>
          )}
        </div>
        <Add
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setMerchantsRewards={setMerchantsRewards}
          merchantsRewards={merchantsRewards}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
        />
        <Edit
          isModalVisible={isEditMerchantRewardModalVisible}
          setIsModalVisible={setIsEditMerchantRewardModalVisible}
          record={record}
          merchantsRewards={merchantsRewards}
          setMerchantsRewards={setMerchantsRewards}
          setCancelState={setCancelState}
          cancelState={cancelState}
        />
        <Totop />
      </div>
    </>
  );
};

export default CreateMerchant;

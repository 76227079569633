import { Button, Form, message, Modal, Input, Switch, Avatar } from "antd";
import { useState, useEffect } from "react";
import DragImage from "../../front/form/NewDragImage";
import { normFile } from "../../shared/dragdropFun";
import {
  uploadImageS3,
  convertImageToBase64,
} from "../../../library/UploadImage";
import { generateRandomFileNameWithoutExtention } from "../../shared/uploadfun";
import {
  addArticleApi,
  editArticleApi,
} from "../../../KIWE-V2/Services/articleApi";
const Add = ({
  setIsModalVisibleArticle,
  isModalVisibleArticle,
  setArticles,
  record,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [contentTouched, setContentTouched] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleAddandUpdateArticle = (values) => {
    if (record) {
      if (readyToSubmit) {
        handleUpdateArticle(values);
      }
    } else {
      handleAddArticle(values);
    }
  };
  const handleAddArticle = async (values) => {
    try {
      setLoading(true);
      const imageUrl = await handleUploadBannerArticle(values);
      const apiValues = {
        ...values,
        content,
      };
      const apiResponse = await addArticleApi(apiValues, imageUrl?.fileUrl);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Article Added Succssfully!",
        };
        message.success(configSuccess);
        const newData = { ...apiResponse.data };
        setArticles((prevData) => [...prevData, newData]);
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleUpdateArticle = async (values) => {
    try {
      setLoading(true);
      const imageUrl = await handleUploadBannerArticle(values);
      const apiValues = {
        ...values,
        content,
      };
      const apiResponse = await editArticleApi(
        apiValues,
        imageUrl?.fileUrl,
        record?.id
      );

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Article Updated Succssfully!",
        };
        message.success(configSuccess);
        const updatedArticle = { ...apiResponse.data };
        setArticles((prevArticles) => {
          const updatedArticles = prevArticles.map((article) => {
            if (article.id === updatedArticle.id) {
              return updatedArticle;
            }
            return article;
          });
          return updatedArticles;
        });
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleUploadBannerArticle = async (values) => {
    const file =
      values.banner && values.banner.length > 0
        ? values.banner[0].originFileObj
        : null;
    let imageUrl = null;

    if (file) {
      const base64Image = await convertImageToBase64(file);
      let randomName = generateRandomFileNameWithoutExtention(
        values?.banner[0]
      );
      imageUrl = await uploadImageS3(randomName, "article", base64Image);
    } else {
      imageUrl = { fileUrl: record?.banner };
    }
    return imageUrl;
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisibleArticle(false);
    setFileList([]);
    form.setFieldsValue({ banner: [] });
  };

  useEffect(() => {
    if (record && isModalVisibleArticle) {
      form.setFieldsValue({
        title: record?.title,
        visible: record?.visible,
      });
      setContent(record?.content || "");
    } else {
      form.resetFields();
      setContent("");
      setFileList([]);
    }
  }, [isModalVisibleArticle, record, form]);

  const handleContentChange = (e) => {
    const contentValue = e.target.value;
    if (contentValue.length <= 255) {
      setContent(contentValue);
    } else {
      message.error("Content should not exceed 255 characters");
    }
    setContentTouched(true);
    // setReadyToSubmit(false);
  };
  const handleTitleChange = () => {
    setReadyToSubmit(false);
  };
  const validateWhiteSpace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter a valid title"));
    }
    return Promise.resolve();
  };
  const defaultFileList = record?.banner
    ? [
        {
          uid: record?.id,
          name: record?.banner.split("article/")[1],
          status: "done",
        },
      ]
    : [];
  return (
    <>
      <Modal
        title={record ? "Edit Article" : "Add Article"}
        visible={isModalVisibleArticle}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddandUpdateArticle}
          autoComplete="off"
          initialValues={
            record
              ? {
                  title: record?.title,
                  visible: record?.visible,
                }
              : null
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {" "}
            {record ? (
              <Avatar
                size={80}
                shape="square"
                style={{ marginRight: "1rem", borderRadius: "10px" }}
                src={record?.banner}
              />
            ) : (
              ""
            )}
            <DragImage
              normFile={normFile}
              filelist={fileList}
              nam1="banner"
              accept=".jpg,.jpeg,image/jpeg,.png,image/png,.webp,image/webp"
              label="Banner Image"
              rules={[
                record
                  ? { required: false }
                  : {
                      required: true,
                      message: "Please input your Banner Image!",
                    },
              ]}
            />
          </div>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input your Title Article!",
              },
              { validator: validateWhiteSpace },
            ]}
          >
            <Input
              aria-label="Title"
              placeholder="Enter Title Article"
              className="input-border"
              onChange={handleTitleChange}
            />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content"
            rules={[
              {
                required: !content.trim(),
                message: "Please input your Content Article!",
              },
            ]}
          >
            {" "}
            <TextArea
              aria-label="Content"
              placeholder="Enter Content Article"
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={handleContentChange}
              value={content}
            />
          </Form.Item>
          <Form.Item
            name="visible"
            label="Activate Article"
            valuePropName="checked"
          >
            <Switch size="middle" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
              onClick={() => setReadyToSubmit(true)}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";

export const buildRequestBody = (
  addtionalRequestBodyData,
  searchText,
  page,
  orderBy
) => {
  return {
    ...addtionalRequestBodyData,
    search: searchText,
    pageNo: page,
    orderBy: orderBy,
    sortOrder: "desc",
    expiryTime: getCurrentTimeStemp(),
  };
};

export const apiCall = async (
  url,
  requestData,
  method = "post",
  decrypt = true
) => {
  try {
    const response = await axiosInstance()[method](url, {
      data: convertPlantextToEncrypted(requestData),
    });
    if (decrypt) {
      return convertEncryptedToPlantext(response?.data?.data);
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchUserDataAndDecryptResponse = async (
  accountTypeValue,
  searchText,
  page
) => {
  try {
    const addtionalRequestBodyData = {
      accountType: accountTypeValue,
    };
    const requestBody = buildRequestBody(
      addtionalRequestBodyData,
      searchText,
      page,
      "id"
    );

    const decryptedResponse = await apiCall("admin/v1/userList", requestBody);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

const prepareUpdateStatusUserData = (userId, newActiveStatus) => {
  return {
    active: newActiveStatus,
    userId: userId,
    expiryTime: getCurrentTimeStemp(),
  };
};

export const updateUserStatus = async (userId, newActiveStatus) => {
  try {
    const data = prepareUpdateStatusUserData(userId, newActiveStatus);

    const decryptedResponse = await apiCall("admin/deleteAdmin", data);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const uploadBulkRegistration = async (numberOfUsers) => {
  try {
    const api_response = await axiosInstance().post(
      `/admin/bulkRegistration/${numberOfUsers}`
    );

    return api_response;
  } catch (error) {
    return error;
  }
};

const buildUpdateUserOrganzationRequestBody = (record, values) => {
  return {
    userId: record?.userId,
    industryTypes: values?.industryTypes,
  };
};
export const addAndEditOrganization = async (record, values) => {
  try {
    const data = buildUpdateUserOrganzationRequestBody(record, values);
    const response = await apiCall("admin/update-hub", data, "put");
    return response;
  } catch (error) {
    throw error;
  }
};
const buildEditBankRequestBody = (values, id, state) => {
  return {
    bankName: values?.bankName,
    accountHolderName: values?.accountHolderName,
    accountNo: values?.accountNo,
    swiftCode: values?.swiftCode,
    branchName: values?.branchName,
    ibanNumber: values?.ibanNumber,
    accountId: state?.account_detail?.accountId,
    userId: id,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const editBankDetails = async (values, id, state) => {
  try {
    const data = buildEditBankRequestBody(values, id, state);
    const response = await apiCall("account/addUpdate", data);

    return response;
  } catch (error) {
    throw error;
  }
};

const buildupdateUserByAdminRequestBody = (values, record) => {
  return {
    userId: record?.userId,
    businessName: record?.businessName,
    taxIdNumber: values?.taxIdNumber,
    businessRegistrationNumber: values?.businessRegistrationNumber,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const updateUserByAdmin = async (values, record) => {
  try {
    const data = buildupdateUserByAdminRequestBody(values, record);
    const response = await apiCall("admin/v1/updateUserByAdmin", data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const DownloadQRCodeBulk = async () => {
  try {
    const response = await apiCall("admin/image/downloadQr", {}, "get");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const buildEditQRCodeRequestBody = (record, values) => {
  return {
    userId: record?.userId,
    businessName: values?.businessName,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const EditQRCodeAPI = async (record, values) => {
  try {
    const data = buildEditQRCodeRequestBody(record, values);
    const response = await apiCall("admin/editQrCode", data, "post", false);
    return response;
  } catch (error) {
    throw error;
  }
};
const buildUpdateBusinessUserRequestBody = (
  values,
  industId,
  industTypeId,
  sellId,
  randNameImage,
  userId
) => {
  return {
    isAutoWithdrawal: values.isAutoWithdrawal,
    studentCodeRequired: values.studentCodeRequired,
    qrRequired: values.qrRequired,
    businessTransactionFees: values.businessTransactionFees,
    website: values.website,
    taxIdNumber: values.taxIdNumber,
    typeOfBusiness: values.typeOfBusiness,
    mobile: values.mobile,
    firstName: values.firstName,
    email: values.email,
    countryCode: values.countryCode,
    businessRegistrationNumber: values.businessRegistrationNumber,
    address: values.address,
    businessName: values.businessName,
    industryId: industId ? industId : values.industryId,
    industryTypeId: industTypeId ? industTypeId : values.industryTypeId,
    sellingTypeId: sellId ? sellId : +values.sellingTypeId,
    profilePicturePath: randNameImage,
    userId: userId,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const updateBusinessUser = async (
  values,
  industId,
  industTypeId,
  sellId,
  randNameImage,
  userId
) => {
  try {
    const data = buildUpdateBusinessUserRequestBody(
      values,
      industId,
      industTypeId,
      sellId,
      randNameImage,
      userId
    );
    const response = await apiCall("admin/v1/updateUserByAdmin", data);

    return response;
  } catch (error) {
    throw error;
  }
};

const buildOtpForBusinessSignUpRequestBody = (values) => {
  return {
    email: values?.email,
  };
};
export const otpForBusinessSignUpAPI = async (values) => {
  try {
    const requestBody = buildOtpForBusinessSignUpRequestBody(values);

    const encryptedResponse = await apiCall(
      "admin/otpForBusinessSignUp",
      requestBody
    );

    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

import { Space, Button, Table, message } from "antd";

import Totop from "../../shared/TopUp";

import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { S3_URL } from "../../../constant";
import { useState, useEffect } from "react";
import swal from "sweetalert2";
import {
  getAllBusinessOffersApi,
  deleteBusinessOfferApi,
} from "../../../KIWE-V1/Services/businessOfferApi";

const BusinessOffer = () => {
  const history = useHistory();
  const [businssOffere, setBusinssOffere] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { pageNo } = useParams();
  const [loading, setLoading] = useState(true);
  const fetchAllBusinessOffers = async (page) => {
    try {
      const response = await getAllBusinessOffersApi(page);
      setTotalPages(response?.totalRecords);
      setBusinssOffere(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchAllBusinessOffers(pageNo);
  }, [pageNo]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
      width: 120,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      sorter: (a, b) => a.discount - b.discount,
      width: 120,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={S3_URL + "business_offer/" + record.image}
              style={{ width: "50%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Cover Image",
      dataIndex: "coverImage",
      key: "coverImage",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={S3_URL + "business_offer/" + record.picturePath}
              style={{ width: "50%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (txt, record) => (
        <>
          <div className="businessOfferBtns">
            <Link
              to={{
                // pathname: '/business-offer-edit',
                state: record,
                pageNo: pageNo,
              }}
            >
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={handleClickEdit}
              >
                Edit
              </Button>
            </Link>
            <Button
              type="danger"
              className="delete"
              onClick={() => handleDeleteClick(record)}
            >
              Delete
            </Button>
          </div>
        </>
      ),
    },
  ];

  const handleClick = () => {
    history.push(`/business-offer-add/${pageNo}`);
  };

  const handleClickEdit = () => {
    history.push(`/business-offer-edit/${pageNo}`);
  };

  const handleDeleteClick = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Business Offer ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteBusinessOfferAsync() {
            try {
              const decryptedResponse = await deleteBusinessOfferApi(record);
              if (decryptedResponse) {
                setBusinssOffere((prevBusinessOffers) =>
                  prevBusinessOffers.filter(
                    (businessOffer) => businessOffer.id !== record.id
                  )
                );
                message.success("Business Offer deleted successfully!!");
              }
            } catch (error) {
              message.error(error.message);
            }
          }
          deleteBusinessOfferAsync();
        }
      });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Perks List</h2>
          <Space>
            <Button type="primary" size="middle" onClick={handleClick}>
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={businssOffere}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNo,
              onChange: (page) => {
                setPage(page);
                history.push(`/business-offer/${page}`);
                fetchAllBusinessOffers(page);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default BusinessOffer;

import React from "react";
import {
  Button,
  Form,
  message,
  InputNumber,
  DatePicker,
  Switch,
  Radio,
} from "antd";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import { useValuPromotion } from "../../../contextAPi/valuPromotion/valuPromotionContext.js";
import {
  addValuPromotionAPI,
  editValuPromotionAPI,
} from "../../../KIWE-V1/Services/valuPromotionApi.js";
const AddPromotionValue = () => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [valuPromotionOption, setValuPromotionOption] = useState("Amount");
  const { valuPromotion, setValuPromotion } = useValuPromotion();
  const valuPromotionOptions = ["Amount", "Precentage"];
  const selectValuPromotion = (e) => {
    setValuPromotionOption(e.target.value);
  };
  const dateFormat = "YYYY/MM/DD";
  const disabledDateToday = (current) => {
    const today = moment().format("YYYY-MM-DD");
    return current && current < moment(today, "YYYY-MM-DD");
  };
  const disabledDateTomorrow = (current) => {
    const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    return current && current < moment(tomorrow, "YYYY-MM-DD");
  };
  const handleAddAndEditValuPromotion = (values) => {
    values = {
      ...values,
      startDate: values?.startDate
        ? values["startDate"]?.format("YYYY-MM-DD")?.toString()
        : null,
      endDate: values?.endDate
        ? values["endDate"]?.format("YYYY-MM-DD")?.toString()
        : null,
    };
    if (state) {
      editValuPromotion(values, valuPromotionOption, state);
    } else {
      addValuPromotion(values, valuPromotionOption);
    }
  };
  const addValuPromotion = async (values, valuPromotionOption) => {
    try {
      setLoading(true);
      const decryptedResponse = await addValuPromotionAPI(
        values,
        valuPromotionOption
      );
      setLoading(false);
      const newData = { ...decryptedResponse?.data };
      setValuPromotion((prevData) => [...prevData, newData]);
      const configSuccess = {
        className: "messageSuccess",
        content: "Added Valu Promotion Successfully!",
      };
      message.success(configSuccess);
      history.goBack();
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const editValuPromotion = async (values, valuPromotionOption, state) => {
    try {
      setLoading(true);
      const decryptedResponse = await editValuPromotionAPI(
        values,
        valuPromotionOption,
        state
      );
      setLoading(false);
      const newData = { ...decryptedResponse?.data };
      setValuPromotion((prevData) => [...prevData, newData]);
      const configSuccess = {
        className: "messageSuccess",
        content: "Edited Valu Promotion Successfully!",
      };
      message.success(configSuccess);
      history.goBack();
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleCancel = () => {
    form.resetFields();
    history.goBack();
  };

  useEffect(() => {
    if (state?.id) {
      form.setFieldsValue({ active: state?.active });
      form.setFieldsValue({ upToLimit: state?.upToLimit });
      form.setFieldsValue({ amount: state?.amount });
      form.setFieldsValue({ percentage: state?.percentage });
    }
  }, [state, form]);
  return (
    <>
      {" "}
      <div style={{ margin: "1rem" }}>
        <div className="container__topheader">
          <h2>{state ? "Edit Valu-Promotion" : "Add Valu-Promotion"}</h2>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="profile__btns__save"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                size="middle"
                className="profile__btns__cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="container" style={{ padding: "2.2rem" }}>
          <div className="profile__form" style={{ padding: "0rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleAddAndEditValuPromotion}
              autoComplete="off"
              initialValues={{
                startDate: state?.id ? moment(state?.startDate) : null,
                endDate: state?.id ? moment(state?.endDate) : null,
              }}
            >
              <Form.Item
                name="user"
                label="Making Promotion Value:"
                className="notificationFrom__userType"
              >
                <Radio.Group
                  onChange={(e) => selectValuPromotion(e)}
                  defaultValue={"Amount"}
                  value={valuPromotionOptions[0]}
                >
                  {valuPromotionOptions.map((item, index) => (
                    <Radio value={item} key={index}>
                      {item}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              {valuPromotionOption === "Precentage" ? (
                <>
                  {" "}
                  <Form.Item
                    name="percentage"
                    label="Percentage Promotion-Valu"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your Percentage  Promotion-Valu!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      defaultValue={0}
                      className="inputs-business"
                      style={{
                        width: "100%",
                        borderRadius: "25px",
                        height: "2.7rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="upToLimit" label="Limit Promotion-Valu">
                    <InputNumber
                      min={0}
                      defaultValue={0}
                      className="inputs-business"
                      style={{
                        width: "100%",
                        borderRadius: "25px",
                        height: "2.7rem",
                      }}
                    />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Amount!",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item name="startDate" label="Start Valu-Promotion">
                <DatePicker
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                  format={dateFormat}
                  disabledDate={disabledDateToday}
                />
              </Form.Item>
              <Form.Item name="endDate" label="End Valu-Promotion">
                <DatePicker
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                  format={dateFormat}
                  disabledDate={disabledDateTomorrow}
                />
              </Form.Item>
              {state ? (
                <Form.Item
                  name="active"
                  label="Active Valu-Promotion"
                  valuePropName="checked"
                >
                  <Switch size="middle" checked={state?.active} />
                </Form.Item>
              ) : (
                <Form.Item
                  name="active"
                  label="Active Valu-Promotion"
                  valuePropName="checked"
                >
                  <Switch size="middle" />
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPromotionValue;

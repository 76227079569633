import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildRequestBody = (page) => {
  return {
    pageNo: page,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const fetchNotificationsData = async (page) => {
  try {
    const requestData = buildRequestBody(page);
    const decryptedResponse = await apiCall(
      "admin/offer-notifications",
      requestData
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
const buildAddNotificationRequestBody = (values, randomNameImage) => {
  return {
    notificationText: values?.notificationText,
    notificationTitle: values?.notificationTitle,
    deviceType: values?.deviceType,
    userType: values?.userType,
    notificationImage: randomNameImage,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addNotificationAPI = async (values, randomNameImage) => {
  try {
    const requestBody = buildAddNotificationRequestBody(
      values,
      randomNameImage
    );
    const decryptedResponse = await apiCall(
      "admin/send_bulk_notification",
      requestBody
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

import { Modal, Button } from "antd";
import { notification_Business_picture_path } from "../../../constant";

const NotificationDetails = ({ handleCancel, isModalVisible, record }) => {
  return (
    <Modal
      title={"Notification Details"}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="modal-business"
    >
      <div className="notifyModal">
        <div className="notifyImgContainer">
          <img
            src={notification_Business_picture_path + record?.imagePath}
            alt=""
          />
        </div>

        <div className="notifyContentContainer">
          <div className="notifyTitle">
          <h4>Notification Title</h4>
            <span>
              {record?.title}
            </span>
          </div>
          <div className="notifyBody"> 
            <h4>Notification Body</h4>
            <span>
              {record?.body}
            </span>
          </div>
        </div>

      </div>

      <div className="column3">
        <Button
          type="primary"
          className="column2__btn-print"
          onClick={handleCancel}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationDetails;

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import InputSearch from "../form/InputSearch";
import Add from "./Add";
import TopUp from "../../shared/TopUp";
import { getAllContents } from "../../../KIWE-V1/Services/contentApi";
const Content = () => {
  const onSearch = (value) => console.log(value);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contents, setContents] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAllContents = async () => {
    try {
      const apiResponse = await getAllContents();
      setContents(apiResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchAllContents();
  }, []);

  const contentColumns = [
    {
      title: "Title Slug",
      dataIndex: "staticContentSlug",
      key: "staticContentSlug",
      sorter: (a, b) => a.staticContentSlug?.localeCompare(b.staticContentSlug),
      ellipsis: true,
    },
    {
      title: "Content",
      dataIndex: "text",
      key: "text",
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit-transaction"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
                console.log(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];
  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Content</h2>
          <Space>
            <InputSearch onSearch={onSearch} />
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal();
                setRecord({});
              }}
            >
              ADD
            </Button>
            <Add
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={contentColumns}
            dataSource={contents}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
        <TopUp />
      </div>
    </>
  );
};

export default Content;

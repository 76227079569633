import React from "react";
import { Button, Form, InputNumber, DatePicker, Switch, Input } from "antd";
import DragImage from "../form/DragImage";

import { normFile } from "../../shared/dragdropFun";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";

const AddSavings = () => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(false);
  const [selectAmount, setSelectAmount] = useState(false);
  const { TextArea } = Input;
  const [visible, setVisible] = useState(false);
  const { state } = useLocation();

  console.log(state);
  const history = useHistory();

  // const { userId } = state;
  // const { userId } = useParams();

  // const [id, setId] = useState();
  const dateFormat = "YYYY/MM/DD";

  const onFinish = (values) => {
    console.log(values);
    // values = {
    //   ...values,
    //   startDate: values?.startDate
    //     ? values["startDate"]?.format("YYYY-MM-DD")?.toString()
    //     : null,
    // };
    console.log(values);

    console.log("values", values);

    // axiosInstance()
    //   .post("valu-promotion", {
    //     data: convertPlantextToEncrypted({
    //       active: values.active,
    //       upToLimit:
    //         value === "Precentage"
    //           ? values.upToLimit === undefined
    //             ? 0
    //             : values.upToLimit
    //           : null,
    //       startDate: values.startDate,
    //       amount: value === "Amount" ? values.amount : null,
    //       endDate: values.endDate,
    //       percentage:
    //         value === "Precentage"
    //           ? values.percentage === undefined
    //             ? 0
    //             : values.percentage
    //           : null,
    //     }),
    //   })
    //   .then((response) => {
    //     // debugger;
    //     if (response.status === 200) {
    //       let api_res = convertEncryptedToPlantext(response.data.data);
    //       console.log("api_response", api_res);
    //       // debugger;
    //       message.success("Submit success!");

    //       history.goBack();
    //     }
    //   })
    //   .catch((error) => {
    //     const err = convertEncryptedToPlantext(error?.response?.data?.data);
    //     message.error(err.message);
    //   });
  };
  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  const disabledDate = (current) => {
    let customDate = today;
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const onChange = (value) => {
    setValue(value);
  };
  const handleCancel = () => {
    form.resetFields();
    // setId(userId);
    history.goBack();
  };

  // useEffect(() => {
  //   if (state?.id) {
  //     form.setFieldsValue({ active: state?.active });
  //     form.setFieldsValue({ upToLimit: state?.upToLimit });
  //     form.setFieldsValue({ amount: state?.amount });

  //     //   form.setFieldsValue({ createdCashBack: createdCashBack });
  //     form.setFieldsValue({ percentage: state?.percentage });
  //     //   form.setFieldsValue({ endCashBack: endCashBack });
  //   }
  // }, [state?.id]);
  const validateRecurringPayment = (rule, value) => {
    if (visible && value) {
      form.validateFields(["amount"]).then((fields) => {
        if (fields.amount && value >= fields.amount) {
          return Promise.reject("Recurring payment should be less than amount");
        }
        return Promise.resolve();
      });
    }
    return Promise.resolve();
  };
  useEffect(() => {
    if (!visible) {
      console.log(visible);
      form.setFieldsValue({ recurringPayment: undefined });
    }
  }, [visible, form]);

  return (
    <>
      {" "}
      <div style={{ margin: "1rem" }}>
        <div className="container__topheader">
          <h2>{"Add Savings"}</h2>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="profile__btns__save"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                size="middle"
                className="profile__btns__cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="container" style={{ padding: "2.2rem" }}>
          <div className="profile__form" style={{ padding: "0rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              // initialValues={{
              //   startDate: state?.id ? moment(state?.startDate) : null,

              // }}
            >
              <Form.Item className="offerImage">
                <DragImage
                  nam1="templatePicturePath"
                  normFile={normFile}
                  label="Savings Image"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Savings Image!",
                    },
                  ]}
                />
                <span className="note">
                  Note :- Please upload image in size of "1440 * 645" for better
                  resolution
                </span>
              </Form.Item>
              <Form.Item
                name="templateTitle"
                label="Savings Title"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Savings Title!",
                  },
                ]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please input your Amount!",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  defaultValue={0}
                  // onChange={onChange}
                  className="inputs-business"
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                />
              </Form.Item>

              <Form.Item
                name="startDate"
                label="Deadline"
                rules={[
                  {
                    required: true,
                    message: "Please input your Deadline Savings!",
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                  //   defaultValue={
                  //     startAt ? moment(startAt, dateFormat) : ''
                  //   }
                  format={dateFormat}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                hasFeedback
                // rules={[
                //   { required: true, message: "Please input your Description!" },
                // ]}
                className="offer-name"
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="active"
                label="Recurring Payment"
                valuePropName="checked"
              >
                <Switch
                  size="middle"
                  checked={visible}
                  onChange={() => setVisible(!visible)}
                />
              </Form.Item>
              {visible ? (
                <Form.Item
                  name="recurringPayment"
                  label="Recurring Payment Amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Recurring Payment Amount!",
                    },
                    // {
                    //   validator: async (rule, value) => {
                    //     if (visible && value) {
                    //       await form
                    //         .validateFields(["amount"])
                    //         .then((fields) => {
                    //           if (fields?.amount && value >= fields?.amount) {
                    //             return Promise.reject(
                    //               "Recurring payment should be less than amount"
                    //             );
                    //           }
                    //           return Promise.resolve();
                    //         });
                    //     }
                    //     return Promise.resolve();
                    //   },
                    //   validateTrigger: ["onBlur", "onChange"],
                    // },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("amount") > value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Recurring payment should be less than amount"
                          )
                        );
                      },
                    }),
                    // {
                    //   validator: async (_, __value) => {
                    //     if (__value >= value) {
                    //       return Promise?.reject(
                    //         "Recurring payment should be less than amount"
                    //       );
                    //     }
                    //     return Promise?.resolve();
                    //   },
                    //   validateTrigger: ["onBlur", "onChange"],
                    // },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    //   onChange={onChange}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                  />
                </Form.Item>
              ) : null}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSavings;

import { useState, useEffect } from "react";
import { Form, message, Select, Button, Input, Col, Row, Switch } from "antd";
import Totop from "../../shared/TopUp";
import { fetchPageNavigationApi } from "../../../KIWE-V2/Services/announcementApi";
import { editAnnouncementApi } from "../../../KIWE-V1/Services/oldAnnouncementApi.js";
import { useHistory, useLocation } from "react-router-dom";
const EditOldAnnouncement = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const location = useLocation();
  const { record } = location.state;
  const [text, setText] = useState(record ? record?.text : "");
  const [contentTouched, setContentTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageNavigations, setPageNavigations] = useState([]);
  const [selectedPageNavigation, setSelectedPageNavigation] = useState(null);
  const [initialValue, setInitialValues] = useState(
    record
      ? {
          backgroundColor: record?.backgroundColor,
          pageNavigationId: record?.navigateTo?.id,
          textColor: record?.textColor,
          enabled: record?.enabled,
          title: record?.title,
          titleColor: record?.titleColor,
          buttonText: record?.buttonText,
          buttonColor: record?.buttonColor,
        }
      : {}
  );
  const history = useHistory();

  const handleChangePageNavigation = (selectedItems) => {
    setSelectedPageNavigation(selectedItems);
  };
  const fetchPageNavigation = async () => {
    try {
      const apiResponse = await fetchPageNavigationApi();
      setPageNavigations(apiResponse.data);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    fetchPageNavigation();
  }, []);

  const handleEditAnnouncement = async (values) => {
    try {
      setLoading(true);
      const enabled = values.enabled !== undefined ? values.enabled : false;
      const apiValues = {
        ...values,
        text,
        enabled,
      };
      const apiResponse = await editAnnouncementApi(apiValues, record?.id);
      if (apiResponse) {
        setLoading(false);

        const configSuccess = {
          className: "messageSuccess",
          content: "Announcement Updated Succssfully!",
        };
        message.success(configSuccess);
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ backgroundColor: record?.backgroundColor });
      form.setFieldsValue({
        pageNavigationId: record?.navigateTo?.id,
      });
      form.setFieldsValue({
        textColor: record?.textColor,
      });
      form.setFieldsValue({
        enabled: record?.enabled,
      });
      form.setFieldsValue({
        title: record?.title,
      });
      form.setFieldsValue({
        titleColor: record?.titleColor,
      });
      form.setFieldsValue({
        buttonText: record?.buttonText,
      });
      form.setFieldsValue({
        buttonColor: record?.buttonColor,
      });
    }
  }, [record, initialValue, form]);
  const handleContentChange = (e) => {
    const contentValue = e.target.value;
    if (contentValue.length <= 255) {
      setText(contentValue);
    } else {
      message.error("Content should not exceed 255 characters");
    }
    setContentTouched(true);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Update Announcement</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleEditAnnouncement}
            autoComplete="off"
            className="notificationFrom"
            initialValues={initialValue}
          >
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="enabled"
                  label="Activate Announcement"
                  valuePropName="checked"
                >
                  <Switch size="middle" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your Title Announcement!",
                    },
                  ]}
                >
                  <Input
                    aria-label="Title"
                    placeholder="Enter Title Announcement"
                    className="input-border"
                  />
                </Form.Item>
              </Col>{" "}
              <Col span={12}>
                <Form.Item
                  name="titleColor"
                  label="Title Color"
                  hasFeedback
                  className="offer-name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Title Color!",
                    },
                  ]}
                >
                  <Input
                    type="color"
                    className="input-border"
                    defaultValue={"#0e4bfb"}
                  />
                </Form.Item>{" "}
              </Col>{" "}
            </Row>

            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                {" "}
                <Form.Item
                  name="backgroundColor"
                  label="Background Color"
                  hasFeedback
                  className="offer-name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Background Color!",
                    },
                  ]}
                >
                  <Input
                    type="color"
                    className="input-border"
                    defaultValue={"#0e4bfb"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pageNavigationId"
                  label="Page navigation"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your Page navigation!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select page navigation"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                    }}
                    className="selectLevel"
                    value={selectedPageNavigation}
                    onChange={handleChangePageNavigation}
                    allowClear
                    dropdownMatchSelectWidth={true}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    showSearch
                  >
                    {pageNavigations.map((item) => (
                      <Select.Option value={item.id}>
                        {item.pageName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="buttonText"
                  label="Button Text"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your Button Text Announcement!",
                    },
                  ]}
                >
                  <Input
                    aria-label="Button Text"
                    placeholder="Enter Button Text Announcement"
                    className="input-border"
                  />
                </Form.Item>
              </Col>{" "}
              <Col span={12}>
                <Form.Item
                  name="buttonColor"
                  label="Button Color"
                  hasFeedback
                  className="offer-name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Button Color!",
                    },
                  ]}
                >
                  <Input
                    type="color"
                    className="input-border"
                    defaultValue={"#0e4bfb"}
                  />
                </Form.Item>{" "}
              </Col>{" "}
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="text"
                  label="Content"
                  rules={[
                    {
                      required: !text.trim(),
                      message: "Please input your Content Announcement!",
                    },
                  ]}
                >
                  {" "}
                  <TextArea
                    aria-label="Content"
                    placeholder="Enter Content Announcement"
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    onChange={handleContentChange}
                    value={text}
                  />
                </Form.Item>
              </Col>{" "}
              <Col span={12}>
                <Form.Item
                  name="textColor"
                  label="Content Color"
                  hasFeedback
                  className="offer-name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Content Color!",
                    },
                  ]}
                >
                  <Input
                    type="color"
                    className="input-border"
                    defaultValue={"#0e4bfb"}
                  />
                </Form.Item>{" "}
              </Col>{" "}
            </Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="add-btn"
                loading={loading}
              >
                Update Announcement
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Totop />
      </div>
    </>
  );
};

export default EditOldAnnouncement;

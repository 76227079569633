import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
export const apiCall = async (
  url,
  requestBody,
  method = "post",
  decrypt = true
) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchAnnouncementsApi = async (page, limit = 10) => {
  try {
    const encryptedResponse = await apiCall(
      `admin/announcement/v1?page=${page}&limit=${limit}`,
      {},
      "get",
      false
    );
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildAnnouncementRequestBody = (values) => {
  return {
    backgroundColor: values?.backgroundColor,
    pageNavigationId: values?.pageNavigationId,
    text: values?.text,
    textColor: values?.textColor || "#0e4bfb",
    enabled: values?.enabled,
    title: values?.title,
    titleColor: values?.titleColor,
    buttonText: values?.buttonText,
    buttonColor: values?.buttonColor,
  };
};
export const addAnnouncementApi = async (values) => {
  try {
    const requestBody = buildAnnouncementRequestBody(values);

    const encryptedResponse = await apiCall(
      "admin/announcement/v1",
      requestBody,
      "post",
      false
    );
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const editAnnouncementApi = async (values, announcmentId) => {
  try {
    const requestBody = buildAnnouncementRequestBody(values);

    const encryptedResponse = await apiCall(
      `admin/announcement/${announcmentId}`,
      requestBody,
      "put",
      false
    );
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

import { Input, Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { addServiceTypeAPI } from "../../../KIWE-V1/Services/serviceType.js";
const Add = ({ setIsModalVisible, isModalVisible, record }) => {
  console.log(record);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValues] = useState(
    record?.id ? { serviceName: record?.serviceName } : null
  );
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      if (length !== 0) {
        setInitialValues({
          serviceName: record?.serviceName,
        });
      } else {
        form.setFieldsValue({ serviceName: "" });

        setInitialValues({});
      }
    }
  }, [record, form]);
  const handleAddServiceType = async (values) => {
    try {
      setLoading(true);
      const decryptedResponse = await addServiceTypeAPI(values, record);
      if (decryptedResponse) {
        setLoading(false);
        message.success(decryptedResponse);
        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);

      message.error(error.message);
    }
  };

  useEffect(() => {
    if (record?.id) {
      form.setFieldsValue({ serviceName: record.serviceName });
    }
  }, [record, initialValue, form]);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={record?.id ? "Edit Service Type" : "Add Service Type"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddServiceType}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item
            name="serviceName"
            label="Service Name"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Service Name!" },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import { Space, Button, Table, message, Avatar } from "antd";
import { useEffect, useState } from "react";

import Totop from "../../shared/TopUp";
import { fetchLevelsRewardDataAndDecryptResponse } from "../../../KIWE-V2/Services/rewardLevel";
import { useHistory } from "react-router";
import { useLevelReward } from "../../../contextAPi/levelReward/levelRewardContext.js";

import { Link } from "react-router-dom";
const LevelReward = () => {
  const { levelsReward, setLevelsRewards } = useLevelReward();

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const fetchLevelsRewards = async () => {
    try {
      const apiResponse = await fetchLevelsRewardDataAndDecryptResponse();

      setLevelsRewards(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error?.error,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchLevelsRewards();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",

      render: (src, record) => (
        <>
          <Avatar src={record?.icon} size="large" />
        </>
      ),
    },
    {
      title: "Color",
      dataIndex: "hexaColor",
      key: "hexaColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record?.hexaColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Cashback %",
      dataIndex: "cashbackPercentage",
      key: "cashbackPercentage",
      sorter: (a, b) => a.cashbackPercentage - b.cashbackPercentage,
      render: (txt, record) => (
        <>
          <span>
            {record?.cashbackPercentage ? record?.cashbackPercentage : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Discount %",
      dataIndex: "discountPercentage",
      key: "discountPercentage",
      sorter: (a, b) => a.discountPercentage - b.discountPercentage,
      render: (txt, record) => (
        <>
          <span>
            {record?.discountPercentage ? record?.discountPercentage : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Expenditure To Maintain",
      dataIndex: "expenditureToMaintain",
      key: "expenditureToMaintain",
      sorter: (a, b) => a.expenditureToMaintain - b.expenditureToMaintain,
    },
    {
      title: "Expenditure To Reach",
      dataIndex: "expenditureToReach",
      key: "expenditureToReach",
      sorter: (a, b) => a.expenditureToReach - b.expenditureToReach,
    },
    {
      title: "Transaction Count To Maintain",
      dataIndex: "transactionCountToMaintain",
      key: "transactionCountToMaintain",
      sorter: (a, b) =>
        a.transactionCountToMaintain - b.transactionCountToMaintain,
    },
    {
      title: "Transaction Count To Reach",
      dataIndex: "transactionCountToReach",
      key: "transactionCountToReach",
      sorter: (a, b) => a?.transactionCountToReach - b?.transactionCountToReach,
    },
    {
      title: "Next Level",
      dataIndex: "nextLevel",
      key: "nextLevel",

      render: (nextLevel) => <span>{nextLevel?.id ? nextLevel?.id : "-"}</span>,
    },
    {
      title: "Time Frame",
      dataIndex: "timeFrame",
      key: "timeFrame",
    },
    {
      title: "Merchant",
      dataIndex: "action",
      key: "action",

      render: (txt, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btnCardsActivate"
              style={{ margin: ".5rem" }}
              onClick={() => {
                history.push({
                  pathname: "/merchant",
                  state: { level: record },
                });
              }}
            >
              View Merchants
            </Button>
          </div>
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (val, record) => (
        <>
          <div>
            <Link
              to={{
                pathname: "/edit-level-reward",
                state: { record: record },
              }}
            >
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
              >
                Edit
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "flex-end" }}
        >
          <h2>Level Reward</h2>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push("/add-level-reward");
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {levelsReward.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={levelsReward}
              scroll={{ x: 900 }}
              loading={loading}
              rowKey="id"
            />
          ) : (
            <div className="messageNoData">No Level Rewards Records Found.</div>
          )}
        </div>

        <Totop />
      </div>
    </>
  );
};

export default LevelReward;

import { Modal, Avatar, Button } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import ParentUser from "./parentUserDetails";

const ModalUser = ({
  selectedRowKey,
  isModalVisibleUserDetails,
  setIsModalVisibleUserDetails,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisibleUserDetails(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <Modal
        title="Account Info"
        visible={isModalVisibleUserDetails}
        onCancel={handleCancel}
        footer={null}
        className="modal"
        width={"45rem"}
      >
        <div className="indi">
          <div className="section_row">
            <Avatar src={selectedRowKey?.profilePicture} size={130} />
            <div>
              {selectedRowKey?.firstName?.trim()?.length !== 0 ? (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>First Name</h3>
                  <span>{selectedRowKey?.firstName}</span>
                </div>
              ) : (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>First Name</h3>
                  <span>-</span>
                </div>
              )}
              {selectedRowKey?.lastName?.trim()?.length !== 0 ? (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>Last Name</h3>
                  <span>{selectedRowKey?.lastName}</span>
                </div>
              ) : (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>Last Name</h3>
                  <span>-</span>
                </div>
              )}
            </div>
          </div>

          <div className="section_row ">
            <div className="section">
              <h3>Birth Date</h3>
              <span>
                {selectedRowKey?.birthDate
                  ? new Date(selectedRowKey?.birthDate).toLocaleDateString()
                  : "-"}
              </span>
            </div>

            <div className="section indi_content">
              <h3>Mobile</h3>
              <span>
                {selectedRowKey?.mobile ? selectedRowKey?.mobile : "-"}
              </span>
            </div>
          </div>

          <div className="section_row ">
            <div className="section">
              <h3>Email</h3>
              <span>
                <span>
                  {selectedRowKey?.email ? selectedRowKey?.email : "-"}
                </span>
              </span>
            </div>
            <div className="section indi_content">
              <h3>Create Date</h3>
              <span>
                {new Date(
                  selectedRowKey?.createdTimeStamp
                ).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="indi_info">
            <div className="col_indi indi_info_item">
              <h3>Account Type</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey.type === undefined ? "-" : selectedRowKey.type}
              </span>
            </div>
            <div className="col_indi indi_info_item">
              <h3>Address</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey.address !== null ? selectedRowKey.address : "-"}
              </span>
            </div>
            {selectedRowKey?.type !== "PARENT" ||
            selectedRowKey?.type !== "ADULT_SUB_ACCOUNT" ? (
              <div className="col_indi indi_info_item">
                <h3>City</h3>
                <span className="qrcode_indi indi_info_item">
                  {selectedRowKey?.city !== null ? selectedRowKey?.city : "-"}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="col_indi indi_info_item">
              <h3>Reward Level</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey?.rewardLevel?.name}
              </span>
            </div>

            {selectedRowKey?.type === "PARENT" ||
            selectedRowKey?.type === "ADULT_SUB_ACCOUNT" ? (
              selectedRowKey?.parent === null ? (
                <div className="col_indi indi_info_item">
                  <h3>Sub accounts</h3>
                  <Link to="/sub-account/">
                    <Button
                      className="btn-child"
                      size="small"
                      style={{ height: "2rem" }}
                      onClick={() =>
                        localStorage.setItem(
                          "parentAccount",

                          JSON.stringify(selectedRowKey)
                        )
                      }
                    >
                      <span className="icon-Adminw"></span>
                      View Sub Account
                    </Button>
                  </Link>
                </div>
              ) : (
                <div className="col_indi indi_info_item">
                  <h3>Parent Account</h3>
                  <Button
                    className="btn-child"
                    size="small"
                    style={{ height: "2rem" }}
                    onClick={() => {
                      showModal();
                    }}
                  >
                    <span className="icon-Adminw"></span>
                    View Parent Account
                  </Button>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>
      <ParentUser
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedRowKey={selectedRowKey}
      />
    </>
  );
};

export default ModalUser;

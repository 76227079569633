import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";

export const apiCall = async (
  url,
  requestBody,
  method = "post",
  decrypt = true
) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchLevelsRewardDataAndDecryptResponse = async () => {
  try {
    const encryptedResponse = await apiCall(
      "v2/admin/reward-level",
      {},
      "get",
      false
    );

    if (encryptedResponse.status === 200) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );
      return decryptedResponse;
    }
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );

    throw decryptedError;
  }
};

const buildAddAndUpdateRewardLevelRequestBody = (values, icon) => {
  return {
    name: values.name,
    expenditureToMaintain: values.expenditureToMaintain,
    expenditureToReach: values.expenditureToReach,
    transactionCountToMaintain: values.transactionCountToMaintain,
    transactionCountToReach: values.transactionCountToReach,
    nextLevelId: values.nextLevelId === 0 ? null : values.nextLevelId,
    timeFrame: values.timeFrame,
    discountPercentage: values.discountPercentage,
    cashbackPercentage: values.cashbackPercentage,
    hexaColor: values.hexaColor,
    icon: icon,
    isDefault: values.isDefault,
  };
};

export const addRewardLevelAPI = async (values, icon) => {
  try {
    const requestBody = buildAddAndUpdateRewardLevelRequestBody(values, icon);
    const encryptedResponse = await apiCall(
      "v2/admin/reward-level",
      requestBody,
      "post",
      false
    );

    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

export const updateRewardLevelAPI = async (values, record, icon) => {
  try {
    const requestBody = buildAddAndUpdateRewardLevelRequestBody(values, icon);
    const encryptedResponse = await apiCall(
      `v2/admin/reward-level/${record?.id}`,
      requestBody,
      "patch",
      false
    );
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

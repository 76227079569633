import { Modal } from "antd";

const ReasonKeys = ({ selectedRowKey, handleCancel, isModalVisible }) => {
  return (
    <>
      <Modal
        title="Reason Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal disputes"
        width={"40rem"}
      >
        <div className="disputes_info_col" style={{ width: "100%" }}>
          <div className="disputes_info_row" style={{ marginTop: "0rem" }}>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Reason</h3>

              <span style={{ maxWidth: "50%" }}>
                {selectedRowKey?.reason?.reason
                  ? selectedRowKey?.reason?.reason
                  : "-"}
              </span>
            </div>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Reason Description</h3>
              <span style={{ maxWidth: "50%" }}>
                {selectedRowKey?.key6 ? selectedRowKey?.key6 : "-"}
              </span>
            </div>
          </div>
          <div style={{ backgroundColor: "#BEBCBB", height: ".5px" }}></div>

          <div className="disputes_info_row ">
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Attachment</h3>
              <a
                target="_blank"
                href={selectedRowKey?.key0 ? selectedRowKey?.key0 : "-"}
                style={{ maxWidth: "50%" }}
              >
                {selectedRowKey?.key0 ? selectedRowKey?.key0 : "-"}
              </a>
            </div>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Cancel Date</h3>
              <span>
                {selectedRowKey?.key1
                  ? new Date(selectedRowKey?.key1).toLocaleDateString()
                  : "-"}
              </span>
            </div>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Cancel Number</h3>
              <span>{selectedRowKey?.key2 ? selectedRowKey?.key2 : "-"}</span>
            </div>
          </div>
          <div style={{ backgroundColor: "#BEBCBB", height: ".5px" }}></div>

          <div className="disputes_info_row " style={{ marginBottom: "0rem" }}>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Amount Received</h3>
              <span>
                <span>{selectedRowKey?.key3 ? selectedRowKey?.key3 : "-"}</span>
              </span>
            </div>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Amount Requested</h3>
              <span>
                <span>{selectedRowKey?.key4 ? selectedRowKey?.key4 : "-"}</span>
              </span>
            </div>
            <div className="disputes_section" style={{ width: "100%" }}>
              <h3>Expected Delivery Date</h3>
              <span>
                <span>
                  {selectedRowKey?.key5
                    ? new Date(selectedRowKey?.key5).toLocaleDateString()
                    : "-"}
                </span>
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReasonKeys;

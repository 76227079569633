import { Avatar, Table, Space, Button } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { S3_URL } from "../../../constant";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import Totop from "../../shared/TopUp";
const EventUser = () => {
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { state } = useLocation();
  console.log(state, "staa");
  const fetchData = async (page, searchText) => {
    axiosInstance()
      .post("event/eventList", {
        data: convertPlantextToEncrypted({
          userId: state?.userId,
          search: searchText,
          pageNo: page,
          orderBy: "id",
          sortOrder: "desc",
          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        // debugger;
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response?.data?.data);
          setTotalPages(api_res?.totalRecords);
          setData(api_res?.data);
          setLoading(false);

          console.log(api_res, "EventUser");
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };
  const columns = [
    {
      title: "Owner",
      dataIndex: "ownerName",
      key: "ownerName",
      sorter: (a, b) => a.ownerName.localeCompare(b.ownerName),
      ellipsis: true,
      width: 153,
    },
    {
      title: "Event Name",
      dataIndex: "walletName",
      key: "walletName",
      sorter: (a, b) => a.walletName.localeCompare(b.walletName),
      ellipsis: true,
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate),
      render: (text, record) => <>{new Date(text).toLocaleDateString()}</>,
      ellipsis: true,
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
      ellipsis: true,
    },
    {
      title: "Contributors",
      dataIndex: "contributors",
      key: "contributors",

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: `/event-detail/${record.eventId}`,
              state: record,
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{
                marginLeft: "-1rem",
                height: "2rem",
                background: "#33B978",
              }}
            >
              <span className="icon-Adminw"></span>
              Contributors
            </Button>
          </Link>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",
      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/transaction-user",
              state: record,
              userId: state?.userId,
              place: "individual",
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{
                marginLeft: "-1rem",
                height: "2rem",
                background: "#33B978",
              }}
            >
              <span className="icon-TransactionFeesw"></span>
              Transactions
            </Button>
          </Link>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Created At",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      ellipsis: true,
      render: (text, record) => <>{new Date(text).toLocaleDateString()}</>,
    },
  ];
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  useEffect(() => {
    fetchData(1, searchText);
  }, []);
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Event User</h2>
        <div className="container">
          <Space direction="horizontal" className="space-transaction">
            <Avatar
              className="transaction-image"
              src={
                S3_URL +
                state?.userId +
                "/profile_picture/" +
                state?.profilePicturePath
              }
            />
            <span className="transaction-user">{state?.firstName}</span>
          </Space>
          <Table
            className="table2"
            columns={columns}
            dataSource={data}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +page,
              onChange: (page) => {
                setPage(page);
                // history.push(`/events/${page}`);
                fetchData(page, searchText);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default EventUser;

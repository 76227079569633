import { GET_ALL_SERVICE_TYPE } from '../actionTypes';
import { getAllServiceTypeAsync } from './api';

export const getServiceType = () => {
  return async (dispatch) => {
    try {
      const result = await getAllServiceTypeAsync();

      dispatch(getServiceTypeSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getServiceTypeSuccess = (serviceType) => {
  return {
    type: GET_ALL_SERVICE_TYPE,
    payload: serviceType,
  };
};

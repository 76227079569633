import { Input, Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { addAndUpdateContentApi } from "../../../KIWE-V1/Services/contentApi";
const Add = ({ isModalVisible, setIsModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState({
    staticContentSlug: record?.staticContentSlug,
    text: record?.text,
  });
  const handleAddAndUpdateContent = async (values) => {
    try {
      await addAndUpdateContentApi(values, record);
      message.success("Submit successfully!");

      // handleCancel();
      // window.location.reload();
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      if (length === 0) {
        form.setFieldsValue({ staticContentSlug: "" });
        form.setFieldsValue({ text: "" });

        setInitialValues({});
      } else {
        setInitialValues({
          staticContentSlug: record?.staticContentSlug,
          text: record?.text,
        });
      }
    }
  }, [record, record?.staticContentSlug, record?.text]);
  useEffect(() => {
    if (record) {
      form.setFieldsValue({ staticContentSlug: record?.staticContentSlug });
      form.setFieldsValue({ text: record?.text });
    }
  }, [record?.staticContentId, record?.staticContentSlug, record?.text]);
  return (
    <>
      <Modal
        title={record?.staticContentId ? "Edit Content" : "Add Content"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndUpdateContent}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item
            name="staticContentSlug"
            label="Title Slug"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Title Slug!" },
            ]}
            className="offer-name"
          >
            <Input value={record?.staticContentSlug} className="input-border" />
          </Form.Item>
          <Form.Item
            name="text"
            label="Content"
            hasFeedback
            rules={[{ required: true, message: "Please input your Content!" }]}
            className="offer-name"
          >
            <Input value={record?.text} className="input-border" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

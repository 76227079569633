import { GET_ALL_SERVICE_TYPE } from '../actionTypes';
const initialState = {
  allServiceType: [],
};
const serviceTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SERVICE_TYPE:
      return { ...state, allServiceType: action.payload };

    default:
      return state;
  }
};
export default serviceTypeReducer;

import { Avatar, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { S3_URL } from "../../../constant";
import Totop from "../../shared/TopUp";
import { fetchAllContributorListForEvent } from "../../../KIWE-V1/Services/eventApi.js";
const EventDetail = () => {
  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [contributorList, setContributorList] = useState([]);
  const { id } = useParams();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);

  const fetchContributorListForEvent = async (page, searchTextFromInput) => {
    try {
      const apiResponse = await fetchAllContributorListForEvent(
        id,
        page,
        searchTextFromInput
      );
      setTotalPages(apiResponse?.totalRecords);
      setContributorList(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchContributorListForEvent(1, searchTextFromInput);
  }, []);

  const columns = [
    {
      title: "Profile Image",
      dataIndex: "profilePicturePath",
      key: "profilePicturePath",
      render: (src, record) => (
        <>
          <Avatar
            src={
              S3_URL +
              record?.userId +
              "/profile_picture/" +
              record?.profilePicturePath
            }
          />
        </>
      ),
      ellipsis: true,
    },
    {
      title: "User Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (txt, value) => (
        <>
          <span>{value?.firstName + " " + value?.lastName}</span>
        </>
      ),
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Events Details</h2>
        <div className="container">
          <div className="container__header">
            <div className="container__header__column1 above">
              <div className="container__header__column1__content ">
                {state?.profilePicturePath ? (
                  <Avatar
                    size="large"
                    alt="imgProfile"
                    src={
                      S3_URL +
                      state?.userId +
                      "/profile_picture/" +
                      state?.profilePicturePath
                    }
                  />
                ) : (
                  ""
                )}
                <span style={{ marginLeft: "0.5rem" }}>{state?.ownerName}</span>
              </div>
            </div>
            <div className="container__header__column2">
              <div className="container__header__column2__row1 above">
                <span className="container__header__span1">Event:</span>
                <span>{state?.walletName}</span>
              </div>
              <div className="container__header__column2__row2">
                <span className="container__header__span1">Owner:</span>
                <span>{state?.ownerName}</span>
              </div>
            </div>
            <div className="container__header__column3">
              <div className="container__header__column3__row1 above">
                <span className="container__header__span1">Event Date:</span>
                <span>{new Date(state?.eventDate).toLocaleDateString()}</span>
              </div>
              <div className="container__header__column3__row2">
                <span className="container__header__span1">Balance:</span>
                <span>
                  {"E£ "} {state?.balance?.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="container__header__column4">
              <div className="container__header__column4__row1 above">
                <span className="container__header__span1">Created At:</span>
                <span>{new Date(state?.createdDate).toLocaleString()}</span>
              </div>
              <div className="container__header__column4__row2">
                <span className="container__header__span1">Description :</span>
                <span>{state?.eventDescription}</span>
              </div>
            </div>
          </div>
          <Table
            style={{ marginTop: "1rem" }}
            className="scrolling"
            columns={columns}
            dataSource={contributorList}
            loading={loading}
            scroll={{ x: 900 }}
            pagination={{
              pageSize: 10,
              total: totalPages,
              onChange: (page) => {
                setPage(page);
                fetchContributorListForEvent(page, searchTextFromInput);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default EventDetail;

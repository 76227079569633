import { Input, Button, Form, message } from "antd";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { editAdminProfileApi } from "../../../../KIWE-V2/Services/adminApi";
const NewProfile = () => {
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleEditAdmiProfile = async (values) => {
    try {
      setLoading(true);
      const apiResponse = await editAdminProfileApi(values);
      setLoading(false);
      localStorage.setItem("firstName", apiResponse?.data?.firstName);
      localStorage.setItem("lastName", apiResponse?.data?.lastName);
      localStorage.setItem("email", apiResponse?.data?.email);
      const configSuccess = {
        className: "messageSuccess",
        content: "Your profile is up to date now",
      };
      message.success(configSuccess);
      history.push("/dashboard");
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };
  const handleCancel = () => {
    history.push("/dashboard");
  };
  const handleClick = () => {
    setValue(!value);
  };
  const userDetails = (value) => {
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    let email = localStorage.getItem("email");

    return {
      firstName: firstName,
      email: email,
      lastName: lastName,
    };
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="container__topheader">
          <h2>Profile</h2>
          <div
            className="profile__btns"
            style={{ display: "flex", marginTop: "1rem" }}
          >
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="profile__btns__save"
                form="myform"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                size="middle"
                className="profile__btns__cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="container profile">
          <div className="profile__form">
            <Form
              id="myform"
              layout="vertical"
              onFinish={handleEditAdmiProfile}
              autoComplete="off"
              initialValues={userDetails(["firstName", "email", "lastName"])}
            >
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input className="inputs" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input className="inputs" />
              </Form.Item>
              <Form.Item
                name={"email"}
                label="Email"
                rules={[
                  { type: "email" },
                  { required: true, message: "Please input a valid email!" },
                ]}
              >
                <Input className="inputs" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="profile__form__change"
                  onClick={handleClick}
                >
                  Change Password
                </Button>
              </Form.Item>
              {value && (
                <div>
                  <Form.Item name="password" label="Password :">
                    <Input.Password
                      className="inputPassword"
                      aria-label="Password :"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password :"
                    dependencies={["password"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          console.log(value, getFieldValue("password"));
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="inputPassword"
                      aria-label="Confirm Password :"
                    />
                  </Form.Item>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewProfile;

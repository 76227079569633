import { Table, message, Collapse } from "antd";
import { useState, useEffect } from "react";
import { fetchCardActionApi } from "../../../KIWE-V2/Services/userAPI.js";

const CardAction = ({ record }) => {
  const { Panel } = Collapse;
  const [totalPages, setTotalPages] = useState(1);
  const [cardActions, setCardActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const fetchCardActions = async (page, record) => {
    setLoading(true);
    try {
      const apiResponse = await fetchCardActionApi(page, record?.id);
      setLoading(false);
      setCardActions(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    if (page) {
      fetchCardActions(page, record);
    } else {
      fetchCardActions(1, record);
    }
  }, [page, record]);
  const cardActionsColumns = [
    {
      title: "Action Type",
      dataIndex: "type",
      key: "type",

      render: (txt, record) => (
        <>
          <span>{record?.type}</span>
        </>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",

      render: (txt, record) => (
        <div>{new Date(record?.createdTimeStamp).toLocaleDateString()}</div>
      ),
    },
  ];

  return (
    <>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="Holder Actions History" key="1">
          <div className="container">
            {cardActions?.length > 0 ? (
              <Table
                className="table2"
                columns={cardActionsColumns}
                dataSource={cardActions}
                size="large"
                scroll={{ x: 900 }}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  total: totalPages,
                  current: +page ? +page : 1,
                  onChange: (page) => {
                    setPage(page);
                  },
                }}
              />
            ) : (
              <div className="messageNoData" style={{ width: "24%" }}>
                No Card Actions Records Found.
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default CardAction;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
import moment from "moment";


const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestData).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(encryptedResponse?.data?.data);
    }
    return encryptedResponse;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
const BuildCreateOrEditMerchantRewardRequestBody = (data) => {
  return {
    ...data,
    expiryDate: moment(data.expiryDate)
      .format("YYYY-MM-DD HH:mm:ss")
      .toString(),
  };
};
export const createMerchantRewardApi = async (data) => {
  try {
    data = BuildCreateOrEditMerchantRewardRequestBody(data);
    const response = await apiCall(`v2/admin/merchant/reward`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getMerchantCodesApi = async () => {
  try {
    const decryptedResponse = await apiCall(`v2/admin/merchant`, {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const editMerchantRewardApi = async (data, id) => {
  try {
    data = BuildCreateOrEditMerchantRewardRequestBody(data);
    const decryptedResponse = await apiCall(
      `v2/admin/merchant/reward/${id}`,
      data,
      "patch"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const getMerchantsApi = async (page = 1, limit = 10) => {
  try {
    const decryptedResponse = await apiCall(
      `v2/admin/reward?page=${page}&limit=${limit}`,
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

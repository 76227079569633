import { Input, Button, Form, message, Modal } from "antd";
import { useRef } from "react";
import { useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  // convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { EditQRCodeAPI } from "../../../KIWE-V1/Services/userAPI";
const EditQRCode = ({
  isModalVisible,
  setIsModalVisible,
  record,
  getContainer,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  console.log(record, "mmmmmmmmmmmmmm");
  const container = useRef(null);
  const handleEditQRCodeAPI = async(values) => {
    try {
      setLoading(true);
      const response = await EditQRCodeAPI(record,values);
      if (response.status === 200) {
        setLoading(false);
        message.success("Edit QR Code Successfully!");
        handleCancel();
      } 
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title={"Edit QR Code"}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="modal"
      getContainer={getContainer}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditQRCodeAPI}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="businessName"
          label="New Business Name"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please inputNew Business Name!",
            },
          ]}
          className="offer-name"
        >
          <Input className="input-border" />
        </Form.Item>

        <Form.Item className="btns">
          <Button
            size="middle"
            loading={loading}
            htmlType="submit"
            className="btns__offer-save"
          >
            Save
          </Button>
          <Button
            size="middle"
            className="btns__offer-cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditQRCode;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody || {}).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt && encryptedResponse && encryptedResponse.data) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildLoginUserRequestBody = (values) => {
  return {
    email: values.email,
    password: values.password,
  };
};
export const newloginUserAPI = async (values) => {
  try {
    const requestBody = buildLoginUserRequestBody(values);
    const encryptedResponse = await apiCall(
      "v2/admin/signin",
      requestBody,
      "post",
      false
    );
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

import axiosInstance from "../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../library/Helper";

export const fetchTransactionsApi = async (page, userId, limit = 10) => {
  try {
    const apiResponse = await axiosInstance().get(
      `v2/admin/transaction?page=${page}&limit=${limit}&userId=${userId}`
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
export const apiCall = async (
  url,
  requestBody,
  method = "post",
  decrypt = true
) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const generateUrlFetchAdminApi = (page, limit = 10, searchText = null) => {
  let url = `v2/admin?page=${page}&limit=${limit}`;

  if (searchText !== null) {
    url += `&filter=${searchText}`;
  }

  return url;
};

export const fetchAllAdminsApi = async (page, searchText) => {
  try {
    const url = generateUrlFetchAdminApi(page, 10, searchText);
    const encryptedResponse = await apiCall(url, {}, "get", false);
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const getAdminsRoles = async () => {
  try {
    const apiResponse = await apiCall("v2/admin/group", {}, "get");
    return apiResponse;
  } catch (error) {
    throw error;
  }
};
export const addAdminApi = async (data) => {
  try {
    const apiResponse = await apiCall("v2/admin", data);
    return apiResponse;
  } catch (error) {
    throw error;
  }
};
export const deleteAdminApi = async (adminId) => {
  try {
    const apiResponse = await apiCall(
      `v2/admin/${adminId}`,
      {},
      "delete",
      false
    );
    return apiResponse;
  } catch (error) {
    throw error;
  }
};
export const editAdminApi = async (adminId, values) => {
  try {
    const response = await apiCall(`v2/admin/${adminId}`, values, "patch");
    return response;
  } catch (error) {
    throw error;
  }
};
export const editAdminProfileApi = async (values) => {
  try {
    const apiResponse = await apiCall("v2/admin", values, "patch");
    return apiResponse;
  } catch (error) {
    throw error;
  }
};

import { GET_ALL_INDUSTRY } from '../actionTypes';
const initialState = {
  allIndustry: [],
};
const industryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INDUSTRY:
      return { ...state, allIndustry: action.payload };

    default:
      return state;
  }
};
export default industryReducer;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const getServiceCodesApi = async () => {
    try {
      const decryptedResponse = await apiCall("serviceCode/fetchServiceCode", {}, "get");
      return decryptedResponse;
    } catch (error) {
      throw error;
    }

}

const buildAddOrEditServiceCodesRequestBody=(values,record,companyID,serviceTypeID)=>{

    return{
        inquiryServiceCode: values.inquiryServiceCode,
        billServiceCode: values.billServiceCode,

        companyId: companyID ? companyID : values.companyId,
        serviceTypeId: serviceTypeID ? serviceTypeID : values.serviceTypeId,
        id: record?.id,
        expiryTime: getCurrentTimeStemp(),
    }
}

export const addOrEditServiceCodesApi = async (values,record,companyID,serviceTypeID) => {
    try {
      const data = buildAddOrEditServiceCodesRequestBody(values,record,companyID,serviceTypeID)
      const decryptedResponse = await apiCall("serviceCode/addServiceCode", data);
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
}
export const deleteServiceCodeApi = async (id) => {
    try {
      const decryptedResponse = await apiCall(`serviceCode/deleteServiceCodeById/${id}`, {},'delete');
      return decryptedResponse;
    } catch (error) {
      throw error;
    }

}
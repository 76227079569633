import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchIndustriesTypesData = async () => {
  try {
    const decryptedResponse = await apiCall(
      "industry/getIndustryType",
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const deleteIndustryTypeAPI = async (record) => {
  try {
    const decryptedResponse = await apiCall(
      "industry/deleteIndustryType/" + record?.id,
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildUpdateHubRequestBody = (record) => {
  return {
    isHub: !record?.isHub,
    id: record?.id,
    expiryTime: getCurrentTimeStemp(),
  };
};

export const updateHubIndustryType = async (record) => {
  try {
    const requestBody = buildUpdateHubRequestBody(record);
    const decryptedResponse = await apiCall(
      "/industry/active-hub",
      requestBody,
      "put"
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildAddAndUpdateRequestBody = (values, randName, record) => {
  return {
    industryTypeName: values?.industryTypeName,
    industryTypeNameAr: values?.industryTypeNameAr,
    industryTypeColor: values?.industryTypeColor || "#0032cb",
    industryTypeLogo: record?.industryTypeLogo
      ? record?.industryTypeLogo
      : randName,
    id: record?.id,
    isHub: values?.isHub,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addAndUpdateIndustryTypeAPI = async (values, randName, record) => {
  try {
    const requestBody = buildAddAndUpdateRequestBody(values, randName, record);

    const decryptedResponse = await apiCall(
      "industry/addIndustryType",
      requestBody,
      "post"
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

const buildFetchLogIndustryTypeRequestBody = (page, industryTypeId) => {
  return {
    industryTypeId: industryTypeId,
    pageNo: page,
    expiryTime: getCurrentTimeStemp(),
  };
};

export const fetchLogIndustryTypeData = async (page, industryTypeId) => {
  try {
    const requestBody = buildFetchLogIndustryTypeRequestBody(
      page,
      industryTypeId
    );
    const decryptedResponse = await apiCall(
      "industry/getIndustryTypeLog",
      requestBody
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

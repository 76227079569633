import { Space, Button, Table, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";
import Add from "./add";
import { S3_URL } from "../../../constant";
import swal from "sweetalert2";
import {
  fetchCompaniesData,
  deleteCompanyAPI,
} from "../../../KIWE-V1/Services/companyApi";
const CompanyList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAllCompanies = async () => {
    try {
      const companies = await fetchCompaniesData();
      setCompanies(companies);
      setLoading(false);
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllCompanies();
  }, []);
  const handleDeleteCompany = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Company ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteCompanyAsync() {
            try {
              const decryptedResponse = await deleteCompanyAPI(record);
              if (decryptedResponse) {
                setCompanies((prevCompanies) =>
                  prevCompanies.filter((company) => company.id !== record.id)
                );
                message.success("Company deleted successfully!!");
              }
            } catch (error) {
              message.error(error.message);
            }
          }
          deleteCompanyAsync();
        }
      });
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: true,
    },
    {
      title: "Company Type",
      dataIndex: "type",
      key: "type",

      ellipsis: true,
    },
    {
      title: "Company Logo",
      dataIndex: "companyLogo",
      key: "companyLogo",
      render: (text, row) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            {console.log(S3_URL + "companyImages/" + row.logo)}
            <img
              src={S3_URL + "companyImages/" + row.logo}
              key={row.logo}
              style={{ width: "20%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="danger"
              className="delete"
              onClick={() => handleDeleteCompany(record)}
            >
              Delete
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Companies</h2>

          <Button
            type="primary"
            size="middle"
            onClick={() => {
              showModal();
            }}
          >
            ADD
          </Button>

          <Add
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
            companies={companies}
            setCompanies={setCompanies}
          />
        </Space>
        <div className="container">
          {companies?.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={companies}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
            />
          ) : (
            <div className="messageNoData">No Company Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default CompanyList;

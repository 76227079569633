import React from "react";
import { Modal } from "antd";

const PolicyDetails = ({ isModalVisible, setIsModalVisible, policyString }) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={"Policy Details"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <div dangerouslySetInnerHTML={{ __html: policyString }} />
      </Modal>
    </>
  );
};

export default PolicyDetails;


import axios from "axios";
import { API_BASE_URL } from "./../constant";
import {
  convertEncryptedToPlantext,
  // convertPlantextToEncrypted,
  // getCurrentTimeStemp,
} from "./Helper";
import { message } from "antd";
class UploadHub {
  static upload(file, id, setLoading, handleCancel) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileUpload", file);
    debugger;
    for (let i of formData.entries()) {
      console.log(i[0], i[1]);
    }
    return new Promise(async (resolve, reject) => {
      const errorHandler = (fn) => {
        return fn.then((data) => [null, data]).catch((err) => [err]);
      };
      const uploadResult = async () => {
        let err, result;

        [err, result] = await errorHandler(
          axios
            .post(API_BASE_URL + `hubs/upload/${id}`, formData)
            .then(function (response) {
              debugger;
              const res = convertEncryptedToPlantext(response?.data?.data);

              setLoading(false);
              message.success(res.message);
              handleCancel();
              setTimeout(function () {
                window.location.reload();
              }, 100);

              console.log(response, res);
            })
            .catch(function (error) {
              debugger;
              setLoading(false);
              message.error("Something went wrong");
              console.log(error.response);
            })
        );

        if (err) reject(err);
        resolve({
          result: result,
        });
      };
      uploadResult();
    });
  }
}
export default UploadHub;

import { Input } from 'antd';
const InputSearch = ({ onSearch, searchTextt }) => {
  const { Search } = Input;

  return (
    <>
      <Search
        placeholder='Search...'
        className='btn-search'
        onSearch={onSearch}
        defaultValue={searchTextt ? searchTextt : ''}
      />
    </>
  );
};

export default InputSearch;

import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";
import classes from "./BaseInsightsStyles.module.css";
import { Select } from "antd";

import { kFilter } from "../../shared/images";
const MultiLine = () => {
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [viewItem, setViewItem] = useState("");
  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/e00d52f4-2fa6-47ee-a0d7-105dd95bde20.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  const BlueColor = "#0032CB";
  const GreenColor = "#33B978";
  const config = {
    data,
    xField: "year",
    yField: "gdp",
    seriesField: "name",
    yAxis: {
      label: {
        formatter: (v) => `4K`,
      },
    },
    colorField: "name", // or seriesField in some cases
    color: ({ name }) => {
      if (name === "United States") {
        return BlueColor;
      } else if (name === "China") {
        return GreenColor;
      }
      return "rgba(0,0,0,0)";
    },
    legend: false,
    smooth: true,
    autoFit: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  const showMenu = () => {
    setView(!view);
  };
  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (!document.getElementById("menu_items").contains(e.target)) {
        setView(false);
      }
    });
  }, []);
  console.log(viewItem, view);
  return (
    <div className={classes.MultiLineContainer}>
      <div className={classes.MultiLineContent}>
        <div className={classes.WraperLeft}>
          <div className={classes.Header}>
            <span>Total Amount Of Sales</span>
          </div>
          <div className={`${classes.Content} ${classes.BaseBetween}`}>
            {" "}
            <span style={{ marginTop: ".5rem" }}>This Month</span>{" "}
            <span style={{ marginTop: ".5rem" }}>Last Month</span>{" "}
          </div>
          <div className={`${classes.Numbers} ${classes.BaseBetween}`}>
            <span style={{ color: "black", marginTop: ".5rem" }}>$1200</span>{" "}
            <span style={{ color: "black", marginTop: ".5rem" }}>$3450</span>
          </div>
        </div>
        <div className={`${classes.WrapeRight} ${classes.BaseBetween}`}>
          <span
            style={{ backgroundColor: `${GreenColor}` }}
            className={classes.circle}
          ></span>

          <span>Sales</span>

          <span
            style={{ backgroundColor: `${BlueColor}` }}
            className={classes.circle}
          ></span>

          <span>kiwe Commission</span>
        </div>
        <div id={"menu_items"} onClick={showMenu} className={classes.filter}>
          <img src={kFilter} alt="logo" />
          <span>Filter</span>
          {view && (
            <div className={classes.menu}>
              <span onClick={() => setViewItem("Daily")}>Daily</span>
              <span onClick={() => setViewItem("Weekly")}>Weekly</span>
              <span onClick={() => setViewItem("Monthly")}>Monthly</span>
            </div>
          )}
        </div>
      </div>
      <Line {...config} />
    </div>
  );
};

export default MultiLine;

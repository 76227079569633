import { Table, Avatar, message, Collapse } from "antd";

import { useState, useEffect } from "react";
import { fetchSubAccountsDataApiCall } from "../../../KIWE-V2/Services/userAPI";
const SubAccount = ({ record }) => {
  const { Panel } = Collapse;
  const [totalPages, setTotalPages] = useState(1);
  const [subAccounts, setSubAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const fetchSubAccount = async (page, record) => {
    setLoading(true);
    try {
      const api_res = await fetchSubAccountsDataApiCall(page, record);
      setLoading(false);
      setSubAccounts(api_res?.data);
      setTotalPages(api_res?.totalRecords);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    if (page) {
      fetchSubAccount(page, record);
    } else {
      fetchSubAccount(1, record);
    }
  }, [page, record]);
  const subAccountsTableColumns = [
    {
      title: "Profile Picture",
      dataIndex: "info",
      key: "info",

      render: (txt, record) => (
        <>
          <Avatar src={record?.profilePicture} size={60} />
        </>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (txt, record) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: (txt, record) => <>{txt ? txt : "-"}</>,
    },

    {
      title: "User Name",
      dataIndex: "username",
      key: "username",

      render: (txt, record) => <div>{txt ? txt : "-"}</div>,
    },
  ];

  return (
    <>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="View Sub Accounts" key="1">
          <div className="container">
            {subAccounts?.length > 0 ? (
              <Table
                className="table2"
                columns={subAccountsTableColumns}
                dataSource={subAccounts}
                size="large"
                scroll={{ x: 900 }}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  total: totalPages,
                  current: +page ? +page : 1,
                  onChange: (page) => {
                    setPage(page);
                  },
                }}
              />
            ) : (
              <div className="messageNoData" style={{ width: "24%" }}>
                No Sub Accounts Records Found.
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default SubAccount;

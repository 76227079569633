import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";

const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let response;
    if (Object.keys(requestData).length !== 0) {
      response = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      response = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(response?.data?.data);
    }
    return response;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
export const fetchMerchantsCashbacksDataAndDecryptResponse = async (
  levelId
) => {
  try {
    const decryptedResponse = await apiCall(
      `v2/admin/merchant/reward?levelId=${levelId}`,
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

import { Button, Form, message, Modal, Input, Switch } from "antd";
import { useState, useEffect } from "react";
import {
  addFAQTopicApi,
  updateFAQTopicApi,
} from "../../../KIWE-V2/Services/FAQTopicApi";
const Add = ({ setIsModalVisible, isModalVisible, setTopics, record }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleAddandUpdateFAQTopic = (values) => {
    if (record) {
      handleUpdateFAQTopic(values);
    } else {
      handleAddFAQTopic(values);
    }
  };
  const handleAddFAQTopic = async (values) => {
    try {
      setLoading(true);

      const apiResponse = await addFAQTopicApi(values);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "FAQ Topic Added Succssfully!",
        };
        message.success(configSuccess);
        const newData = { ...apiResponse.data };
        setTopics((prevData) => [...prevData, newData]);
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleUpdateFAQTopic = async (values) => {
    try {
      setLoading(true);

      const apiResponse = await updateFAQTopicApi(values, record?.id);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "FAQ Topic Updated Succssfully!",
        };
        message.success(configSuccess);
        const updatedFAQTopic = { ...apiResponse.data };
        setTopics((prevFAQTopics) => {
          const updatedFAQTopics = prevFAQTopics.map((topic) => {
            if (topic.id === updatedFAQTopic.id) {
              return updatedFAQTopic;
            }
            return topic;
          });
          return updatedFAQTopics;
        });
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (record && isModalVisible) {
      form.setFieldsValue({
        title: record?.title,
        visible: record?.visible,
      });
    } else {
      form.resetFields();
    }
  }, [isModalVisible, record, form]);
  const validateWhiteSpace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter a valid title topic"));
    }
    return Promise.resolve();
  };
  return (
    <>
      <Modal
        title={record ? "Update FAQ Topic" : "Add FAQ Topic"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddandUpdateFAQTopic}
          autoComplete="off"
          initialValues={
            record
              ? {
                  title: record?.title,
                  visible: record?.visible,
                }
              : null
          }
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input your Title Topic!",
              },
              { validator: validateWhiteSpace },
            ]}
          >
            <Input
              aria-label="Title"
              placeholder="Enter Title Topic!"
              className="input-border"
            />
          </Form.Item>
          <Form.Item
            name="visible"
            label="Activate FAQ Topic"
            valuePropName="checked"
          >
            <Switch size="middle" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchCompaniesData = async () => {
  try {
    const decryptedResponse = await apiCall("company/fetchCompany", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
const buildAddAndEditCompanyRequestBody = (values, randName, record) => {
  return {
    type: values.type,
    name: values.name,
    logo: randName,
    id: record?.id,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addAndEditCompanyAPI = async (values, randName, record) => {
  try {
    const requestBody = buildAddAndEditCompanyRequestBody(
      values,
      randName,
      record
    );

    const decryptedResponse = await apiCall("company/addCompany", requestBody);

    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const deleteCompanyAPI = async (record) => {
  try {
    const decryptedResponse = await apiCall(
      "company/deleteCompanyById/" + record?.id,
      {},
      "delete"
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

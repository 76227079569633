import { Button, Form, message, Modal, Input, Switch } from "antd";
import { useState, useEffect } from "react";
import { addFAQApi, editFAQApi } from "../../../KIWE-V2/Services/FAQsApi";
const Add = ({ setIsModalVisible, isModalVisible, setFAQs, FAQ, record }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState("");
  const [answerTouched, setAnswerTouched] = useState(false);
  const [answerContainsOnlyWhiteSpace, setAnswerContainsOnlyWhiteSpace] =
    useState(false);
  const [answerRequiredError, setAnswerRequiredError] = useState(false);
  const [answerWhiteSpaceError, setAnswerWhiteSpaceError] = useState(false);

  const handleAddandUpdateFAQ = async (values) => {
    if (record) {
      handleUpdateFAQ(values);
    } else {
      handleAddFAQ(values);
    }
  };
  const handleAddFAQ = async (values) => {
    try {
      setLoading(true);
      debugger;
      if (!answer.trim()) {
        debugger;
        setAnswerTouched(true);
        setAnswerRequiredError(true);
        return;
      }
      const apiValues = {
        ...values,
        answer,
      };
      const apiResponse = await addFAQApi(apiValues, FAQ?.id);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "FAQ Added Succssfully!",
        };
        message.success(configSuccess);
        const newData = { ...apiResponse.data };
        setFAQs((prevData) => [...prevData, newData]);
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleUpdateFAQ = async (values) => {
    try {
      setLoading(true);

      const apiValues = {
        ...values,
        answer,
      };
      const apiResponse = await editFAQApi(apiValues, record?.id);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "FAQ Updated Succssfully!",
        };
        message.success(configSuccess);
        const updatedFAQ = { ...apiResponse.data };
        setFAQs((prevFAQs) => {
          const updatedfAQs = prevFAQs.map((FAQsTopic) => {
            if (FAQsTopic.id === updatedFAQ.id) {
              return updatedFAQ;
            }
            return FAQsTopic;
          });
          return updatedfAQs;
        });
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setAnswer("");
  };
  // const handleAnswerChange = (e) => {
  //   setAnswer(e.target.value);
  //   setAnswerTouched(true);
  //   setAnswerContainsOnlyWhiteSpace(e.target.value.trim() === "");
  // };
  const handleAnswerChange = (e) => {
    const value = e.target.value;
    setAnswer(value);
    setAnswerTouched(true);
    setAnswerRequiredError(false);

    if (value !== value.trim()) {
      // setAnswerWhiteSpaceError(true);
      const configError = {
        className: "messageError",
        content: "Answer cannot consist of only whitespace characters!",
      };
      message.error(configError);
    } else if (value.trim() === "") {
      setAnswerRequiredError(true);
    } else {
      setAnswerWhiteSpaceError(false);
    }
  };
  useEffect(() => {
    if (record && isModalVisible) {
      form.setFieldsValue({
        question: record?.question,
        visible: record?.visible,
      });
      setAnswer(record?.answer);
      setAnswerContainsOnlyWhiteSpace(record?.answer.trim() === "");
    } else {
      form.resetFields();
      setAnswer("");
      setAnswerContainsOnlyWhiteSpace(false);
      setAnswerRequiredError(false);
    }
  }, [isModalVisible, record, form]);
  const validateWhiteSpace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter a valid question"));
    }
    return Promise.resolve();
  };
  return (
    <>
      <Modal
        title={record ? "Edit FAQ" : "Add FAQ"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddandUpdateFAQ}
          autoComplete="off"
        >
          <Form.Item
            name="question"
            label="Question"
            rules={[
              {
                required: true,
                message: "Please input your Question!",
              },
              { validator: validateWhiteSpace },
            ]}
          >
            <Input
              aria-label="Question"
              placeholder="Enter Question!"
              className="input-border"
            />
          </Form.Item>
          <Form.Item
            name="answer"
            label="Answer"
            rules={[
              {
                required: answer.trim() === "",
                message: "Please input your Answer!",
              },
            ]}
          >
            {" "}
            <TextArea
              aria-label="Answer"
              placeholder="Enter Answer"
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={handleAnswerChange}
              value={answer}
            />
          </Form.Item>
          <Form.Item
            name="visible"
            label="Activate FAQ"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please input your activate FAQ!",
              },
            ]}
          >
            <Switch size="middle" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import Add from "./Add";
import { getWithdrawalTypeApi } from "../../../KIWE-V1/Services/withdrawalTypeApi";
const WithdrawalType = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [withdrawalTypeData, setWithdrawalTypeData] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleGetWithdrawalType = async () => {
    try {
      const apiResponse = await getWithdrawalTypeApi();
      setWithdrawalTypeData(apiResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    handleGetWithdrawalType();
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,

      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Withdrawal Type</h2>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal();
                setRecord(null);
              }}
            >
              ADD
            </Button>

            <Add
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
              setWithdrawalTypeData={setWithdrawalTypeData}
              withdrawalTypeData={withdrawalTypeData}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={withdrawalTypeData}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default WithdrawalType;

import { Table } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Totop from "../../shared/TopUp";

const MerchantCashback = () => {
  const location = useLocation();
  const { merchantCashback } = location.state || {};

  const [merchantsCashback, setMerchantsCashback] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setMerchantsCashback(merchantCashback?.merchantRewards);
  }, [merchantCashback?.merchantRewards]);
  const columns = [
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (txt, record) => (
        <>
          <span>{new Date(txt)?.toLocaleDateString()}</span>
        </>
      ),
      sorter: (a, b) => new Date(a?.expiryDate) - new Date(b?.expiryDate),
    },
    {
      title: "Min Amount Spent",
      dataIndex: "minAmountToSpend",
      key: "minAmountToSpend",
      render: (txt, record) => (
        <>
          <span>
            {typeof record?.minAmountToSpend === "number"
              ? record?.minAmountToSpend
              : "-"}
          </span>
        </>
      ),
      sorter: (a, b) => a?.minAmountToSpend - b?.minAmountToSpend,
    },
    {
      title: "Max Cashback Amount",
      dataIndex: "maxCashbackAmount",
      key: "maxCashbackAmount",
      render: (txt, record) => (
        <>
          <span>
            {typeof record?.maxCashbackAmount === "number"
              ? record?.maxCashbackAmount
              : "-"}
          </span>
        </>
      ),
      sorter: (a, b) => a?.maxCashbackAmount - b?.maxCashbackAmount,
    },
    {
      title: "Cashback %",
      dataIndex: "cashbackPercentage",
      key: "cashbackPercentage",
      render: (txt, record) => (
        <>
          <span>
            {typeof record?.cashbackPercentage === "number"
              ? record?.cashbackPercentage
              : "-"}
          </span>
        </>
      ),
      sorter: (a, b) => a?.cashbackPercentage - b?.cashbackPercentage,
    },
    {
      title: "Locked",
      dataIndex: "active",
      key: "active",
      render: (txt, record) => (
        <>
          <span>{record?.active ? "UNLOCKED" : "LOCKED"}</span>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Merchant Cashback</h2>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={merchantsCashback}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>

        <Totop />
      </div>
    </>
  );
};

export default MerchantCashback;

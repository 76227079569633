import {getCurrentTimeStemp} from "../../library/Helper";
import { apiCall } from "./userAPI";
const buildEditBankRequestBody=(values,id,state)=>{
    return{
      bankName: values?.bankName,
      accountHolderName: values?.accountHolderName,
      accountNo: values?.accountNo,
      swiftCode: values?.swiftCode,
      branchName: values?.branchName,
      ibanNumber: values?.ibanNumber,
      accountId: state?.account_detail?.accountId,
      userId: id,
      expiryTime: getCurrentTimeStemp(),
    }
  }
  export const editBankDetails = async (values,id,state) => {
    try {
  
      const data =buildEditBankRequestBody(values,id,state);
      const response = await apiCall("account/addUpdate", data);
      return response;
  
    } catch (error) {
      throw error;
    }
  }
import { combineReducers } from 'redux';
// import registerReducer from './registeration/reducer'
import companyReducer from './company/reducer';
import serviceTypeReducer from './service-type/reducer';
import sellingTypeReducer from './selling-type/reducer';

import industryReducer from './Industry/reducer';
import industryTypeReducer from './Industry-type/reducer';
export default combineReducers({
  // registerReducer,
  companyReducer,
  serviceTypeReducer,
  industryReducer,
  industryTypeReducer,
  sellingTypeReducer,
});

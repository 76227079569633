import { Table, Button, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import Totop from "../../shared/TopUp";
import { useState, useEffect } from "react";
import { notification_Business_picture_path } from "../../../constant";
import NotificationDetails from "./notificationDetails";
import moment from "moment";
import { fetchBusinessNotificationData } from "../../../KIWE-V1/Services/businessNotification";
const NotificationBusiness = () => {
  const [businessNotifications, setBusinessNotifications] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [isModalVisible1, setIsModalVisibleD] = useState(false);

  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState();
  const fetchBusinessNotification = async () => {
    try {
      const businessNotifcations = await fetchBusinessNotificationData();
      setBusinessNotifications(businessNotifcations?.data);
      setTotalPages(businessNotifcations?.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  useEffect(() => {
    fetchBusinessNotification();
  }, []);

  const handleCancel = () => {
    setIsModalVisibleD(false);
  };
  const showModalDetails = (record) => {
    setIsModalVisibleD(true);
    setRecord(record);
  };
  const columns = [
    {
      title: "Notification ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: "Notification Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
    },
    {
      title: "Notification Body",
      dataIndex: "body",
      key: "body",
      sorter: (a, b) => a?.body?.localeCompare(b?.body),
    },
    {
      title: "Notification Image",
      dataIndex: "imagePath",
      key: "imagePath",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={notification_Business_picture_path + record?.imagePath}
              style={{ width: "25%", borderRadius: "25px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (src, record) => (
        <>
          <Button
            className="btn-icon"
            size="middle"
            type="primary"
            shape="round"
            icon={<EyeOutlined />}
            onClick={() => showModalDetails(record)}
          >
            Details
          </Button>
        </>
      ),
    },
    {
      title: "Date And Time",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text, record) => (
        <>{moment(record?.createdTimeStamp).format("D MMM, yyyy h:mm a")}</>
      ),
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Business Requests</h2>
        {totalPages !== 0 ? (
          <div className="container">
            <Table
              className="table2"
              columns={columns}
              dataSource={businessNotifications}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
            />

            <NotificationDetails
              handleCancel={handleCancel}
              isModalVisible={isModalVisible1}
              record={record}
            />
          </div>
        ) : (
          <h4 className="noNotify">
            You Donot Get Any
            <br /> Notifications Yet
          </h4>
        )}
        <Totop />
      </div>
    </>
  );
};

export default NotificationBusiness;

import { buildRequestBody, apiCall } from "./userAPI";

export const fetchEventTransactionAndDecryptResponse = async (
  walletId,
  searchText,
  page
) => {
  try {
    const addtionalRequestBodyData = {
      walletId,
    };
    const requestData = buildRequestBody(
      addtionalRequestBodyData,
      searchText,
      page
    );
    const decryptedResponse = await apiCall(`wallet/transaction`, requestData);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchUserSummeryTransactionsAndDecryptResponse = async (
  place,
  userId,
  searchText,
  page
) => {
  try {
    const addtionalRequestBodyData = {
      place,
      userId,
    };
    const requestData = buildRequestBody(
      addtionalRequestBodyData,
      searchText,
      page
    );
    const decryptedResponse = await apiCall(
      `wallet/transactionSummary`,
      requestData
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchUserTransactionAndDecryptResponse = async (
  walletId,
  searchText,
  page
) => {
  try {
    const addtionalRequestBodyData = {
      walletId: walletId,
    };
    const requestBody = buildRequestBody(
      addtionalRequestBodyData,
      searchText,
      page,
      "id"
    );
    const decryptedResponse = await apiCall("transaction", requestBody);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const exportUserTransactionsAndDecryptResponse = async (
  accountType,
  walletId,
  ownerId,
  startDate,
  endDate
) => {
  try {
    const addtionalRequestBodyData = {
      accountType: accountType,
      walletId: walletId,
      ownerId: ownerId,
      startDate: startDate,
      endDate: endDate,
    };
    const requestBody = buildRequestBody(
      addtionalRequestBodyData,
      "",
      1,
      "transactionId"
    );
    const decryptedResponse = await apiCall("transaction/export", requestBody);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const fetchAllTransactionAndDecryptResponse = async (
  searchText,
  page
) => {
  try {
    const addtionalRequestBodyData = {
      accountType: "business",
    };
    const requestData = buildRequestBody(
      addtionalRequestBodyData,
      searchText,
      page,
      "id"
    );
    const decryptedResponse = await apiCall(
      "admin/v1/allTransactions",
      requestData
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

const generateExportTransactionUrl = () => {
  return "transaction/export_all_transactions";
};

export const exportTransactionsByDateOrSearchTextFromInputAndDecryptResponse =
  async (searchTextFromInput, startDate, endDate) => {
    try {
      const url = generateExportTransactionUrl();
      const addtionalRequestBodyData = {
        accountType: "business",
      };
      if (startDate && endDate) {
        addtionalRequestBodyData.startDate = startDate;
        addtionalRequestBodyData.endDate = endDate;
      }
      const requestBody = buildRequestBody(
        addtionalRequestBodyData,
        searchTextFromInput,
        1,
        "transactionId"
      );
      const decryptedResponse = await apiCall(url, requestBody);
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  };

export const exportTransactionsByTransactionsIdsAndDecryptResponse = async (
  selectedRowKeys
) => {
  try {
    const url = generateExportTransactionUrl();
    const requestBody = {
      accountType: "business",
      transactionIds: selectedRowKeys,
    };
    const decryptedResponse = await apiCall(url, requestBody);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

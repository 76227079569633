import { Input, Button, Form, message, Modal } from "antd";
import DragImage from "../form/DragImage";
import { useEffect, useState } from "react";

import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { INDUSTRY_ALBUM_NAME } from "../../../constant";
import { addAndUpdateIndustryAPI } from "../../../KIWE-V1/Services/industryAPI.js";

const Add = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValues] = useState(
    record?.id
      ? {
          industryName: record?.industryName,
          industryNameAr: record?.industryNameAr,
          industryLogo: record?.industryLogo,
        }
      : null
  );
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      console.log(length);
      if (length !== 0) {
        setInitialValues({
          industryName: record?.industryName,
          industryNameAr: record?.industryNameAr,
          industryLogo: record?.industryLogo,
        });
      } else {
        form.setFieldsValue({ industryName: "" });
        form.setFieldsValue({ industryNameAr: "" });
        form.setFieldsValue({ industryLogo: "" });

        setInitialValues({});
      }
    }
  }, [record]);

  const addandUpdateIndustry = async (values) => {
    try {
      setLoading(true);
      const randName = generateRandomFileName(values.industryLogo[0]);
      handleImageUpload(
        values.industryLogo[0].originFileObj,
        randName,
        INDUSTRY_ALBUM_NAME
      );

      const apiResponse = await addAndUpdateIndustryAPI(
        values,
        randName,
        record
      );

      if (apiResponse.status === 200) {
        setLoading(false);
        message.success("Submit success!");

        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);

      message.error(error.message);
    }
  };
  const handleAddandUpdateIndustry = (values) => {
    if (record) {
      addandUpdateIndustry(values);
    } else {
      addandUpdateIndustry(values);
    }
  };

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ industryName: record.industryName });
      form.setFieldsValue({ industryNameAr: record.industryNameAr });

      form.setFieldsValue({ industryLogo: record.industryLogo });
    }
  }, [record, initialValue, form]);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={record?.id ? "Edit Industry" : "Add Industry"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddandUpdateIndustry}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item className="offerImage ">
            <DragImage
              // nam='file'
              nam1="industryLogo"
              normFile={normFile}
              label="Industry Logo"
              rules={[
                { required: true, message: "Please input your Industry Logo!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="industryName"
            label="Industry Name"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Industry Name!" },
            ]}
            className="offer-name industry"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item
            name="industryNameAr"
            label="Industry Name Arabic"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Industry Name Arabic!",
              },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={false}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

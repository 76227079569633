import { Form, Input, Button, Alert } from "antd";
import { convertEncryptedToPlantext } from "./../../../library/Helper";

import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoginLogo } from "../../shared/images";
import avatarMale from "../../../assets/images/img_avatar_male.png";
import avatarMale2 from "../../../assets/images/avatar2_male.png";
import avatarFemale from "../../../assets/images/img_avatar_female.png";
import avatarFemale2 from "../../../assets/images/avatar6_female.png";
import logoLogin from "../../../assets/images/LogoLogin.svg";
import Infooo from "../../../assets/images/Orix-warning.svg";

import avatar1 from "../../../assets/images/avatar1.jfif";
import avatar2 from "../../../assets/images/avatar2.jfif";
import avatar3 from "../../../assets/images/avatar3.jfif";
import avatar4 from "../../../assets/images/avatar4.jfif";
import avatar5 from "../../../assets/images/avatar5.jfif";
import { loginUserAPI } from "../../../KIWE-V1/Services/loginApi";
import { newloginUserAPI } from "../../../KIWE-V2/Services/loginApi.js";

import { CloseOutlined } from "@ant-design/icons";
const Login = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { type } = location?.state || { type: "oldCMS" };
  var avatarList = [avatar1, avatar2, avatar3, avatar4, avatar5];
  var randomAvatarList =
    avatarList[Math.floor(Math.random() * avatarList.length)];
  useEffect(() => {
    localStorage.setItem("typeRoleAdmin", type);
  }, []);
  const handleOldLoginUser = async (values) => {
    try {
      setLoading(true);
      const apiResponse = await loginUserAPI(values);
      if (apiResponse.status === 200) {
        setLoading(false);
        let decryptedResponse = convertEncryptedToPlantext(
          apiResponse.data.data
        );
        localStorage.setItem(
          "accessToken",
          decryptedResponse.authenticationToken
        );
        localStorage.setItem("user_id", decryptedResponse.userId);
        localStorage.setItem("avatarItem", randomAvatarList);
        localStorage.setItem(
          "auth_user_info",
          JSON.stringify(decryptedResponse)
        );
        localStorage.setItem("firstName", decryptedResponse?.firstName);
        localStorage.setItem("lastName", decryptedResponse?.lastName);
        history.push("/dashboard");
      } else {
        setLoading(false);

        setIsValid(true);
      }
    } catch (error) {
      setLoading(false);

      if (error) setIsValid(true);
    }
  };
  const handleNewLoginUser = async (values) => {
    try {
      setLoading(true);
      const apiResponse = await newloginUserAPI(values);
      if (apiResponse.status === 200) {
        setLoading(false);
        let decryptedResponse = convertEncryptedToPlantext(
          apiResponse.data.data
        );
        localStorage.setItem(
          "accessToken",
          decryptedResponse?.authenticationToken
        );
        localStorage.setItem("user_id", decryptedResponse?.id);
        localStorage.setItem("avatarItem", randomAvatarList);
        localStorage.setItem(
          "auth_user_info",
          JSON.stringify(decryptedResponse)
        );
        localStorage.setItem("email", decryptedResponse?.email);
        localStorage.setItem("firstName", decryptedResponse?.firstName);
        localStorage.setItem("lastName", decryptedResponse?.lastName);

        history.push({
          pathname: "/dashboard",
          state: { type: "newCMS" },
        });
      } else {
        setLoading(false);

        setIsValid(true);
      }
    } catch (error) {
      setLoading(false);

      if (error) setIsValid(true);
    }
  };
  const handleSignInUser = (values) => {
    if (type === "oldCMS") {
      handleOldLoginUser(values);
    } else {
      handleNewLoginUser(values);
    }
  };
  return (
    <>
      <div className="login-container">
        <div className="login-container__form">
          <Form
            form={form}
            name="register"
            onFinish={handleSignInUser}
            layout="vertical"
            scrollToFirstError
            className="form"
          >
            <Form.Item name="header">
              <h3>Heyy, Welcome back!</h3>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
                {
                  required: true,
                  message: "Please enter your email!",
                },
              ]}
              hasFeedback
            >
              <Input
                className="email"
                placeholder="Email"
                aria-label="Email"
                style={{ borderRadius: "25px", width: "95%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
              hasFeedback
              className="messageError password"
            >
              <Input.Password
                style={{
                  marginBottom: ".5rem",
                  borderRadius: "25px",
                  width: "95%",
                }}
                aria-label="Password"
                placeholder="Password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </Form.Item>
            {isValid ? (
              <Form.Item>
                <div style={{ color: "#FD5394" }}>
                  {" "}
                  <img className="orix-Warning" src={Infooo} alt="" />
                  <span>Invalid email or password</span>
                </div>
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                style={{ borderRadius: "25PX" }}
                loading={loading}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="login_image">
          <img src={logoLogin} alt="" />
        </div>
      </div>
    </>
  );
};

export default Login;

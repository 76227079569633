import { useState, useEffect } from "react";
import { message, Tag, Collapse, Select, Switch, Button } from "antd";
import { FlagFilled } from "@ant-design/icons";
import {
  fetchCardUserDetailsData,
  updateUser,
} from "../../../KIWE-V2/Services/userAPI.js";
import {
  updateCard,
  addCustomerIdApi,
} from "../../../KIWE-V2/Services/cardApi.js";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import swal from "sweetalert2";
import FreezeModal from "./FreezeModal";
import CardAction from "./logActions.jsx";
import Reasons from "./reason.jsx";
import Transactions from "./transaction.jsx";
import SubAccount from "./subAccount.jsx";
import ParentUser from "./parentUserDetails.jsx";
import Edit from "./edit";
import AddKitNo from "./addKitNo.jsx";
import { useHistory } from "react-router-dom";
const UserDetails = ({ record }) => {
  const { Panel } = Collapse;
  const [cardUserDetails, setCardUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleKitNo, setIsModalVisibleKitNo] = useState(false);
  const history = useHistory();
  const fetchCardUserDetails = async () => {
    try {
      const apiResponse = await fetchCardUserDetailsData(record?.id);
      setCardUserDetails(apiResponse);
    } catch (error) {
      message.error(error?.message);
    }
  };
  useEffect(() => {
    fetchCardUserDetails();
  }, [record?.id]);
  const handleCardUpdate = async (value, record, statusType) => {
    setLoading(true);
    try {
      const response = await updateCard(
        record?.cards[0]?.id,
        statusType,
        value
      );

      const updatedCardData = convertEncryptedToPlantext(
        response.data.data
      ).data;

      setLoading(false);
      const configSuccess = {
        className: "messageSuccess",
        content: `${
          statusType === "cardStatus" ? "Card_Status" : "Bank_KYC_Status"
        } Updated successfully!`,
      };
      message.success(configSuccess);
      const updatedUserDetails = {
        ...cardUserDetails,
        cards: [
          {
            ...cardUserDetails?.cards[0],
            ...updatedCardData,
          },
        ],
      };

      setCardUserDetails(updatedUserDetails);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const handleChangeBankKycStatus = (value, cardUserDetails) => {
    handleCardUpdate(value, cardUserDetails, "bankKycStatus");
  };
  const handleCardStatus = (value, record) => {
    handleCardUpdate(value, record, "cardStatus");
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalKitNo = () => {
    setIsModalVisibleKitNo(true);
  };
  const handleAML = (status, id) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text:
          status === "APPROVED"
            ? "Are you sure want to Approve this AML?"
            : "Are you sure want to Reject this AML?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          const userData = { amlVerificationStatus: status };
          async function updateUserAsync() {
            try {
              const updated = await updateUser(id, userData);
              const decryptedResponse = convertEncryptedToPlantext(
                updated.data.data
              ).data;

              setCardUserDetails((prevUserDetails) => {
                return { ...prevUserDetails, ...decryptedResponse };
              });
            } catch (error) {
              message.error(error?.message);
            }
          }
          updateUserAsync();
        }
      });
  };

  const handleAddCustomerId = (userId) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to Add customer id this card?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function updateCardAsync() {
            try {
              const apiResponse = await addCustomerIdApi(userId);
              const configSuccess = {
                className: "messageSuccess",
                content: "Customer created successfully.",
              };
              message.success(configSuccess);
              window.location.reload();
            } catch (error) {
              const configError = {
                className: "messageError",
                content: error?.message,
              };
              message.error(configError);
            }
          }
          updateCardAsync();
        }
      });
  };

  return (
    <>
      <div className="container__header userCardDetails">
        <div className="container__header__column1">
          <div className="container__header__column1__row1 rowAlign">
            <span className="container__header__span1 spanColor"></span>
            <span>{cardUserDetails?.username} </span>
          </div>
          <div className="container__header__column1__row2 rowAlign">
            <span className="container__header__span1 spanColor"></span>
            <span>
              {cardUserDetails?.firstName + " " + cardUserDetails?.lastName}
            </span>
          </div>
          {cardUserDetails?.mobile ? (
            <div className="container__header__column1__row3 rowAlign">
              <span className="container__header__span1 spanColor"></span>
              <span>{cardUserDetails?.mobile}</span>
            </div>
          ) : (
            ""
          )}
          {cardUserDetails?.email ? (
            <div className="container__header__column1__row4 rowAlign">
              <span className="container__header__span1 spanColor"></span>
              <span>{cardUserDetails?.email}</span>
            </div>
          ) : (
            ""
          )}
          <div className="container__header__column1__row5 rowAlign">
            <span className="container__header__span1"></span>
            <span>
              {" "}
              {cardUserDetails?.birthDate
                ? new Date(cardUserDetails?.birthDate).toLocaleDateString()
                : "-"}
            </span>
          </div>
          <div className="container__header__column1__row6 rowAlign">
            <span className="container__header__span1 spanColor"></span>
            <span>
              {cardUserDetails?.flagged === false ? (
                <FlagFilled style={{ cursor: "pointer" }} />
              ) : (
                <FlagFilled style={{ color: "#FECD2F", cursor: "pointer" }} />
              )}
            </span>
          </div>
        </div>
        <div className="container__header__column2">
          <div
            className="container__header__column2__row1 rowAlign"
            style={{
              display: "flex",
            }}
          >
            <span className="container__header__span1 spanColor">
              EKYC Status:{" "}
            </span>
            <span>
              {" "}
              {cardUserDetails?.ekycStatus === "PENDING" ? (
                <div className="custom-tag onboarding-status-pending">
                  Pending
                </div>
              ) : cardUserDetails?.ekycStatus === "REJECTED" ? (
                <div
                  color="red"
                  className="custom-tag onboarding-status-rejected"
                >
                  Rejected
                </div>
              ) : (
                <div
                  color="green"
                  className="custom-tag onboarding-status-approved"
                >
                  Approved
                </div>
              )}
            </span>
          </div>
          <div
            className="container__header__column2__row2 rowAlign"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="container__header__span1 spanColor">
              Card Request Status:{" "}
            </span>
            <span>
              {" "}
              {cardUserDetails?.cards[0]?.cardRequestStatus === "PENDING" ? (
                <Tag
                  color="orange"
                  className="custom-tag onboarding-status-pending"
                >
                  Pending
                </Tag>
              ) : cardUserDetails?.cards[0]?.cardRequestStatus ===
                "REQUESTED" ? (
                <Tag
                  color="blue"
                  style={{ padding: "0.1rem 2.4rem" }}
                  className="custom-tag"
                >
                  Requested
                </Tag>
              ) : (
                <Tag color="green" className="custom-tag">
                  Issued
                </Tag>
              )}
            </span>
          </div>
          <div className="container__header__column2__row3 rowAlign">
            <span className="container__header__span1 spanColor">
              Card Request Date:{" "}
            </span>
            <span>
              {cardUserDetails?.cards[0]?.createdTimeStamp === null
                ? "-"
                : new Date(
                    cardUserDetails?.cards[0]?.createdTimeStamp
                  ).toLocaleDateString()}
            </span>
          </div>
          <div className="container__header__column2__row4 rowAlign">
            <span className="container__header__span1 spanColor">
              Pick Up Date:{" "}
            </span>
            <span>
              {" "}
              {cardUserDetails?.cards[0]?.cardPickupDate
                ? new Date(
                    cardUserDetails?.cards[0]?.cardPickupDate
                  ).toLocaleDateString()
                : "-"}
            </span>
          </div>
          <div className="container__header__column2__row5 rowAlign">
            <span className="container__header__span1"></span>
            <span></span>
          </div>
        </div>
        <div className="container__header__column3">
          <div
            className="container__header__column3__row1 rowAlign"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="container__header__span1 spanColor">
              Card Status:{" "}
            </span>
            <span>
              {" "}
              {cardUserDetails?.cards[0]?.cardStatus &&
              cardUserDetails?.cards[0]?.cardStatus === "PENDING" ? (
                <div className="custom-tag onboarding-status-pending">
                  Pending
                </div>
              ) : cardUserDetails?.cards[0]?.cardStatus === "ACTIVE" ? (
                <div className="custom-tag onboarding-status-approved">
                  Active
                </div>
              ) : cardUserDetails?.cards[0]?.cardStatus === "FROZEN" ? (
                <Tag
                  color="blue"
                  style={{ padding: "0.1rem 2.4rem" }}
                  className="custom-tag"
                >
                  Frozen
                </Tag>
              ) : cardUserDetails?.cards[0]?.cardStatus === "DEACTIVATED" ? (
                <div className="custom-tag onboarding-status-rejected">
                  Deactivated
                </div>
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="container__header__column3__row2 rowAlign">
            <span className="container__header__span1 spanColor">
              Card Expiry Date:{" "}
            </span>
            <span>
              {cardUserDetails?.cards[0]?.cardDetails?.expiryDate
                ? new Date(
                    cardUserDetails?.cards[0]?.cardDetails?.expiryDate
                  ).toLocaleDateString()
                : "-"}
            </span>
          </div>
          <div className="container__header__column3__row3 rowAlign">
            <span className="container__header__span1 spanColor">
              Account Number:{" "}
            </span>
            <span>
              {cardUserDetails?.paytabsCustomerId ? (
                cardUserDetails?.paytabsCustomerId
              ) : cardUserDetails?.ekycStatus === "APPROVED" ? (
                <Button
                  type="primary"
                  data-testid="btn"
                  onClick={() => handleAddCustomerId(record?.id)}
                >
                  Add customer
                </Button>
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="container__header__column3__row4 rowAlign">
            <span className="container__header__span1 spanColor">
              Account Type:{" "}
            </span>
            <span>
              {cardUserDetails?.type === undefined
                ? "-"
                : cardUserDetails?.type}
            </span>
          </div>
          <div className="container__header__column3__row5 rowAlign">
            <span className="container__header__span1"></span>
            <span></span>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="KitNo, KYC & AML Actions" key="1">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "80%",
              margin: "0 auto",
            }}
          >
            {cardUserDetails?.paytabsCustomerId ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <h3>Link customer to a card:</h3>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    showModalKitNo();
                  }}
                >
                  Add KitNo
                </Button>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <h3>bank Kyc Status:</h3>
              <Select
                loading={loading}
                defaultValue={
                  cardUserDetails?.cards[0]?.bankKycStatus === "PENDING"
                    ? "Light"
                    : cardUserDetails?.cards[0]?.bankKycStatus
                }
                style={{ width: 182 }}
                onChange={(value) =>
                  handleChangeBankKycStatus(value, cardUserDetails)
                }
                options={[
                  { value: "LIGHT", label: "Light", disabled: true },
                  { value: "FULLY", label: "Fully" },
                  { value: "RETURN", label: "Return For Collection" },
                ]}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>AML Status:</h3>
              {
                //   {cardUserDetails?.amlVerificationStatus === "APPROVED" ? (
                //     <Switch
                //       checked
                //       checkedChildren="Approved"
                //       unCheckedChildren="Rejected"
                //       disabled
                //       style={{
                //         borderRadius: "15px",
                //         color: "#20ce24",
                //         background: "#ebffeb",
                //         height: "1.8rem",
                //         width: "6.5rem",
                //         fontWeight: "bold",
                //       }}
                //     />
                //   ) : cardUserDetails?.amlVerificationStatus === "REJECTED" ? (
                //     <Switch
                //       checked={false}
                //       checkedChildren="Approved"
                //       unCheckedChildren="Rejected"
                //       disabled
                //       style={{
                //         borderRadius: "25px",
                //         color: "#fd2a2f",
                //         background: "rgb(254 246 246)",
                //         height: "1.5rem",
                //         width: "6.5rem",
                //         fontWeight: "bold",
                //       }}
                //     />
                //   ) : cardUserDetails?.ekycStatus === "APPROVED" ||
                //     cardUserDetails?.amlVerificationStatus === "PENDING" ? (
                //     <div style={{ display: "flex", alignItems: "center" }}>
                //       <Switch
                //         checkedChildren="Approve"
                //         unCheckedChildren="Reject"
                //         //   defaultChecked={record?.ekycStatus === "APPROVED"}
                //         onChange={(checked) =>
                //           handleAML(checked ? "APPROVED" : "REJECTED", record?.id)
                //         }
                //       />
                //     </div>
                //   ) : (
                //     <div style={{ display: "flex", alignItems: "center" }}>
                //       <Switch
                //         checkedChildren="Approve"
                //         unCheckedChildren="Reject"
                //         defaultChecked={false}
                //         disabled
                //       />
                //     </div>
                //   )}
              }
              {cardUserDetails?.amlVerificationStatus === "APPROVED" ? (
                <>
                  <button
                    style={{
                      border: "1px solid transparent",
                      borderRadius: "15px",
                      color: "#20ce24",
                      background: "#ebffeb",
                      height: "2rem",
                      width: "6.5rem",

                      fontWeight: "bold",
                    }}
                  >
                    {cardUserDetails?.amlVerificationStatus
                      .charAt(0)
                      .toUpperCase() +
                      cardUserDetails?.amlVerificationStatus.slice(1)}
                  </button>
                </>
              ) : cardUserDetails?.amlVerificationStatus === "REJECTED" ? (
                <>
                  <button
                    style={{
                      border: "1px solid transparent",
                      borderRadius: "25px",
                      color: "#fd2a2f",
                      background: "rgb(254 246 246)",

                      height: "2rem",
                      width: " 6.5rem",

                      fontWeight: "bold",
                    }}
                  >
                    {cardUserDetails?.amlVerificationStatus
                      .charAt(0)
                      .toUpperCase() +
                      cardUserDetails?.amlVerificationStatus.slice(1)}
                  </button>
                </>
              ) : cardUserDetails?.ekycStatus === "APPROVED" &&
                cardUserDetails?.amlVerificationStatus === "PENDING" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    type="primary"
                    className="btn paid approve"
                    size="middle"
                    onClick={() => handleAML("APPROVED", record?.id)}
                  >
                    Approve
                  </Button>
                  <Button
                    type="danger"
                    size="small"
                    className="declined rejectBtn"
                    onClick={() => handleAML("REJECTED", record?.id)}
                  >
                    Reject
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    type="primary"
                    className="btn paid approve"
                    size="middle"
                    onClick={() => handleAML("APPROVED", record?.id)}
                    disabled={true}
                  >
                    Approve
                  </Button>
                  <Button
                    type="danger"
                    size="small"
                    className="declined rejectBtn"
                    onClick={() => handleAML("REJECTED", record?.id)}
                    disabled={true}
                  >
                    Reject
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Panel>
      </Collapse>

      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="Actions" key="1">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <h3>Card Status:</h3>
            {cardUserDetails?.cards[0]?.cardStatus === "ACTIVE" ? (
              <div>
                <Button
                  type="primary"
                  className="btn paid"
                  size="middle"
                  style={{ marginRight: ".5rem" }}
                  onClick={() => {
                    showModal();
                  }}
                >
                  Freeze
                </Button>
                <Button
                  type="danger"
                  size="small"
                  className="declined"
                  style={{ height: "2rem" }}
                  onClick={() =>
                    handleCardStatus("DEACTIVATED", cardUserDetails)
                  }
                >
                  Deactivated
                </Button>
              </div>
            ) : cardUserDetails?.cards[0]?.cardStatus === "FROZEN" ? (
              <>
                <div>
                  <Button
                    type="primary"
                    className="btn paid"
                    size="middle"
                    style={{ marginRight: ".5rem" }}
                    onClick={() => handleCardStatus("ACTIVE", cardUserDetails)}
                  >
                    Unfreeze
                  </Button>
                  <Button
                    type="danger"
                    size="small"
                    className="declined"
                    style={{ height: "2rem" }}
                    onClick={() =>
                      handleCardStatus("DEACTIVATED", cardUserDetails)
                    }
                  >
                    Deactivated
                  </Button>
                </div>
              </>
            ) : cardUserDetails?.cards[0]?.cardStatus === "DEACTIVATED" ? (
              <>
                <button
                  style={{
                    border: "1px solid transparent",
                    borderRadius: "25px",
                    color: "rgb(245, 80, 83)",
                    background: "rgb(254 246 246)",
                    height: "2.5rem",
                    width: " 7rem",
                    fontWeight: "bold",
                  }}
                >
                  {cardUserDetails?.cards[0]?.cardStatus
                    ?.charAt(0)
                    .toUpperCase() +
                    cardUserDetails?.cards[0]?.cardStatus?.slice(1)}
                </button>
              </>
            ) : (
              "-"
            )}
          </div>
        </Panel>
      </Collapse>
      <CardAction record={record} />

      <Reasons record={record} />
      <Transactions record={record} />
      {cardUserDetails?.type === "PARENT" ||
      cardUserDetails?.type === "ADULT_SUB_ACCOUNT" ? (
        cardUserDetails?.parent === undefined &&
        cardUserDetails?.type === "PARENT" ? (
          <SubAccount record={record} />
        ) : (
          <ParentUser cardUserDetails={cardUserDetails} />
        )
      ) : (
        ""
      )}

      <FreezeModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        cardUserDetails={cardUserDetails}
        setCardUserDetails={setCardUserDetails}
      />
      <Edit
        cardUserDetails={cardUserDetails}
        setCardUserDetails={setCardUserDetails}
      />
      <AddKitNo
        isModalVisible={isModalVisibleKitNo}
        setIsModalVisible={setIsModalVisibleKitNo}
        record={record}
      />
    </>
  );
};

export default UserDetails;

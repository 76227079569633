import { Space, Button, Table, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";
import Add from "./add";

import swal from "sweetalert2";

import {
  getServiceCodesApi,
  deleteServiceCodeApi,
} from "../../../KIWE-V1/Services/serviceCodeApi";

const ServiceCode = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [servicesCodes, setServicesCodes] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleGetServiceCodes = async () => {
    try {
      const decryptedResponse = await getServiceCodesApi();

      setServicesCodes(decryptedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    handleGetServiceCodes();
  }, []);
  const handleDeleteServiceCode = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Service Code ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(async function (isConfirm) {
        if (isConfirm.value) {
          try {
            await deleteServiceCodeApi(record?.id);
            message.success("Service Code Deleted Successfully!");
            setServicesCodes(
              servicesCodes.filter((servicecode) => {
                if (servicecode.id !== record?.id) {
                  return servicecode;
                }
              })
            );
          } catch (error) {
            message.error(error.message);
          }
        }
      });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
      ellipsis: true,
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: (a, b) => a.serviceName?.localeCompare(b.serviceName),
      ellipsis: true,
    },

    {
      title: "Bill Service Code",
      dataIndex: "billServiceCode",
      key: "billServiceCode",

      ellipsis: true,
    },

    {
      title: "Inquiry Service Code",
      dataIndex: "inquiryServiceCode",
      key: "inquiryServiceCode",

      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (txt, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
                console.log(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="danger"
              className="delete"
              onClick={() => handleDeleteServiceCode(record)}
            >
              Delete
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Service Code</h2>

          <Button
            type="primary"
            size="middle"
            onClick={() => {
              showModal();
              setRecord({});
            }}
          >
            ADD
          </Button>

          <Add
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
          />
        </Space>
        <div className="container">
          {servicesCodes.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={servicesCodes}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
            />
          ) : (
            <div className="messageNoData">No Service Code Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default ServiceCode;

import { Table, Space, Button, message } from "antd";

import Totop from "../../shared/TopUp";
import Add from "./add";
import { useState, useEffect } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { S3_URL } from "../../../constant";
import { toast } from "react-toastify";
import swal from "sweetalert2";

import {
  fetchIndustriesDataAndDecryptResponse,
  deleteIndustryAPI,
} from "../../../KIWE-V1/Services/industryAPI.js";

const Industry = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  const fetchIndustries = async () => {
    try {
      const apiResponse = await fetchIndustriesDataAndDecryptResponse();

      setIndustries(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const errorData = convertEncryptedToPlantext(error?.response?.data?.data);
      message.error(errorData.message);
    }
  };
  useEffect(() => {
    fetchIndustries();
  }, []);

  const columns = [
    {
      title: "Industry Name",
      dataIndex: "industryName",
      key: "industryName",
      sorter: (a, b) => a.industryName.localeCompare(b.industryName),
      ellipsis: true,
    },
    {
      title: "Industry Name Arabic",
      dataIndex: "industryNameAr",
      key: "industryNameAr",
      sorter: (a, b) => a.industryNameAr.localeCompare(b.industryNameAr),
      ellipsis: true,
    },
    {
      title: "Industry Logo",
      dataIndex: "industryLogo",
      key: "industryLogo",
      render: (text, record) => (
        <>
          <img
            src={S3_URL + "industry/" + record.industryLogo}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "20%",
              borderRadius: "6px",
              margin: "0 auto",
              background: "rgb(226 226 233)",
            }}
            alt=""
          />
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          {roles[0] === "SUPER_ADMIN" ||
          "ADMIN" ||
          "FINANCES" ||
          "OPERATIONS" ? (
            <div>
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={() => {
                  showModal();
                  setRecord(record);
                  console.log(record);
                }}
              >
                Edit
              </Button>
              <Button
                type="danger"
                className="delete"
                onClick={() => handleDeleteClick(record)}
              >
                Delete
              </Button>
            </div>
          ) : (
            ""
          )}
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const handleDeleteClick = (data) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Industry ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(async function (isConfirm) {
        if (isConfirm.value) {
          try {
            const response = await deleteIndustryAPI(data.id);
            if (response.status === 200) {
              toast.success("Success !! Industry deleted successfully");
              window.location.reload();
            }
          } catch (error) {
            toast.error(error.message);
          }
        }
      });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Industry</h2>
          <Space>
            {roles[0] === "SUPER_ADMIN" ||
            "ADMIN" ||
            "FINANCES" ||
            "OPERATIONS" ? (
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  showModal();
                  setRecord({});
                }}
              >
                ADD
              </Button>
            ) : (
              ""
            )}

            <Add
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={industries}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Industry;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let response;
    if (Object.keys(requestData).length !== 0) {
      response = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      response = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(response?.data?.data);
    }
    return response;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
export const getAllContents = async () => {
  try {
    const response = await apiCall("content/fetchStaticContent", {}, "get");
    return response;
  } catch (error) {
    throw error;
  }
};
const buildAddAndUpdateContentRequestBody = (values, record) => {
  return {
    staticContentSlug: values?.staticContentSlug,
    text: values?.text,
    ...(record && { staticContentId: record?.staticContentId }),
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addAndUpdateContentApi = async (values, record) => {
  try {
    const data = buildAddAndUpdateContentRequestBody(values, record);
    const response = await apiCall("content/addUpdate", data);
    return response;
  } catch (error) {
    throw error;
  }
};

import Widget from "./Widget";
import { Link } from "react-router-dom";
import { Table, Avatar, Row, Col, message } from "antd";
import Totop from "../../shared/TopUp";
import { useEffect, useState, useCallback } from "react";
import { S3_URL } from "../../../constant";
import moment from "moment";
import {
  fetchRecentTransactionsData,
  fetchRecentEventsData,
  fetchCounterUsersData,
  fetchCounterTransactionsData,
  fetchPayTabsCommissionData,
  fetchKiweCommissionData,
  fetchTotalMoneyAddedData,
  fetchWithdrawalCountersData,
  fetcEventCountersData,
} from "../../../KIWE-V1/Services/dashboardApi";
const Dashboard = () => {
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  const [users, setUsers] = useState({});
  const [transactionCounter, setTransactionCounter] = useState(0);
  const [eventCounter, setEventCounter] = useState(0);
  const [TabsCommission, setTabsCommission] = useState(-1);
  const [allAddedMoney, setAllAddedMoney] = useState(0);
  const [withdrawalData, setWithdrawalData] = useState({});

  const [kiweCommission, setKiweCommission] = useState(0);
  const [events, setEvents] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [isDisplay, setIsDisplay] = useState(false);
  const roleSuperAdmin = roles.find((itm) => itm === "SUPER_ADMIN");
  useEffect(() => {
    fetchRecentTransactions();
    fetchRecentEvents();
    fetchCounterUsers();
    fetchCounterTransactions();
    fetchPayTabsCommission();
    fetchKiweCommission();
    fetchTotalMoneyAdded();
    fetchWithdrawalCounters();
    fetchEventCounter();
  }, []);
  const isRoleSuperAdminOrFinance = useCallback(() => {
    if (
      roles.find((itm) => itm === "SUPER_ADMIN") ||
      roles.find((itm) => itm === "FINANCES")
    ) {
      return true;
    }
    return false;
  }, [roles]);
  const fetchRecentTransactions = useCallback(async () => {
    if (
      isRoleSuperAdminOrFinance() ||
      roles.find((itm) => itm === "CUSTOMER_SUPPORT") ||
      roles.find((itm) => itm === "OPERATIONS")
    ) {
      setIsDisplay(true);
      try {
        const apiResponse = await fetchRecentTransactionsData();
        if (apiResponse) {
          setTransactions(apiResponse?.data);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [roles, isRoleSuperAdminOrFinance]);

  const fetchRecentEvents = useCallback(async () => {
    try {
      const apiResponse = await fetchRecentEventsData();
      if (apiResponse) {
        setEvents(apiResponse?.data);
      }
    } catch (error) {
      // message.error(error.message);
    }
  }, []);
  const fetchCounterUsers = useCallback(async () => {
    if (roleSuperAdmin === "SUPER_ADMIN") {
      try {
        const apiResponse = await fetchCounterUsersData();
        if (apiResponse) {
          setUsers(apiResponse?.data);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [roles]);
  const fetchCounterTransactions = useCallback(async () => {
    if (isRoleSuperAdminOrFinance()) {
      try {
        const apiResponse = await fetchCounterTransactionsData();
        if (apiResponse) {
          setTransactionCounter(apiResponse);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [isRoleSuperAdminOrFinance]);
  const fetchPayTabsCommission = useCallback(async () => {
    if (isRoleSuperAdminOrFinance()) {
      try {
        const apiResponse = await fetchPayTabsCommissionData();
        if (apiResponse) {
          setTabsCommission(apiResponse);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [isRoleSuperAdminOrFinance]);
  const fetchKiweCommission = useCallback(async () => {
    if (isRoleSuperAdminOrFinance()) {
      try {
        const apiResponse = await fetchKiweCommissionData();
        if (apiResponse) {
          setKiweCommission(apiResponse);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [isRoleSuperAdminOrFinance]);
  const fetchTotalMoneyAdded = useCallback(async () => {
    if (isRoleSuperAdminOrFinance()) {
      try {
        const apiResponse = await fetchTotalMoneyAddedData();
        if (apiResponse) {
          setAllAddedMoney(apiResponse);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [isRoleSuperAdminOrFinance]);

  const fetchWithdrawalCounters = useCallback(async () => {
    if (isRoleSuperAdminOrFinance()) {
      try {
        const apiResponse = await fetchWithdrawalCountersData();
        if (apiResponse) {
          setWithdrawalData(apiResponse?.data);
        }
      } catch (error) {
        // message.error(error.message);
      }
    }
  }, [isRoleSuperAdminOrFinance]);
  const fetchEventCounter = useCallback(async () => {
    try {
      const apiResponse = await fetcEventCountersData();
      if (apiResponse) {
        setEventCounter(apiResponse);
      }
    } catch (error) {
      // message.error(error.message);
    }
  }, []);

  const cards = [
    users?.individualUsers
      ? {
          title: users?.individualUsers.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          content: "Individual User",
          url: "/indivdual-user/",
        }
      : null,
    users?.businessUsers
      ? {
          title: users?.businessUsers.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          content: "Business User",
          url: "/business-user/",
        }
      : null,
    transactionCounter
      ? {
          title: transactionCounter.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          content: "Payment Transactions",
          url: "/dashboard",
        }
      : null,
    // roles.find((itm) => itm === "SUPER_ADMIN")
    //   ? {
    //       title: eventCounter.toLocaleString(undefined, {
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2,
    //       }),
    //       content: "Events",
    //       url: "/events/1",
    //     }
    //   : roles.find((itm) => itm === "FINANCES")
    //   ? null
    // : {
    //     title: eventCounter.toLocaleString(undefined, {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2,
    //     }),
    //     content: "Events",
    //     url: "/events/1",
    //   },

    // TabsCommission >= 0
    //   ? {
    //       title:
    //         TabsCommission === 0.0
    //           ? "0.0"
    //           : TabsCommission.toLocaleString(undefined, {
    //               minimumFractionDigits: 2,
    //               maximumFractionDigits: 2,
    //             }),
    //       content: "Paytabs Commission",
    //       url: "/dashboard",
    //     }
    //   : null,

    kiweCommission
      ? {
          title: kiweCommission?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          content: "Kiwe Commission",
          url: "/dashboard",
        }
      : null,
    allAddedMoney
      ? {
          title: allAddedMoney?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          content: "Total Added Money",
          url: "/dashboard",
        }
      : null,
    withdrawalData.holdAmount
      ? {
          title: withdrawalData?.holdAmount
            ? withdrawalData?.holdAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.0",
          content: "Total money on hold",
          url: "/dashboard",
        }
      : null,

    withdrawalData.paidAmount
      ? {
          title: withdrawalData?.paidAmount
            ? withdrawalData?.paidAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0,
          content: "Total money paid",
          url: "/dashboard",
        }
      : null,
    // roles.find((itm) => itm === "SUPER_ADMIN") ||
    // roles.find((itm) => itm === "FINANCES")
    //   ? {
    //       title: (0).toLocaleString(undefined, {
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2,
    //       }),
    //       content: "Total cash-back",
    //       url: "/dashboard",
    //     }
    //   : null,
  ];
  const recentEventsColumns = [
    {
      title: "From",
      dataIndex: "owner",
      render: (text, record) => (
        <>
          <Avatar
            src={
              S3_URL +
              record.ownerId +
              "/profile_picture/" +
              record.profilePicturePath
            }
            style={{ margin: "0 5px" }}
          />
          {record.ownerName?.split(" ")[0]}
        </>
      ),
    },
    {
      title: "Wallet Name",
      dataIndex: "walletName",
      render: (txt, record) => <>{record.walletName}</>,
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      render: (text, record) => <>{new Date(text).toLocaleDateString()}</>,
    },
    {
      title: "Time Stamp",
      dataIndex: "createdDate",
      render: (text, record) => (
        <>{moment(text).format("D MMM, yyyy h:mm a")}</>
      ),
    },
  ];
  const recentTransactionsColumns = [
    {
      title: "From",
      dataIndex: "owner",
      render: (text, record) => (
        <>
          <Avatar
            src={
              S3_URL +
              record.senderId +
              "/profile_picture/" +
              record.senderProfileProfilePicture
            }
            style={{ margin: "0 5px" }}
          />
          {record.senderName?.split(" ")[0]}
        </>
      ),
    },
    {
      title: "Transaction To",
      dataIndex: "transactionTo",
      render: (text, record) => (
        <>
          <Avatar
            src={
              S3_URL +
              record.receiverId +
              "/profile_picture/" +
              record.receiverProfilePicture
            }
            style={{ margin: "0 5px" }}
          />
          {record.receiverName}
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (txt, record) => (
        <>
          {record.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          EGP
        </>
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      render: (txt, record) => (
        <>
          {" "}
          {record.message && record.message.length > 14
            ? record.message.substr(0, 12) + ".."
            : record.message}
        </>
      ),
    },
    {
      title: "Timp Stamp",
      dataIndex: "createdTimeStamp",
      render: (text, record) => (
        <>{moment(text).format("D MMM, yyyy h:mm a")}</>
      ),
    },
  ];

  return (
    <>
      <Row
        gutter={[16, 16]}
        wrap={true}
        justify="flex-start"
        className="rowCardsDashboard"
      >
        {cards
          .filter((el) => el != null)
          .map((card, index) => (
            <Widget
              key={index}
              title={card.title}
              content={card.content}
              url={card.url}
            />
          ))}
      </Row>

      <Row
        gutter={[16, 16]}
        wrap={true}
        justify="space-around"
        className="rowTableDashboard"
      >
        {
          // <Col
          //   span={8}
          //   xs={24}
          //   sm={24}
          //   md={24}
          //   lg={isDisplay ? 12 : 22}
          //   className="latestEvent"
          // >
          //   <div className="header-table">
          //     <h4 className="header-table__h4">Latest Event </h4>
          //     <Link to="/events/1">
          //       <h4 style={{ color: "#808191", fontWeight: "bold" }}>See More</h4>
          //     </Link>
          //   </div>
          //   <Table
          //     className="table1"
          //     columns={recentEventsColumns}
          //     dataSource={events}
          //     pagination={false}
          //     scroll={{ x: 600 }}
          //   />
          // </Col>
        }
        {isDisplay ? (
          <Col span={8} xs={24} sm={24} md={24} lg={24}>
            <div className="header-table">
              <h4 className="header-table__h4">Latest Transactions </h4>
              <Link to="/transactions">
                <h4 style={{ color: "#808191", fontWeight: "bold" }}>
                  See More
                </h4>
              </Link>
            </div>
            <Table
              className="table1"
              columns={recentTransactionsColumns}
              dataSource={transactions}
              pagination={false}
              scroll={{ x: 600 }}
            />
          </Col>
        ) : (
          ""
        )}
      </Row>

      <Totop />
    </>
  );
};
export default Dashboard;

import { Space, Avatar, Button, Table, message, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import InputSearch from "../form/InputSearch";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BankTransfer } from "../../shared/images";
import BankDetails from "./BankDetails";
import BusinessUserDetail from "./BusinessUserDetails";
import Totop from "../../shared/TopUp";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { S3_URL } from "../../../constant";
// import { useParams } from "react-router-dom";
import BusinessAdjustment from "./businessAdjustment";
import Image from "./image";
import whiteIndustry from "../../../assets/images/industryWhite.svg";
import sellingTypeWhite from "../../../assets/images/sellingTypeWhite.svg";
import { fetchUserDataAndDecryptResponse } from "../../../KIWE-V1/Services/userAPI";

// import Document from "./documents";
// admin/deleteAdmin-->in Individual Users
// admin/updateUserByAdmin-->admin/deleteAdmin-->in business user
const BusinessUser = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const search_textFromParams = params.get("search_text");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);

  const [isModalVisible1, setIsModalVisibleD] = useState(false);

  const [isModalVisibleDocument, setIsModalVisibleDocument] = useState(false);

  const [isModalVisible2, setIsModalVisibleB] = useState(false);
  const [businessUser, setBusinessUser] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const [record, setRecord] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));

  let indust = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "OPERATIONS"
  );
  const fetchBusinessUser = async (page, searchTextFromInput) => {
    try {
      const api_res = await fetchUserDataAndDecryptResponse(
        "business",
        searchTextFromInput,
        page
      );
      setTotalPages(api_res?.totalRecords);
      setBusinessUser(api_res?.data);
      setLoading(false);
      console.log(api_res, "business");
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const showModalDetails = (record) => {
    setIsModalVisibleD(true);
    setRecord(record);
  };
  const showModalBusinessAdjustment = (record) => {
    setIsModalVisibleB(true);
    setRecord(record);
  };
  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record?.account_detail);
    setFirstName(record?.firstName);
  };
  const showModalDocument = (record) => {
    setIsModalVisibleDocument(true);
    setRecord(record);
  };
  const showModall = (record) => {
    setIsModalVisibleImage(true);
    setRecord(record?.account_detail);
    setFirstName(record?.firstName);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancelImage = () => {
    setIsModalVisibleImage(false);
  };
  const handleCancelDetails = () => {
    setIsModalVisibleD(false);
  };
  const handleCancelDocument = () => {
    setIsModalVisibleDocument(false);
  };
  var onSearch = (value) => {
    setSearchTextFromInput(value);
    history.push(`/business-user/?pageNum=1&search_text=${value}`);
  };
  // const getRowKey = (record) => {
  //   return `${record.key}`;
  // };
  // const setRowKey = (record) => {
  //   const selectedRowKey = getRowKey(record);

  //   setSelectedRowKey(record);
  // };
  const handleViewClick = (data) => {
    axiosInstance()
      .post("admin/businessDocs", {
        data: convertPlantextToEncrypted({
          userId: data.userId,
          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          var api_response = convertEncryptedToPlantext(response?.data?.data);
          console.log(api_response);
          setDocuments(api_response);
          showModalDocument(data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.data);
        showModalDocument(data);
      });
  };

  useEffect(() => {
    if (pageNum && search_textFromParams) {
      fetchBusinessUser(pageNum, search_textFromParams);
    } else if (pageNum) {
      fetchBusinessUser(pageNum, searchTextFromInput);
    } else {
      fetchBusinessUser(1, searchTextFromInput);
    }
  }, [pageNum, search_textFromParams]);
  const columns = [
    {
      title: "Profile Image",
      dataIndex: "profilePicturePath",
      key: "profilePicturePath",
      // width: 130,
      render: (src, value) => (
        <>
          <Avatar
            src={
              S3_URL +
              value.userId +
              "/profile_picture/" +
              value.profilePicturePath
            }
            size="large"
            onClick={() => {
              showModall();
              setRecord(value);
            }}
          />
        </>
      ),
      // ellipsis: true,
    },
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
      sorter: (a, b) => a.businessName?.length - b.businessName?.length,
      // width: 160,
      // ellipsis: true,
    },
    {
      title: "User Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName?.length - b.firstName?.length,
      // ellipsis: true,
      // width: 125,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,
      // ellipsis: true,
      render: (no) => <>0{no}</>,
      // width: 95,
    },
    {
      title: "Bank Details",
      dataIndex: "bank",
      key: "bank",
      render: (src, record) => (
        <>
          <span
            className="image"
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              margin: "0 auto",
              width: "30px",
            }}
            onClick={() => showModal(record)}
          >
            <img src={BankTransfer} alt="" />
          </span>
        </>
      ),

      // ellipsis: true,
      // width: 120,
    },
    {
      title: "Details",
      dataIndex: "view",
      key: "view",
      render: (src, record) => (
        <>
          <Button
            className="btn-icon"
            size="middle"
            onClick={() => showModalDetails(record)}
            type="primary"
            shape="round"
            style={{ background: "#f5f5f5" }}
            icon={<EyeOutlined style={{ color: "black" }} />}
          ></Button>
        </>
      ),
      // width: 150,

      // ellipsis: true,
    },

    // {
    //   title: "Documents",
    //   dataIndex: "view",
    //   key: "view",
    //   render: (src, record) => (
    //     <>
    //       <Button
    //         className="btn-icon"
    //         size="middle"
    //         onClick={() => handleViewClick(record)}
    //         type="primary"
    //         shape="round"
    //       >
    //         Documents
    //       </Button>
    //     </>
    //   ),

    //   // ellipsis: true,
    // },

    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   sorter: (a, b) => a.email?.length - b.email?.length,
    //   // ellipsis: true,
    //   // width: 88,
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "createdDate",
    //   key: "createdDate",
    //   render: (text) => <>{new Date(text).toLocaleString()}</>,
    //   sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
    // },
    {
      title: "Tickets",
      dataIndex: "Tickets",
      key: "Tickets",

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/tickets",
              state: record,
              place: "business",
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{ height: "2rem" }}
            >
              Tickets
            </Button>
          </Link>
        </>
      ),
      // ellipsis: true,
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/transaction-user",
              state: record,
              place: "business",
              searchTextt: searchTextFromInput,
              pageNo: pageNum,
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{ marginLeft: "-1rem", height: "2rem" }}
            >
              <span className="icon-TransactionFeesw"></span>
              Transactions
            </Button>
          </Link>
        </>
      ),
      // ellipsis: true,
    },
    {
      title: "Cash-Back",
      dataIndex: "cashback",
      key: "cashback",
      // width: 180,
      render: (txt, record) => (
        <div
          style={{
            display: " flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Link
            to={{
              pathname: `/cash-back/${record?.userId}`,
              state: record,

              // place: 'business',
            }}
          >
            <Button
              // className="btn-edit"
              size="middle"
              type="primary"
            >
              Cash Back
            </Button>
          </Link>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // width: 180,
      render: (txt, record) => (
        <div
          style={{
            display: " flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {record?.active === true ? (
            <Button
              className="btn-active"
              onClick={() => showModalBusinessAdjustment(record)}
              size="middle"
            >
              Active
            </Button>
          ) : (
            <Button
              className="btn-inactive"
              onClick={() => showModalBusinessAdjustment(record)}
              size="middle"
              style={{ marginRight: ".5rem" }}
            >
              Inactive
            </Button>
          )}
          <Link
            to={{
              pathname: "/business-user-edit",
              state: record,
              pageNo: pageNum,
              searchTextt: searchTextFromInput,
              // place: 'business',
            }}
          >
            <Button
              // className="btn-edit"
              size="middle"
              type="primary"
              style={{ background: "#f5f5f5", color: "black" }}
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
      // ellipsis: true,
    },
  ];
  // function onChange(pagination, sorter, extra) {
  //   console.log("params", pagination, sorter, extra);
  // }
  // const handleClick = (record) => {
  //   console.log(record);
  // };
  const handleIndustry = () => {
    history.push("/industry");
  };
  const handleSellingType = () => {
    history.push("/selling-type");
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader-1">
          <h2>Business Users</h2>
          <Space>
            <InputSearch
              onSearch={onSearch}
              searchTextt={
                search_textFromParams
                  ? search_textFromParams
                  : searchTextFromInput
              }
            />
            {indust ? (
              <Space>
                <Button
                  type="primary"
                  size="middle"
                  onClick={handleIndustry}
                  style={{
                    height: "2.5rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="btnHover"
                >
                  {" "}
                  <span>
                    <img
                      src={whiteIndustry}
                      alt="-"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>
                  <span style={{ marginLeft: ".5rem", fontWeight: "bold" }}>
                    Industry
                  </span>
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  onClick={handleSellingType}
                  style={{ height: "2.5rem" }}
                  className="btnHover"
                >
                  <span>
                    <img
                      src={sellingTypeWhite}
                      alt="-"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>

                  <span style={{ marginLeft: ".5rem", fontWeight: "bold" }}>
                    Selling Type
                  </span>
                </Button>
              </Space>
            ) : (
              ""
            )}
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={businessUser}
            // onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNum ? +pageNum : 1,
              onChange: (page) => {
                history.push(
                  `/business-user/?pageNum=${page}&search_text=${
                    search_textFromParams ? search_textFromParams : ""
                  }`
                );
              },
            }}
          />
        </div>

        <BusinessUserDetail
          handleCancelDetails={handleCancelDetails}
          isModalVisible1={isModalVisible1}
          record={record}
        />
        <BankDetails
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
          record={record}
          firstName={firstName}
        />

        <Totop />
        <BusinessAdjustment
          isModalVisible2={isModalVisible2}
          setIsModalVisibleB={setIsModalVisibleB}
          record={record}
        />
        <Image
          isModalVisibleImage={isModalVisibleImage}
          setIsModalVisibleImage={setIsModalVisibleImage}
          record={record}
          handleCancelImage={handleCancelImage}
        />
        {/* <Document /> */}
        <Modal
          title={record?.businessName + " - Documents "}
          visible={isModalVisibleDocument}
          onCancel={handleCancelDocument}
          footer={null}
          className="modal"
        >
          <div
            style={{
              display: "flex",
              margin: "0 auto",
              justifyContent: "center",
            }}
          >
            <table>
              {documents.length > 0 ? (
                documents.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <a
                        target="_blank"
                        href={
                          S3_URL +
                          record.userId +
                          "/attachment/" +
                          item.mediaPath
                        }
                      >
                        {item.mediaTitle}
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Records Found</td>
                </tr>
              )}{" "}
            </table>
          </div>

          {/* <Button
            size='middle'
            type='primary'
            shape='round'
            onClick={handleCancelDocument}
            style={{ display: 'flex', margin: '0 auto' }}
          >
            Close
          </Button> */}
        </Modal>
      </div>
    </>
  );
};

export default BusinessUser;

import { Avatar, Button, DatePicker, Space } from "antd";

const CardTrans = ({ src, nam, onChange2, handleExport, loading }) => {
  const { RangePicker } = DatePicker;
  if (handleExport) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          paddingBottom: "1rem",
        }}
      >
        <Space style={{ marginLeft: "2rem", padding: "0.5rem 0" }}>
          <Avatar src={src} style={{ width: "60px", height: "60px" }} />
          <span style={{ margin: "0 1rem" }}>{nam}</span>
        </Space>

        <Space style={{ padding: "0.5rem 0" }}>
          <RangePicker onChange={onChange2} />
          <Button
            type="primary"
            size="middle"
            onClick={handleExport}
            loading={loading}
          >
            Export
          </Button>
        </Space>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Space style={{ marginLeft: "2rem", padding: "0.5rem 0" }}>
          <Avatar src={src} style={{ width: "60px", height: "60px" }} />
          <span style={{ margin: "0 1rem" }}>{nam}</span>
        </Space>
      </div>
    );
  }
};

export default CardTrans;

import React, { useState } from "react";
import Totop from "../../shared/TopUp";
import { Space, Button, Input, Form, message, Modal } from "antd";
import { otpForBusinessSignUpAPI } from "../../../KIWE-V1/Services/userAPI.js";
function OTP() {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();
  const handleOtpForBusinessSignUp = async (values) => {
    try {
      setLoading(true);

      const decryptedResponse = await otpForBusinessSignUpAPI(values);
      if (decryptedResponse) {
        setLoading(false);
        const configSuccess = {
          className: "messageSuccess",
          content: decryptedResponse.message,
        };
        message.success(configSuccess);
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <div style={{ margin: "1rem" }}>
      <h2>OTP</h2>
      <div className="container">
        <Space
          direction="horizontal"
          className="topHeader"
          style={{ justifyContent: "center", marginTop: "1.4rem" }}
        >
          <Button type="primary" size="middle" onClick={() => showModal()}>
            Get OTP For Email
          </Button>
        </Space>
      </div>
      <Totop />
      <Modal
        title={"GET OTP"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOtpForBusinessSignUp}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="Email :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your E-mail!" },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input style={{ borderRadius: "25px" }} />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default OTP;

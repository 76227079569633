import { Input, Button, Form, message } from "antd";
import { useHistory } from "react-router-dom";
import DragImage from "../form/DragImage";
import { useEffect } from "react";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { BUSINESS_OFFER_ALBUM_NAME } from "../../../constant";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addOrEditBusinessOfferApi } from "../../../KIWE-V1/Services/businessOfferApi";
const Edit = () => {
  // if (localStorage.getItem('record') === undefined) {
  //   localStorage.removeItem('record');
  // }
  // var { name, image, picturePath, discount, code, description, website, id } =
  //   JSON.parse(localStorage.getItem('record'));

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const history = useHistory();
  const { state } = useLocation();
  const { pageNo } = useParams();
  console.log(pageNo, "test");
  // const { picturePath, discount, code, description, website, id } = state;
  // console.log(state);
  // console.log(
  //   name,
  //   image,
  //   picturePath,
  //   discount,
  //   code,
  //   description,
  //   website,
  //   id
  // );
  const handleEditBusinessOffer = async (values) => {
    console.log(state?.id);
    // message.success('Submit success!');
    if (state?.id) {
      let randNameImage = "";
      console.log("values", values);
      if (!(typeof values.image === "string")) {
        randNameImage = generateRandomFileName(values.image[0]);
        handleImageUpload(
          values.image[0].originFileObj,
          randNameImage,
          BUSINESS_OFFER_ALBUM_NAME
        );
      } else {
        randNameImage = values.image;
      }
      let randNameCoverImage = "";

      if (!(typeof values.picturePath === "string")) {
        randNameCoverImage = generateRandomFileName(values.picturePath[0]);
        handleImageUpload(
          values.picturePath[0].originFileObj,
          randNameCoverImage,
          BUSINESS_OFFER_ALBUM_NAME
        );
      } else {
        randNameCoverImage = values.picturePath;
      }
      try {
        await addOrEditBusinessOfferApi(
          values,
          randNameImage,
          randNameCoverImage,
          state?.id
        );
        message.success("Business Offer Updated Successfully!");
        history.push(`/business-offer/${+pageNo}`);
      } catch (error) {
        message.error(error.message);
      }
    }
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
    localStorage.removeItem("record");
  };
  useEffect(() => {
    if (state?.id) {
      form.setFieldsValue({ name: state?.name });
      form.setFieldsValue({ code: state?.code });
      form.setFieldsValue({ image: state?.image });
      form.setFieldsValue({ discount: state?.discount });
      form.setFieldsValue({ description: state?.description });
      form.setFieldsValue({ website: state?.website });
      form.setFieldsValue({ picturePath: state?.picturePath });
    }
  }, [state?.id]);
  const handleCancel = () => {
    form.resetFields();
    history.push(`/business-offer/${pageNo}`);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="container__topheader">
          <h2>Edit Business Offer</h2>
          <div
            className="profile__btns"
            style={{ display: "flex", marginTop: "1rem" }}
          >
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="profile__btns__save"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                size="middle"
                className="profile__btns__cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="container profile">
          <div className="profile__form modal" style={{ padding: "1rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleEditBusinessOffer}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                hasFeedback
                rules={[{ required: true, message: "Please input your Name!" }]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item className="offerImage">
                <DragImage
                  normFile={normFile}
                  nam1="image"
                  label="Offer Image"
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: 'Please input your Offer Image!',
                  //     },
                  //   ]}
                />
              </Form.Item>
              <Form.Item
                name="discount"
                label="Discount"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Discount!" },
                ]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Description!" },
                ]}
                className="offer-name"
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item
                name="code"
                label="Code"
                hasFeedback
                rules={[{ required: true, message: "Please input your Code!" }]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item className="offerImage">
                <DragImage
                  normFile={normFile}
                  nam1="picturePath"
                  label="Logo"
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: 'Please input your Logo!',
                  //     },
                  //   ]}
                />
              </Form.Item>
              <Form.Item
                name="website"
                label="Website"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Website!" },
                ]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;

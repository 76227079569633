import { Button, Form, message, Input } from "antd";
import { useHistory } from "react-router-dom";
import { S3_QRCODEURL } from "../../../constant";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import EditQRCode from "./editQRCode";
import { useRef } from "react";

import { editBankDetails } from "../../../KIWE-V1/Services/bankAccountApi";

const EditBank = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const buttonRef = useRef(null);
  const { state, pageNo } = useLocation();

  const { userId, mobile } = state;
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEditBankDetails = async (values) => {
    try {
      await editBankDetails(values, userId, state);
      const messageSuccess = {
        className: "messageSuccess",
        content: "Bank details updated successfully!",
      };
      message.success(messageSuccess);
      history.goBack();
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (state?.account_detail?.accountId) {
      form.setFieldsValue({ bankName: state?.account_detail?.bankName });
      form.setFieldsValue({
        accountHolderName: state?.account_detail?.accountHolderName,
      });
      form.setFieldsValue({ accountNo: state?.account_detail?.accountNo });
      form.setFieldsValue({ swiftCode: state?.account_detail?.swiftCode });
      form.setFieldsValue({ branchName: state?.account_detail?.branchName });
      form.setFieldsValue({ ibanNumber: state?.account_detail?.ibanNumber });
    }
  }, [state?.account_detail?.accountId]);

  const handleCancel = () => {
    form.resetFields();
    history.goBack();
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="profile__form" style={{ padding: "0rem" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleEditBankDetails}
            autoComplete="off"
          >
            <div className="container__topheader">
              <h2>Bank Details</h2>
              <div style={{ display: "flex", marginTop: "1rem" }}>
                <Form.Item>
                  <Button
                    size="middle"
                    htmlType="submit"
                    className="profile__btns__save"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    size="middle"
                    className="profile__btns__cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </div>
            </div>
            <div className="container">
              <Form.Item
                label="Bank Account Name"
                name="accountHolderName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Bank Account Name!",
                  },
                ]}
              >
                <Input className="inputs-business" />
              </Form.Item>
              <Form.Item
                name="bankName"
                label="Bank Name"
                rules={[
                  { required: true, message: "Please input your Bank Name!" },
                ]}
              >
                <Input className="inputs-business" />
              </Form.Item>
              <Form.Item
                name="branchName"
                label="Branch Name"
                rules={[
                  { required: true, message: "Please input your Branch Name!" },
                ]}
              >
                <Input className="inputs-business" />
              </Form.Item>
              <Form.Item
                name="accountNo"
                label="Bank Account Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your Bank Account Number!",
                  },
                ]}
              >
                <Input className="inputs-business" />
              </Form.Item>
              <Form.Item
                name="swiftCode"
                label="SWIFT Code"
                rules={[
                  { required: true, message: "Please input your Swift Code!" },
                ]}
              >
                <Input className="inputs-business" />
              </Form.Item>
              <Form.Item
                name="ibanNumber"
                label="Iban Number"
                rules={[
                  { required: true, message: "Please input your Iban Number!" },
                ]}
              >
                <Input className="inputs-business" />
              </Form.Item>
              <div className="qrCode">
                <img
                  src={
                    S3_QRCODEURL + userId + "/qr_code/%2B20-" + mobile + ".png"
                  }
                  className="qrCode__image"
                  alt="-"
                />
                <a
                  className="qrCode__header"
                  href={
                    S3_QRCODEURL +
                    userId +
                    "/qr_code_pdf/%2B20-" +
                    mobile +
                    ".pdf"
                    // S3_QRCODEURL + userId + "/qr_code_pdf/" + userId + ".pdf"
                  }
                >
                  Download QR code
                </a>

                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    showModal();
                  }}
                  style={{ marginTop: "1rem" }}
                  ref={buttonRef}
                >
                  Edit QR Code
                </Button>

                <EditQRCode
                  setIsModalVisible={setIsModalVisible}
                  isModalVisible={isModalVisible}
                  getContainer={() => buttonRef.current}
                  record={state}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditBank;

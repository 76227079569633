import { Input, Button, Form, message, Modal, InputNumber } from "antd";
import { useEffect } from "react";
import { editTransactionFeesAPI } from "../../../KIWE-V1/Services/transactionFeesApi";
import { useState } from "react";

const Edit = ({ isModalVisible, setIsModalVisible, record }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleEditTransactionFees = async (values) => {
    try {
      setLoading(true);
      const decryptedResponse = await editTransactionFeesAPI(values, record);
      if (decryptedResponse) {
        setLoading(false);
        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);

      message.error(error.message);
    }
  };

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ fees: record?.fees });
      form.setFieldsValue({ feesType: record?.feesType });
    }
  }, [record, form]);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Edit Transaction Fees"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEditTransactionFees}
          autoComplete="off"
          initialValues={
            record?.id ? { fees: record?.fees, feesType: record?.feesType } : {}
          }
        >
          <Form.Item
            name="feesType"
            label="Fees Type"
            hasFeedback
            className="offer-name"
          >
            <Input disabled style={{ borderRadius: "25px" }} />
          </Form.Item>
          <Form.Item
            name="fees"
            label="Fees(%)"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Fess(%)!",
              },
            ]}
            className="offer-name"
          >
            <InputNumber
              min={0}
              max={100}
              style={{ width: "100%", borderRadius: "25px" }}
              defaultValue={0}
            />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;

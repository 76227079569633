const is_development = false;
// const is_development = process.env.NODE_ENV === 'production' ? false : true;
export const BEFORE_LOGIN_ACTION = ["/", "/login"];
export const AFTER_LOGIN_ACTION = [
  "/dashboard",
  "/profile",
  "/admin",
  "/admin-add",
  "/user",
  "/content",
];
export const API_BASE_URL =
  is_development === true
    ? "http://102.217.68.101:8088/"
    : "https://kiweapp.com/api/";
export const Env = is_development === true ? "stage" : "live";
export const S3_URL =
  "https://s3.eu-central-1.amazonaws.com/kiweapp/" + Env + "/";
export const OFFER_S3_URL =
  "https://kiweapp.s3.eu-central-1.amazonaws.com/offer/";

export const TEMPLATE_S3_URL = S3_URL + "gift_card_templates_picture/";
export const notification_picture_path = S3_URL + "notification_picture_path/";
export const notification_Business_picture_path = S3_URL + "Notifications/";

export const INDUSTRY_ALBUM_NAME = "kiweapp/" + Env + "/industry";
export const COMPANY_ALBUM_NAME = "kiweapp/" + Env + "/companyImages";

export const INDUSTRY_TYPE_ALBUM_NAME =
  "kiweapp/" + Env + "/industry_type/images";
export const BUSINESS_OFFER_ALBUM_NAME = "kiweapp/" + Env + "/business_offer";
export const OFFER_LIST_ALBUM = "kiweapp/offer";

export const NO_IMAGE = "No Image";
export const NOT_AVAILABLE = "Not Available";
export const LIMIT = 10;
export const FILE_PREFIX_CODE = 255;
export const S3_QRCODEURL =
  "https://kiweapp.s3.eu-central-1.amazonaws.com/" + Env + "/";

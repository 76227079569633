import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  getCurrentTimeStemp,
  convertPlantextToEncrypted,
} from "../../library/Helper";

export const fetchIndustriesDataAndDecryptResponse = async () => {
  try {
    const response = await axiosInstance().get("industry/getIndustryForCMS");
    if (response.status === 200) {
      const decryptedResponse = convertEncryptedToPlantext(
        response?.data?.data
      );
      return decryptedResponse;
    }
  } catch (error) {
    throw error;
  }
};
const buildAddAndUpdateRequestBody = (values, randName, record) => {
  return {
    industryName: values.industryName,
    industryNameAr: values.industryNameAr,
    industryLogo: randName,
    id: record?.id,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addAndUpdateIndustryAPI = async (values, randName, record) => {
  try {
    const requestBody = buildAddAndUpdateRequestBody(values, randName, record);
    const response = await axiosInstance().post("industry/addIndustry", {
      data: convertPlantextToEncrypted(requestBody),
    });

    return response;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const deleteIndustryAPI = async (id) => {
  try {
    const response = await axiosInstance().get(`industry/deleteIndustry/${id}`);
    return response;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

import {
  InputNumber,
  Button,
  Form,
  Modal,
  DatePicker,
  Switch,
  Select,
  message,
} from "antd";
import { useEffect } from "react";
import moment from "moment";
import { editMerchantRewardApi } from "../../../KIWE-V2/Services/merchantApi";

const Edit = ({
  isModalVisible,
  setIsModalVisible,
  record,
  setCancelState,
  cancelState,
  merchantsRewards,
  setMerchantsRewards,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const handleEditMerchantReward = async (values) => {
    try {
      const discountPercentage =
        values.discountPercentage !== undefined ? values.discountPercentage : 0;
      const cashbackPercentage =
        values.cashbackPercentage !== undefined ? values.cashbackPercentage : 0;

      const apiValues = {
        ...values,
        discountPercentage,
        cashbackPercentage,
      };

      const response = await editMerchantRewardApi(apiValues, record.id);
      const updatedMerchantRewards = merchantsRewards.map((merchant) => {
        if (merchant.id === record.id) {
          return response;
        } else {
          return merchant;
        }
      });
      setMerchantsRewards(updatedMerchantRewards);
      const configSuccess = {
        className: "messageSuccess",
        content: "Merchant reward updated successfully!",
      };
      message.success(configSuccess);

      handleCancel();
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
      handleCancel();
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setCancelState(!cancelState);
  };

  const disabledDate = (current) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return current && current < currentDate;
  };

  const validateCashbackPercentage = (rule, value) => {
    if (value !== undefined && (value < 0 || value > 100)) {
      return Promise.reject("Cashback percentage must be between 0 and 100");
    }
    return Promise.resolve();
  };

  const validateDiscountPercentage = (rule, value) => {
    if (value !== undefined && (value < 0 || value > 100)) {
      return Promise.reject("Discount percentage must be between 0 and 100");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        expiryDate: moment(record.expiryDate),
        minAmountToSpend: record.minAmountToSpend,
        maxCashbackAmount: record.maxCashbackAmount,
        cashbackPercentage: record.cashbackPercentage,
        discountPercentage: record.discountPercentage,
        active: record.active,
      });
    }
  }, [form, record, cancelState]);

  return (
    <>
      <Modal
        title={"Edit Merchant Reward"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEditMerchantReward}
          autoComplete="off"
        >
          <Form.Item
            name="expiryDate"
            label="Expiry Date"
            rules={[{ required: true, message: "Please input Expiry Date!" }]}
          >
            <DatePicker
              showTime
              style={{ width: "100%", borderRadius: "25px", height: "2.6rem" }}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            name="minAmountToSpend"
            label="Min Amount Spent"
            rules={[
              {
                required: true,
                message: "Please input your Min Amount!",
              },
            ]}
          >
            <InputNumber
              min={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
            />
          </Form.Item>
          <Form.Item
            name="maxCashbackAmount"
            label="Max Cashback Amount"
            rules={[
              {
                required: true,
                message: "Please input your Max Cashback Amount!",
              },
            ]}
          >
            <InputNumber
              min={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
            />
          </Form.Item>
          <Form.Item
            name="cashbackPercentage"
            label="Cashback %"
            rules={[
              {
                validator: validateCashbackPercentage,
              },
            ]}
            onChange={(value) => {
              form.setFieldsValue({ discountPercentage: 0 });
            }}
          >
            <InputNumber
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
            />
          </Form.Item>
          <Form.Item
            name="discountPercentage"
            label="Discount %"
            rules={[
              {
                validator: validateDiscountPercentage,
              },
            ]}
          >
            <InputNumber
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
              onChange={(value) => {
                form.setFieldsValue({ cashbackPercentage: 0 });
              }}
            />
          </Form.Item>
          <Form.Item label="Locked" name="active" valuePropName="checked">
            <Switch className="blueSwitch" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {"Edit Merchant Reward"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;

import { Space, Table, Avatar, Switch, Button, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";
import { useHistory } from "react-router-dom";
import {
  fetchFairsApi,
  updateFairStatus,
} from "../../../KIWE-V2/Services/fairsApi";
import { EyeOutlined } from "@ant-design/icons";

import { convertEncryptedToPlantext } from "../../library/Helper";
import { useFair } from "../../../contextAPi/fair/FairContext.js";
import { S3_URL } from "../../../constant.js";
import PolicyDetails from "./policyDetails.jsx";
const Fairs = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const { fairsData, setFairsData } = useFair();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [policyString, setPolicyString] = useState();
  const fetchFairsData = async (page) => {
    try {
      const apiResponse = await fetchFairsApi(page);

      setLoading(false);
      setFairsData(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);
      const errorResponse = convertEncryptedToPlantext(error?.message);
      message.error(errorResponse);
    }
  };
  const handleUpdateFairStatus = (record) => {
    const newFairStatus = !record?.enabled;
    updateFairStatus(record?.id, newFairStatus)
      .then((response) => {
        let updatedFairs = fairsData.map((fair) => {
          if (fair.id === record.id) return { ...fair, enabled: newFairStatus };
          return fair;
        });
        setFairsData(updatedFairs);
      })
      .catch((error) => {
        const configError = {
          className: "messageError",
          content: error.message,
        };
        message.error(configError);
      });
  };

  useEffect(() => {
    if (pageNum) {
      fetchFairsData(pageNum);
    } else {
      fetchFairsData(1);
    }
  }, [pageNum]);
  const showModalDetails = (policyString) => {
    setIsModalVisible(true);
    setPolicyString(policyString);
  };
  const fairsColumns = [
    {
      title: "Business Name",
      dataIndex: "businessUser?.businessName",
      key: "businessUser?.businessName",
      sorter: (a, b) =>
        a.businessUser?.businessName.localeCompare(
          b.businessUser?.businessName
        ),
      render: (txt, record) => (
        <>
          {record?.businessUser?.businessName
            ? record?.businessUser?.businessName
            : "-"}
        </>
      ),
    },
    {
      title: "Business photo",
      dataIndex: "businessUser?.profilePicturePath",
      key: "businessUser?.profilePicturePath",

      render: (src, record) => (
        <>
          <Avatar
            src={
              S3_URL +
              record?.businessUser?.id +
              "/profile_picture/" +
              record?.businessUser?.profilePicturePath
            }
            size="large"
          />
        </>
      ),
    },
    {
      title: "Fair Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b?.name),
      render: (txt, record) => <>{record?.name ? record?.name : "-"}</>,
    },
    {
      title: "Fair Photo",
      dataIndex: "banner",
      key: "banner",

      render: (src, record) => (
        <>
          <Avatar src={record?.banner} size="large" />
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (txt, record) => (
        <>
          <span>{new Date(txt)?.toLocaleDateString()}</span>
        </>
      ),
      sorter: (a, b) => new Date(a?.startDate) - new Date(b?.startDate),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (txt, record) => (
        <>
          <span>{new Date(txt)?.toLocaleDateString()}</span>
        </>
      ),
      sorter: (a, b) => new Date(a?.endDate) - new Date(b?.endDate),
    },
    {
      title: "Arabic Policy",
      dataIndex: "arabicPolicy",
      key: "arabicPolicy",

      render: (txt, record) => (
        <>
          {" "}
          <Button
            className="btn-icon"
            size="middle"
            onClick={() => showModalDetails(record?.arabicPolicy)}
            type="primary"
            shape="round"
            icon={<EyeOutlined />}
            style={{ background: "#f5f5f5", color: "black" }}
          >
            Arabic Policy
          </Button>
        </>
      ),
    },
    {
      title: "English Policy",
      dataIndex: "englishPolicy",
      key: "englishPolicy",

      render: (txt, record) => (
        <>
          {" "}
          <Button
            className="btn-icon"
            size="middle"
            onClick={() => showModalDetails(record?.englishPolicy)}
            type="primary"
            shape="round"
            icon={<EyeOutlined />}
            style={{ background: "#f5f5f5", color: "black" }}
          >
            English Policy
          </Button>
        </>
      ),
    },
    {
      title: "Activiation",
      dataIndex: "enabled",
      key: "enabled",

      render: (txt, record) => (
        <>
          <Switch
            style={{
              display: "flex",
              justifyContent: "center",
              margin: " auto",
            }}
            className="greenSwitch"
            size="middle"
            onChange={(value) => handleUpdateFairStatus(record, value)}
            checked={record?.enabled}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader-1">
          <h2>Fairs</h2>
          <Button
            type="primary"
            size="middle"
            onClick={() => {
              history.push("/add-fair");
            }}
          >
            ADD
          </Button>
        </Space>

        <div className="container">
          {fairsData?.length > 0 ? (
            <Table
              className="table2"
              columns={fairsColumns}
              dataSource={fairsData}
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/fairs/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "20%" }}>
              No Fairs Records Found.
            </div>
          )}
        </div>
        <PolicyDetails
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          policyString={policyString}
        />
        <Totop />
      </div>
    </>
  );
};

export default Fairs;

import { Form, Input, message, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { addOrEditWithdrawalTypeApi } from "../../../KIWE-V1/Services/withdrawalTypeApi";
const Add = ({
  isModalVisible,
  setIsModalVisible,
  record,
  setWithdrawalTypeData,
  withdrawalTypeData,
}) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(null);
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      console.log(length);
      if (length !== 0) {
        setInitialValues({
          name: record?.name,
        });
      } else {
        form.setFieldsValue({ name: "" });

        setInitialValues(null);
      }
    }
  }, [record]);
  const handleAddOrEditWithdrawalType = async (values) => {
    try {
      await addOrEditWithdrawalTypeApi(values, record);
      message.success(
        `Withdrawal Type is ${record ? "updated" : "added"} successfully!`
      );
      // if(record){
      //   setWithdrawalTypeData((prev)=>{
      //    return prev.map((element)=>{
      //       if(element.id===record.id){
      //         return {...values,id:record.id};
      //       }
      //       return element
      //     })
      //   })
      // }else{

      //         setWithdrawalTypeData((prev)=>{
      //           return [...prev,{...values,id:prev.length+1}]
      //         })

      window.location.reload();

      // }
      handleCancel();
    } catch (error) {
      message.error(error.message);
    }
  };

  // const onFinishFailed = () => {
  //   message.error('Submit failed!');
  // };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (record) {
      form.setFieldsValue({ name: record?.name });
    }
  }, [record, form]);
  return (
    <>
      <Modal
        title={record?.id ? "Edit Withdrawal Type" : "Add Withdrawal Type"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddOrEditWithdrawalType}
          autoComplete="off"
          initialValues={{
            name: record?.name,
          }}
        >
          <Form.Item
            name="name"
            label="Fees :"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Withdrawal Type Name!",
              },
            ]}
          >
            <Input
              value={record?.name}
              style={{ borderRadius: "25px", height: "2.5rem" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              Add Withdrawal Type
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import { Space, Button, Table, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";

import Add from "./add";

import { fetchSellingTypeDataAndDecryptResponse } from "../../../KIWE-V1/Services/sellingTypeAPI.js";
const SellingType = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [sellingType, setSellingType] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchSellingType = async () => {
    try {
      const apiResponse = await fetchSellingTypeDataAndDecryptResponse();

      setSellingType(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchSellingType();
  }, []);

  const columns = [
    {
      title: "Selling Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type),
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              onClick={() => {
                showModal();
                setRecord(record);
              }}
              // style={{ margin: '.5rem' }}
            >
              Edit
            </Button>
            {/* <Button type='danger' className='delete'>
              Delete
            </Button> */}
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Selling Type</h2>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal();
                setRecord({});
              }}
            >
              ADD
            </Button>

            <Add
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={sellingType}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default SellingType;

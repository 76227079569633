import { GET_ALL_INDUSTRY } from '../actionTypes';
import { getAllIndustryAsync } from './api';

export const getIndustry = () => {
  return async (dispatch) => {
    try {
      const result = await getAllIndustryAsync();

      dispatch(getIndustrySuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getIndustrySuccess = (industry) => {
  return {
    type: GET_ALL_INDUSTRY,
    payload: industry,
  };
};

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestData).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(encryptedResponse?.data?.data);
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const getWithdrawalTypeApi = async () => {
  try {
    const decryptedResponse = await apiCall("withdrawal_type/list", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

const buildAddOrEditWithdrawalTypeRequestBody = (values, record) => {
  return {
    name: values.name,
    id: record?.id,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addOrEditWithdrawalTypeApi = async (values, record) => {
  const data = buildAddOrEditWithdrawalTypeRequestBody(values, record);
  if (record) {
    try {
      const decryptedResponse = await apiCall(
        "admin/update_withdrawal_type",
        data
      );
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  } else {
    try {
      const decryptedResponse = await apiCall(
        "admin/add_withdrawal_type",
        data
      );
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  }
};

import axiosInstance from "../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "./Helper";

const buildUploadImageS3RequestBody = (imageName, uploadType, fileBase64) => {
  return {
    name: imageName,
    uploadType: uploadType,
    fileBase64: fileBase64,
  };
};
export const uploadImageS3 = async (imageName, uploadType, fileBase64) => {
  try {
    console.log(imageName, "imageName");
    const url = "v2/admin/card-user/upload";
    const requestBody = buildUploadImageS3RequestBody(
      imageName,
      uploadType,
      fileBase64
    );
    const apiResponse = await axiosInstance().post(url, {
      data: convertPlantextToEncrypted(requestBody),
    });
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptError;
  }
};
export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(null);
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Data = event.target.result.split(",")[1];
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const uploadOldImageS3 = async (imageName, uploadType, fileBase64) => {
  try {
    console.log(imageName, "imageName");
    const url = "admin/upload";
    const requestBody = buildUploadImageS3RequestBody(
      imageName,
      uploadType,
      fileBase64
    );
    const apiResponse = await axiosInstance().post(url, {
      data: convertPlantextToEncrypted(requestBody),
    });
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptError;
  }
};


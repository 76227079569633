import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import InputSearch from "../form/InputSearch";
import { useHistory } from "react-router";
import swal from "sweetalert2";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  fetchKiwePromotionData,
  deleteKiwePromotionAPI,
} from "../../../KIWE-V1/Services/kiwePromotionApi.js";
import { useKiwePromotion } from "../../../contextAPi/KiwePromotion/KiwePromotionContext";
const Promotions = (props) => {
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const { kiwePromotion, setKiwePromotion } = useKiwePromotion();
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");

  const history = useHistory();
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <span>{moment(text).format("D MMM, yyyy h:mm a")}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <span>{moment(text).format("D MMM, yyyy h:mm a")}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",

      render: (text) => (
        <div>
          {text ? (
            <span style={{ color: "#20CE24" }}>Active</span>
          ) : (
            <span style={{ color: "#FD5394" }}>Not Active</span>
          )}
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "edit",

      key: "edit",
      render: (text, record) => (
        <div
          style={{
            display: " flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{
              backgroundColor: "#FD5394",
              color: "white",
              border: "none",
              // marginRight: "-1rem",
            }}
            type="primary"
            onClick={() => handleDeleteKiwePromotion(record)}
            size="middle"
          >
            Delete
          </Button>
          <Link>
            <Button
              style={{
                backgroundColor: "#002984",
                color: "white",
                border: "none",
              }}
              type="primary"
              onClick={() =>
                history.push("/promotion-user", {
                  edit: true,
                  id: record?.id,
                  record,
                })
              }
              size="middle"
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  const onSearch = (value) => setSearchTextFromInput(value);
  const handleDeleteKiwePromotion = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure you want to delete this Promotion ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteKiwePromotionAsync() {
            try {
              const decryptedResponse = await deleteKiwePromotionAPI(record);
              if (decryptedResponse) {
                setKiwePromotion((prevKiwePromotion) =>
                  prevKiwePromotion.filter(
                    (kiwePromotion) => kiwePromotion.id !== record.id
                  )
                );
                const configSuccess = {
                  className: "messageSuccess",
                  content: "Kiwe Promotion deleted successfully!!",
                };
                message.success(configSuccess);
              }
              if (kiwePromotion.length <= 1) {
                const previousPage = pageNum > 1 ? pageNum - 1 : 1;

                history.push(`/view_promotions/?pageNum=${previousPage}`);
              }
            } catch (error) {
              const configError = {
                className: "messageError",
                content: "Something went wrong please try again",
              };
              message.error(configError);
            }
          }
          deleteKiwePromotionAsync();
        }
      });
  };
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const fetchKiwePromotinData = async (page) => {
    try {
      const apiResponse = await fetchKiwePromotionData(
        page,
        searchTextFromInput
      );
      setTotalPages(apiResponse.totalRecords);
      setKiwePromotion(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    if (pageNum) {
      fetchKiwePromotinData(pageNum, searchTextFromInput);
    } else {
      fetchKiwePromotinData(1, searchTextFromInput);
    }
  }, [pageNum]);

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Kiwe Promotions</h2>
          <Space>
            <InputSearch onSearch={onSearch} />

            <Button
              type="primary"
              size="middle"
              onClick={() =>
                history.push("/promotion-user", {
                  edit: false,
                })
              }
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          <Table
            columns={columns}
            dataSource={kiwePromotion}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNum ? +pageNum : 1,

              onChange: (page) => {
                setPage(page);
                history.push(`/view_promotions/?pageNum=${page}`);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Promotions;

import { Input, Button, Form, message, Modal } from "antd";
import {
  updateUserByAdmin,
  updateUserStatus,
} from "../../../KIWE-V1/Services/userAPI";

const BusinessAdjustment = ({
  isModalVisible2,
  setIsModalVisibleB,
  record,
}) => {
  const [form] = Form.useForm();
  const handleUpdateStatusUser = () => {
    const newActiveStatus = !record?.active;
    updateUserStatus(record?.userId, newActiveStatus)
      .then((response) => {
        debugger;
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUserByAdminAndStatus = async (values) => {
    try {
      await updateUserByAdmin(values, record);
      handleUpdateStatusUser();
      handleCancel();
    } catch (error) {
      message.error(error.message);
    }
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisibleB(false);
  };
  return (
    <Modal
      title={"Business Adjustment"}
      visible={isModalVisible2}
      onCancel={handleCancel}
      footer={null}
      className="modal"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={updateUserByAdminAndStatus}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={
          record?.active
            ? {
                taxIdNumber: record?.taxIdNumber,
                businessRegistrationNumber: record?.businessRegistrationNumber,
              }
            : {}
        }
      >
        <Form.Item
          name="businessRegistrationNumber"
          label="Registration Number"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input yourRegistration Number!",
            },
          ]}
          className="offer-name"
        >
          <Input className="input-border" />
        </Form.Item>
        <Form.Item
          name="taxIdNumber"
          label="Tax ID"
          hasFeedback
          rules={[{ required: true, message: "Please input your Tax ID!" }]}
          className="offer-name"
        >
          <Input className="input-border" />
        </Form.Item>
        <Form.Item className="btns">
          <Button size="middle" htmlType="submit" className="btns__offer-save">
            Save
          </Button>
          <Button
            size="middle"
            className="btns__offer-cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BusinessAdjustment;

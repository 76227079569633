const Branch = ({ record, cardFlag }) => {
  //   const { branches } = record;
  //   console.log(record.businessName);
  return (
    <>
      {cardFlag ? (
        <ul className="branch">
          {record?.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      ) : (
        <ul className="branch">
          {record?.branches.map((item) => (
            <li>{item.businessName}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Branch;

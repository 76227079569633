import React, { useEffect, useState } from "react";
import { UpOutlined } from "@ant-design/icons";

const Totop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      duration: 500,
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  });
  return (
    <React.Fragment>
      <div
        className="totop"
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      >
        <div className="totop__arrow">
          <UpOutlined
            style={{
              display: "flex",
              justifyContent: "center",
              height: "1.1rem",
              width: "20px",
              margin: "5px auto",
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Totop;

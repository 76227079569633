import { GET_ALL_INDUSTRY_TYPE } from '../actionTypes';
const initialState = {
  allIndustryType: [],
};
const industryTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INDUSTRY_TYPE:
      return { ...state, allIndustryType: action.payload };

    default:
      return state;
  }
};
export default industryTypeReducer;

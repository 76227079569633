import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let response;
    if (Object.keys(requestData).length !== 0) {
      response = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      response = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(response?.data?.data);
    }
    return response;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
export const getAdmins = async (page, searchText) => {
  try {
    const response = await apiCall(
      `admin/list?page=${page}&name=${searchText}&email=${searchText}`,
      {},
      "get"
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteAdmin = async (id) => {
  try {
    const response = await apiCall(
      `admin/remove/account/${id}`,
      {},
      "delete",
      false
    );
    return response.status === 200;
  } catch (error) {
    throw error;
  }
};
const buildEditAdminRequestBody = (record, values) => {
  return {
    userId: record.userId,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    roles: values.role,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const editAdminApi = async (record, values) => {
  try {
    const data = buildEditAdminRequestBody(record, values);
    const response = await apiCall("admin/edit", data, "put");
    return response;
  } catch (error) {
    throw error;
  }
};
export const addAdminApi = async (data) => {
  try {
    const response = await apiCall("admin/register", data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getAdminsRoles = async () => {
  try {
    const response = await apiCall("admin/adminRoles", {}, "get");
    return response;
  } catch (error) {
    throw error;
  }
};
const buildEditAdminProfileRequestBody = (values) => {
  return {
    userId: localStorage.getItem("user_id"),
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    password: values.password,
  };
};
export const editAdminProfileApi = async (values) => {
  try {
    const requestBody = buildEditAdminProfileRequestBody(values);
    const apiResponse = await apiCall("admin/edit", requestBody, "put");
    return apiResponse;
  } catch (error) {
    throw error;
  }
};

import React, { createContext, useState, useContext } from "react";

const KiwePromotionContext = createContext();

export function KiwePromotionProvider({ children }) {
  const [kiwePromotion, setKiwePromotion] = useState([]);

  return (
    <KiwePromotionContext.Provider value={{ kiwePromotion, setKiwePromotion }}>
      {children}
    </KiwePromotionContext.Provider>
  );
}

export function useKiwePromotion() {
  return useContext(KiwePromotionContext);
}

import React from "react";
import { Layout } from "antd";
import __menu from "../../assets/images/menu.svg";
import "./SideBar.css";
const { useState } = React;

const SideBar = ({ menu, collapsed }) => {
  return (
    <Layout.Sider
      className={collapsed ? "test" : "sidebar"}
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      collapsible
      collapsed={collapsed}
      trigger={null}
      width={208}
      // style={{ width: "unset !important" }}
    >
      {menu}
    </Layout.Sider>
  );
};

export default SideBar;

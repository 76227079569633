import { Table, message, Tag, Space, Cascader, Select, DatePicker } from "antd";
import { FlagFilled } from "@ant-design/icons";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { Link, useHistory } from "react-router-dom";
import {
  fetchCardUserData,
  updateUserCardFlagged,
} from "../../../KIWE-V2/Services/userAPI.js";
import InputSearch from "../../front/form/InputSearch";
const CardUser = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const search_text = params.get("search_text");
  const history = useHistory();
  const Option = Select;

  const [selectedRowKey, setSelectedRowKey] = useState({});

  const [searchText, setSearchText] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [ekycStatus, setEkycStatus] = useState(null);
  const [cardRequestStatus, setCardRequestStatus] = useState(null);
  const [cardStatus, setCardStatus] = useState(null);

  const [amlVerificationStatus, setAmlVerificationStatus] = useState(null);
  const [pickuped, setPickuped] = useState(null);

  const [cardUserData, setCardUserData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [record, setRecord] = useState([]);

  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedPickUpDate, setSelectedPickUpDate] = useState([]);
  const [selectedRequestDate, setSelectedRequestDate] = useState([]);

  const onSearch = (value) => {
    setSearchText(value);
    history.push(`/card-user/?pageNum=1`);

    fetchCardUser(
      1,
      value,
      searchTextFilter,
      cardStatus,
      pickuped,
      ekycStatus,
      cardRequestStatus,
      amlVerificationStatus,
      selectedDate,
      selectedPickUpDate,
      selectedRequestDate
    );
  };
  const onSearchFilter = () => {};
  const onChangeFilter = (value) => {
    setSearchTextFilter(value);
  };
  const handleDateChange = (dates, dateStrings) => {
    setSelectedDate(dateStrings);
    if (
      !dateStrings ||
      (Array.isArray(dateStrings) && dateStrings.every((str) => !str))
    ) {
      setSelectedDate([]);

      history.push(`/card-user/?pageNum=1`);
      fetchCardUser(
        1,
        searchText,
        searchTextFilter,
        cardStatus,
        pickuped,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        [],
        selectedPickUpDate,
        selectedRequestDate
      );
    }
  };
  const handlePickUpDateChange = (dates, dateStrings) => {
    setSelectedPickUpDate(dateStrings);
    if (
      !dateStrings ||
      (Array.isArray(dateStrings) && dateStrings.every((str) => !str))
    ) {
      setSelectedPickUpDate([]);

      history.push(`/card-user/?pageNum=1`);
      fetchCardUser(
        1,
        searchText,
        searchTextFilter,
        cardStatus,
        pickuped,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        selectedDate,
        [],
        selectedRequestDate
      );
    }
  };
  const handleRequestDateChange = (dates, dateStrings) => {
    setSelectedRequestDate(dateStrings);
    if (
      !dateStrings ||
      (Array.isArray(dateStrings) && dateStrings.every((str) => !str))
    ) {
      setSelectedRequestDate([]);

      history.push(`/card-user/?pageNum=1`);
      fetchCardUser(
        1,
        searchText,
        searchTextFilter,
        cardStatus,
        pickuped,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        selectedDate,
        selectedPickUpDate,
        []
      );
    }
  };
  const onChangeFilterColumns = (value) => {
    if (!value || (value.length === 1 && !value[0])) {
      setSelectedDate([]);
      setSelectedPickUpDate([]);
      setSelectedRequestDate([]);
      setCardStatus(null);
      setCardRequestStatus(null);
      setAmlVerificationStatus(null);
      setEkycStatus(null);
      setPickuped(null);
      fetchCardUser(
        1,
        searchText,
        searchTextFilter,
        null,
        null,
        null,
        null,
        null,
        [],
        [],
        []
      );
      return;
    }
    if (value[0] === "cardStatus") {
      setCardStatus(value[1]);
    }
    if (value[0] === "cardStatus" && value[1] === "ALL") {
      setCardStatus(null);
    }
    if (value[0] === "pickuped") {
      setPickuped(value[1]);
    }
    if (value[0] === "pickuped" && value[1] === "ALL") {
      setPickuped(null);
    }
    if (value[0] === "ekycStatus") {
      setEkycStatus(value[1]);
    }
    if (value[0] === "ekycStatus" && value[1] === "ALL") {
      setEkycStatus(null);
    }
    if (value[0] === "cardRequestStatus") {
      setCardRequestStatus(value[1]);
    }
    if (value[0] === "cardRequestStatus" && value[1] === "ALL") {
      setCardRequestStatus(null);
    }
    if (value[0] === "amlVerificationStatus") {
      setAmlVerificationStatus(value[1]);
    }
    if (value[0] === "amlVerificationStatus" && value[1] === "ALL") {
      setAmlVerificationStatus(null);
    }

    history.push(`/card-user/?pageNum=1`);
    fetchCardUser(
      1,
      searchText,
      searchTextFilter,
      cardStatus,
      pickuped,
      ekycStatus,
      cardRequestStatus,
      amlVerificationStatus,
      selectedDate,
      selectedPickUpDate,
      selectedRequestDate
    );
  };
  const options = [
    {
      value: "cardStatus",
      label: "Card Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "ACTIVE",
          label: "ACTIVE",
        },
        {
          value: "FROZEN",
          label: "FROZEN",
        },
        {
          value: "DEACTIVATED",
          label: "DEACTIVATED",
        },
      ],
    },
    {
      value: "pickuped",
      label: "Picked Up",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: false,
          label: "Picked Up",
        },
        {
          value: true,
          label: "Not Picked Up",
        },
      ],
    },
    {
      value: "ekycStatus",
      label: "EKYC Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "REJECTED",
          label: "REJECTED",
        },
        {
          value: "FAILED",
          label: "FAILED",
        },
        {
          value: "APPROVED",
          label: "APPROVED",
        },
      ],
    },
    {
      value: "cardRequestStatus",
      label: "Card Issuance Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "REQUESTED",
          label: "REQUESTED",
        },
        {
          value: "ISSUED",
          label: "ISSUED",
        },
        {
          value: "RECEIVED",
          label: "RECEIVED",
        },
      ],
    },
    {
      value: "amlVerificationStatus",
      label: "AML Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "APPROVED",
          label: "APPROVED",
        },
        {
          value: "REJECTED",
          label: "REJECTED",
        },
      ],
    },

    {
      value: "createdTimeStamp",
      label: "Joining Date",
      children: [
        {
          value: "datepicker",

          label: <DatePicker.RangePicker onChange={handleDateChange} />,
        },
      ],
    },
    {
      value: "pickTimeStamp",
      label: "PickUp Date",
      children: [
        {
          value: "pickup",
          label: <DatePicker.RangePicker onChange={handlePickUpDateChange} />,
        },
      ],
    },
    {
      value: "cardRequestDate",
      label: "Request Date",
      children: [
        {
          value: "request",
          label: <DatePicker.RangePicker onChange={handleRequestDateChange} />,
        },
      ],
    },
  ];
  const fetchCardUser = async (
    page,
    searchText,
    searchTextFilter,
    cardStatus,
    pickuped,
    ekycStatus,
    cardRequestStatus,
    amlVerificationStatus,
    dateStrings,
    pickupDate,
    requestDate
  ) => {
    try {
      const apiResponse = await fetchCardUserData(
        page,
        searchText,
        searchTextFilter,
        cardStatus,
        pickuped,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        dateStrings,
        pickupDate,
        requestDate
      );

      setLoading(false);
      setCardUserData(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);
      const decryptedError = convertEncryptedToPlantext(error?.message);
      message.error(decryptedError);
    }
  };
  const setRowKey = (record) => {
    const selectedRowKey = getRowKey(record);

    setSelectedRowKey(record);
  };

  const getRowKey = (record) => {
    return `${record.key}`;
  };

  useEffect(() => {
    if (pageNum) {
      fetchCardUser(
        pageNum,
        searchText,
        searchTextFilter,
        cardStatus,
        pickuped,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        selectedDate,
        selectedPickUpDate,
        selectedRequestDate
      );
    } else {
      fetchCardUser(
        1,
        searchText,
        searchTextFilter,
        cardStatus,
        pickuped,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        selectedDate,
        selectedPickUpDate,
        selectedRequestDate
      );
    }
  }, [
    pageNum,
    searchText,
    cardStatus,
    pickuped,
    ekycStatus,
    cardRequestStatus,
    amlVerificationStatus,
    selectedDate,
    selectedPickUpDate,
    selectedRequestDate,
  ]);
  const handleUpdateUserFlagged = (record) => {
    const newFlagged = !record.flagged;
    updateUserCardFlagged(record?.id, newFlagged)
      .then((response) => {
        let updatedUsers = cardUserData.map((user) => {
          if (user.id === record.id) return { ...user, flagged: newFlagged };
          return user;
        });
        setCardUserData(updatedUsers);
      })
      .catch((error) => {
        const configError = {
          className: "messageError",
          content: error.message,
        };
        message.error(configError);
      });
  };

  const cardUserColumns = [
    {
      title: "Add Flag",
      dataIndex: "flagged",
      key: "flagged",

      render: (txt, record) => (
        <>
          {record?.flagged === false ? (
            <FlagFilled
              style={{ cursor: "pointer" }}
              onClick={() => handleUpdateUserFlagged(record)}
            />
          ) : (
            <FlagFilled
              style={{ color: "#FECD2F", cursor: "pointer" }}
              onClick={() => handleUpdateUserFlagged(record)}
            />
          )}
        </>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
      render: (text, record) => (
        <>
          {" "}
          <Link
            style={{ color: "#000000" }}
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
          >
            {text}
          </Link>
        </>
      ),
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a?.mobile - b?.mobile,

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
            style={{ color: "#000000" }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowKey(record);
              }}
            >
              {record?.mobile ? record?.mobile : "-"}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: "EKYC Status",
      dataIndex: "ekycStatus",
      key: "ekycStatus",

      render: (txt, record) => (
        <>
          <Link
            style={{ color: "#000000" }}
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
          >
            {record?.ekycStatus === "PENDING" ? (
              <div className="custom-tag onboarding-status-pending">
                Pending
              </div>
            ) : record?.ekycStatus === "REJECTED" ? (
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
              >
                Rejected
              </div>
            ) : record?.ekycStatus === "APPROVED" ? (
              <div
                color="green"
                className="custom-tag onboarding-status-approved"
              >
                Approved
              </div>
            ) : record?.ekycStatus === "FAILED" ? (
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
              >
                Failed
              </div>
            ) : (
              "-"
            )}
          </Link>
        </>
      ),
    },
    {
      title: "Card Issuance Status",
      dataIndex: "cards",
      key: "cards",
      render: (text, record) => (
        <>
          {" "}
          <Link
            style={{ color: "#000000" }}
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {record?.cards[0]?.cardRequestStatus === "PENDING" ? (
                <Tag
                  color="orange"
                  className="custom-tag onboarding-status-pending"
                >
                  Pending
                </Tag>
              ) : record?.cards[0]?.cardRequestStatus === "REQUESTED" ? (
                <Tag
                  color="blue"
                  style={{ padding: "0.1rem 2.4rem" }}
                  className="custom-tag"
                >
                  Requested
                </Tag>
              ) : (
                <Tag color="green" className="custom-tag">
                  Issued
                </Tag>
              )}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: "Card Status",
      dataIndex: "cards",
      key: "cards",

      render: (txt, record) => (
        <>
          {" "}
          <Link
            style={{ color: "#000000" }}
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {record?.cards[0]?.cardStatus &&
              record?.cards[0]?.cardStatus === "PENDING" ? (
                <div className="custom-tag onboarding-status-pending">
                  Pending
                </div>
              ) : record?.cards[0]?.cardStatus === "ACTIVE" ? (
                <div className="custom-tag onboarding-status-approved">
                  Active
                </div>
              ) : record?.cards[0]?.cardStatus === "FROZEN" ? (
                <Tag
                  color="blue"
                  style={{ padding: "0.1rem 2.4rem" }}
                  className="custom-tag"
                >
                  Frozen
                </Tag>
              ) : record?.cards[0]?.cardStatus === "DEACTIVATED" ? (
                <div className="custom-tag onboarding-status-rejected">
                  Deactivated
                </div>
              ) : (
                "-"
              )}
            </div>
          </Link>
        </>
      ),
    },
    {
      title: "Bank KYC Status",
      dataIndex: "cards",
      key: "cards",
      render: (txt, record) => (
        <>
          {" "}
          <Link
            style={{ color: "#000000" }}
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {record?.cards[0]?.bankKycStatus === "PENDING"
                ? "Light"
                : record?.cards[0]?.bankKycStatus}
            </div>
          </Link>
        </>
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (txt, record) => (
        <>
          {" "}
          <Link
            style={{ color: "#000000" }}
            to={{
              pathname: "/card-details",
              state: { recordData: record },
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {record?.rewardLevel?.name ? record?.rewardLevel?.name : "-"}
            </div>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader-1">
          <h2>Card Users</h2>
          <Space>
            <Select
              showSearch
              className="onboarding_filter_select"
              optionFilterProp="children"
              onChange={onChangeFilter}
              onSearch={onSearchFilter}
              style={{ marginRight: ".2rem" }}
              defaultValue={null}
            >
              <Select.Option value={null}>Search By</Select.Option>
              <Select.Option value="username">Username</Select.Option>
              <Select.Option value="mobile">Mobile</Select.Option>
              <Select.Option value="email">Email</Select.Option>
            </Select>
            <InputSearch
              onSearch={onSearch}
              searchTextt={search_text ? search_text : searchText}
            />

            <Cascader
              options={options}
              onChange={onChangeFilterColumns}
              className="custom-cascader"
              placeholder="Filter"
              dropdownMenuColumnStyle={{
                height: "2rem",

                overflow: "hidden",
              }}
            />
          </Space>
        </Space>
        <div className="container">
          {cardUserData?.length > 0 ? (
            <Table
              className="table2"
              columns={cardUserColumns}
              dataSource={cardUserData}
              rowKey="id"
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/card-user/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No Card User Records Found.
            </div>
          )}
        </div>

        <Totop />
      </div>
    </>
  );
};

export default CardUser;

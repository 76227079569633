import { pageNotFound } from '../shared/images';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
const PageNotFound = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/login');
  };
  return (
    <>
      <div style={{ margin: '1rem' }}>
        <div className='container-NotFound'>
          <div className='container-NotFound__image'>
            <img src={pageNotFound} alt='' />
          </div>
          <div className='container-NotFound__content'>
            <h2 className='container-NotFound__content__header'>
              {' '}
              Page Not Found
            </h2>
            <p className='container-NotFound__content__para'>
              Sorry, we can’t find the page you’re looking for.
            </p>

            <Button
              type='primary'
              className='container-NotFound__content__btn'
              onClick={handleClick}
            >
              Back To Home
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;

import axiosInstance from "../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../library/Helper";

export const fetchTicketsDataAndDecryptResponse = async (
  userId,
  page,
  limit = 10
) => {
  try {
    const response = await axiosInstance().get(
      `admin/user-purchased-tickets/${userId}?page=${page}&limit=${limit}`
    );
    if (response.status === 200) {
      const decryptedResponse = convertEncryptedToPlantext(
        response?.data?.data
      );
      return decryptedResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const redeemTicketsDataResponse = async (userTicket) => {
  try {
    const response = await axiosInstance().put(`/tickets/redeem/${userTicket}`);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    throw error;
  }
};
export const refundTicketsDataResponse = async (userTicket) => {
  try {
    const response = await axiosInstance().post(`tickets/refund/${userTicket}`);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

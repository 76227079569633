import { useState } from "react";
import UserDetails from "./userDetails";
import { useLocation } from "react-router-dom";

const CardDetails = () => {
  const location = useLocation();
  const { recordData } = location.state;
  return (
    <>
      {" "}
      <div style={{ margin: "1rem" }}>
        <h2>Card User Details</h2>
        <div className="container">
          {" "}
          <UserDetails record={recordData} />
        </div>
      </div>
    </>
  );
};

export default CardDetails;

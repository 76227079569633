import { GET_ALL_COMPANY } from '../actionTypes';
import { getAllCompanyAsync } from './api';

export const getCompanies = () => {
  return async (dispatch) => {
    try {
      const result = await getAllCompanyAsync();

      dispatch(getCompanySuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getCompanySuccess = (companies) => {
  return {
    type: GET_ALL_COMPANY,
    payload: companies,
  };
};

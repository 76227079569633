// import { Modal, Button } from "antd";
// import { useState } from "react";

// import axiosInstance from "../../../helpers/axiosInstance";
// import {
//   convertEncryptedToPlantext,
//   convertPlantextToEncrypted,
//   getCurrentTimeStemp,
// } from "../../../library/Helper";
// import { S3_URL } from "../../../constant";
// import moment from "moment";
// const BusinessUserDetail = ({
//   handleCancelDetails,
//   isModalVisible1,
//   record,
// }) => {
//   const [isModalVisibleDocument, setIsModalVisibleDocument] = useState(false);
//   const [documents, setDocuments] = useState([]);
//   const [recordd, setRecord] = useState([]);

//   const handleViewClick = (data) => {
//     axiosInstance()
//       .post("admin/businessDocs", {
//         data: convertPlantextToEncrypted({
//           userId: data.userId,
//           expiryTime: getCurrentTimeStemp(),
//         }),
//       })
//       .then((response) => {
//         if (response.status === 200) {
//           var api_response = convertEncryptedToPlantext(response?.data?.data);
//           console.log(api_response);
//           setDocuments(api_response);
//           showModalDocument(data);
//         }
//       })
//       .catch((error) => {
//         console.log(error.response.data.data);
//         showModalDocument(data);
//       });
//   };
//   const showModalDocument = (record) => {
//     setIsModalVisibleDocument(true);
//     setRecord(record);
//   };
//   const handleCancelDocument = () => {
//     setIsModalVisibleDocument(false);
//   };
//   return (
//     <Modal
//       title={record?.businessName + "|Kiwe Business Details"}
//       visible={isModalVisible1}
//       onCancel={handleCancelDetails}
//       footer={null}
//       className="modal-business-Detail"
//       width={950}
//     >
//       <div className="modal-above">
//         <div className="column1">
//           <div className="column1__column">
//             <span className="column1__column__header">User ID</span>
//             <span className="column1__column__footer">
//               {record?.userId ? record?.userId : "No Return"}
//             </span>
//           </div>
//           <div className="column1__column">
//             <span className="column1__column__header">Business Name</span>
//             <span className="column1__column__footer">
//               {record?.businessName ? record?.businessName : "No Return"}
//             </span>
//           </div>
//           <div className="column1__column">
//             <span className="column1__column__header">Email</span>
//             <span className="column1__column__footer">
//               {record?.email ? record?.email : " - "}
//             </span>
//           </div>

//           <div className="column1__column">
//             <span className="column1__column__header">Tax ID Number</span>
//             <span className="column1__column__footer">
//               {record?.taxIdNumber ? record?.taxIdNumber : "-"}
//             </span>
//           </div>
//           <div className="column1__column">
//             <span className="column1__column__header">Selling Type</span>
//             <span className="column1__column__footer">
//               {record?.sellingType ? record?.sellingType : "-"}
//             </span>
//           </div>
//           <div className="column1__column">
//             <span className="column1__column__header">Industry</span>
//             <span className="column1__column__footer">
//               {record?.industryName ? record?.industryName : "-"}
//             </span>
//           </div>
//         </div>

//         <div className="column2">
//           <div className="column2__column">
//             <span className="column2__column__header">
//               Business Description
//             </span>
//             <span className="column2__column__footer">not return</span>
//           </div>

//           <div className="column2__column">
//             <span className="column2__column__header">Mobile Number </span>
//             <span className="column2__column__footer">
//               {record?.mobile ? record?.mobile : "-"}
//             </span>
//           </div>
//           <div className="column2__column">
//             <span className="column2__column__header">Website</span>
//             <span className="column2__column__footer">
//               {record?.website ? record?.website : "-"}
//             </span>
//           </div>
//           <div className="column2__column">
//             <span className="column2__column__header">Business Type</span>
//             <span className="column2__column__footer">
//               {record?.typeOfBusiness ? record?.typeOfBusiness : "-"}
//             </span>
//           </div>
//           <div className="column2__column">
//             <span className="column2__column__header">Registeration No</span>
//             <span className="column2__column__footer">
//               {record?.businessRegistrationNumber
//                 ? record?.businessRegistrationNumber
//                 : "-"}
//             </span>
//           </div>
//           <div className="column2__column">
//             <span className="column2__column__header">Created At</span>
//             <span className="column2__column__footer">
//               {record?.createdDate
//                 ? moment(record?.createdDate).format("D MMM, yyyy h:mm a")
//                 : "-"}
//             </span>
//           </div>
//         </div>
//         <div className="column4">
//           <div className="column4__column">
//             <span className="column4__column__header">Address</span>
//             <span className="column4__column__footer">
//               {record?.address ? record?.address : "-"}
//             </span>
//           </div>

//           <div className="column4__column">
//             <span className="column4__column__header">Person in Charge </span>
//             <span className="column4__column__footer">
//               {record?.firstName ? record?.firstName : "-"}
//             </span>
//           </div>
//           <div className="column4__column">
//             <span className="column4__column__header">Status</span>
//             <span className="column4__column__footer">
//               {record?.active ? "Active" : "Inactive"}
//             </span>
//           </div>
//           <div className="column4__column">
//             <span className="column4__column__header">
//               Allow Auto Withdrawal
//             </span>
//             <span className="column4__column__footer">
//               {record?.isAutoWithdrawal ? "Yes" : "No"}
//             </span>
//           </div>
//           <div className="column4__column">
//             <span className="column4__column__header">
//               Business Transaction Fees
//             </span>
//             <span className="column4__column__footer">
//               {record?.businessTransactionFees}%
//             </span>
//           </div>
//           <div className="column4__column">
//             <span
//               className="column4__column__header"
//               style={{ marginTop: ".444rem" }}
//             >
//               Documents
//             </span>
//             <span className="column4__column__footer">
//               <Button
//                 className="btn-icon"
//                 size="middle"
//                 onClick={() => handleViewClick(record)}
//                 type="primary"
//                 shape="round"
//                 style={{ marginLeft: "0rem", marginTop: ".22rem" }}
//               >
//                 Documents
//               </Button>
//             </span>
//           </div>
//         </div>
//       </div>
//       <div className="column3">
//         <Button
//           type="primary"
//           className="column1__btn-export"
//           onClick={handleCancelDetails}
//         >
//           Close
//         </Button>
//         {/* <Button type="primary" className="column2__btn-print">
//               Print
//             </Button> */}
//         <Modal
//           title={record?.businessName + " - Documents "}
//           visible={isModalVisibleDocument}
//           onCancel={handleCancelDocument}
//           footer={null}
//           className="modal"
//         >
//           <div
//             style={{
//               display: "flex",
//               margin: "0 auto",
//               justifyContent: "center",
//             }}
//           >
//             <table>
//               {documents.length > 0 ? (
//                 documents.map((item, i) => (
//                   <tr key={i}>
//                     <td>
//                       <a
//                         target="_blank"
//                         href={
//                           S3_URL +
//                           record.userId +
//                           "/attachment/" +
//                           item.mediaPath
//                         }
//                       >
//                         {item.mediaTitle}
//                       </a>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td>No Records Found</td>
//                 </tr>
//               )}{" "}
//             </table>
//           </div>

//           {/* <Button
//             size='middle'
//             type='primary'
//             shape='round'
//             onClick={handleCancelDocument}
//             style={{ display: 'flex', margin: '0 auto' }}
//           >
//             Close
//           </Button> */}
//         </Modal>
//       </div>
//     </Modal>
//   );
// };

// export default BusinessUserDetail;
import { Modal, Button } from "antd";
import { useState } from "react";

import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { S3_URL } from "../../../constant";
import moment from "moment";
const BusinessUserDetail = ({
  handleCancelDetails,
  isModalVisible1,
  record,
}) => {
  const [isModalVisibleDocument, setIsModalVisibleDocument] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [recordd, setRecord] = useState([]);

  const handleViewDocuments = (data) => {
    axiosInstance()
      .post("admin/businessDocs", {
        data: convertPlantextToEncrypted({
          userId: data.userId,
          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          var api_response = convertEncryptedToPlantext(response?.data?.data);
          console.log(api_response);
          setDocuments(api_response);
          showModalDocument(data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.data);

        showModalDocument(data);
      });
  };
  const showModalDocument = (record) => {
    setIsModalVisibleDocument(true);
    setRecord(record);
  };
  const handleCancelDocument = () => {
    setIsModalVisibleDocument(false);
  };

  return (
    <Modal
      title={record?.businessName + "|Kiwe Business Details"}
      visible={isModalVisible1}
      onCancel={handleCancelDetails}
      footer={null}
      className="modal-business-Detail"
      width={950}
    >
      <div style={{ width: "100%" }}>
        <h4
          style={{ fontWeight: "bold", fontSize: "25px", marginLeft: ".2rem" }}
        >
          Point Of Contact
        </h4>
        <div className="modal-above">
          <div className="column1">
            <div>
              <div className="column1__column">
                <span className="column1__column__header">User ID</span>

                <span className="column1__column__footer">
                  {record?.userId ? record?.userId : "No Return"}
                </span>
              </div>

              <div className="column1__column">
                <span className="column1__column__header">
                  Person in Charge
                </span>

                <span className="column1__column__footer">
                  {record?.firstName ? record?.firstName : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="column2">
            <div>
              <div className="column2__column">
                <span className="column2__column__header">Email</span>
                <span className="column2__column__footer">
                  {" "}
                  {record?.email ? record?.email : " - "}
                </span>
              </div>

              <div className="column2__column">
                <span className="column2__column__header">Status </span>
                {record?.active ? (
                  <span
                    className="column2__column__footer"
                    style={{ color: "#33b978" }}
                  >
                    Active{" "}
                  </span>
                ) : (
                  <span
                    className="column2__column__footer"
                    style={{ color: "#f55053" }}
                  >
                    Inactive
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="column4">
            <div className="column4__column">
              <span
                className="column4__column__header headerColumn4"
                style={{ marginTop: "0rem" }}
              >
                Mobile Number
              </span>
              <span className="column4__column__footer">
                {record?.mobile ? record?.mobile : "-"}
              </span>
            </div>
            <div className="column4__column col4">
              <span className="column4__column__header">Industry</span>
              <span className="column4__column__footer">
                {record?.industryName ? record?.industryName : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <h4
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            marginTop: "1rem",
            marginLeft: ".2rem",
          }}
        >
          Business Info
        </h4>
        <div className="modal-above">
          <div className="column1">
            <div>
              <div className="column1__column">
                <span className="column1__column__header">Business Name</span>

                <span className="column1__column__footer">
                  {record?.businessName ? record?.businessName : "No Return"}
                </span>
              </div>

              <div className="column1__column">
                <span className="column1__column__header">
                  Business Description
                </span>

                <span className="column1__column__footer">not return</span>
              </div>
            </div>
          </div>

          <div className="column2">
            <div>
              <div className="column2__column">
                <span className="column2__column__header">Business Type</span>
                <span className="column2__column__footer">
                  {" "}
                  {record?.typeOfBusiness ? record?.typeOfBusiness : "-"}
                </span>
              </div>

              <div className="column2__column">
                <span className="column2__column__header">Address </span>
                <span className="column2__column__footer">
                  {record?.address ? record?.address : "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="column4">
            <div className="column4__column">
              <span className="column4__column__header">Selling Type</span>
              <span className="column4__column__footer">
                {record?.sellingType ? record?.sellingType : "-"}
              </span>
            </div>

            <div className="column4__column">
              <span className="column4__column__header">Social Link</span>
              <span className="column4__column__footer">
                {record?.website ? record?.website : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <h4
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            marginTop: "1rem",
            marginLeft: ".2rem",
          }}
        >
          Legal
        </h4>
        <div className="modal-above">
          <div className="column1">
            <div className="column1__column">
              <span className="column1__column__header">
                Commerical Register Number
              </span>

              <span className="column1__column__footer">
                {record?.businessRegistrationNumber
                  ? record?.businessRegistrationNumber
                  : "-"}
              </span>
            </div>
          </div>

          <div className="column2">
            <div className="column2__column">
              <span className="column2__column__header">Tax ID Number</span>
              <span className="column2__column__footer">
                {" "}
                {record?.taxIdNumber ? record?.taxIdNumber : "-"}
              </span>
            </div>
          </div>

          <div className="column4">
            <div className="column4__column">
              <span className="column4__column__header">Created At</span>
              <span className="column4__column__footer">
                {record?.createdDate
                  ? moment(record?.createdDate).format("D MMM, yyyy h:mm a")
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="trans-doc">
        <div className="trans">
          <h4
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              marginTop: "1rem",
              marginLeft: ".2rem",
            }}
          >
            Transactions
          </h4>
          <div className="modal-above">
            <div className="column1" style={{ width: "unset" }}>
              <div className="column1__column">
                <span className="column1__column__header">
                  Transaction Fees
                </span>

                <span className="column1__column__footer">
                  {record?.businessTransactionFees}%
                </span>
              </div>
            </div>

            <div className="column2" style={{ width: "unset" }}>
              <div className="column2__column">
                <span className="column2__column__header">
                  {" "}
                  Auto Withdrawal
                </span>
                <span className="column2__column__footer">
                  {" "}
                  {record?.isAutoWithdrawal ? "ON" : "OFF"}
                </span>
              </div>
            </div>
            <div className="column4" style={{ width: "unset" }}>
              <div className="column4__column">
                <span className="column4__column__header"></span>
                <span className="column4__column__footer"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="doc">
          <h4
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              marginTop: "1rem",
              marginLeft: ".2rem",
            }}
          >
            Documents
          </h4>
          <div className="modal-above">
            <div className="column1" style={{ height: "7rem", width: "unset" }}>
              <div className="column1__column">
                <span className="column1__column__header">Document</span>

                <span className="column1__column__footer">
                  <Button
                    className="btn-icon"
                    size="middle"
                    onClick={() => handleViewDocuments(record)}
                    type="primary"
                    shape="round"
                    style={{ marginLeft: "0rem", marginTop: ".22rem" }}
                  >
                    Documents
                  </Button>
                </span>
              </div>
            </div>

            <div className="column2 col2">
              <div className="column2__column">
                <span className="column2__column__header"></span>
                <span className="column2__column__footer"> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column3">
        <Button
          type="primary"
          className="column1__btn-export"
          onClick={handleCancelDetails}
        >
          Close
        </Button>
        {/* <Button type="primary" className="column2__btn-print">
              Print
            </Button> */}
        <Modal
          title={record?.businessName + " - Documents "}
          visible={isModalVisibleDocument}
          onCancel={handleCancelDocument}
          footer={null}
          className="modal"
        >
          <div
            style={{
              display: "flex",
              margin: "0 auto",
              justifyContent: "center",
            }}
          >
            <table>
              {documents.length > 0 ? (
                documents.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <a
                        target="_blank"
                        href={
                          S3_URL +
                          record.userId +
                          "/attachment/" +
                          item.mediaPath
                        }
                      >
                        {item.mediaTitle}
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Records Found</td>
                </tr>
              )}{" "}
            </table>
          </div>

          {/* <Button
            size='middle'
            type='primary'
            shape='round'
            onClick={handleCancelDocument}
            style={{ display: 'flex', margin: '0 auto' }}
          >
            Close
          </Button> */}
        </Modal>
      </div>
    </Modal>
  );
};

export default BusinessUserDetail;

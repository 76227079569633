import { Space, Button, Table, Modal, message } from "antd";
import { useEffect, useState } from "react";
import InputSearch from "../form/InputSearch";
import AddBusinessOwner from "./add";
import Totop from "../../shared/TopUp";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import Branch from "./branches";
import { fetchBusinessOwnerData } from "../../../KIWE-V1/Services/businessOwnerApi.js";

const Index = () => {
  const [record, setRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleBranch, setIsModalVisibleBranch] = useState(false);

  const [businessOwner, setBusinessOwner] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const { pageNo } = useParams();
  const history = useHistory();
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  const onSearch = (value) => {
    setSearchText(value);
    fetchBusinessOwners(1, value);
  };
  const fetchBusinessOwners = async (page, searchText) => {
    try {
      const response = await fetchBusinessOwnerData(page, searchText);
      setBusinessOwner(response?.data);
      setTotalPages(response?.totalRecords);
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    fetchBusinessOwners(pageNo, searchText);
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      ellipsis: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a?.email?.length - b?.email?.length,
      ellipsis: true,
    },
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
      sorter: (a, b) => a?.businessName?.length - b?.businessName?.length,
      render: (text, record) => (
        <>
          {text ? (
            text
          ) : (
            <span
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              -
            </span>
          )}
        </>
      ),
    },
    {
      title: "Branch",
      dataIndex: "branches",
      key: "branches",
      render: (text, record) => (
        <>
          <div className="eye">
            <EyeOutlined
              style={{
                cursor: "pointer",
                width: "50px",
              }}
              onClick={() => {
                showModalBranch();
                setRecord(record);
              }}
            />
          </div>
        </>
      ),

      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (val, record) => (
        <>
          {roles[0] === "SUPER_ADMIN" || "ADMIN" ? (
            <div>
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={() => {
                  showModal();
                  setRecord(record);
                }}
              >
                Edit
              </Button>
            </div>
          ) : (
            " - "
          )}
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalBranch = () => {
    setIsModalVisibleBranch(true);
  };

  const handleCancel = () => {
    setIsModalVisibleBranch(false);
  };
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Business Owner</h2>
          <Space>
            <InputSearch onSearch={onSearch} />
            {roles[0] === "SUPER_ADMIN" || "ADMIN" ? (
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  showModal();
                  setRecord(null);
                }}
              >
                ADD
              </Button>
            ) : (
              ""
            )}
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={businessOwner}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNo,
              onChange: (page) => {
                setPage(page);
                history.push(`/business-owner/${page}`);
                fetchBusinessOwners(page, searchText);
              },
            }}
          />

          <AddBusinessOwner
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
          />

          <Modal
            title="Branches"
            visible={isModalVisibleBranch}
            footer={null}
            onCancel={handleCancel}
            className="modal-business"
          >
            <Branch record={record} />
          </Modal>
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Index;

import React from "react";
import { Space, Button, Table, message } from "antd";

import Totop from "../../shared/TopUp";

import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchValuPromotionData } from "../../../KIWE-V1/Services/valuPromotionApi";
import { useValuPromotion } from "../../../contextAPi/valuPromotion/valuPromotionContext.js";
const PromotionValue = () => {
  const history = useHistory();

  const { valuPromotion, setValuPromotion } = useValuPromotion();
  const [totalPages, setTotalPages] = useState(1);
  const { pageNo } = useParams();
  const [loading, setLoading] = useState(true);
  const fetchValuPromotinData = async (page) => {
    try {
      const apiResponse = await fetchValuPromotionData(page);
      setTotalPages(apiResponse.totalRecords);
      setValuPromotion(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    fetchValuPromotinData(pageNo);
  }, [pageNo]);
  const columns = [
    {
      title: "Promotion ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a?.amount - b?.amount,
      render: (text, record) => (
        <>{record?.amount === null ? "-" : record?.amount}</>
      ),
    },
    {
      title: "Precentage",
      dataIndex: "percentage",
      key: "percentage",
      sorter: (a, b) => a?.percentage - b?.percentage,
      render: (text, record) => (
        <>{record?.percentage === null ? "-" : record?.percentage}</>
      ),
    },
    {
      title: "Up-To-Limit",
      dataIndex: "upToLimit",
      key: "upToLimit",
      sorter: (a, b) => a?.upToLimit - b?.upToLimit,
      render: (text, record) => (
        <>{record?.upToLimit === null ? "-" : record?.upToLimit}</>
      ),
    },
    {
      title: "Start-Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => <>{new Date(text)?.toLocaleDateString()}</>,
      sorter: (a, b) => new Date(a?.startDate) - new Date(b?.startDate),
    },
    {
      title: "End-Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record) => <>{new Date(text)?.toLocaleDateString()}</>,
      sorter: (a, b) => new Date(a?.endDate) - new Date(b?.endDate),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (txt, record) => (
        <>
          <div>
            <Link
              to={{
                pathname: `/promotion-value-add/${pageNo}`,
                state: record,
                pageNo: pageNo,
              }}
            >
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
              >
                Edit
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];
  const handleClick = () => {
    history.push(`/promotion-value-add/${pageNo}`);
  };

  return (
    <>
      {" "}
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Promotion Valu</h2>
          {valuPromotion.length <= 0 ? (
            <Space>
              <Button type="primary" size="middle" onClick={handleClick}>
                ADD
              </Button>
            </Space>
          ) : (
            ""
          )}
        </Space>

        <div className="container">
          {valuPromotion.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={valuPromotion}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={false}
            />
          ) : (
            <div className="messageNoData">No Valu promotions added yet!</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default PromotionValue;

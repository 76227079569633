import axios from "axios";
import { API_BASE_URL } from "../constant";

export default (history = null) => {
  const baseURL = API_BASE_URL;

  let headers = {};

  if (localStorage.accessToken) {
    headers.api_key = localStorage.accessToken;
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
    timeout: 2 * 60 * 1000,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 403) {
        localStorage.removeItem("token");

        if (history) {
          history.push("/");
        } else {
          window.location = "/";
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};

////طريقة الاسخدام/////
// axiosInstance()
//   .post("/login", {
//     password,
//     username,
//   })
//   .then((res) => {
//     console.log(res);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

import { Table, Button, message, Select } from "antd";

import { useState, useEffect } from "react";

import Totop from "../../shared/TopUp";

import { convertEncryptedToPlantext } from "../../../library/Helper";
import FreezeModal from "./FreezeModal";

import { fetchUserData } from "../../../KIWE-V2/Services/userAPI.js";
import { updateCard } from "../../../KIWE-V2/Services/cardApi.js";

import { useHistory } from "react-router-dom";
import Infooo from "../../../assets/images/infooo.svg";
import ModalUser from "./infoUserDetails";
import Edit from "./edit";
const ActiveUser = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");

  const history = useHistory();

  const [totalPages, setTotalPages] = useState(1);

  const [activeUsers, setActiveUsers] = useState([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleUserDetails, setIsModalVisibleUserDetails] =
    useState(false);
  const [isModalVisibleLevelReward, setIsModalVisibleLevelReward] =
    useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState({});

  const setRowKey = (record) => {
    setSelectedRowKey(record);
  };

  const fetchActiveUser = async (page, cardStatus) => {
    setLoading(true);
    try {
      const api_res = await fetchUserData(page, cardStatus);
      setLoading(false);
      setActiveUsers(api_res?.data);
      console.log(api_res?.data);
      setTotalPages(api_res?.totalRecords);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalUserDetails = () => {
    setIsModalVisibleUserDetails(true);
  };
  const showModalLevelReward = () => {
    setIsModalVisibleLevelReward(true);
  };
  const handleCardUpdate = async (value, record, statusType) => {
    setLoading(true);
    try {
      const response = await updateCard(
        record?.cards[0]?.id,
        statusType,
        value
      );

      const updatedCardData = convertEncryptedToPlantext(
        response.data.data
      ).data;

      setLoading(false);
      const configSuccess = {
        className: "messageSuccess",
        content: `${
          statusType === "cardStatus" ? "Card_Status" : "Bank_KYC_Status"
        } Updated successfully!`,
      };
      message.success(configSuccess);

      setActiveUsers((prevActiveUsers) =>
        updateStateWithResponse(prevActiveUsers, record, updatedCardData)
      );
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const updateStateWithResponse = (
    prevActiveUsers,
    record,
    updatedCardData
  ) => {
    return prevActiveUsers.map((user) => {
      if (user.id === record.id) {
        if (user.cards && user.cards.length > 0) {
          const updatedCard = {
            ...user.cards[0],
            ...updatedCardData,
          };
          return {
            ...user,
            cards: [updatedCard],
          };
        }
      }
      return user;
    });
  };
  const handleChangeBankKycStatus = (value, record) => {
    handleCardUpdate(value, record, "bankKycStatus");
  };

  const handleCardStatus = (value, record) => {
    handleCardUpdate(value, record, "cardStatus");
  };
  useEffect(() => {
    if (pageNum) {
      fetchActiveUser(pageNum, "ACTIVE,FROZEN,DEACTIVATED");
    } else {
      fetchActiveUser(1, "ACTIVE,FROZEN,DEACTIVATED");
    }
  }, [pageNum]);

  const activeUserColumns = [
    {
      title: "Info",
      dataIndex: "info",
      key: "info",

      render: (txt, record) => (
        <>
          <img
            style={{ cursor: "pointer" }}
            src={Infooo}
            onClick={() => {
              showModalUserDetails();
              setRowKey(record);
            }}
            alt=""
          />
        </>
      ),
    },

    {
      title: "Account # / User-ID at MDP",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,

      render: (txt, record) => <>{txt}</>,
    },
    {
      title: "User  Name",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
      render: (text, record) => <>{text}</>,
    },

    {
      title: "Bank KYC Status",
      dataIndex: "cards",
      key: "cards",

      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record?.cards[0]?.banckKycStatus
            ? record?.cards[0]?.banckKycStatus
            : "-"}
        </div>
      ),
    },
    {
      title: "Card Status",
      dataIndex: "cards",
      key: "cards",

      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record?.cards[0]?.cardStatus ? record?.cards[0]?.cardStatus : "-"}
        </div>
      ),
    },
    {
      title: "User Status",
      dataIndex: "blocked",
      key: "blocked",

      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record?.blocked === true ? (
            <span style={{ color: "#FD5394" }}>Inactive</span>
          ) : (
            <span style={{ color: "#20CE24" }}>Active</span>
          )}
        </div>
      ),
    },
    {
      title: "Bank KYC Status",
      dataIndex: "cards",
      key: "cards",
      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            loading={loading}
            defaultValue={
              record?.cards[0]?.banckKycStatus === "PENDING"
                ? "Light"
                : record?.cards[0]?.banckKycStatus
            }
            style={{ width: 182 }}
            onChange={(value) => handleChangeBankKycStatus(value, record)}
            options={[
              { value: "LIGHT", label: "Light", disabled: true },
              { value: "FULLY", label: "Fully" },
              { value: "RETURN", label: "Return For Collection" },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Freezing Reasons",

      dataIndex: "reasons",
      key: "reasons",
      render: (txt, record) => (
        <Button
          className="btn-icon"
          size="middle"
          onClick={() => history.push({ pathname: "/reasons", state: record })}
          type="primary"
          shape="round"
        >
          Freezing Reasons
        </Button>
      ),
    },
    {
      title: "Activate Card_Status",
      dataIndex: "cards",
      key: "cards",

      render: (text, record) => (
        <>
          {record?.cards[0]?.cardStatus === "ACTIVE" ? (
            <div className="btnCardsActivate">
              <Button
                type="primary"
                className="btn paid"
                size="middle"
                style={{ margin: ".5rem" }}
                onClick={() => {
                  showModal();
                  setRecord(record);
                }}
              >
                Freeze
              </Button>
              <Button
                type="danger"
                size="small"
                className="declined"
                style={{ height: "2rem" }}
                onClick={() => handleCardStatus("DEACTIVATED", record)}
              >
                Deactivated
              </Button>
            </div>
          ) : record?.cards[0]?.cardStatus === "FROZEN" ? (
            <>
              <div className="btnCardsActivate">
                <Button
                  type="primary"
                  className="btn paid"
                  size="middle"
                  style={{ margin: ".5rem" }}
                  onClick={() => handleCardStatus("ACTIVE", record)}
                >
                  Unfreeze
                </Button>
                <Button
                  type="danger"
                  size="small"
                  className="declined"
                  style={{ height: "2rem" }}
                  onClick={() => handleCardStatus("DEACTIVATED", record)}
                >
                  Deactivated
                </Button>
              </div>
            </>
          ) : record?.cards[0]?.cardStatus === "DEACTIVATED" ? (
            <>
              <div
                color="red"
                style={{ width: "6rem" }}
                className="custom-tag onboarding-status-rejected"
              >
                {record?.cards[0]?.cardStatus?.charAt(0).toUpperCase() +
                  record?.cards[0]?.cardStatus?.slice(1)}
              </div>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Level Reward",
      dataIndex: "level",
      key: "level",

      render: (txt, record) => (
        <>
          <Button
            type="primary"
            size="middle"
            style={{ margin: ".5rem" }}
            onClick={() => {
              showModalLevelReward();
              setRecord(record);
            }}
          >
            Edit Level
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Active Card Users</h2>
        <div className="container">
          {activeUsers.length > 0 ? (
            <Table
              className="table2"
              columns={activeUserColumns}
              dataSource={activeUsers}
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);

                  history.push(`/active-user/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No Active User Records Found.
            </div>
          )}
          <FreezeModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
            setActiveUsers={setActiveUsers}
            updateStateWithResponse={updateStateWithResponse}
          />
          <ModalUser
            selectedRowKey={selectedRowKey}
            isModalVisibleUserDetails={isModalVisibleUserDetails}
            setIsModalVisibleUserDetails={setIsModalVisibleUserDetails}
          />
          <Edit
            isModalVisibleLevelReward={isModalVisibleLevelReward}
            setIsModalVisibleLevelReward={setIsModalVisibleLevelReward}
            record={record}
            activeUsers={activeUsers}
            setActiveUsers={setActiveUsers}
          />
          <ModalUser
            selectedRowKey={selectedRowKey}
            isModalVisibleUserDetails={isModalVisibleUserDetails}
            setIsModalVisibleUserDetails={setIsModalVisibleUserDetails}
          />
          <Edit
            isModalVisibleLevelReward={isModalVisibleLevelReward}
            setIsModalVisibleLevelReward={setIsModalVisibleLevelReward}
            record={record}
            activeUsers={activeUsers}
            setActiveUsers={setActiveUsers}
          />
        </div>

        <Totop />
      </div>
    </>
  );
};

export default ActiveUser;

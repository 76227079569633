import {
  InputNumber,
  Button,
  Form,
  Modal,
  DatePicker,
  Switch,
  Select,
  message,
} from "antd";
import { useState, useEffect } from "react";
import {
  createMerchantRewardApi,
  getMerchantCodesApi,
} from "../../../KIWE-V2/Services/merchantApi";
import { fetchLevelsRewardDataAndDecryptResponse } from "../../../KIWE-V2/Services/rewardLevel";
import { convertEncryptedToPlantext } from "../../../library/Helper";

const Add = ({
  isModalVisible,
  setIsModalVisible,
  setMerchantsRewards,
  merchantsRewards,
}) => {
  const [form] = Form.useForm();
  const [merchantsCodes, setMerchantsCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [levelsReward, setLevelsRewards] = useState([]);
  const { Option } = Select;

  const handleCreateMerchantReward = async (values) => {
    try {
      const discountPercentage =
        values.discountPercentage !== undefined ? values.discountPercentage : 0;
      const cashbackPercentage =
        values.cashbackPercentage !== undefined ? values.cashbackPercentage : 0;

      const apiValues = {
        ...values,
        discountPercentage,
        cashbackPercentage,
      };

      const apiResponse = await createMerchantRewardApi(apiValues);
      if (apiResponse) {
        setMerchantsRewards([...merchantsRewards, apiResponse]);
        const configSuccess = {
          className: "messageSuccess",
          content: "Merchant reward created successfully!",
        };
        message.success(configSuccess);
        handleCancel();
        const currentMultipleOfTen =
          Math.ceil(merchantsRewards.length / 10) * 10;
        if (merchantsRewards?.length === currentMultipleOfTen) {
          window.location.reload();
        }
      }
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const handleGetMerchantsCodes = async () => {
    try {
      const response = await getMerchantCodesApi();
      if (response) {
        setMerchantsCodes(response);
      }
    } catch (error) {
      message.error(error.error);
    }
  };

  const fetchLevelsRewards = async () => {
    try {
      const apiResponse = await fetchLevelsRewardDataAndDecryptResponse();
      setLevelsRewards(apiResponse?.data);
    } catch (error) {
      const errorData = convertEncryptedToPlantext(error?.response?.data?.data);
      const configError = {
        className: "messageError",
        content: errorData?.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    fetchLevelsRewards();
  }, []);

  useEffect(() => {
    handleGetMerchantsCodes();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const disabledDate = (current) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return current && current < currentDate;
  };

  const validateCashbackPercentage = (rule, value) => {
    if (value !== undefined && (value < 0 || value > 100)) {
      return Promise.reject("Cashback percentage must be between 0 and 100");
    }
    return Promise.resolve();
  };

  const validateDiscountPercentage = (rule, value) => {
    if (value !== undefined && (value < 0 || value > 100)) {
      return Promise.reject("Discount percentage must be between 0 and 100");
    }
    return Promise.resolve();
  };

  const handleMerchantCodeChange = (value) => {
    setSelectedCode(value);
  };

  const initialValues = {
    active: true,
    discountPercentage: 0,
    cashbackPercentage: 0,
  };

  return (
    <>
      <Modal
        title={"Create Merchant Reward"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateMerchantReward}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            name="expiryDate"
            label="Expiry Date"
            rules={[{ required: true, message: "Please input Expiry Date!" }]}
          >
            <DatePicker
              showTime
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            name="minAmountToSpend"
            label="Min Amount Spent"
            rules={[
              {
                required: true,
                message: "Please input your Min Amount!",
              },
            ]}
          >
            <InputNumber
              min={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
            />
          </Form.Item>

          <Form.Item
            name="merchantId"
            label="Merchant Names"
            rules={[
              {
                required: true,
                message: "Please input your Merchant Name!",
              },
            ]}
          >
            <Select
              placeholder="Select a code"
              onChange={handleMerchantCodeChange}
              value={selectedCode}
              className="selectMerchant"
            >
              {merchantsCodes.length > 0 &&
                merchantsCodes.map((code) => (
                  <Option key={code.id} value={code.id}>
                    {code.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="maxCashbackAmount"
            label="Max Cashback Amount"
            rules={[
              {
                required: true,
                message: "Please input your Max Cashback Amount!",
              },
            ]}
          >
            <InputNumber
              min={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
            />
          </Form.Item>

          <Form.Item
            name="cashbackPercentage"
            label="Cashback %"
            rules={[
              {
                validator: validateCashbackPercentage,
              },
            ]}
          >
            <InputNumber
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
              onChange={(value) => {
                form.setFieldsValue({ discountPercentage: 0 });
              }}
            />
          </Form.Item>

          <Form.Item
            name="discountPercentage"
            label="Discount %"
            rules={[
              {
                validator: validateDiscountPercentage,
              },
            ]}
          >
            <InputNumber
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.6rem",
              }}
              onChange={(value) => {
                form.setFieldsValue({ cashbackPercentage: 0 });
              }}
            />
          </Form.Item>

          <Form.Item label="Locked" name="active" valuePropName="checked">
            <Switch className="blueSwitch" />
          </Form.Item>

          <Form.Item
            label="Level"
            name="levelId"
            rules={[
              {
                required: true,
                message: "Please input your level!",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              className="selectMerchant"
              placeholder="Select a level"
            >
              {levelsReward.length > 0 &&
                levelsReward.map((level) => (
                  <Option key={level.id} value={level.id}>
                    {level.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {"Create Merchant Reward"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

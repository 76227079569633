import { Table, Button, message, Switch } from "antd";
import { useLocation } from "react-router-dom";
import Totop from "../../shared/TopUp";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { useState, useEffect } from "react";
import swal from "sweetalert2";

import {
  fetchTicketsDataAndDecryptResponse,
  redeemTicketsDataResponse,
  refundTicketsDataResponse,
} from "../../../KIWE-V1/Services/ticketsAPI.js";

const Tickets = () => {
  const [totalPages, setTotalPages] = useState(1);

  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const { state, place } = useLocation();
  const fetchUserTickets = async (page) => {
    try {
      const apiResponse = await fetchTicketsDataAndDecryptResponse(
        state?.userId,
        page
      );
      setTotalPages(apiResponse?.totalRecords);

      setTickets(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const configErrorNotFound = {
        className: "messageError",
        content: "There are no Tickets for This User",
      };
      const configErrorServer = {
        className: "messageError",
        content: "something went wrong!",
      };
      if (error.response && error.response.status === 404) {
        message.error(configErrorNotFound);
      } else {
        message.error(configErrorServer);
      }
    }
  };

  useEffect(() => {
    fetchUserTickets(1);
  }, []);
  const [isDisabled, setIsDisabled] = useState(true);

  const columns = [
    {
      title: "Fair Name",
      dataIndex: "fairName",
      key: "fairName",
      sorter: (a, b) => a?.fairName?.localeCompare(b?.fairName),
      ellipsis: true,
    },

    {
      title: "Ticket Name",
      dataIndex: "ticketName",
      key: "ticketName",
      sorter: (a, b) => a?.ticketName?.localeCompare(b?.ticketName),
      ellipsis: true,
    },
    {
      title: "Is Redeem",
      dataIndex: "restriction",
      key: "restriction",

      render: (text, record) => (
        <>
          <div>
            <Switch
              disabled={record.hasRedeemed === false ? isDisabled : false}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
              size="middle"
              onChange={() => handleRedeemTickets(record)}
              checked={record.hasRedeemed}
            />
          </div>
        </>
      ),
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              //   className="btn"
              size="middle"
              style={{ margin: ".5rem" }}
              onClick={() => handleRefundTickets(record?.userTicket)}
            >
              Refund
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
      width: 170,
    },
  ];

  const columns2 = [
    {
      title: "Fair Name",
      dataIndex: "fairName",
      key: "fairName",
      sorter: (a, b) => a?.fairName?.localeCompare(b?.fairName),
      ellipsis: true,
    },

    {
      title: "Ticket Name",
      dataIndex: "ticketName",
      key: "ticketName",
      sorter: (a, b) => a?.ticketName?.localeCompare(b?.ticketName),
      ellipsis: true,
    },
  ];

  const handleRedeemTickets = async (record) => {
    try {
      const response = await redeemTicketsDataResponse(record?.userTicket);
      if (response) {
        fetchUserTickets(page);

        const data = tickets.map((user) => {
          if (user.userTicket === record.userTicket)
            return { ...user, hasRedeemed: !record.hasRedeemed };
          else return user;
        });

        setTickets(data);
      }
    } catch (error) {
      return message.error(error.message);
    }
  };

  const handleRefundTickets = (id) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to Refund this Ticket?",

        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(async (isConfirm) => {
        if (isConfirm.value) {
          try {
            const apiResponse = await refundTicketsDataResponse(id);
            handleSuccessfulRequest(apiResponse);
          } catch (error) {
            handleFailedRequest(error);
          }
        }
      });
  };
  const handleSuccessfulRequest = (response) => {
    if (response.status === 200) {
      let apiResponse = convertEncryptedToPlantext(response?.data?.data);
      fetchUserTickets(page);

      var successMessage = apiResponse?.data?.message;
      const successMessageSplit = successMessage.split(" ");
      for (var i = 0; i < successMessageSplit.length; i++) {
        successMessageSplit[i] =
          successMessageSplit[i].charAt(0).toUpperCase() +
          successMessageSplit[i].slice(1);
      }
      const lastSuccessMessage = successMessageSplit.join(" ");
      const configSuccess = {
        className: "messageSuccess",
        content: `${lastSuccessMessage} With Amount ${apiResponse?.data?.amount}`,
      };
      message.success(configSuccess);
    }
  };
  const handleFailedRequest = (errorResponse) => {
    const error = convertEncryptedToPlantext(
      errorResponse?.response?.data?.data
    );
    var errorMessage = error?.message;
    const errorMessageSplit = errorMessage.split(" ");
    for (var i = 0; i < errorMessageSplit.length; i++) {
      errorMessageSplit[i] =
        errorMessageSplit[i].charAt(0).toUpperCase() +
        errorMessageSplit[i].slice(1);
    }
    const lastErrorMessage = errorMessageSplit.join(" ");
    const configErrorServer = {
      className: "messageError",
      content: lastErrorMessage,
    };
    message.error(configErrorServer);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Tickets</h2>
        <div className="container">
          {tickets.length > 0 ? (
            <Table
              className="table2"
              columns={place === "individual" ? columns : columns2}
              dataSource={tickets}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +page,
                onChange: (page) => {
                  setPage(page);
                  fetchUserTickets(page);
                },
              }}
            />
          ) : (
            <div className="messageNoData">No Tickets Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Tickets;

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { Link, useHistory } from "react-router-dom";
import Add from "./add";
import { fetchFAQToicsApi } from "../../../KIWE-V2/Services/FAQTopicApi";
const Topics = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [topics, setTopics] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchAllTopics = async (page) => {
    try {
      const apiResponse = await fetchFAQToicsApi(page);

      setLoading(false);
      setTopics(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);

      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    if (pageNum) {
      fetchAllTopics(pageNum);
    } else {
      fetchAllTopics(1);
    }
  }, [pageNum]);

  const TopicsColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Topic Status",
      dataIndex: "visible",
      key: "visible",

      render: (txt, record) => (
        <>
          <div>
            {record?.visible === true ? (
              <div className="custom-tag onboarding-status-approved">
                Active
              </div>
            ) : record?.visible === false ? (
              <div className="custom-tag onboarding-status-rejected">
                InActive
              </div>
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "View FAQs",
      dataIndex: "view",
      key: "view",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                history.push({
                  pathname: "/view-FAQs",
                  state: { FAQ: record },
                });
              }}
            >
              View FAQs
            </Button>
          </div>
        </>
      ),
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Topics</h2>
          <Space style={{ justifyContent: "flex-end" }}>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal(null);
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {topics.length > 0 ? (
            <Table
              className="table2"
              columns={TopicsColumns}
              dataSource={topics}
              onChange={onChange}
              rowKey="id"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/topics/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No FAQ Topics Records Found.
            </div>
          )}
        </div>
        <Add
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          topics={topics}
          setTopics={setTopics}
          record={record}
        />
        <Totop />
      </div>
    </>
  );
};

export default Topics;

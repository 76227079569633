import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Table,
  message,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Radio,
  Dropdown,
} from "antd";
import Totop from "../../shared/TopUp";
import DragImage from "../form/DragImage";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { useHistory } from "react-router-dom";
import { Env } from "../../../constant";
import { useParams } from "react-router-dom";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import arrowRight from "../../../assets/images/icons8-right-arrow-100 1.svg";

const { RangePicker } = DatePicker;

function Add_Quiz() {
  //data
  const [form] = Form.useForm();
  const [questionsQuiz,setQuestionsQuiz]=useState(0)
  console.log("questionsQuiz",questionsQuiz)
  const [QuestionOptions,setQuestionOptions]=useState([])
  const [RightAnsQuestion,setRightAnsQuestion]=useState([])
  const [rightAns, setrightAns] = useState([]);
  console.log(questionsQuiz)
  console.log(QuestionOptions)
  const prefix = (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0032cb",
        color: "white",
        fontWeight: "bold",

        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        height: "2.4rem",

        padding: "0px 5px",
      }}
    >
      EGP
    </span>
  );
  //functions
  const onFinish = (values) => {
    let formattedRightAns=rightAns.map((item)=>item.pos)
    let formattedOptions=QuestionOptions.map((item)=>{
      return {
        options:item.opts
      }
    })
    for(let i=0;i<values?.questions.length;i++){
      const elem=formattedOptions[i]
      if(!elem){
        return message.error('You should provide options')
      }
      if(elem.options.length===0){
        console.log('You should have at least one option')
        return message.error('You should have at least one option')
      }
      for(let j=0;j<elem.options.length;j++){
        const item=elem.options[j]
        if(item.optStr.length===0){
          console.log('please provide text in the options')
          return message.error('please provide text in the options')
        }
      }
    }
   
   
    console.log('formattedOptions',formattedOptions,'rightAns',formattedRightAns);

    // const randNameImage = generateRandomFileName(values?.quizImage[0]);

    // handleImageUpload(
    //   values?.quizImage[0].originFileObj,
    //   randNameImage,
    //   "kiweapp/" + Env + "/quiz"
    // );
    // let startDate = undefined;
    // let endDate = undefined;
    // if (values.Picker) {
    //   const itemone = values.Picker[0];
    //   const itemtwo = values.Picker[1];
    //   startDate = itemone.format("YYYY-MM-DD HH:mm:ss");
    //   endDate = itemtwo.format("YYYY-MM-DD HH:mm:ss");
    // }
    // axiosInstance()
    //   .post("admin/quiz", {
    //     data: convertPlantextToEncrypted({
    //       quizTitle: values?.quizTitle,
    //       startDate: startDate,
    //       endDate: endDate,
    //       price: values?.price,
    //       scope: values?.scope,
    //       question: values?.questions,
    //       options:QuestionOptions,
    //       rightAns: rightAns,
    //       quizImage: randNameImage,
    //       expiryTime: getCurrentTimeStemp(),
    //     }),
    //   })
    //   .then((response) => {
    //     if (response.status === 200) {
    //       let api_res = convertEncryptedToPlantext(response.data.data);
    //       console.log("api_response", api_res);
    //       message.success("Submit success!");
    //     }
    //   })
    //   .catch((error) => {
    //     const err = convertEncryptedToPlantext(error?.response?.data?.data);
    //     message.error(err.message);
    //     console.log(error);
    //   });
  };
  const addField = (fields,add) => {
      fields.push({
        name: 0,
        key: 0,
        fieldKey: 0,
      });
      add();
  };

  return (
    <div style={{ margin: "1rem" }}>
      <h2>Add Quiz</h2>
      <div className="container">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="notificationFrom"
        >
          <Form.Item>
            <DragImage
              normFile={normFile}
              nam1="quizImage"
              label="Quiz Image"
              rules={[
                {
                  required: true,
                  message: "Please input your Notfication Image!",
                },
              ]}
            />
            <span className="note">
              Note :- Please upload image in size of "1440 * 645" for better
              resolution
            </span>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="Picker"
                label="Select Date"
                rules={[
                  {
                    required: true,
                    message: "Please input the Date",
                  },
                ]}
              >
                <RangePicker
                  separator={
                    <img
                      src={arrowRight}
                      alt="-"
                      style={{ width: "15px", height: "15px" }}
                    />
                  }
                  showTime
                  style={{ height: "2.4rem", width: "100%" }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="time"
                label="Time in Seconds"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please Input Your Time in Seconds !",
                  },
                ]}
              >
                <Input
                  min={0}
                  type="number"
                  placeholder="Enter Time in Seconds"
                  className="input-border"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="quizTitle"
                label="Title Quiz"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your quiz Title Quiz!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Title Quiz"
                  className="input-border"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="price"
                label="Prize"
                hasFeedback
                rules={[
                  { required: true, message: "Please Input Your Prize !" },
                ]}
              >
                <Input placeholder="Enter Prize" className="input-border" />
               
              </Form.Item>
            </Col>
          </Row>
          <Form.List
              name="questions"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error("At least 1 question"));
                    }
                    
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {console.log('fields',fields,errors)}
                  {fields.length===0&&addField(fields,add)}
                  
                  {fields.map((field, index) => (
                    <Form.Item
                      label={"Question"}
                      required={true}
                      key={field.key}
                    >
                      {index===0&&(
                         <div style={{width:'100%',display:'flex',alignItems:'center'}}>

                         <Form.Item
                           {...field}
                           validateTrigger={["onChange", "onBlur"]}
                           rules={[
                             {
                               required: true,
                               whitespace: true,
                               message:
                                 "Please input question or delete this field.",
                             },
                           ]}
                           style={{width:'70%',marginRight:'1rem'}}
                         >
                           <Input
                             placeholder="Enter Question"
                             style={{height:'2.4rem',marginTop:'0.5rem'}}
                           />
   
                         </Form.Item>
                         <Form.Item   style={{marginTop:'0.5rem'}}>
                       
                           <Button
                             type="primary"
                             onClick={() =>{
                               // setRightAnsQuestion((prev)=>[...prev,{key:fields.length,pos:0}])
                               // setQuestionOptions((prev)=>[...prev,{question:fields.length,opts:[{opt:0,optStr:''}]}])
                               add()
                             }}
                           
                             icon={<PlusOutlined />}
                           >
                             Add Question
                           </Button>
                          
                         </Form.Item>
                         </div>
                      )}
                      {index>=1&&(
                        <div style={{display:'flex',alignItems:'center'}}>
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input question or delete this field.",
                              },
                            ]}
                            style={{width:'70%',marginRight:'0.5rem'}}
                          >
                            <Input
                              placeholder="Enter Question"
                              style={{height:'2.4rem',marginTop:'0.5rem'}}
                            />
                          
                          </Form.Item>
                        <Form.Item>
                        <CloseCircleOutlined
                                
                                className="dynamic-delete-button"
                                onClick={() =>{
                                  let newArr=[...rightAns]
                                  let qOpts=[...QuestionOptions]
                                  qOpts.splice(field.name,1)
                                  newArr.splice(field.name,1)
                                  setrightAns(newArr)
                                  setQuestionOptions(qOpts)
                                  remove(field.name)
                                }}
                            />
                        </Form.Item>
                        </div>
                      )}
                      <Form.ErrorList errors={errors} />
                   <Form.Item>
                   
                    <Button
                      type="primary"
                      onClick={()=>{
                        let newArr=[...QuestionOptions]
                        if(!(newArr[field.name])){
                          newArr.push({question:field.name,opts:[{opt:0,optStr:''}]})
                        }
                        else{
                          let L= newArr[field.name].opts.length
                          newArr[field.name].opts.push({opt:L,optStr:''})
                        }
                        setQuestionOptions(newArr)
                        let newArrAns=[...rightAns]
                        if(!(newArrAns[field.name])){
                          newArrAns.push({key:field.name,pos:0})
                        }else{
                          newArrAns[field.name].pos=newArr[field.name].opts.length-1
                        }
                        setrightAns(newArrAns)
                      }}
                      style={{ width: "30%" ,marginTop:'2rem'}}
                      icon={<PlusOutlined />}
                    >
                      Add Options
                    </Button>
                   
                  </Form.Item>
                      {(QuestionOptions&&QuestionOptions[field.name])&&QuestionOptions[field.name].opts.map((val,pos)=>{
                        return (
                          <div key={`option ${field.name}-${pos}`}>
                          <Form.Item
                          key={`option ${field.name}-${pos}`}
                       
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input option or delete this field.",
                              
                            },
                          ]}
                          >
                           
                           <Input
                            placeholder="Enter Option"
                          
                            value={ QuestionOptions[field.name].opts[pos].optStr}
                            onChange={(e)=>{
                              let val=e.target.value;
                              let newArr=[...QuestionOptions]
                              newArr[field.name].opts[pos].optStr=val;
                              setQuestionOptions(newArr)
                            }}
                            style={{ width: "60%", height: "2.4rem" ,marginTop:'0.5rem'}}
                            />
                       
                             {QuestionOptions[field.name].opts.length > 0 ? (
                              <CloseCircleOutlined
                                style={{ marginLeft: "10px" }}
                                className="dynamic-delete-button"
                                onClick={() => {
                                  let newArrAns=[...rightAns]
                                  let newArr=[...QuestionOptions]
                                  console.log(pos,newArr[field.name].opts.length)
                                  
                                  if((pos===rightAns[field.name].pos&&rightAns[field.name].pos===newArr[field.name].opts.length-1)||pos<rightAns[field.name].pos){
                                    newArrAns[field.name].pos -=1;
                                    console.log('posssss',newArrAns)
                                    setRightAnsQuestion(newArrAns)
                                  }
                                 
                                  
                                  newArr[field.name].opts.splice(pos,1)
                                  setQuestionOptions(newArr)
                                }}
                              />
                            ) : null}
                             {QuestionOptions[field.name].opts.length >0 && rightAns[field.name].pos === pos ? (
                                <CheckCircleTwoTone
                                  twoToneColor="#52c41a"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() =>{
                                    let newArr=[...rightAns]
                                    if(!(newArr[field.name])){
                                      newArr({key:field.name,pos})
                                    }else{
                                      newArr[field.name].pos=pos
                                    }
                                    setrightAns(newArr)
                                  } }
                                />
                              ) : null}
                        {QuestionOptions[field.name].opts.length >0 && rightAns[field.name].pos !== pos ? (
                          <CheckCircleOutlined
                            style={{ marginLeft: "10px" }}
                            onClick={() =>{
                              let newArr=[...rightAns]
                              if(!(newArr[field.name])){
                                newArr({key:field.name,pos})
                              }else{
                                newArr[field.name].pos=pos
                              }
                              setrightAns(newArr)
                            } }
                          />
                        ) : null}
                          </Form.Item>
                          </div>
                        )
                      })}

                    </Form.Item>
                  ))}
                 
                </>
              )}
            </Form.List>
            <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              Add Quiz
            </Button>
            </Form.Item>
          {/* <Form.Item>
            <Form.Item
              name="question"
              label="Question"
              hasFeedback
              rules={[
                { required: true, message: "Please Input Your Question Text!" },
              ]}
            >
              <Input placeholder="Enter Question" className="input-border" />
            </Form.Item>
            <Form.List
              name="options"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error("At least 1 option"));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {console.log(fields)}
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 ? "Options" : ""}
                      required={true}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input option name or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Option Name"
                          style={{ width: "60%", height: "2.4rem" }}
                        />
                      </Form.Item>
                      {fields.length > 0 ? (
                        <CloseCircleOutlined
                          style={{ marginLeft: "10px" }}
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                      {fields.length > 0 && rightAns === field.key ? (
                        <CheckCircleTwoTone
                          twoToneColor="#52c41a"
                          style={{ marginLeft: "10px" }}
                          onClick={() => setrightAns(field.key)}
                        />
                      ) : null}
                      {fields.length > 0 && rightAns !== field.key ? (
                        <CheckCircleOutlined
                          style={{ marginLeft: "10px" }}
                          onClick={() => setrightAns(field.key)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      style={{ width: "30%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Option
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
           
          </Form.Item> */}
        </Form>
      </div>
      <Totop />
    </div>
  );
}

export default Add_Quiz;

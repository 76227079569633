import { Input, Button, Form, message } from "antd";
import { useHistory } from "react-router-dom";
import DragImage from "../form/DragImage";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { BUSINESS_OFFER_ALBUM_NAME } from "../../../constant";

import { useParams } from "react-router-dom";
import { addOrEditBusinessOfferApi } from "../../../KIWE-V1/Services/businessOfferApi";
const Add = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const history = useHistory();
  const { pageNo } = useParams();
  const handleAddBusinessOffer = async (values) => {
    const randNameImage = generateRandomFileName(values?.image[0]);
    const randNameCoverImage = generateRandomFileName(values?.picturePath[0]);
    handleImageUpload(
      values?.image[0].originFileObj,
      randNameImage,
      BUSINESS_OFFER_ALBUM_NAME
    );
    handleImageUpload(
      values?.picturePath[0].originFileObj,
      randNameCoverImage,
      BUSINESS_OFFER_ALBUM_NAME
    );
    try {
      await addOrEditBusinessOfferApi(
        values,
        randNameImage,
        randNameCoverImage
      );
      message.success("Business Offer Added Successfully!");
      history.push(`/business-offer/${+pageNo}`);
    } catch (error) {
      message.error(error.message);
    }
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleCancel = () => {
    form.resetFields();
    history.push(`/business-offer/${pageNo}`);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="container__topheader">
          <h2>Add Business Offer</h2>
          <div
            className="profile__btns"
            style={{ display: "flex", marginTop: "1rem" }}
          >
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="profile__btns__save"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                size="middle"
                className="profile__btns__cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="container profile">
          <div className="profile__form modal" style={{ padding: "1rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleAddBusinessOffer}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                hasFeedback
                rules={[{ required: true, message: "Please input your Name!" }]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item className="offerImage">
                <DragImage
                  normFile={normFile}
                  className="labelImage"
                  label="Offer Image"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Offer Image!",
                    },
                  ]}
                  hasFeedback
                  nam1="image"
                />
              </Form.Item>
              <Form.Item
                name="discount"
                label="Discount"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Discount!" },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Discount should contain just number",
                  },
                ]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Description!" },
                ]}
                className="offer-name"
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item
                name="code"
                label="Code"
                hasFeedback
                rules={[{ required: true, message: "Please input your Code!" }]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
              <Form.Item className="offerImage">
                <DragImage
                  normFile={normFile}
                  nam1="picturePath"
                  label="Logo"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Logo!",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="website"
                label="Website"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Website!" },
                ]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;

import { Space, Avatar, Button, Table, Switch, message, Modal } from "antd";

import { useState, useEffect } from "react";

import InputSearch from "../form/InputSearch";
import ModalUser from "./UserDetails";
import { Link } from "react-router-dom";
import Totop from "../../shared/TopUp";
import { PlusOutlined } from "@ant-design/icons";
import { S3_QRCODEURL } from "../../../constant";
import Infooo from "../../../assets/images/infooo.svg";
import { S3_URL } from "../../../constant";
import { useHistory } from "react-router-dom";
import Edit from "./edit";
import UploadBulkAccount from "./uploadBulkAccount.jsx";
import {
  fetchUserDataAndDecryptResponse,
  updateUserStatus,
  DownloadQRCodeBulk,
} from "../../../KIWE-V1/Services/userAPI";
const IndividualUser = (props) => {
  const authUserInfo = localStorage.getItem("auth_user_info");

  const { roles } = authUserInfo ? JSON.parse(authUserInfo) : { roles: [] };

  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const search_textFromParams = params.get("search_text");

  const [selectedRowKey, setSelectedRowKey] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleOrg, setIsModalVisibleOrg] = useState(false);
  const [isModalVisibleQR, setIsModalVisibleQR] = useState(false);
  const [isModalVisibleUploadBulkAccount, setIsModalVisibleUploadBulkAccount] =
    useState(false);

  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [indivdualUser, setIndivdualUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [page, setPage] = useState(1);
  const [record, setRecord] = useState([]);

  const history = useHistory();

  const onSearch = (value) => {
    console.log(value);
    setSearchTextFromInput(value);
    history.push(`/indivdual-user/?pageNum=1&search_text=${value}`);

    localStorage.removeItem("JointAccount");
  };
  const fetchIndividualUser = async (page, searchTextFromInput) => {
    try {
      const api_res = await fetchUserDataAndDecryptResponse(
        "individual",
        searchTextFromInput,
        page
      );
      setTotalPages(api_res?.totalRecords);
      setIndivdualUser(api_res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const setRowKey = (record) => {
    const selectedRowKey = getRowKey(record);

    setSelectedRowKey(record);
  };
  const getRowKey = (record) => {
    return `${record.key}`;
  };
  const showModal = (record) => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdateStatusUser = (record) => {
    const newActiveStatus = !record.active;
    updateUserStatus(record.userId, newActiveStatus)
      .then((response) => {
        console.log("del admin", response);
        if (response.status === 200) {
        }

        let updatedUsers = indivdualUser.map((user) => {
          if (user.userId === record.userId)
            return { ...user, active: newActiveStatus };
          return user;
        });
        setIndivdualUser(updatedUsers);
      })
      .catch((error) => {});
  };

  let CUSTOMER_SUPPORT = roles.find((itm) => itm === "CUSTOMER_SUPPORT");
  let SUPER_ADMIN = roles.find((itm) => itm === "SUPER_ADMIN");

  const showModalOrg = (record) => {
    setIsModalVisibleOrg(true);
    setRecord(record);
  };
  const columns = [
    {
      title: "Info",
      dataIndex: "firstName",
      key: "firstName",

      render: (txt, record) => (
        <>
          <img
            style={{ cursor: "pointer" }}
            src={Infooo}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
            alt=""
          />
        </>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.firstName?.trim()?.length !== 0 ? record?.firstName : "-"}
          </span>
        </>
      ),
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",

      sorter: (a, b) => a.lastName.localeCompare(b.lastName),

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.lastName?.trim()?.length !== 0 ? record?.lastName : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.username ? record?.username : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.email ? record?.email : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,

      width: 100,
      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.mobile ? record?.mobile : "-"}
          </span>
        </>
      ),
    },
    {
      title: "EKYC Status",
      dataIndex: "ekycStatus",
      key: "ekycStatus",
      sorter: (a, b) => a.ekycStatus.localeCompare(b.ekycStatus),
      render: (txt, record) => (
        <>
          <>
            {txt === "PENDING" ? (
              <div
                className="custom-tag onboarding-status-pending"
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
                style={{ cursor: "pointer" }}
              >
                Pending
              </div>
            ) : txt === "REJECTED" ? (
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
                style={{ cursor: "pointer" }}
              >
                Rejected
              </div>
            ) : (
              <div
                color="green"
                className="custom-tag onboarding-status-approved"
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
                style={{ cursor: "pointer" }}
              >
                Approved
              </div>
            )}
          </>
        </>
      ),
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/transaction-user",
              state: record,
              place: "individual",
              searchTextt: searchTextFromInput,
              pageNo: pageNum,
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{ marginLeft: "-1rem", height: "2rem" }}
            >
              <span className="icon-TransactionFeesw"></span>
              Transactions
            </Button>
          </Link>
        </>
      ),
      // ellipsis: true,
    },
    {
      title: "Organization",
      dataIndex: "organiztaion",
      key: "view",
      render: (src, record) => (
        <>
          <Button
            className="btn-icon"
            size="middle"
            onClick={() => showModalOrg(record)}
            type="primary"
            shape="round"
          >
            Edit
          </Button>
        </>
      ),
      // width: 150,

      // ellipsis: true,
    },
  ];

  const columns1 = [
    {
      title: "Info",
      dataIndex: "firstName",
      key: "firstName",

      // ellipsis: true,
      render: (txt, record) => (
        <>
          <img
            style={{ cursor: "pointer" }}
            src={Infooo}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
            alt=""
          />
        </>
      ),
    },
    {
      title: "Profile Image",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (text, record) => (
        <>
          <Avatar
            style={{ cursor: "pointer" }}
            src={
              S3_URL +
              record?.userId +
              "/profile_picture/" +
              record?.profilePicturePath
            }
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          />
        </>
      ),
      // ellipsis: true,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      // ellipsis: true,
      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.firstName?.trim()?.length !== 0 ? record?.firstName : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",

      sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),

      // ellipsis: true,
      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.lastName?.trim()?.length !== 0 ? record?.lastName : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.username ? record?.username : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.email ? record?.email : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,

      width: 100,
      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.mobile ? record?.mobile : "-"}
          </span>
        </>
      ),
    },
    {
      title: "EKYC Status",
      dataIndex: "ekycStatus",
      key: "ekycStatus",
      sorter: (a, b) => a.ekycStatus.localeCompare(b.ekycStatus),
      render: (txt, record) => (
        <>
          <>
            {record?.ekycStatus === "PENDING" ? (
              <div
                className="custom-tag onboarding-status-pending"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
              >
                Pending
              </div>
            ) : record?.ekycStatus === "REJECTED" ? (
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
                style={{ cursor: "pointer" }}
              >
                Rejected
              </div>
            ) : record?.ekycStatus === "APPROVED" ? (
              <div
                color="green"
                className="custom-tag onboarding-status-approved"
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
                style={{ cursor: "pointer" }}
              >
                Approved
              </div>
            ) : record?.ekycStatus === "FAILED" ? (
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
                onClick={() => {
                  showModal();
                  setRowKey(record);
                }}
              >
                Failed
              </div>
            ) : (
              "-"
            )}
          </>
        </>
      ),
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/transaction-user",
              state: record,
              place: "individual",
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{ marginLeft: "-1rem", height: "2rem" }}
            >
              <span className="icon-TransactionFeesw"></span>
              Transactions
            </Button>
          </Link>
        </>
      ),
    },
    {
      title: "Activiation",
      dataIndex: "active",
      key: "active",

      render: (txt, record) => (
        <>
          {console.log("switch", record)}
          <Switch
            style={{
              display: "flex",
              justifyContent: "center",
              margin: " auto",
            }}
            size="middle"
            onChange={(value) => handleUpdateStatusUser(record, value)}
            checked={record?.active}
          />
        </>
      ),
    },
    {
      title: "Card",
      dataIndex: "card",
      key: "card",

      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {txt ? txt : "-"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {txt ? txt : "-"}
        </div>
      ),
    },
  ];
  const showModalQR = (record) => {
    setIsModalVisibleQR(true);
    setRecord(record);
  };
  const showModalUploadBulkAccount = (record) => {
    setIsModalVisibleUploadBulkAccount(true);
    setRecord(record);
  };
  const handleCancelQR = () => {
    setIsModalVisibleQR(false);
  };
  useEffect(() => {
    if (pageNum && search_textFromParams) {
      fetchIndividualUser(pageNum, search_textFromParams);
    } else if (pageNum) {
      fetchIndividualUser(pageNum, searchTextFromInput);
    } else {
      fetchIndividualUser(1, searchTextFromInput);
    }
  }, [pageNum, search_textFromParams]);
  const handleDownloadQRCodeBulk = async () => {
    try {
      setLoadingDownload(true);
      const response = await DownloadQRCodeBulk();
      const decodedData = Buffer.from(response, "base64");
      const blob = new Blob([decodedData], {
        type: "application/zip",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "QrCode.zip";
      a.click();
      window.URL.revokeObjectURL(url);
      setLoadingDownload(false);
    } catch (error) {
      setLoadingDownload(false);
      message.error(error?.message);
    }
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader-1">
          <h2>Individual Users</h2>
          <Space>
            <InputSearch
              onSearch={onSearch}
              searchTextt={
                search_textFromParams
                  ? search_textFromParams
                  : searchTextFromInput
              }
            />

            {
              <Space>
                <Button
                  loading={loadingDownload}
                  type="primary"
                  onClick={() => handleDownloadQRCodeBulk()}
                >
                  Download QR-Code Bulk
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    showModalUploadBulkAccount();
                    // setRecord(null);
                  }}
                  icon={<PlusOutlined className="anticon-bold" />}
                >
                  Bulk Account
                </Button>
              </Space>
            }
          </Space>
        </Space>
        <div className="container">
          {SUPER_ADMIN ? (
            <Table
              className="table2"
              columns={columns1}
              dataSource={indivdualUser}
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  history.push(
                    `/indivdual-user/?pageNum=${page}&search_text=${
                      search_textFromParams ? search_textFromParams : ""
                    }`
                  );
                },
              }}
            />
          ) : CUSTOMER_SUPPORT ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={indivdualUser}
              size="large"
              scroll={{ x: 900 }}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(
                    `/indivdual-user/?pageNum=${page}&search_text=${
                      search_textFromParams ? search_textFromParams : ""
                    }`
                  );
                },
              }}
            />
          ) : (
            ""
          )}

          <ModalUser
            selectedRowKey={selectedRowKey}
            handleCancel={handleCancel}
            isModalVisible={isModalVisible}
          />
          <Edit
            isModalVisible={isModalVisibleOrg}
            setIsModalVisible={setIsModalVisibleOrg}
            record={record}
          />
        </div>
        <Modal
          title={"Download QR Code"}
          visible={isModalVisibleQR}
          onCancel={handleCancelQR}
          footer={null}
          className="modal"
        >
          <div className="qrCode">
            <img
              src={
                S3_QRCODEURL +
                record?.userId +
                "/qr_code/%2B20-" +
                record?.mobile +
                ".png"
              }
              className="qrCode__image"
              alt="-"
            />
            <a
              className="qrCode__header"
              href={
                S3_QRCODEURL +
                record?.userId +
                "/qr_code_pdf/%2B20-" +
                record?.mobile +
                ".pdf"
              }
            >
              Download QR code
            </a>
          </div>
          {/* <Button
            size='middle'
            type='primary'
            shape='round'
            onClick={handleCancelDocument}
            style={{ display: 'flex', margin: '0 auto' }}
          >
            Close
          </Button> */}
        </Modal>
        <UploadBulkAccount
          setIsModalVisible={setIsModalVisibleUploadBulkAccount}
          isModalVisible={isModalVisibleUploadBulkAccount}
          record={record}
        />
        <Totop />
      </div>
    </>
  );
};

export default IndividualUser;

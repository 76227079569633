import { EyeOutlined } from "@ant-design/icons";
import Branch from "../business-owner/branches";
import { useState } from "react";
import { Modal } from "antd";

const UserCard = ({ state, place, role }) => {
  const [isModalVisibleBranch, setIsModalVisibleBranch] = useState(false);

  let bus = place === "business" ? true : false;
  let rolee = role === "businessOwner" ? true : false;
  const showModalBranch = () => {
    setIsModalVisibleBranch(true);
  };
  const handleCancel = () => {
    setIsModalVisibleBranch(false);
  };
  return (
    <div className="container__header">
      <div className="container__header__column1">
        <div className="container__header__column1__row1 above">
          <span className="container__header__span1">
            Total Number of Transactions:
          </span>
          <span> {state?.totalNoOfTransactions}</span>
        </div>
        <div className="container__header__column1__row2">
          <span className="container__header__span1">
            {bus ? "Total Withdrawn Amount:" : "Total Money Paid:"}
          </span>
          <span>
            E£{" "}
            {bus
              ? state?.withdrawnAmount?.toFixed(2)
              : state?.totalMoneyPaid?.toFixed(2)}
          </span>
        </div>
      </div>
      <div
        className="container__header__column2"
        // style={{ maxWidth: "33%", overflow: "hidden" }}
      >
        <div className="container__header__column2__row1 above">
          <span className="container__header__span1">
            {bus ? "Total Sales:" : "Total Money Added:"}
          </span>
          <span>
            E£{" "}
            {bus
              ? state?.totalSales?.toFixed(2)
              : state?.totalMoneyAdded?.toFixed(2)}
          </span>
        </div>
        <div className="container__header__column2__row2">
          <span className="container__header__span1">
            {bus ? "Total Kiwe Commition:" : "Total Number of request:"}
          </span>
          <span>
            {bus
              ? `E£ ${state?.totalkiweCommission?.toFixed(2)}`
              : state?.totalRequestedMoneyCount}
          </span>
        </div>
      </div>
      <div className="container__header__column3">
        <div className="container__header__column3__row1 above">
          <span className="container__header__span1">Current Balance:</span>
          <span>E£{state?.walletBalance?.toFixed(2)}</span>
        </div>
        <div className="container__header__column3__row2">
          <span className="container__header__span1">
            {bus ? "Total Kiwe Tip:" : "Total Money Requested:"}
          </span>
          <span>
            E£{" "}
            {bus
              ? state?.totalTipAmount?.toFixed(2)
              : state?.totalRequestedMoney?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="container__header__column4">
        <div className="container__header__column4__row1 above">
          <span className="container__header__span1">
            {bus ? "Total Hold Amount:" : "Total Send Money:"}
          </span>
          <span>
            E£{" "}
            {bus
              ? state?.pendingAmount?.toFixed(2)
              : state?.totalSentMoney?.toFixed(2)}
          </span>
        </div>

        {rolee ? (
          <div className="container__header__column4__row2">
            <span className="container__header__span1">{"Branches:"}</span>

            <EyeOutlined
              style={{
                cursor: "pointer",
                width: "50px",
              }}
              onClick={() => {
                showModalBranch();
              }}
            />
          </div>
        ) : (
          ""
        )}

        <div style={{ visibility: "hidden", display: "none" }}>
          <span className="container__header__span1">Total Hold Amount:</span>
          <span> {state?.pendingAmount}</span>
        </div>
      </div>
      <Modal
        title="Branches"
        visible={isModalVisibleBranch}
        footer={null}
        onCancel={handleCancel}
        className="modal-business"
      >
        {" "}
        <Branch record={state?.branch} cardFlag={true} />
      </Modal>
    </div>
  );
};

export default UserCard;

import axiosInstance from '../../helpers/axiosInstance';
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from '../../library/Helper';
export const getAllIndustryAsync = async () => {
  const response = await axiosInstance()
    .get('industry/getIndustryForCMS', {
      data: convertPlantextToEncrypted({
        expiryTime: getCurrentTimeStemp(),
      }),
    })
    .then((response) => {
      if (response.status === 200) {
        let api_res = convertEncryptedToPlantext(response?.data?.data);

        return api_res.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
};

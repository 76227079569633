import React from "react";
import { Button, Form, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../../library/Helper";
import { getBlockReasonsAPI } from "../../../KIWE-V2/Services/cardApi";
import { updateCard } from "../../../KIWE-V2/Services/cardApi";

const FreezeModal = ({
  isModalVisible,
  setIsModalVisible,
  record,
  setActiveUsers,
  updateStateWithResponse,
}) => {
  const [form] = Form.useForm();

  const [selectedReason, setSelectedReason] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (selectedReason) => {
    setSelectedReason(selectedReason);
  };
  const handleFreezeCard = async (values) => {
    setLoading(true);
    try {
      const response = await updateCard(
        record?.cards[0]?.id,
        "cardStatus",
        "FROZEN",
        values?.blockReasonId
      );

      const updatedCardData = convertEncryptedToPlantext(
        response.data.data
      ).data;
      setActiveUsers((prevActiveUsers) =>
        updateStateWithResponse(prevActiveUsers, record, updatedCardData)
      );
      handleCancel();
      setLoading(false);
      const configSuccess = {
        className: "messageSuccess",
        content: "Card_Status Updated successfully!",
      };
      message.success(configSuccess);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const fetchAllBlockReasons = async () => {
    try {
      const apiResponse = await getBlockReasonsAPI();
      setReasons(apiResponse);
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllBlockReasons();
  }, []);
  return (
    <>
      <Modal
        title={"Freeze Card_Status"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFreezeCard}
          autoComplete="off"
        >
          <Form.Item
            name="blockReasonId"
            label="Reasons"
            hasFeedback
            rules={[{ required: true, message: "Please input your Reason!" }]}
          >
            <Select
              placeholder="Select Reason"
              value={selectedReason}
              onChange={handleChange}
              allowClear
              className="select-p"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {reasons.map((item) => (
                <Select.Option value={item?.id}>{item?.reason}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="add-btn"
              loading={loading}
            >
              Freeze
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FreezeModal;

import React, { createContext, useState, useContext } from "react";

const ValuPromotionContext = createContext();

export function ValuPromotionProvider({ children }) {
  const [valuPromotion, setValuPromotion] = useState([]);

  return (
    <ValuPromotionContext.Provider value={{ valuPromotion, setValuPromotion }}>
      {children}
    </ValuPromotionContext.Provider>
  );
}

export function useValuPromotion() {
  return useContext(ValuPromotionContext);
}

import { Table } from "antd";

import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";

import { useLocation } from "react-router-dom";

const Reasons = (props) => {
  const location = useLocation();
  const record = location.state;

  const [deactivationReasons, setDeactivationReasons] = useState([]);
  useEffect(() => {
    if (record?.cards[0] && record?.cards[0]?.blockReasons?.length > 0) {
      setDeactivationReasons(record?.cards[0]?.blockReasons);
    } else {
      setDeactivationReasons([]);
    }
  }, [record]);
  const blockReasonsTableColumns = [
    {
      title: "Reason",
      dataIndex: "blockReason",
      key: "blockReason",

      render: (txt, record) => (
        <div>
          {record?.blockReason["reason"] ? record?.blockReason["reason"] : "-"}
        </div>
      ),
    },
    {
      title: "UserId",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,
      render: (txt, record) => <>{txt}</>,
    },

    {
      title: "Type",
      dataIndex: "blockedBy",
      key: "blockedBy",

      render: (txt, record) => <div>{txt ? txt : "-"}</div>,
    },
    {
      title: "Date",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (txt, record) => (
        <>
          <span>
            {record?.createdTimeStamp === null
              ? "-"
              : new Date(record?.createdTimeStamp).toLocaleDateString()}
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Reasons</h2>
        <div className="container">
          {deactivationReasons?.length > 0 ? (
            <Table
              className="table2"
              columns={blockReasonsTableColumns}
              dataSource={deactivationReasons}
              size="large"
              scroll={{ x: 900 }}
              //   loading={loading}
              pagination={false}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No Reasons Records Found.
            </div>
          )}
        </div>

        <Totop />
      </div>
    </>
  );
};

export default Reasons;

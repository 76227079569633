import { Space, Button, Table, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";

import axiosInstance from "../../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { TEMPLATE_S3_URL } from "../../../constant";
import { toast } from "react-toastify";
import swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
// import { Redirect } from 'react-router-dom';

const Savings = () => {
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  const [page, setPage] = useState(1);
  console.log(page);
  const [totalPages, setTotalPages] = useState(1);
  const { pageNo } = useParams();
  const [data, setData] = useState([]);
  const [record, setRecord] = useState(null);
  // const [fireRedirect, setFireRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    axiosInstance()
      .get("admin/giftcards/templates")
      .then((response) => {
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response.data.data);
          setData(api_res.data);
          setLoading(false);
          console.log("gift cards", api_res);
        }
      })
      .catch((error) => {
        setLoading(false);

        const err = convertEncryptedToPlantext(error?.response?.data?.data);
        message.error(err.message);
      });
  }, []);

  const columns = [
    {
      title: "Saving Title",
      dataIndex: "templateTitle",
      key: "templateTitle",
      sorter: (a, b) => a.templateTitle.localeCompare(b.templateTitle),
    },
    {
      title: "Target Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a?.amount - b?.amount,
      render: (text, record) => (
        <>{record?.amount === null ? "-" : record?.amount}</>
      ),
    },
    {
      title: "Recurring Payment Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a?.amount - b?.amount,
      render: (text, record) => (
        <>{record?.amount === null ? "-" : record?.amount}</>
      ),
    },
    {
      title: "Deadline",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => <>{new Date(text)?.toLocaleDateString()}</>,
      sorter: (a, b) => new Date(a?.startDate) - new Date(b?.startDate),
    },
    {
      title: "Saving Image",
      dataIndex: "templatePicturePath",
      key: "templatePicturePath",
      render: (text, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={TEMPLATE_S3_URL + record.templatePicturePath}
              style={{ width: "10%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "templateTitle",
      key: "templateTitle",
      sorter: (a, b) => a.templateTitle.localeCompare(b.templateTitle),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          {roles[0] === "SUPER_ADMIN" ||
          "ADMIN" ||
          "FINANCES" ||
          "OPERATIONS" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={() => {
                  setRecord(record);
                  console.log(record);
                }}
              >
                Edit
              </Button>
              <Button
                type="danger"
                className="delete"
                onClick={() => handleDeleteClick(record)}
              >
                Delete
              </Button>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const handleDeleteClick = (data) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Gift Card ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          axiosInstance()
            .delete("admin/giftcards/templates/" + data.templateId)
            .then((response) => {
              if (response.status === 200) {
                toast.success("Success !! Gift Card deleted successfully");
                console.log("success");
                window.location.reload();
                // setFireRedirect(true);
              }
            })
            .catch((error) => {
              toast.error("some thing went error");
            });
        }
      });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Savings Money</h2>

          {roles[0] === "SUPER_ADMIN" ||
          "ADMIN" ||
          "FINANCES" ||
          "OPERATIONS" ? (
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push(`/savings-add/${pageNo}`);
              }}
            >
              ADD
            </Button>
          ) : (
            ""
          )}
        </Space>
        <div className="container">
          {data.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={data}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNo,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/savings/${page}`);
                  // fetchData(page);
                },
              }}
            />
          ) : (
            <div className="messageNoData">No Savings Records Found.</div>
          )}
        </div>
        <Totop />
        {/* {fireRedirect ? <Redirect to={'/gift-card'} /> : ''} */}
      </div>
    </>
  );
};

export default Savings;

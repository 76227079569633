import { Button, Form, message, Modal, InputNumber } from "antd";

import { useEffect, useState } from "react";

import { convertEncryptedToPlantext } from "../../../library/Helper";

import { uploadBulkRegistration } from "../../../KIWE-V1/Services/userAPI.js";

const UploadBulkAccount = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const uploadBulkUsersAccount = async (values) => {
    try {
      setLoading(true);
      const api_res = await uploadBulkRegistration(values?.number);
      handleSuccessfulRequest(api_res);
    } catch (error) {
      setLoading(false);
      handleFailedRequest(error);
    }
  };
  const handleSuccessfulRequest = (api_res) => {
    if (api_res.status === 200) {
      setLoading(false);
      message.success("Submit success!");
      handleCancel();
    }
  };
  const handleFailedRequest = (error) => {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    message.error(err.message);
  };
  useEffect(() => {
    form.setFieldsValue({
      fileList: [],
    });
  }, []);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={"Bulk Account"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={uploadBulkUsersAccount}
          autoComplete="off"
          initialValues={{
            file: "",
          }}
        >
          <Form.Item
            name="number"
            label="Number of Account"
            rules={[
              {
                required: true,
                message: "Please input your Number of Account!",
              },
            ]}
          >
            <InputNumber
              min={0}
              defaultValue={0}
              // onChange={onChange}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.7rem",
              }}
            />
          </Form.Item>

          <Form.Item className="btns" style={{ marginTop: "0rem" }}>
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default UploadBulkAccount;

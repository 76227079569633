import React from "react";
import {
  Form,
  message,
  Button,
  // Input,
  Radio,
  Select,
  Table,
  Space,
  DatePicker,

  // InputNumber,
} from "antd";
import { useState, useEffect } from "react";
import UserCard from "../individual_user/UserCard";
import {
  fetchFinanceBusinessOwnersData,
  fetchTransactionSummaryData,
  exportTransactionApi,
  getBusinessOwnerApi,
} from "../../../KIWE-V1/Services/financeBusinessOwnerApi";

const FinanceBusinessOwner = () => {
  const [form] = Form.useForm();
  // const Option = Select.Option;

  const [financeOwnerData, setFinanceOwnerData] = useState([]);
  // const [total, setTotal] = useState(0);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [value, setValue] = useState(1);
  const [selectedItemsOwner, setSelectedItemsOwner] = useState(null);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  console.log(page);
  const [dataOwner, setDataOwner] = useState({});
  const { RangePicker } = DatePicker;

  const [totalPages, setTotalPages] = useState(1);
  const [businessOwnerData, setBusinessOwnerData] = useState([]);

  // const [record, setRecord] = useState([]);

  // const history = useHistory();
  function onChange2(dates, dateStrings) {
    if (dates) {
      setDate({ startDate: dateStrings[0], endDate: dateStrings[1] });
    }
  }
  //get all business owners
  const handleGetAllFinanceBusinessOwners = async () => {
    try {
      const apiResponse = await fetchFinanceBusinessOwnersData();
      setFinanceOwnerData(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    handleGetAllFinanceBusinessOwners();
  }, []);

  const onFinish = (values) => {
    console.log(values);
  };

  const handleChange2 = (selectedItems) => {
    console.log(selectedItems, "selectedItems");
    //id of the selected branch
    setSelectedItemsOwner(selectedItems);

    fetchTransactionSummary(selectedItems, true);
    fetchBusinessOwner(1, selectedItems);
  };

  const fetchTransactionSummary = async (selectedItems, flag) => {
    try {
      const apiResponse = await fetchTransactionSummaryData(
        selectedItems,
        flag
      );
      setDataOwner(apiResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const fetchBusinessOwner = async (page, userId) => {
    try {
      const apiResponse = await getBusinessOwnerApi(page, userId);
      setTotalPages(apiResponse?.totalRecords);
      setBusinessOwnerData(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  const columns = [
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (txt, value) => (
        <>
          {value.transactionType === "addMoney"
            ? "Money Added"
            : value.transactionType === "in"
            ? "Money In"
            : value.transactionType === "out"
            ? "Money Out"
            : value.transactionType === "withdrawMoney"
            ? "Withdraw Money"
            : "-"}
        </>
      ),

      // ellipsis: true,
      // width: 180,
    },
    {
      title: "From/To person",
      dataIndex: "personName",
      key: "personName",
      sorter: (a, b) => a.personName.localeCompare(b.personName),
      // ellipsis: true,
      render: (txt, value) => <>{txt ? txt : "-"}</>,

      // width: 170,
    },
    {
      title: "Transaction Fee",
      dataIndex: "txnFees",
      sorter: (a, b) => a.txnFees - b.txnFees,
      // ellipsis: true,
      // width: 154,
    },
    {
      title: "Transaction Charge",
      dataIndex: "txnCharges",
      sorter: (a, b) => a.txnCharges - b.txnCharges,
      // ellipsis: true,
      // width: 180,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      // ellipsis: true,
      render: (txt, value) => <>0{txt}</>,
      // width: 110,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      // ellipsis: true,
      render: (txt, value) => <>E£ {txt?.toFixed(2)}</>,
      // width: 110,
    },
    {
      title: "Tip",
      dataIndex: "tipAmount",
      sorter: (a, b) => a.tipAmount - b.tipAmount,
      // ellipsis: true,
      render: (txt) => <>{txt ? txt : "-"}</>,
      // width: 105,
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      // ellipsis: true,
      // width: 110,
      render: (txt) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Status",
      dataIndex: "transaction_status",
      key: "transaction_status",
      // ellipsis: true,
      // width: 110,
      render: (txt) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
      // ellipsis: true,
      render: (txt) => <>{new Date(txt).toLocaleString()}</>,
    },
  ];
  const handleExport = async () => {
    if (date.startDate && date?.endDate) {
      try {
        const apiResponse = await exportTransactionApi(
          selectedItemsOwner,
          date?.startDate,
          date?.endDate
        );
        if (apiResponse.data) {
          window.open(apiResponse?.data, "noopener,noreferrer");
        } else {
          message.error("There are no any Transactions in This Date");
        }
      } catch (error) {
        if (error.response.status === 404) {
          message.error("There are no any Transactions in This Date");
        } else {
          message.error("Something went wrong please try again");
        }
      }
    }
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    if (e.target.value === 2) {
      setBusinessOwnerData([]);
      fetchTransactionSummary(null, false);
    }
    if (e.target.value === 1) {
      setDataOwner({});
    }
    setValue(e.target.value);
  };
  const handelUserCard = () => {
    console.log("iam in", dataOwner);
    if (value === 1 && Object.keys(dataOwner).length) {
      return (
        <UserCard state={dataOwner} place={"business"} role={"businessOwner"} />
      );
    }
    if (value === 2 && Object.keys(dataOwner).length) {
      return (
        <UserCard state={dataOwner} place={"business"} role={"businessUser"} />
      );
    }
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Finance Business</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="notificationFrom"
          >
            {" "}
            <Form.Item
              valuePropName="checked"
              name="options"
              label="Pick Business Type"
            >
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Business Owner</Radio>
                <Radio value={2}>Business User</Radio>
              </Radio.Group>
            </Form.Item>
            {value === 1 && (
              <Form.Item
                name="businessOwnerId"
                label="Choose Business Owners"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Business Owner!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Business Owners"
                  value={selectedItemsOwner}
                  onChange={handleChange2}
                  allowClear
                  className="select-p"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue?.toLowerCase())
                  }
                  showSearch
                >
                  {financeOwnerData.map((item) => (
                    <Select.Option value={item.id}>
                      {item.firstName + " " + item.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
          {handelUserCard()}

          {businessOwnerData.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2rem",
                  flexWrap: "wrap",
                  paddingBottom: "1rem",
                }}
              >
                <Space style={{ padding: "0.5rem 0" }}>
                  <RangePicker onChange={onChange2} />
                  <Button type="primary" size="middle" onClick={handleExport}>
                    Export
                  </Button>
                </Space>
              </div>
              <Table
                style={{ marginTop: "1rem" }}
                className="table2"
                columns={columns}
                dataSource={businessOwnerData}
                scroll={{ x: 900 }}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  total: totalPages,
                  current: +page,
                  onChange: (page) => {
                    setPage(page);
                    fetchBusinessOwner(page, selectedItemsOwner);
                  },
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default FinanceBusinessOwner;

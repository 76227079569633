import { Button, Form, message, Modal, Input, Switch, Avatar } from "antd";
import { useState, useEffect } from "react";
import DragImage from "../../front/form/NewDragImage";
import { normFile } from "../../shared/dragdropFun";
import {
  uploadImageS3,
  convertImageToBase64,
} from "../../../library/UploadImage";
import { generateRandomFileNameWithoutExtention } from "../../shared/uploadfun";
import { addHubApi, editHubApi } from "../../../KIWE-V2/Services/hubApi";

const Add = ({ setIsModalVisible, isModalVisible, setHubs, record }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleAddandUpdateHub = (values) => {
    if (record) {
      if (readyToSubmit) {
        handleUpdateHub(values);
      }
    } else {
      handleAddHub(values);
    }
  };

  const handleAddHub = async (values) => {
    try {
      setLoading(true);
      const imageUrl = await handleUploadBannerHub(values);
      if (imageUrl) {
        values.image = imageUrl.fileUrl;
      }
      const apiResponse = await addHubApi(values);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Hub Added Successfully!",
        };
        message.success(configSuccess);
        const newData = { ...apiResponse.data };
        setHubs((prevData) => [...prevData, newData]);
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const handleUpdateHub = async (values) => {
    try {
      setLoading(true);
      const imageUrl = await handleUploadBannerHub(values);
      if (imageUrl) {
        values.image = imageUrl.fileUrl;
      }
      const apiResponse = await editHubApi(values, record?.id);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Hub Updated Successfully!",
        };
        message.success(configSuccess);
        const updatedHub = { ...apiResponse.data };
        setHubs((prevHubs) => {
          const updatedHubs = prevHubs.map((hub) => {
            if (hub.id === updatedHub.id) {
              return updatedHub;
            }
            return hub;
          });
          return updatedHubs;
        });
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const handleUploadBannerHub = async (values) => {
    const file =
      values.image && values.image.length > 0
        ? values.image[0].originFileObj
        : null;
    if (file) {
      const base64Image = await convertImageToBase64(file);
      let randomName = generateRandomFileNameWithoutExtention(values?.image[0]);
      const imageUrl = await uploadImageS3(randomName, "hub", base64Image);
      return imageUrl;
    } else {
      return record?.image ? { fileUrl: record.image } : null;
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setFileList([]);
    form.setFieldsValue({ banner: [] });
  };

  useEffect(() => {
    if (record && isModalVisible) {
      form.setFieldsValue({
        title: record?.title,
        visible: record?.visible,
        backgroundColor: record?.backgroundColor,
      });
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [isModalVisible, record, form]);

  const handleTitleChange = () => {
    setReadyToSubmit(false);
  };

  const validateWhiteSpace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter a valid title"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Modal
        title={record ? "Edit PayHub" : "Add PayHub"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddandUpdateHub}
          autoComplete="off"
          initialValues={
            record
              ? {
                  title: record?.title,
                  visible: record?.visible,
                  backgroundColor: record?.backgroundColor,
                }
              : null
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {record ? (
              <Avatar
                size={80}
                shape="square"
                style={{ marginRight: "1rem", borderRadius: "10px" }}
                src={record?.image}
              />
            ) : (
              ""
            )}
            <DragImage
              normFile={normFile}
              filelist={fileList}
              nam1="image"
              accept=".jpg,.jpeg,image/jpeg,.png,image/png,.webp,image/webp"
              label="Banner Image"
            />
          </div>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input your Title PayHub!",
              },
              { validator: validateWhiteSpace },
            ]}
          >
            <Input
              aria-label="Title"
              placeholder="Enter Title PayHub"
              className="input-border"
              onChange={handleTitleChange}
            />
          </Form.Item>
          <Form.Item
            name="backgroundColor"
            label="PayHub Background Color"
            hasFeedback
            className="offer-name"
            rules={[
              {
                required: true,
                message: "Please input your PayHub Background Color!",
              },
            ]}
          >
            <Input
              type="color"
              className="input-border"
              defaultValue={"#0e4bfb"}
            />
          </Form.Item>
          <Form.Item
            name="visible"
            label="Activate PayHub"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please input your activate PayHub!",
              },
            ]}
          >
            <Switch size="middle" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
              onClick={() => setReadyToSubmit(true)}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

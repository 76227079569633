import {
  Input,
  InputNumber,
  Button,
  Form,
  Select,
  message,
  Col,
  Row,
  Switch,
} from "antd";

import { useState } from "react";
import { addRewardLevelAPI } from "../../../KIWE-V2/Services/rewardLevel";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import DragImage from "../../front/form/DragImage";
import { normFile } from "../../shared/dragdropFun";
import { uploadImageS3 } from "../../../library/UploadImage";
import { generateRandomFileName } from "../../shared/uploadfun";
import { useLevelReward } from "../../../contextAPi/levelReward/levelRewardContext.js";
import { useHistory } from "react-router";
const AddRewardLevel = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { levelsReward, setLevelsRewards } = useLevelReward();
  const history = useHistory();
  const addLevelsRewards = async (values) => {
    try {
      setLoading(true);
      const file =
        values.icon && values.icon.length > 0
          ? values.icon[0].originFileObj
          : null;
      const base64Image = await convertImageToBase64(file);
      let randomName = generateRandomFileName(values?.icon[0]);
      const imageUrl = await uploadImageS3(
        randomName,
        "reward_level",
        base64Image
      );
      const discountPercentage =
        values.discountPercentage !== undefined ? values.discountPercentage : 0;
      const cashbackPercentage =
        values.cashbackPercentage !== undefined ? values.cashbackPercentage : 0;
      const isDefault = levelsReward.length === 0 ? values.isDefault : false;
      const apiValues = {
        ...values,
        discountPercentage,
        cashbackPercentage,
        isDefault,
      };

      const apiResponse = await addRewardLevelAPI(apiValues, imageUrl.fileUrl);

      if (apiResponse.status === 201) {
        setLoading(false);
        const configSuccess = {
          className: "messageSuccess",
          content: "Added New Reward Level Successfully!",
        };
        message.success(configSuccess);

        const decryptedResponse = convertEncryptedToPlantext(
          apiResponse?.data?.data
        );

        const newData = { ...decryptedResponse };
        setLevelsRewards((prevData) => [...prevData, newData]);
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(null);
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(",")[1];
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  const validateCashbackPercentage = (rule, value) => {
    if (value < 0 || value > 100) {
      return Promise.reject("Cashback percentage must be between 0 and 100");
    }
    return Promise.resolve();
  };

  const validateDiscountPercentage = (rule, value) => {
    if (value !== undefined && (value < 0 || value > 100)) {
      return Promise.reject("Discount percentage must be between 0 and 100");
    }
    return Promise.resolve();
  };

  const handleCashbackChange = (value) => {
    form.setFieldsValue({ discountPercentage: 0 });
  };

  const handleDiscountChange = (value) => {
    form.setFieldsValue({ cashbackPercentage: 0 });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Add Level Reward</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            onFinish={addLevelsRewards}
            autoComplete="off"
            initialValues={{
              discountPercentage: 0,
              cashbackPercentage: 0,
              nextLevelId: 0,
              timeFrame: "MONTHLY",
              hexaColor: "#0e4bfb",
            }}
          >
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <DragImage
                  normFile={normFile}
                  nam1="icon"
                  label="Icon"
                  filelist={[]}
                />
              </Col>
              {levelsReward.length === 0 ? (
                <Col span={12}>
                  <Form.Item
                    name="isDefault"
                    label="Default Level"
                    valuePropName="checked"
                  >
                    <Switch size="middle" />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item name="hexaColor" label="Level Color" hasFeedback>
                  <Input
                    type="color"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                    defaultValue={"#0e4bfb"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Level Name"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please input Level Name!" },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="transactionCountToReach"
                  label="Transaction Count To Reach"
                  rules={[
                    {
                      required: true,

                      message: "Please input Transaction Count To Reach!",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "unset",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="transactionCountToMaintain"
                  label="Transaction Count To Maintain"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input your Transaction Count To Maintain!",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",

                      height: "unset",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="expenditureToMaintain"
                  label="Expenditure To Maintain"
                  rules={[
                    {
                      required: true,
                      message: "Please input Expenditure To Maintain!",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",

                      height: "unset",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="expenditureToReach"
                  label="Expenditure To Reach"
                  rules={[
                    {
                      required: true,

                      message: "Please input Expenditure To Reach!",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "unset",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="cashbackPercentage"
                  label="Cashback %"
                  rules={[
                    {
                      validator: validateCashbackPercentage,
                    },
                  ]}
                >
                  <InputNumber
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                    onChange={handleCashbackChange}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="discountPercentage"
                  label="Discount %"
                  rules={[
                    {
                      validator: validateDiscountPercentage,
                    },
                  ]}
                >
                  <InputNumber
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                    onChange={handleDiscountChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item label={"Time Frame"} name={"timeFrame"}>
                  <Select
                    defaultValue={"MONTHLY"}
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                    }}
                    className="selectLevel"
                  >
                    <Option value="DAILY">Daily</Option>
                    <Option value="WEEKLY">Weekly</Option>
                    <Option value="MONTHLY">Monthly</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="nextLevelId"
                  label="Next Level Id"
                  rules={[
                    {
                      required: false,

                      message: "Please input Next Level Id!",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    className="inputs-business"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "unset",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
                className="add-btn"
              >
                Add Reward Level
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddRewardLevel;

import { Space, Button, Table, message, Modal, Card, Tag } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { EyeOutlined } from "@ant-design/icons";
import { fetchAnnouncementsApi } from "../../../KIWE-V2/Services/announcementApi";
import { useHistory, Link } from "react-router-dom";

const Announcements = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllAnnouncements = async (page) => {
    try {
      const apiResponse = await fetchAnnouncementsApi(page);

      setLoading(false);
      setAnnouncements(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);

      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    if (pageNum) {
      fetchAllAnnouncements(pageNum);
    } else {
      fetchAllAnnouncements(page);
    }
  }, [page, pageNum]);

  const AnnouncementsColumns = [
    {
      title: "Banner Name",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Banner Image",
      dataIndex: "bannerImage",
      key: "bannerImage",
      render: (txt, record) => (
        <div>
          <EyeOutlined
            onClick={() => {
              showModal();
              setRecord(record);
            }}
          />
        </div>
      ),
    },
    {
      title: "Text Color",
      dataIndex: "textColor",
      key: "textColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record.textColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (text, record) => (
        <>
          <div
            style={{
              backgroundColor: record?.tag?.backgroundColor,
              color: record?.tag?.nameColor,
              padding: "3px 6px",
              borderRadius: "25px",
              width: "5.5rem",
              height: "2rem",
              display: "flex",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {record?.tag?.name}
          </div>
        </>
      ),
    },
    {
      title: "Tag Background Color",
      dataIndex: "tag.backgroundColor",
      key: "tag.backgroundColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record?.tag?.backgroundColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Tag Name",
      dataIndex: "tag.name",
      key: "tag.name",
      render: (txt, record) => (
        <>{record?.tag?.name ? record?.tag?.name : "-"}</>
      ),
    },
    {
      title: "Navigate To",
      dataIndex: "navigateTo.pageName",
      key: "navigateTo.pageName",
      render: (txt, record) => (
        <>{record?.navigateTo?.pageName ? record?.navigateTo?.pageName : "-"}</>
      ),
    },
    {
      title: "Announcement Status",
      dataIndex: "enabled",
      key: "enabled",

      render: (txt, record) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {record?.enabled === true ? (
              <div className="custom-tag onboarding-status-approved">
                Active
              </div>
            ) : record?.enabled === false ? (
              <div className="custom-tag onboarding-status-rejected">
                InActive
              </div>
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Link
              to={{
                pathname: "/edit-announcement",
                state: { record: record },
              }}
            >
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
              >
                Edit
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Announcements</h2>
          <Space style={{ justifyContent: "flex-end" }}>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push("/add-announcement");
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {announcements.length > 0 ? (
            <Table
              className="table2"
              columns={AnnouncementsColumns}
              dataSource={announcements}
              rowKey="id"
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/announcement/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No Announcements Records Found.
            </div>
          )}
        </div>

        <Totop />
        <Modal
          title={
            <div className="custom-modal-kyc-title">
              <h3 style={{ fontWeight: "bold" }}>Bannar/Photo</h3>
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={600}
          className="modal"
        >
          <div className="creditContainer">
            <Card className="creditCard">
              <div className="creditCardImage">
                <img src={record?.photo} alt="banner" />
              </div>
            </Card>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Announcements;

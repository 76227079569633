import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchTransactionFeesData = async () => {
  try {
    const decryptedResponse = await apiCall("transaction_fees/list", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
const buildEditTransactionFeesRequestBody = (values, record) => {
  return {
    id: record?.id,
    fees: values.fees,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const editTransactionFeesAPI = async (values, record) => {
  try {
    const requestBody = buildEditTransactionFeesRequestBody(values, record);

    const decryptedResponse = await apiCall(
      "admin/update_transaction_fees",
      requestBody
    );

    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

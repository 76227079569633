import { Button, Space, Table, message } from "antd";
import { useHistory } from "react-router-dom";
import Totop from "../../shared/TopUp";
import { useState, useEffect } from "react";
import { notification_picture_path } from "../../../constant";
import { useParams } from "react-router-dom";
import moment from "moment";
import { fetchNotificationsData } from "../../../KIWE-V1/Services/notificationApi.js";
const Notification = () => {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { pageNo } = useParams();

  const fetchAllNotifications = async (page) => {
    try {
      const apiResponse = await fetchNotificationsData(page);
      setTotalPages(apiResponse?.totalRecords);
      setNotifications(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  useEffect(() => {
    fetchAllNotifications(pageNo);
  }, [pageNo]);

  const columns = [
    {
      title: "Notification ID",
      dataIndex: "notificationId",
      key: "notificationId",
      sorter: (a, b) => a.notificationId - b.notificationId,
    },
    {
      title: "Notification Text",
      dataIndex: "notificationText",
      key: "notificationText",
      sorter: (a, b) => a.notificationText?.localeCompare(b.notificationText),
    },
    {
      title: "Notification Image",
      dataIndex: "notification_image",
      key: "notification_image",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={notification_picture_path + record?.notification_image}
              style={{ width: "15%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text, record) => (
        <>{moment(record?.createdTimeStamp).format("D MMM, yyyy h:mm a")}</>
      ),
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const handleClick = () => {
    history.push(`/add-notification/${pageNo}`);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Notification</h2>
          <Space>
            <Button type="primary" size="middle" onClick={handleClick}>
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={notifications}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNo,
              onChange: (page) => {
                setPage(page);

                history.push(`/notification/${page}`);

                fetchAllNotifications(page);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Notification;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";

export const buildRequestBody = (
  addtionalRequestBodyData,
  searchText,
  page,
  orderBy
) => {
  return {
    ...addtionalRequestBodyData,
    search: searchText,
    pageNo: page,
    orderBy: orderBy,
    sortOrder: "desc",
    expiryTime: getCurrentTimeStemp(),
  };
};

export const apiCall = async (
  url,
  requestData,
  method = "post",
  decrypt = true
) => {
  try {
    const apiResponse = await axiosInstance()[method](url, {
      data: convertPlantextToEncrypted(requestData),
    });
    if (decrypt) {
      return convertEncryptedToPlantext(apiResponse?.data?.data);
    }
    return apiResponse;
  } catch (error) {
    return error;
  }
};

export const fetchWithdrawalListAndDecryptResponse = async (
  searchText,
  page
) => {
  try {
    const addtionalRequestBodyData = {
      accountType: "business",
    };
    const requestBody = buildRequestBody(
      addtionalRequestBodyData,
      searchText,
      page,
      "id"
    );

    const decryptedResponse = await apiCall(
      "admin/withdrawalRequests",
      requestBody
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

const generateExportWithdrawalUrl = (isDate) => {
  if (isDate) {
    return "transaction/export-withdrawal-list/by/date";
  } else {
    return "transaction/export_withdrawal_list";
  }
};
export const exportWithdrawalByDateOrSearchTextFromInputAndDecryptResponse =
  async (searchTextFromInput, startDate, endDate, isDate, page) => {
    try {
      const url = generateExportWithdrawalUrl(isDate);
      const addtionalRequestBodyData = {
        accountType: "business",
      };
      if (startDate && endDate) {
        addtionalRequestBodyData.startDate = startDate;
        addtionalRequestBodyData.endDate = endDate;
      }
      const requestBody = buildRequestBody(
        addtionalRequestBodyData,
        searchTextFromInput,
        page,
        "id"
      );
      const decryptedResponse = await apiCall(url, requestBody);
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  };
export const updateWithdrawalListStatus = async (status, transactionId) => {
  const requestBody = {
    transactionId: transactionId,
    transaction_status: status,
    expiryTime: getCurrentTimeStemp(),
  };
  const decryptedResponse = await apiCall(
    "admin/update_withdrawal_req_status",
    requestBody
  );
  return decryptedResponse;
};

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import InputSearch from "../form/InputSearch";
import AddRole from "./AddAdmin/AddRole";
import Totop from "../../shared/TopUp";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { FormOutlined } from "@ant-design/icons";
import moment from "moment";
import delIco from "../../../assets/images/delete.svg";
import contentIcon from "../../../assets/images/contentButtonWhite.svg";
import { getAdmins, deleteAdmin } from "../../../KIWE-V1/Services/adminApi";

const Index = () => {
  const [record, setRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));

  const onSearch = (value) => {
    setSearchText(value);

    fetchAdmins(1, value);
  };
  const fetchAdmins = async (page, searchText) => {
    try {
      const apiResponse = await getAdmins(page, searchText);
      setAdmins(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleDeleteAdmin = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Admin?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(async function (isConfirm) {
        if (isConfirm.value) {
          try {
            const response = await deleteAdmin(record.userId);
            if (response) {
              window.location.reload();
              message.success("deleted successfully");
            }
          } catch (error) {
            message.error(error.message);
          }
        }
      });
  };
  const { pageNo } = useParams();
  const history = useHistory();
  useEffect(() => {
    fetchAdmins(pageNo, searchText);
  }, []);
  const adminColumns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) =>
        a.firstName?.localeCompare(b.firstName, { ignorePunctuation: true }),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) =>
        a.lastName?.localeCompare(b.lastName, { ignorePunctuation: true }),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.length - b.email?.length,
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text) => <>{moment(text).format("D MMM, yyyy h:mm a")}</>,
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role?.length - b.role?.length,
      render: (text, record) => (
        <>
          {record?.roles.length > 0 ? (
            <>
              {record?.roles.map((item) => (
                <span>{item.roleName},</span>
              ))}
            </>
          ) : (
            <div
              className="eye"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              -{" "}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (val, record) => (
        <>
          {roles[0] === "SUPER_ADMIN" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormOutlined
                style={{ margin: ".5rem", fontSize: "18px", color: "#696974" }}
                onClick={() => {
                  showModal();
                  setRecord(record);
                }}
              />

              <img
                src={delIco}
                style={{ width: "18px", cursor: "pointer" }}
                onClick={() => handleDeleteAdmin(record)}
                alt=""
              />
            </div>
          ) : (
            " - "
          )}
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleContent = () => {
    history.push("/content");
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Admin</h2>
          <Space>
            <InputSearch onSearch={onSearch} />
            <Space>
              <Button
                type="primary"
                size="middle"
                onClick={handleContent}
                className="btnHover"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={contentIcon}
                    alt="-"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: "5px",
                    }}
                  />
                  <span style={{ fontWeight: "bold" }}>Content</span>
                </span>
              </Button>

              {roles[0] === "SUPER_ADMIN" ? (
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    showModal();
                    setRecord(null);
                  }}
                >
                  Add Admin
                </Button>
              ) : (
                ""
              )}
            </Space>
            <AddRole
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={adminColumns}
            dataSource={admins}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNo,
              onChange: (page) => {
                setPage(page);
                history.push(`/admin/${page}`);

                fetchAdmins(page, searchText);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Index;

import { Modal, Button } from "antd";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";

const BankDetails = ({
  data,
  handleCancel,
  isModalVisible,
  record,
  firstName,
}) => {
  console.log(record?.transactionId);
  const handlExport = () => {
    axiosInstance()
      .post("transaction/export_withdrawal_payment_details", {
        data: convertPlantextToEncrypted({
          transactionId: record?.transactionId,
          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        // debugger;
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response?.data?.data);
          window.open(api_res?.data, "noopener,noreferrer");
          handleCancel();
          console.log(api_res, "exp");
        }
      })
      .catch((error) => {
        // debugger;

        console.log(error.response);
      });
  };
  return (
    <Modal
      title={firstName ? firstName : record?.senderName + " Bank Account"}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="modal-business"
    >
      <div
        className="modal-above"
        style={{ padding: "unset", border: "unset" }}
      >
        <div className="column1" style={{ width: "unset" }}>
          <div className="column1__column">
            <span className="column1__column__header">Bank Name</span>
            <span className="column1__column__footer">
              {record?.bankName ? record?.bankName : "no return"}
            </span>
          </div>
          <div className="column1__column">
            <span className="column1__column__header">Branch Name</span>
            <span className="column1__column__footer">
              {record?.branchName ? record?.branchName : "not return"}
            </span>
          </div>
          <div className="column1__column">
            <span className="column1__column__header">Bank Account Name</span>
            <span className="column1__column__footer">
              {record?.accountHolderName
                ? record?.accountHolderName
                : "no return"}
            </span>
          </div>
        </div>

        <div className="column2" style={{ width: "unset" }}>
          <div className="column2__column">
            <span className="column2__column__header">Bank Account Number</span>
            <span className="column2__column__footer">
              {record?.accountNo ? record?.accountNo : "no return"}
            </span>
          </div>

          <div className="column2__column">
            <span className="column2__column__header">Iban Number </span>
            <span className="column2__column__footer">
              {record?.ibanNumber ? record?.ibanNumber : "not return"}
            </span>
          </div>
          <div className="column2__column">
            <span className="column2__column__header">Swift Code</span>
            <span className="column2__column__footer">
              {record?.swiftCode ? record?.swiftCode : "not return"}
            </span>
          </div>
        </div>
      </div>
      <div className="column3">
        <Button
          type="primary"
          className="column1__btn-export"
          onClick={handlExport}
        >
          Export
        </Button>
        <Button
          type="primary"
          className="column2__btn-print"
          onClick={handleCancel}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default BankDetails;

import { Space, Button, Table, message, Modal, Card, Tag } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { useHistory, Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { fetchAnnouncementsApi } from "../../../KIWE-V1/Services/oldAnnouncementApi";
const OldAnnouncements = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllAnnouncements = async (page) => {
    try {
      const apiResponse = await fetchAnnouncementsApi(page);
      setLoading(false);
      setAnnouncements(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);

      // const configError = {
      //   className: "messageError",
      //   content: error.message,
      // };
      // message.error(configError);
    }
  };

  useEffect(() => {
    if (pageNum) {
      fetchAllAnnouncements(pageNum);
    } else {
      fetchAllAnnouncements(1);
    }
  }, [pageNum]);

  const AnnouncementsColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Title Color",
      dataIndex: "titleColor",
      key: "titleColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record.titleColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Background Color",
      dataIndex: "backgroundColor",
      key: "backgroundColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record?.backgroundColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Content",
      dataIndex: "text",
      key: "text",
      render: (txt, record) => (
        <div>
          <EyeOutlined
            onClick={() => {
              showModal();
              setRecord(record);
            }}
          />
        </div>
      ),
    },

    {
      title: "Content Color",
      dataIndex: "textColor",
      key: "textColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record?.textColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Button Text",
      dataIndex: "buttonText",
      key: "buttonText",
      render: (txt, record) => (
        <div>{record?.buttonText ? record?.buttonText : "-"}</div>
      ),
    },

    {
      title: "Button Color",
      dataIndex: "buttonColor",
      key: "buttonColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record?.buttonColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },

    {
      title: "Navigate To",
      dataIndex: "navigateTo.pageName",
      key: "navigateTo.pageName",
      render: (txt, record) => (
        <>{record?.navigateTo?.pageName ? record?.navigateTo?.pageName : "-"}</>
      ),
    },
    {
      title: "Announcement Status",
      dataIndex: "enabled",
      key: "enabled",

      render: (txt, record) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {record?.enabled === true ? (
              <div className="custom-tag onboarding-status-approved">
                Active
              </div>
            ) : record?.enabled === false ? (
              <div className="custom-tag onboarding-status-rejected">
                InActive
              </div>
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Link
              to={{
                pathname: "/edit-old-announcement",
                state: { record: record },
              }}
            >
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
              >
                Edit
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Announcements</h2>
        <div className="container">
          <Space
            direction="horizontal"
            className="topHeader"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push("/add-old-announcement");
              }}
            >
              ADD
            </Button>
          </Space>
          {announcements.length > 0 ? (
            <Table
              className="table2"
              columns={AnnouncementsColumns}
              dataSource={announcements}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/old-announcement/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No Announcements Records Found.
            </div>
          )}
        </div>

        <Totop />
        <Modal
          title={
            <div className="custom-modal-kyc-title">
              <h3 style={{ fontWeight: "bold" }}>Content</h3>
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={600}
          className="modal"
        >
          <div className="creditContainer">
            <Card className="creditCard">
              <div className="creditCardImage" style={{ fontSize: "14px" }}>
                {record?.text}
              </div>
            </Card>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default OldAnnouncements;

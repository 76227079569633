// import React from "react";
// import { Pie } from "@ant-design/plots";
// import classes from "./BaseInsightsStyles.module.css";

// const Wheel = () => {
//   const data = [
//     {
//       type: "red",
//       value: 75,
//     },
//     {
//       type: "blue",
//       value: 25,
//     },
//     // {
//     //   type: "green",
//     //   value: 25,
//     // },
//   ];
//   const config = {
//     // appendPadding: 10,
//     data,
//     angleField: "value",

//     // color: ["#F55053", "#0032CB", "#33B978"],
//     colorField: "type", // or seriesField in some cases
//     color: ({ type }) => {
//       if (type === "red") {
//         return "#33B978";
//       } else {
//         return "#FD2A2F";
//       }
//     },
//     dius: 0.8,
//     statistic: {
//       title: false,
//       content: false,
//     },
//     label: false,
//     legend: false,
//     interactions: [
//       {
//         type: "element-active",
//       },
//     ],
//   };
//   return (
//     <div className={`${classes.WheelContainer} `}>
//       <div className={`${classes.BaseBetweenCloumn} ${classes.infoWheel}`}>
//         <h5 className={`${classes.Header} ${classes.wheelHeader}`}>
//           Adding Method
//         </h5>
//         <div className={classes.BaseBetweenCloumn}>
//           <div className={classes.BaseBetween}>
//             <span
//               style={{ backgroundColor: "#F55053" }}
//               className={classes.full_circle}
//             ></span>
//             &nbsp;&nbsp;
//             <span>ValU</span>
//           </div>
//           <div className={classes.BaseBetween}>
//             <span
//               style={{ backgroundColor: "#0032CB" }}
//               className={classes.full_circle}
//             ></span>
//             &nbsp;&nbsp;
//             <span>Visa</span>
//           </div>
//           <div className={classes.BaseBetween}>
//             <span
//               style={{ backgroundColor: "#33B978" }}
//               className={classes.full_circle}
//             ></span>
//             &nbsp;&nbsp;
//             <span>MasterCard</span>
//           </div>
//         </div>
//       </div>

//       <div className={classes.WheelPie}>
//         <Pie {...config} style={{ width: "320px" }} />
//       </div>
//     </div>
//   );
// };

// export default Wheel;
import React from "react";
import { Pie } from "@ant-design/plots";
import classes from "./BaseInsightsStyles.module.css";

const Wheel = () => {
  const data = [
    {
      type: "red",
      value: 73,
    },
    {
      type: "blue",
      value: 27,
    },
    // {
    //   type: "green",
    //   value: 25,
    // },
  ];
  const config = {
    // appendPadding: 10,
    data,
    angleField: "value",

    // color: ["#F55053", "#0032CB", "#33B978"],
    colorField: "type", // or seriesField in some cases
    color: ({ type }) => {
      if (type === "red") {
        return "#33B978";
      } else {
        return "#FD2A2F";
      }
    },
    dius: 0.8,
    statistic: {
      title: false,
      content: false,
    },
    label: false,
    legend: false,
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div className={classes.BarContainer}>
      <h5 className={classes.Header}>Status</h5>
      <div
        className={
          `${classes.WrapeRight} ${classes.BaseBetween}` + " secondPartBar"
        }
      >
        <span
          style={{ backgroundColor: "rgb(245, 80, 83)" }}
          className={classes.circle}
        ></span>

        <span>Success</span>

        <span
          style={{ backgroundColor: "rgb(51, 185, 120)" }}
          className={classes.circle}
        ></span>

        <span>Fail</span>
      </div>

      <div className={classes.WheelPie}>
        <Pie {...config} style={{ width: "320px" }} />
      </div>
    </div>
  );
};

export default Wheel;

import { Avatar, Collapse } from "antd";
const ParentUser = ({ cardUserDetails }) => {
  const { Panel } = Collapse;

  return (
    <>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="View Parent Details" key="1">
          <div
            className="indi"
            style={{
              width: "40%",
              border: "1px solid #f1f1f5",
              boxSizing: "border-box",
              borderRadius: "18px",
              background: "#ffffff",
              margin: "0 auto",
              padding: "1rem 0rem",
            }}
          >
            <div className="section_row">
              <Avatar
                src={cardUserDetails?.parent?.profilePicture}
                size={130}
              />
              <div>
                {cardUserDetails?.parent?.firstName?.trim()?.length !== 0 ? (
                  <div
                    className="section indi_content"
                    style={{ width: "105px" }}
                  >
                    <h3>First Name</h3>
                    <span>{cardUserDetails?.parent?.firstName}</span>
                  </div>
                ) : (
                  <div
                    className="section indi_content"
                    style={{ width: "105px" }}
                  >
                    <h3>First Name</h3>
                    <span>-</span>
                  </div>
                )}
                {cardUserDetails?.parent?.lastName?.trim()?.length !== 0 ? (
                  <div
                    className="section indi_content"
                    style={{ width: "105px" }}
                  >
                    <h3>Last Name</h3>
                    <span>{cardUserDetails?.parent?.lastName}</span>
                  </div>
                ) : (
                  <div
                    className="section indi_content"
                    style={{ width: "105px" }}
                  >
                    <h3>Last Name</h3>
                    <span>-</span>
                  </div>
                )}
              </div>
            </div>

            <div className="section_row">
              <div className="section">
                <h3>Parent Id</h3>
                <span>{cardUserDetails?.id ? cardUserDetails?.id : "-"}</span>
              </div>

              <div className="section indi_content">
                <h3>User Name</h3>
                <span>
                  {cardUserDetails?.parent?.username
                    ? cardUserDetails?.parent?.username
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default ParentUser;

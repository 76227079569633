import { Input, Button, Form, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { addAndEditOrganization } from "../../../KIWE-V1/Services/userAPI";
const Edit = ({ isModalVisible, setIsModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(
    record
      ? {
          industryTypes: record?.organizations?.map(
            (item) => item.industryTypeId
          ),
          hubId: record?.hubId,
        }
      : null
  );
  console.log(record?.userId, "cccccccccccc");
  // console.log(record?.branches.map((item) => item.businessName));
  const handleAddAndEditOrganization=async(values)=>{
    try {
      await addAndEditOrganization(record,values);
      message.success("Submit success!");
      handleCancel();
      window.location.reload();
    } catch (error) {
      return message.error(error.message);
    }
  }
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      console.log(length);
      if (length !== 0) {
        setInitialValues({
          industryTypes: record?.organizations?.map(
            (item) => item.industryTypeId
          ),
          hubId: record?.hubId,
        });
      } else {
        form.setFieldsValue({ industryTypes: "" });
        form.setFieldsValue({ hubId: "" });

        setInitialValues({});
      }
    }
  }, [record]);

  useEffect(() => {
    // debugger;
    if (record) {
      form.setFieldsValue({
        industryTypes: record?.organizations?.map(
          (item) => item.industryTypeId
        ),
      });
      form.setFieldsValue({
        hubId: record?.hubId,
      });
    }
  }, [record, initialValue]);
  const [options, setOptions] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  useEffect(() => {
    axiosInstance()
      .get("industry/getIndustryType", {
        data: convertPlantextToEncrypted({
          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response?.data.data);
          setOptions(api_res.data);
          console.log("Industry types", api_res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Modal
        title={record ? "Edit Organization" : "Add Organization"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndEditOrganization}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={initialValue}
        >
          {record?.hubId ? (
            <Form.Item
              name="hubId"
              label="Hub ID"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Enter Mobile!',
              //   },
              // ]}
              // style={{ marginLeft: "-3rem" }}
            >
              <Input className="inputs-business" disabled />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            name="industryTypes"
            label="Organiztion"
            hasFeedback
            rules={
              record
                ? []
                : [
                    {
                      required: true,
                      message: "Please input your Organizations!",
                    },
                  ]
            }
          >
            <Select
              mode="multiple"
              placeholder="Select Branch"
              value={selectedItems}
              onChange={handleChange}
              allowClear
              // style={{ width: '100%', marginTop: '.4rem' }}
              className="select-p"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {options.map((item) => (
                <Select.Option
                  value={item.id}
                  // title={item.businessName}
                  // key={item.businessName}
                >
                  {item.industryTypeName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {record ? "Edit Organization" : "Add Organization"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;

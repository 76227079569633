import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const generateFetchUserApi = (
  page,
  limit = 10,
  cardStatus,
  searchText = null,
  searchTextFilter = null,
  ekycStatus = null,
  cardRequestStatus = null,
  amlVerificationStatus = null,
  dateStrings = null,
  pickupDate = null
) => {
  let url = `v2/admin/card-user?page=${page}&limit=${limit}&cardStatus=${cardStatus}`;

  if (
    searchTextFilter === "username" ||
    searchTextFilter === "mobile" ||
    searchTextFilter === "email"
  ) {
    url += `&${searchTextFilter}=${searchText}`;
  }
  if (ekycStatus !== null) {
    url += `&ekycStatus=${ekycStatus}`;
  }

  if (cardRequestStatus !== null) {
    url += `&cardRequestStatus=${cardRequestStatus}`;
  }
  if (amlVerificationStatus !== null) {
    url += `&amlStatus=${amlVerificationStatus}`;
  }
  if (dateStrings && dateStrings.length > 0) {
    url += `&startDate=${dateStrings[0]}&endDate=${dateStrings[1]}`;
  }

  if (pickupDate && pickupDate.length > 0) {
    url += `&startPickupDate=${pickupDate[0]}&endPickupDate=${pickupDate[1]}`;
  }
  return url;
};

export const fetchUserData = async (
  page,

  cardStatus,
  searchText,
  searchTextFilter,
  ekycStatus,
  cardRequestStatus,
  amlVerificationStatus,
  dateStrings,
  pickupDate
) => {
  const url = generateFetchUserApi(
    page,
    10,
    cardStatus,
    searchText,
    searchTextFilter,
    ekycStatus,
    cardRequestStatus,
    amlVerificationStatus,
    dateStrings,
    pickupDate
  );

  try {
    const response = await axiosInstance().get(url);
    const decryptedResponse = convertEncryptedToPlantext(response?.data?.data);
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

const generateUpdateUserUrl = (userId) => {
  const BASE_URL = "v2/admin/card-user";
  return `${BASE_URL}/${userId}`;
};

const updateUserApiCall = async (url, data) => {
  try {
    const decryptedResponse = await axiosInstance().patch(url, {
      data: convertPlantextToEncrypted(data),
    });
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const updateUser = async (userId, data) => {
  const url = generateUpdateUserUrl(userId);
  const userData = { ...data };
  const updated = await updateUserApiCall(url, userData);
  return updated;
};
const buildUpdateUserLevelRewardRequestBody = (values) => {
  return {
    levelId: values?.rewardLevel,
  };
};
export const editLevelReward = async (record, values) => {
  try {
    const requestBody = buildUpdateUserLevelRewardRequestBody(values);
    const apiResponse = await updateUserApiCall(
      `v2/admin/card-user/${record?.id}/update-level`,
      requestBody
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchSubAccountsDataApiCall = async (page, record) => {
  try {
    const apiResponse = await axiosInstance().get(
      `v2/admin/card-user/${record.id}/sub-accounts?page=${page}&limit=10`
    );
    return convertEncryptedToPlantext(apiResponse?.data?.data);
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );

    throw decryptedError;
  }
};

const generateCardUserApi = (
  page,
  limit = 10,
  searchText = null,
  searchTextFilter = null,
  cardStatus = null,
  pickuped = null,
  ekycStatus = null,
  cardRequestStatus = null,
  amlVerificationStatus = null,
  dateStrings = null,
  pickupDate = null,
  requestDate = null
) => {
  let url = `v2/admin/card-user?page=${page}&limit=${limit}`;

  if (
    searchTextFilter === "username" ||
    searchTextFilter === "mobile" ||
    searchTextFilter === "email"
  ) {
    url += `&${searchTextFilter}=${searchText}`;
  }
  if (cardStatus !== null) {
    url += `&cardStatus=${cardStatus}`;
  }
  if (pickuped !== null) {
    url += `&pickuped=${pickuped}`;
  }
  if (ekycStatus !== null) {
    url += `&ekycStatus=${ekycStatus}`;
  }

  if (cardRequestStatus !== null) {
    url += `&cardRequestStatus=${cardRequestStatus}`;
  }
  if (amlVerificationStatus !== null) {
    url += `&amlStatus=${amlVerificationStatus}`;
  }
  if (dateStrings && dateStrings.length > 0) {
    url += `&startDate=${dateStrings[0]}&endDate=${dateStrings[1]}`;
  }

  if (pickupDate && pickupDate.length > 0) {
    url += `&startPickupDate=${pickupDate[0]}&endPickupDate=${pickupDate[1]}`;
  }
  if (requestDate && requestDate.length > 0) {
    url += `&startRequestDate=${requestDate[0]}&endRequestDate=${requestDate[1]}`;
  }
  return url;
};

export const fetchCardUserData = async (
  page,
  searchText,
  searchTextFilter,
  cardStatus,
  pickuped,
  ekycStatus,
  cardRequestStatus,
  amlVerificationStatus,
  dateStrings,
  pickupDate,
  requestDate
) => {
  try {
    const url = generateCardUserApi(
      page,
      10,
      searchText,
      searchTextFilter,
      cardStatus,
      pickuped,
      ekycStatus,
      cardRequestStatus,
      amlVerificationStatus,
      dateStrings,
      pickupDate,
      requestDate
    );
    const apiResponse = await axiosInstance().get(url);
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};
const prepareUpdateUserCardFlagged = (newFlagged) => {
  return {
    flagged: newFlagged,
  };
};
export const updateUserCardFlagged = async (userId, newFlagged) => {
  try {
    const requestBody = prepareUpdateUserCardFlagged(newFlagged);

    const apiResponse = await axiosInstance().patch(
      `v2/admin/card-user/${userId}`,
      requestBody
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedResponse = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedResponse;
  }
};

export const fetchCardUserDetailsData = async (userId) => {
  try {
    const apiResponse = await axiosInstance().get(
      `v2/admin/card-user/${userId}`
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};
export const fetchCardActionApi = async (page, userId, limit = 10) => {
  try {
    const apiResponse = await axiosInstance().get(
      `v2/admin/card-user/${userId}/card-actions?page=${page}&limit=${limit}`
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

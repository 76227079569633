import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";

export const apiCall = async (
  url,
  requestBody,
  method = "post",
  decrypt = true
) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const generateFetchDisputesApi = (
  page,
  limit = 10,
  searchText = null,
  searchTextFilter = null,
  disputeStatus = null
) => {
  let url = `v2/admin/dispute?page=${page}&limit=${limit}`;
  if (
    searchTextFilter === "disputeId" ||
    searchTextFilter === "mobile" ||
    searchTextFilter === "userId" ||
    searchTextFilter === "transactionId"
  ) {
    url += `&${searchTextFilter}=${searchText}`;
  }
  if (disputeStatus !== null) {
    url += `&status=${disputeStatus}`;
  }
  return url;
};

export const fetchDisputesApi = async (
  page,
  searchText,
  searchTextFilter,
  disputeStatus
) => {
  const url = generateFetchDisputesApi(
    page,
    10,
    searchText,
    searchTextFilter,
    disputeStatus
  );
  try {
    const encryptedResponse = await apiCall(url, {}, "get", false);

    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

export const updateDisputeStatus = async (disputeId, status) => {
  try {
    const url = `v2/admin/dispute/${disputeId}`;
    const data = { status: status };
    const encryptedResponse = apiCall(url, data, "patch", false);
    return encryptedResponse;
  } catch (error) {
    const decryptError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptError;
  }
};

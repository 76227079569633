import { Space, Button, Table, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";
import Add from "./add";
import { TEMPLATE_S3_URL } from "../../../constant";
import swal from "sweetalert2";
import {
  fetchGiftsCardsData,
  deleteGiftCardAPI,
} from "../../../KIWE-V1/Services/giftCard";
const GiftCard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));

  const [giftsCards, setGiftsCards] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllGiftsCards = async () => {
    try {
      const giftsCards = await fetchGiftsCardsData();
      setGiftsCards(giftsCards?.data);
      setLoading(false);
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllGiftsCards();
  }, []);

  const columns = [
    {
      title: "Gift Title",
      dataIndex: "templateTitle",
      key: "templateTitle",
      sorter: (a, b) => a.templateTitle.localeCompare(b.templateTitle),
      ellipsis: true,
    },
    {
      title: "Gift Image",
      dataIndex: "templatePicturePath",
      key: "templatePicturePath",
      render: (text, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={TEMPLATE_S3_URL + record.templatePicturePath}
              style={{ width: "10%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          {roles[0] === "SUPER_ADMIN" ||
          "ADMIN" ||
          "FINANCES" ||
          "OPERATIONS" ? (
            <div>
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={() => {
                  showModal();
                  setRecord(record);
                  console.log(record);
                }}
              >
                Edit
              </Button>
              <Button
                type="danger"
                className="delete"
                onClick={() => handleDeleteClick(record)}
              >
                Delete
              </Button>
            </div>
          ) : (
            ""
          )}
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const handleDeleteClick = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Gift Card ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteGiftCardAsync() {
            try {
              const decryptedResponse = await deleteGiftCardAPI(record);
              if (decryptedResponse) {
                setGiftsCards((prevGiftsCards) =>
                  prevGiftsCards.filter(
                    (giftCard) => giftCard.templateId !== record.templateId
                  )
                );
                message.success("Gift Card deleted successfully!");
              }
            } catch (error) {
              message.error(error.message);
            }
          }
          deleteGiftCardAsync();
        }
      });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Gift Cards</h2>

          {roles[0] === "SUPER_ADMIN" ||
          "ADMIN" ||
          "FINANCES" ||
          "OPERATIONS" ? (
            <Space>
              {" "}
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  showModal();
                  setRecord({});
                }}
              >
                ADD
              </Button>
            </Space>
          ) : (
            ""
          )}

          <Add
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
          />
        </Space>
        <div className="container">
          {giftsCards.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={giftsCards}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
            />
          ) : (
            <div className="messageNoData">No Gift Card Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default GiftCard;

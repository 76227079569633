import React, { createContext, useState, useContext } from "react";

const LevelRewardContext = createContext();

export function LevelRewardProvider({ children }) {
  const [levelsReward, setLevelsRewards] = useState([]);

  return (
    <LevelRewardContext.Provider value={{ levelsReward, setLevelsRewards }}>
      {children}
    </LevelRewardContext.Provider>
  );
}

export function useLevelReward() {
  return useContext(LevelRewardContext);
}

import { Button, Form, message, Modal, Select, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../../redux/company/actions";
import { getServiceType } from "../../../redux/service-type/actions";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { addOrEditServiceCodesApi } from "../../../KIWE-V1/Services/serviceCodeApi";
const Add = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState({});

  console.log(record);
  const { Option } = Select;
  const dispatch = useDispatch();
  const companies = useSelector((state) => state?.companyReducer?.allCompany);
  const serviceType = useSelector(
    (state) => state?.serviceTypeReducer?.allServiceType
  );

  const handleAddOrEditServiceCodes = async (values) => {
    let companyID, serviceTypeID;
    if (record?.id) {
      companyID = companies.find((itm) => itm?.name === values?.companyId)?.id;
      serviceTypeID = serviceType.find(
        (itm) => itm?.serviceName === values?.serviceTypeId
      )?.id;
    }
    try {
      await addOrEditServiceCodesApi(values, record, companyID, serviceTypeID);
      message.success(
        `Service Code ${record ? "Added" : "Edited"} Successfully!`
      );
      handleCancel();
      window.location.reload();
    } catch (error) {
      message.error(error.message);
    }
  };
  var companyName;
  if (record?.companyId) {
    companyName = companies.filter((itm) => itm.id === record?.companyId);
  }
  console.log(companyName);
  var serviceName;
  if (record?.serviceTypeId) {
    serviceName = serviceType.filter((itm) => itm.id === record?.serviceTypeId);
  }

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getServiceType());
  }, []);
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      console.log(length);
      if (length !== 0) {
        setInitialValues({
          inquiryServiceCode: record?.inquiryServiceCode,
          billServiceCode: record?.billServiceCode,
          companyId: record?.name,
          serviceTypeId: record?.serviceName,
        });
      } else {
        form.setFieldsValue({ inquiryServiceCode: "" });
        form.setFieldsValue({ billServiceCode: "" });
        form.setFieldsValue({ companyId: "" });
        form.setFieldsValue({ serviceTypeId: "" });
        setInitialValues({});
      }
    }
  }, [record]);
  useEffect(() => {
    if (record?.id) {
      form.setFieldsValue({ inquiryServiceCode: record?.inquiryServiceCode });

      form.setFieldsValue({ billServiceCode: record?.billServiceCode });
      form.setFieldsValue({ companyId: companyName[0]?.name });
      form.setFieldsValue({ serviceTypeId: serviceName[0]?.serviceName });
    }
  }, [record, initialValue]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const handleChange = (e) => {
    console.log(e?.target?.value, e, "eee");
  };
  return (
    <>
      <Modal
        title={record?.id ? "Edit Service Code" : "Add Service Code"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddOrEditServiceCodes}
          autoComplete="off"
          initialValues={{
            inquiryServiceCode: record?.inquiryServiceCode,
            billServiceCode: record?.billServiceCode,
            companyId: record?.name,
            serviceTypeId: record?.serviceName,
          }}
        >
          <Form.Item
            // name={record ? 'companyName' : 'companyId'}
            name={"companyId"}
            label="Company Name"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Company Name!" },
            ]}
            className="offer-name"
          >
            <Select
              placeholder="Select Company Name"
              style={{ width: "100%" }}
              className="selectCompanyService select-p"
              onChange={handleChange}
            >
              {companies?.map((item) => (
                <Option key={item?.id}>{item?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            // name={record ? 'serviceName' : 'serviceTypeId'}
            name={"serviceTypeId"}
            label="Service-Type Name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Service-Type Name!",
              },
            ]}
            className="offer-name"
          >
            <Select
              placeholder="Select Service-Type Name"
              style={{ width: "100%" }}
              className="selectCompanyService select-p"
              onChange={handleChange}
            >
              {serviceType?.map((item) => (
                <Option
                  // selected={
                  //   record ? (item.id === form.companyId ? true : false) : ''
                  // }
                  key={item?.id}
                >
                  {item?.serviceName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="billServiceCode"
            label="Bill Service Code"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Bill Service Code!",
              },
            ]}
            className="offer-name"
          >
            <InputNumber
              min={0}
              defaultValue={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
              }}
            />
          </Form.Item>
          <Form.Item
            name="inquiryServiceCode"
            label="Inquiry Service Code"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Inquiry Service Code!",
              },
            ]}
            className="offer-name"
          >
            <InputNumber
              min={0}
              defaultValue={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
              }}
            />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

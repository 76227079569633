import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";

export const apiCall = async (
  url,
  requestData,
  method = "post",
  decrypt = true
) => {
  try {
    const apiResponse = await axiosInstance()[method](url, {
      data: convertPlantextToEncrypted(requestData),
    });
    if (decrypt) {
      return convertEncryptedToPlantext(apiResponse?.data?.data);
    }
    return apiResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchBusinssUsersAndDecryptResponse = async () => {
  try {
    const decryptedResponse = await apiCall("owners/fetch/business", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchBusinssOwnersAndDecryptResponse = async () => {
  try {
    const decryptedResponse = await apiCall(
      "owners?page=0&size=2147483647",
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const buildRequestBody = (page, id) => {
  return {
    accountType: "business",
    search: "",
    pageNo: page,
    orderBy: "id",
    sortOrder: "desc",
    expiryTime: getCurrentTimeStemp(),
    id: id,
  };
};
export const fetchWithdrawalPendingBusinessAndDecryptResponse = async (
  page,
  id
) => {
  try {
    const requestBody = buildRequestBody(page, id);

    const decryptedResponse = await apiCall(
      "owners/Withdrawal/pending",
      requestBody
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const payoutWithdrawalPendingForBusinessUsersAndDecryptResponse = async (
  amount,
  transactions,
  businessOwnerId,
  businessUserId,
  flagBusiness
) => {
  try {
    const requestBody = {
      amount: amount,
      transactions: transactions,
    };
    flagBusiness
      ? (requestBody.businessUserId = businessUserId)
      : (requestBody.businessOwnerId = businessOwnerId);

    const decryptedResponse = await apiCall("payouts", requestBody);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

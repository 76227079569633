import { Table, message } from "antd";

import Totop from "../../shared/TopUp";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { S3_URL } from "../../../constant";
import { fetchLogIndustryTypeData } from "../../../KIWE-V1/Services/industryTypeApi";
const Logs = ({ record }) => {
  const [logsIndustryType, setLogsIndustryType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const { id } = useParams();

  const fetchIndustryTypeLog = async (page) => {
    try {
      const apiResponse = await fetchLogIndustryTypeData(page, id);
      setTotalPages(apiResponse?.totalRecords);
      setLogsIndustryType(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchIndustryTypeLog(1);
  }, []);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "userId",
      key: "userId",
      sorter: (a, b) => a.userId - b.userId,
      ellipsis: true,
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      key: "profilePicture",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={
                S3_URL +
                record.userId +
                "/profile_picture/" +
                record.profilePicturePath
              }
              style={{ width: "20%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      render: (txt, record) => (
        <>
          <span>
            {" "}
            {record.firstName} {record.lastName}
          </span>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (txt, record) => (
        <>
          <div>
            {record.exit ? (
              <span
                style={{
                  margin: ".5rem",
                  color: "#ff4d4f",
                  fontWeight: "bold",
                }}
              >
                Exit
              </span>
            ) : (
              <span
                // type='primary'
                // className='btn edit'

                style={{
                  margin: ".5rem",
                  color: "#33B978",
                  fontWeight: "bold",
                }}
              >
                Entry
              </span>
            )}
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Logs</h2>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={logsIndustryType}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              onChange: (page) => {
                fetchIndustryTypeLog(page);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Logs;

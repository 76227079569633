import React from "react";
import { Column } from "@ant-design/plots";
import classes from "./BaseInsightsStyles.module.css";

const Bar = () => {
  const data = [
    {
      name: "Success",
      country: "Jan",
      type: "city1",
      value: 14500,
    },
    {
      name: "Fail",
      country: "Jan",
      type: "city2",
      value: 8500,
    },

    {
      name: "Fail",
      country: "Jan",
      type: "city3",
      value: 1000,
    },
    {
      country: "Feb",
      type: "city1",
      value: 9000,
    },
    {
      country: "Feb",
      type: "city2",
      value: 8500,
    },
    {
      country: "Feb",
      type: "city3",
      value: 8500,
    },
    {
      country: "Mar",
      type: "city1",
      value: 16000,
    },
    {
      country: "Mar",
      type: "city2",
      value: 5000,
    },
    {
      country: "Mar",
      type: "city3",
      value: 1000,
    },

    {
      country: "Apr",
      type: "city1",
      value: 14000,
    },
    {
      country: "Apr",
      type: "city2",
      value: 9000,
    },
    {
      country: "Apr",
      type: "city3",
      value: 9000,
    },
    {
      country: "May",
      type: "city1",
      value: 16000,
    },
    {
      country: "May",
      type: "city2",
      value: 6000,
    },
    {
      country: "May",
      type: "city3",
      value: 4000,
    },
    {
      country: "Jun",
      type: "city1",
      value: 12000,
    },
    {
      country: "Jun",
      type: "city2",
      value: 8000,
    },
    {
      country: "Jun",
      type: "city3",
      value: 5000,
    },
  ];

  const config = {
    data,
    xField: "country",
    yField: "value",
    yAxis: {
      label: {
        formatter: (v) => ``,
      },
    },

    seriesField: "type",
    isGroup: "true",
    legend: false,
    dodgePadding: 5,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    maxColumnWidth: 14,
    padding: 40,
    color: ({ type }) => {
      if (type === "city1") {
        return "rgb(245, 80, 83)";
      }
      if (type === "city2") {
        return "rgb(0, 50, 203)";
      }
      return "rgb(51, 185, 120)";
    },
  };

  return (
    <div className={classes.BarContainer}>
      <h5 className={classes.Header}>Method</h5>
      <div
        className={
          `${classes.WrapeRight} ${classes.BaseBetween}` + " secondPartBar"
        }
      >
        <span
          style={{ backgroundColor: "rgb(245, 80, 83)" }}
          className={classes.circle}
        ></span>

        <span>ValU</span>

        <span
          style={{ backgroundColor: "rgb(0, 50, 203)" }}
          className={classes.circle}
        ></span>

        <span>Visa</span>
        <span
          style={{ backgroundColor: "rgb(51, 185, 120)" }}
          className={classes.circle}
        ></span>

        <span>Master Card</span>
      </div>
      <Column {...config} />
    </div>
  );
};

export default Bar;

import { GET_ALL_COMPANY } from '../actionTypes';
const initialState = {
  allCompany: [],
};
const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY:
      return { ...state, allCompany: action.payload };

    default:
      return state;
  }
};
export default companyReducer;

import { Space, Table, Cascader, Tag, Select, message, Button } from "antd";
import { useState, useEffect } from "react";
import InputSearch from "../../front/form/InputSearch";
import Totop from "../../shared/TopUp";
import { useHistory } from "react-router-dom";
import { convertEncryptedToPlantext } from "../../library/Helper";
import {
  fetchDisputesApi,
  updateDisputeStatus,
} from "../../../KIWE-V2/Services/disputeApi";
import { EyeOutlined } from "@ant-design/icons";
import ReasonKeys from "./reasonKeys";
const Disputes = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const search_text = params.get("search_text");
  const history = useHistory();
  const [selectedRowKey, setSelectedRowKey] = useState({});

  const Option = Select;
  const [searchText, setSearchText] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [disputeStatus, setDisputeStatus] = useState(null);
  const [disputesData, setDisputesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const options = [
    {
      value: "disputeStatus",
      label: "Dispute Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },

        {
          value: "UNDER_REVIEW",
          label: "UNDER_REVIEW",
        },

        {
          value: "REFUND_IN_PROGRESS",
          label: "REFUND_IN_PROGRESS",
        },
        {
          value: "REFUNDED",
          label: "REFUNDED",
        },
        {
          value: "REJECTED",
          label: "REJECTED",
        },
      ],
    },
  ];
  const showModal = (record) => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const setRowKey = (record) => {
    setSelectedRowKey(record);
  };
  const onSearch = (value) => {
    if (!searchTextFilter) {
      const configWarning = {
        className: "messageWarning",
        content: "Please select from Search By first.",
      };
      message.warning(configWarning);
      return;
    }
    setSearchText(value);
    history.push(`/disputes/?pageNum=1`);
    fetchDisputesData(1, value, searchTextFilter, disputeStatus);
  };

  const fetchDisputesData = async (
    page,
    searchText,
    searchTextFilter,
    disputeStatus
  ) => {
    try {
      const apiResponse = await fetchDisputesApi(
        page,
        searchText,
        searchTextFilter,
        disputeStatus
      );

      setLoading(false);
      setDisputesData(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);
      const errorResponse = convertEncryptedToPlantext(error?.message);
      message.error(errorResponse);
    }
  };
  const onChangeFilterColumns = (value) => {
    if (!value || (value.length === 1 && !value[0])) {
      setDisputeStatus(null);
      fetchDisputesData(1, searchText, searchTextFilter, null);
      return;
    }
    if (value[0] === "disputeStatus") {
      setDisputeStatus(value[1]);
    }
    if (value[0] === "disputeStatus" && value[1] === "ALL") {
      setDisputeStatus(null);
    }
    history.push(`/disputes/?pageNum=1`);
    fetchDisputesData(1, searchText, searchTextFilter, disputeStatus);
  };
  useEffect(() => {
    if (pageNum) {
      fetchDisputesData(pageNum, searchText, searchTextFilter, disputeStatus);
    } else {
      fetchDisputesData(1, searchText, searchTextFilter, disputeStatus);
    }
  }, [pageNum, searchText, searchTextFilter, disputeStatus]);
  const handleDisputesStatus = async (status, record) => {
    try {
      setLoading(true);
      const apiResponse = await updateDisputeStatus(record?.id, status);

      const updatedDispteStatusData = convertEncryptedToPlantext(
        apiResponse.data.data
      ).data;
      setLoading(false);
      const configSuccess = {
        className: "messageSuccess",
        content: "Dispute status updated successfully!",
      };
      message.success(configSuccess);
      setDisputesData((prevDisputes) =>
        updateDisputeStatusWithResponse(
          prevDisputes,
          record,
          updatedDispteStatusData
        )
      );
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const updateDisputeStatusWithResponse = (
    prevDisputes,
    record,
    updatedDispteStatusData
  ) => {
    return prevDisputes.map((dispute) => {
      if (dispute.id === record.id) {
        if (dispute.status) {
          const updatedDispute = {
            ...dispute,
            ...updatedDispteStatusData,
          };
          return {
            ...dispute,
            status: updatedDispute.status,
          };
        }
      }
      return dispute;
    });
  };

  const disputesColumns = [
    {
      title: "Dispute ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: "Dispute Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <span>
          {record?.status?.status === "UNDER_REVIEW" ? (
            <Tag
              color="orange"
              className="custom-tag onboarding-status-pending"
              style={{ width: "7rem" }}
            >
              Under Review
            </Tag>
          ) : record?.status?.status === "REFUND_IN_PROGRESS" ? (
            <Tag
              color="blue"
              style={{
                width: "7.6rem",
                color: "#0E4BFB",
                border: "1px solid #0E4BFB",
              }}
              className="custom-tag"
            >
              Refund In Progress
            </Tag>
          ) : record?.status?.status === "REFUNDED" ? (
            <Tag
              color="green"
              style={{
                width: "7rem",
                color: "#20ce24",
                border: " 1px solid #20ce24",
                background: "#e8ffd7",
              }}
              className="custom-tag"
            >
              Refunded
            </Tag>
          ) : (
            <Tag
              color="pink"
              style={{
                width: "7rem",
                color: "#fd5394",
                border: "1px solid #fd5394",
                background: "#ffe4f8",
              }}
              className="custom-tag"
            >
              Rejected
            </Tag>
          )}
        </span>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text, record) => (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <EyeOutlined
              onClick={() => {
                showModal();
                setRowKey(record);
              }}
            />
          </div>
        </>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction.id",
      key: "transaction.id",
      sorter: (a, b) => a?.transaction?.id - b?.transaction?.id,

      render: (text, record) => (
        <>{record?.transaction?.id ? record?.transaction?.id : "-"}</>
      ),
    },
    {
      title: "Amount",
      dataIndex: "transaction?.amount",
      key: "transaction?.amount",
      sorter: (a, b) => a?.transaction?.amount - b?.transaction?.amount,

      render: (txt, record) => (
        <>
          <span>
            {record?.transaction?.amount ? record?.transaction?.amount : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Merchant",
      dataIndex: "transaction.merchantName",
      key: "transaction.merchantName",

      render: (txt, record) => (
        <>
          <span>
            {record?.transaction?.merchantName
              ? record?.transaction?.merchantName
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Transaction Reference",
      dataIndex: "transaction.tranRef",
      key: "transaction.tranRef",

      render: (txt, record) => (
        <div>
          {record?.transaction?.tranRef ? record?.transaction?.tranRef : "-"}
        </div>
      ),
    },
    {
      title: "User ID",
      dataIndex: "user.id",
      key: "user.id",
      sorter: (a, b) => a?.user?.id - b?.user?.id,

      render: (text, record) => <>{record?.user?.id}</>,
    },
    {
      title: "First Name",
      dataIndex: "user.firstName",
      key: "user.firstName",
      sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
      render: (txt, record) => (
        <>{record?.user?.firstName ? record?.user?.firstName : "-"}</>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "user.lastName",
      key: "user.lastName",
      sorter: (a, b) => a.user.lastName.localeCompare(b.user.lastName),
      render: (txt, record) => (
        <>{record?.user?.lastName ? record?.user?.lastName : "-"}</>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "user.mobile",
      key: "user.mobile",
      sorter: (a, b) => a?.user?.mobile - b?.user?.mobile,

      render: (txt, record) => (
        <>
          <span>{record?.user?.mobile ? record?.user?.mobile : "-"}</span>
        </>
      ),
    },
    {
      title: "Account Number",
      dataIndex: "user.accountNumber",
      key: "user.accountNumber",
      sorter: (a, b) => a?.user?.accountNumber - b?.user?.accountNumber,

      render: (txt, record) => (
        <>
          <span>
            {record?.user?.accountNumber ? record?.user?.accountNumber : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Edit Status",
      dataIndex: "status.status",
      key: "status.status",

      render: (text, record) => (
        <>
          {record?.status?.status === "UNDER_REVIEW" ? (
            <div className="btnCardsActivate">
              <Button
                type="primary"
                className="btn paid"
                size="middle"
                style={{ margin: ".5rem" }}
                onClick={() =>
                  handleDisputesStatus("REFUND_IN_PROGRESS", record)
                }
              >
                Refund in progress
              </Button>
              <Button
                type="danger"
                size="small"
                className="declined"
                data-testid="reject"
                style={{ height: "2rem" }}
                onClick={() => handleDisputesStatus("REJECTED", record)}
              >
                Reject
              </Button>
            </div>
          ) : record?.status?.status === "REFUND_IN_PROGRESS" ? (
            <>
              <div className="btnCardsActivate">
                <Button
                  type="primary"
                  className="btn paid"
                  size="middle"
                  style={{ margin: ".5rem" }}
                  onClick={() => handleDisputesStatus("REFUNDED", record)}
                >
                  Refund
                </Button>
              </div>
            </>
          ) : record?.status?.status === "REJECTED" ? (
            <>
              <div
                color="red"
                style={{ width: "6rem" }}
                className="custom-tag onboarding-status-rejected"
              >
                {record?.status?.status?.charAt(0).toUpperCase() +
                  record?.status?.status?.slice(1)}
              </div>
            </>
          ) : record?.status?.status === "REFUNDED" ? (
            <>
              <div
                color="red"
                style={{ width: "6rem" }}
                className="custom-tag onboarding-status-approved"
              >
                {record?.status?.status?.charAt(0).toUpperCase() +
                  record?.status?.status?.slice(1)}
              </div>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  const onChangeFilter = (value) => {
    setSearchTextFilter(value);
  };

  const onSearchFilter = () => {};

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader-1">
          <h2>Disputes</h2>
          <Space>
            <Select
              showSearch
              className="onboarding_filter_select"
              optionFilterProp="children"
              onChange={onChangeFilter}
              onSearch={onSearchFilter}
              dropdownStyle={{
                width: "auto",
                minWidth: "120px",
                overflowY: "visible",
              }}
              defaultValue={null}
            >
              <Select.Option value={null}>Search By</Select.Option>
              <Select.Option value="disputeId">Dispute ID</Select.Option>
              <Select.Option value="transactionId">
                Transaction ID
              </Select.Option>
              <Select.Option value="userId">User ID</Select.Option>
              <Select.Option value="mobile">Mobile</Select.Option>
            </Select>
            <InputSearch
              onSearch={onSearch}
              searchTextt={search_text ? search_text : searchText}
            />
            <Cascader
              data-testid="my-cascader"
              options={options}
              onChange={onChangeFilterColumns}
              className="custom-cascader"
              placeholder="Filter"
              dropdownMenuColumnStyle={{
                overflowY: "auto",
              }}
              dropdownClassName="custom-cascader-dropdown-menu"
            />
          </Space>
        </Space>

        <div className="container">
          {disputesData?.length > 0 ? (
            <Table
              className="table2"
              columns={disputesColumns}
              dataSource={disputesData}
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/disputes/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No Disputes Records Found.
            </div>
          )}
        </div>
        <ReasonKeys
          selectedRowKey={selectedRowKey}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
        />
        <Totop />
      </div>
    </>
  );
};

export default Disputes;

import { Table, message, Collapse } from "antd";
import { useState, useEffect } from "react";
import { fetchTransactionsApi } from "../../../KIWE-V2/Services/transactionApi.js";

const Transactions = ({ record }) => {
  const { Panel } = Collapse;
  const [totalPages, setTotalPages] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const fetchTransactions = async (page, record) => {
    setLoading(true);
    try {
      const apiResponse = await fetchTransactionsApi(page, record?.id);
      setLoading(false);
      setTransactions(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    if (page) {
      fetchTransactions(page, record);
    } else {
      fetchTransactions(1, record);
    }
  }, [page, record]);
  const transactionColumns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      sorter: (a, b) => a?.id - b?.id,
    },

    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (txt, record) => (
        <>
          <span>{record?.transactionType}</span>
        </>
      ),
    },
    {
      title: "Merchant Name",
      dataIndex: "merchantName",
      key: "merchantName",
      sorter: (a, b) => a?.merchantName?.localeCompare(b?.merchantName),

      render: (txt, record) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Amount",
      dataIndex: "billingAmount",
      key: "billingAmount",

      render: (txt, record) => <>E£ {txt?.toFixed(2)}</>,
    },
    {
      title: "Transaction Reference",
      dataIndex: "tranRef",
      key: "tranRef",

      render: (txt, record) => (
        <>
          <span>{record?.tranRef ? record?.tranRef : "-"}</span>
        </>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",

      render: (txt, record) => (
        <div>{new Date(record?.createdTimeStamp).toLocaleDateString()}</div>
      ),
    },
  ];

  return (
    <>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="Transactions" key="1">
          <div className="container">
            {transactions?.length > 0 ? (
              <Table
                data-testid="transactions-table"
                className="table2"
                columns={transactionColumns}
                dataSource={transactions}
                size="large"
                scroll={{ x: 900 }}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  total: totalPages,
                  current: +page ? +page : 1,
                  onChange: (page) => {
                    setPage(page);
                  },
                }}
              />
            ) : (
              <div className="messageNoData" style={{ width: "24%" }}>
                No Transactions Records Found.
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default Transactions;

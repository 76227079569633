import axiosInstance from "../../helpers/axiosInstance";

import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post") => {
  try {
    const response = await axiosInstance()[method](url, {
      data: convertPlantextToEncrypted(requestData),
    });

    return response;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );

    throw decryptedError;
  }
};
const buildCashBackRequestBody = (values, id, edit) => {
  values = {
    ...values,
    startAt: values?.startAt
      ? values["startAt"]?.format("YYYY-MM-DD")?.toString()
      : null,
    endAt: values?.endAt
      ? values["endAt"]?.format("YYYY-MM-DD")?.toString()
      : null,
  };
  return {
    activeCashBack: values.activeCashBack,
    upToLimit: values.upToLimit === undefined ? 0 : values.upToLimit,
    startAt: values.startAt,
    endAt: values.endAt,
    percentage: values.percentage === undefined ? 0 : values.percentage,
    ...(edit ? { id } : { userId: id }),
    expiryTime: getCurrentTimeStemp(),
  };
};
export const addAndEditCashBackAPI = async (values, id, edit = false) => {
  try {
    const data = buildCashBackRequestBody(values, id, edit);

    const response = await apiCall(
      "admin/v1/cashback",
      data,
      edit ? "put" : "post"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

import { Space, Avatar, Table, Button, message } from "antd";
import { Link } from "react-router-dom";
import Totop from "../../shared/TopUp";
import { useState, useEffect } from "react";

import { S3_URL } from "../../../constant";




import { fetchChildrenUserDataAndDecryptResponse } from "../../../KIWE-V1/Services/jointAccountsAPI";
const JointAccounts = () => {
  const [totalPages, setTotalPages] = useState(1);
  console.log(totalPages);
  const [userChildren, setUserChildren] = useState([]);



  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  var { userId, firstName, profilePicturePath } = JSON.parse(
    localStorage.getItem("JointAccount")
  );




  const fetchUserChildren = async () => {
    try {
      const api_res = await fetchChildrenUserDataAndDecryptResponse(userId);
      setTotalPages(api_res?.totalRecords);

      setUserChildren(api_res?.data);




      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: "There are no linked junior accounts to this account",
      };
      const configError1 = {
        className: "messageError",
        content: "something went wrong!",
      };
      if (error.response.status === 404) {
        message.error(configError);
      } else {
        message.error(configError1);
      }
    }
  };
  useEffect(() => {




    fetchUserChildren(1);



  }, []);
  const columns = [
    {
      title: "Profile picture ",
      dataIndex: "profilePicture",
      key: "profilePicture",
      render: (txt, record) => (
        <>
          <Avatar
            src={
              S3_URL +
              record?.userId +
              "/profile_picture/" +
              record?.profilePicturePath
            }
          />
        </>
      ),
      ellipsis: true,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      ellipsis: true,
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),
      ellipsis: true,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a?.mobile - b?.mobile,
      ellipsis: true,
    },
    {
      title: "Limit Per Day",
      dataIndex: "limitPerDay",
      key: "limitPerDay",
      ellipsis: true,
    },
    {
      title: "Available Limit",
      dataIndex: "availableLimit",
      key: "availableLimit",
      ellipsis: true,
    },

    {
      title: "Events",
      dataIndex: "events",
      key: "events",
      width: 150,
      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/event-user",
              state: record,
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{ height: "2rem" }}
            >
              <span className="icon-Events"></span>
              Events
            </Button>
          </Link>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",

      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/transaction-user",
              state: record,
              place: "individual",
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{ marginLeft: "-1rem", height: "2rem" }}
            >
              <span className="icon-TransactionFeesw"></span>
              Transactions
            </Button>
          </Link>
        </>
      ),
      ellipsis: true,
    },
  ];
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Joint Account</h2>
        <div className="container">
          {userId ? (
            <Space direction="horizontal" style={{ margin: "1rem" }}>
              <Avatar
                // className='transaction-image'
                size={"large"}
                // style={{ width: '80%', height: '5rem' }}
                src={S3_URL + userId + "/profile_picture/" + profilePicturePath}
              />
              <span>{firstName}</span>
            </Space>
          ) : (
            ""
          )}




          {userChildren.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={userChildren}


              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
            />
          ) : (
            <div className="messageNoData">No Juniors Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default JointAccounts;

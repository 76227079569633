import { Input, Button, Form, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import {
  addAndUpdateBusinessOwnerApi,
  fetchBusinessData,
} from "../../../KIWE-V1/Services/businessOwnerApi";

const Add = ({ isModalVisible, setIsModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState({});

  const handleAddAndUpdateBusinessOwner = async (values) => {
    if (record) {
      try {
        await addAndUpdateBusinessOwnerApi(values, record);
        message.success("Business Owner Updated Successfully!");
        handleCancel();
      } catch (error) {
        message.error(error.message);
      }
    } else {
      try {
        await addAndUpdateBusinessOwnerApi(values, record);
        message.success("Business Owner Added Successfully!");
        handleCancel();
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setTimeout(function () {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    // debugger;
    if (record) {
      form.setFieldsValue({ email: record?.email });
      form.setFieldsValue({ firstName: record?.firstName });
      form.setFieldsValue({ lastName: record?.lastName });
      form.setFieldsValue({
        businessUsers: record?.branches?.map((item) => item.businessId),
      });
    }
  }, [record, initialValue]);
  const [options, setOptions] = useState([]);
  const fetchBusinessUser = async () => {
    try {
      const response = await fetchBusinessData();
      setOptions(response);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchBusinessUser();
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };
  return (
    <>
      <Modal
        title={record?.id ? "Edit Business Owner" : "Add Business Owner"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndUpdateBusinessOwner}
          autoComplete="off"
          initialValues={
            // record
            //   ? {
            //       email: record?.email,
            //       firstName: record?.firstName,
            //       lastName: record?.lastName,
            //       businessUsers: record?.branches.map(
            //         (item) => item.businessId
            //       ),
            //     }
            //   : null
            {
              email: record?.email,
              firstName: record?.firstName,
              lastName: record?.lastName,
              businessUsers: record?.branches?.map((item) => item.businessId),
            }
          }
        >
          <Form.Item
            name="firstName"
            label="First Name :"
            // className='offer-name'
            hasFeedback
            rules={[
              { required: true, message: "Please input your First Name!" },
            ]}
          >
            <Input style={{ borderRadius: "25px" }} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name :"
            // className='offer-name'
            hasFeedback
            rules={[
              { required: true, message: "Please input your Last Name!" },
            ]}
          >
            <Input style={{ borderRadius: "25px" }} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your E-mail!" },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input style={{ borderRadius: "25px" }} />
          </Form.Item>
          {record ? (
            ""
          ) : (
            <>
              <Form.Item
                name="password"
                label="Password :"
                hasFeedback
                rules={
                  record
                    ? []
                    : [
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]
                }
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password :"
                dependencies={["password"]}
                hasFeedback
                rules={
                  record
                    ? []
                    : [
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]
                }
              >
                <Input.Password />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="businessUsers"
            label="Branch"
            hasFeedback
            rules={
              record
                ? []
                : [{ required: true, message: "Please input your Branch!" }]
            }
          >
            <Select
              mode="multiple"
              placeholder="Select Branch"
              value={selectedItems}
              onChange={handleChange}
              allowClear
              // style={{ width: '100%', marginTop: '.4rem' }}
              className="select-p"
              // filterOption={(input, option) => {
              //   return (
              //     option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              //     option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              //   );
              // }}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {options.map((item) => (
                <Select.Option
                  value={item.id}
                  // title={item.businessName}
                  // key={item.businessName}
                >
                  {item.businessName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {record ? "Edit Business Owner" : "Add Business Owner"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const buildRequestBody = (parentId) => {
  return {
    parentId: parentId,
    expiryTime: getCurrentTimeStemp(),
  };
};
const apiCall = async (url, requestData) => {
  try {
    const response = await axiosInstance().post(url, {
      data: convertPlantextToEncrypted(requestData),
    });

    if (response.status === 200) {
      return convertEncryptedToPlantext(response?.data?.data);
    }
  } catch (error) {
    throw error;
  }
};




export const fetchChildrenUserDataAndDecryptResponse = async (userId) => {




  try {
    const requestBody = buildRequestBody(userId);

    const decryptedResponse = await apiCall("child/childlist", requestBody);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

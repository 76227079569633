import { Table, Collapse, message } from "antd";

import { useState, useEffect } from "react";

import { fetchCardReasonsApi } from "../../../KIWE-V2/Services/cardApi.js";
const Reasons = ({ record }) => {
  const { Panel } = Collapse;
  const [deactivationReasons, setDeactivationReasons] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const fetchCardReasonss = async (page, record) => {
    setLoading(true);
    try {
      const apiResponse = await fetchCardReasonsApi(page, record?.cards[0]?.id);
      setLoading(false);
      setDeactivationReasons(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (page) {
      fetchCardReasonss(page, record);
    } else {
      fetchCardReasonss(1, record);
    }
  }, [page, record]);
  const blockReasonsTableColumns = [
    {
      title: "Reason",
      dataIndex: "blockReason",
      key: "blockReason",

      render: (txt, record) => (
        <div>
          {record?.blockReason["reason"] ? record?.blockReason["reason"] : "-"}
        </div>
      ),
    },
    {
      title: "UserId",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,
      render: (txt, record) => <>{txt}</>,
    },

    {
      title: "Type",
      dataIndex: "blockedBy",
      key: "blockedBy",

      render: (txt, record) => <div>{txt ? txt : "-"}</div>,
    },
    {
      title: "Date",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (txt, record) => (
        <>
          <span>
            {record?.createdTimeStamp === null
              ? "-"
              : new Date(record?.createdTimeStamp).toLocaleDateString()}
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header="Deactivation Reasons" key="1">
          <div className="container">
            {deactivationReasons?.length > 0 ? (
              <Table
                className="table2"
                columns={blockReasonsTableColumns}
                dataSource={deactivationReasons}
                size="large"
                scroll={{ x: 900 }}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  total: totalPages,
                  current: +page ? +page : 1,
                  onChange: (page) => {
                    setPage(page);
                  },
                }}
              />
            ) : (
              <div className="messageNoData" style={{ width: "24%" }}>
                No Reasons Records Found.
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default Reasons;

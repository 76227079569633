import { useHistory } from "react-router-dom";
import logoLogin from "../../../assets/images/LogoLogin.svg";
import { Button } from "antd";

const Home = () => {
  const history = useHistory();

  return (
    <>
      <div className="login-container">
        <div
          className="login-container__form"
          style={{ display: "flex", margin: "0 auto", width: "unset" }}
        >
          {" "}
          <Button
            style={{ width: "15rem", marginLeft: ".5rem" }}
            onClick={() => {
              history.push({
                pathname: "/login",
                state: { type: "oldCMS" },
              });
            }}
          >
            Login V1
          </Button>
          <Button
            style={{ width: "15rem", marginLeft: ".5rem" }}
            onClick={() => {
              history.push({
                pathname: "/login",
                state: { type: "newCMS" },
              });
            }}
          >
            Login V2
          </Button>
        </div>
      </div>
    </>
  );
};

export default Home;

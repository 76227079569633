import { Space, Button, Table, message, Modal, Card, Avatar } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Add from "./add";
import { fetchArticlesApi } from "../../../KIWE-V2/Services/articleApi";
const Article = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleArticle, setIsModalVisibleArticle] = useState(false);

  const [isModalVisibleContent, setIsModalVisibleContent] = useState(false);

  const [articles, setArticles] = useState([]);

  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllArticles = async (page) => {
    try {
      const apiResponse = await fetchArticlesApi(page);

      setLoading(false);
      setArticles(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);

      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    if (pageNum) {
      fetchAllArticles(pageNum);
    } else {
      fetchAllArticles(1);
    }
  }, [pageNum]);

  const ArticlesColumns = [
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      render: (txt, record) => (
        <>
          <Avatar
            src={record?.banner}
            size="large"
            onClick={() => {
              showModal();
              setRecord(record);
            }}
          />
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "View",
      dataIndex: "content",
      key: "content",
      render: (txt, record) => (
        <div>
          <EyeOutlined
            onClick={() => {
              showModalContent();
              setRecord(record);
            }}
          />
        </div>
      ),
    },
    {
      title: "Article Status",
      dataIndex: "visible",
      key: "visible",

      render: (txt, record) => (
        <>
          <div>
            {record?.visible === true ? (
              <div className="custom-tag onboarding-status-approved">
                Active
              </div>
            ) : record?.visible === false ? (
              <div className="custom-tag onboarding-status-rejected">
                InActive
              </div>
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModalArticle(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalContent = () => {
    setIsModalVisibleContent(true);
  };

  const showModalArticle = (record) => {
    setIsModalVisibleArticle(true);
    setRecord(record);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancelContent = () => {
    setIsModalVisibleContent(false);
  };
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Articles</h2>{" "}
          <Space style={{ justifyContent: "flex-end" }}>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModalArticle(null);
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {articles.length > 0 ? (
            <Table
              className="table2"
              columns={ArticlesColumns}
              dataSource={articles}
              rowKey="id"
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/articles/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No Articles Records Found.
            </div>
          )}
        </div>

        <Totop />
        <Modal
          title={
            <div className="custom-modal-kyc-title">
              <h3 style={{ fontWeight: "bold" }}>Bannar</h3>
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={600}
          className="modal"
        >
          <div className="creditContainer">
            <Card className="creditCard">
              <div className="creditCardImage">
                <img src={record?.banner} alt="banner" />
              </div>
            </Card>
          </div>
        </Modal>

        <Modal
          title={
            <div className="custom-modal-kyc-title">
              <h3 style={{ fontWeight: "bold" }}>Article</h3>
            </div>
          }
          visible={isModalVisibleContent}
          onCancel={handleCancelContent}
          footer={null}
          width={500}
          className="modal"
        >
          <div className="creditContainer">
            <Card className="creditCard articleContent">
              <div className="creditCardImage" style={{ fontSize: "14px" }}>
                <div className="kiweImage">
                  <img src={record?.banner} alt="Image" />
                </div>

                <h2 style={{ fontWeight: "bold", marginTop: "1rem" }}>
                  {record?.title}
                </h2>
                {record?.content}
              </div>
            </Card>
          </div>
        </Modal>
        <Add
          isModalVisibleArticle={isModalVisibleArticle}
          setIsModalVisibleArticle={setIsModalVisibleArticle}
          articles={articles}
          setArticles={setArticles}
          record={record}
        />
      </div>
    </>
  );
};

export default Article;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const generateUpdateCardApi = (cardId) => {
  const BASE_URL = "v2/admin/card";
  return `${BASE_URL}/${cardId}`;
};

const updateCardApiCall = async (url, data) => {
  try {
    const decryptedResponse = await axiosInstance().patch(url, {
      data: convertPlantextToEncrypted(data),
    });

    return decryptedResponse;
  } catch (error) {
    const decryptError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptError;
  }
};
export const updateCard = async (
  cardId,
  statusType,
  value,
  blockReasonId = null
) => {
  const url = generateUpdateCardApi(cardId);
  const data = { [statusType]: value };
  if (statusType === "bankKycStatus") {
    isValidBankKycStatus(value);
  }
  if (statusType === "cardStatus") {
    isValidCardStatus(value);
    data.blockReasonId !== null
      ? (data.blockReasonId = blockReasonId)
      : (data.blockReasonId = null);
  }
  const response = await updateCardStatus(url, data);
  return response;
};

const updateCardStatus = async (url, data) => {
  try {
    const response = await updateCardApiCall(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const isValidBankKycStatus = (value) => {
  if (value === "FULLY" || value === "RETURN") {
    return true;
  } else {
    throw new Error("Invalid card status value provided.");
  }
};
const isValidCardStatus = (value) => {
  if (value === "ACTIVE" || value === "FROZEN" || value === "DEACTIVATED") {
    return true;
  } else {
    throw new Error("Invalid card status value provided.");
  }
};
export const getBlockReasonsAPI = async () => {
  try {
    const apiResponse = await axiosInstance().get(
      "v2/admin/block-reason/lookup?agent=CMS"
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchCardReasonsApi = async (page, cardId, limit = 10) => {
  try {
    const apiResponse = await axiosInstance().get(
      `v2/admin/block-reason/card/${cardId}?page=${page}&limit=${limit}`
    );
    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

const linkCustomerToCardRequestBody = (values, userId) => {
  return {
    cardId: values?.cardId,
    userId: userId,
  };
};
export const linkCustomerToCardApi = async (values, userId) => {
  try {
    const requestBody = linkCustomerToCardRequestBody(values, userId);
    const apiResponse = await axiosInstance().post(
      "v2/admin/card/link",
      requestBody
    );

    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );

    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

export const addCustomerIdApi = async (userId) => {
  try {
    const apiResponse = await axiosInstance().post(
      `/v2/admin/card-user/${userId}`,
      {}
    );

    const decryptedResponse = convertEncryptedToPlantext(
      apiResponse?.data?.data
    );

    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

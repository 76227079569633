import { Link } from "react-router-dom";
import { Col, Card } from "antd";
import icreaseIcon from "../../../assets/images/increaseSalary.svg";
import decreaseIcon from "../../../assets/images/decreaseSalary.svg";
const Widget = ({ url, title, content }) => {
  const numericTitle = parseFloat(title);
  const icon =
    numericTitle >= 100 ? (
      <img
        src={icreaseIcon}
        alt="_"
        style={{ height: "22px", width: "22px" }}
      />
    ) : (
      <img
        src={decreaseIcon}
        alt="_"
        style={{ height: "22px", width: "22px" }}
      />
    );
  return (
    <>
      <Col span={24} xs={12} sm={16} md={8} lg={6} className="paddingCard">
        <Link to={url}>
          <Card title={title} bordered={true}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{content}</span>
              {icon}
            </div>
          </Card>
        </Link>
      </Col>
    </>
  );
};

export default Widget;

import { Button, Form, message, Select, Collapse } from "antd";
import { useEffect, useState } from "react";
import { editLevelReward } from "../../../KIWE-V2/Services/userAPI.js";
import { fetchLevelsRewardDataAndDecryptResponse } from "../../../KIWE-V2/Services/rewardLevel";

const Edit = ({ cardUserDetails, setCardUserDetails }) => {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(
    cardUserDetails
      ? {
          rewardLevel: cardUserDetails?.rewardLevel?.id,
        }
      : null
  );

  const handleEditLevelReward = async (values) => {
    try {
      const apiResponse = await editLevelReward(cardUserDetails, values);
      if (apiResponse.status === 200) {
        const messageSuccess = {
          className: "messageSuccess",
          content: apiResponse?.message,
        };
        message.success(messageSuccess);

        setCardUserDetails((prevCardUser) => {
          return {
            ...prevCardUser,
            ...apiResponse?.data,
          };
        });
      }
    } catch (error) {
      const messageError = {
        className: "messageError",
        content: error.message ? error.message : error.errors[0],
      };

      if (Array.isArray(error.errors)) {
        message.error(messageError);
      } else {
        message.error(messageError);
      }
    }
  };

  useEffect(() => {
    if (cardUserDetails) {
      let length = Object.keys(cardUserDetails).length;

      if (length !== 0) {
        setInitialValues({
          rewardLevel: cardUserDetails?.rewardLevel?.id,
        });
      } else {
        form.setFieldsValue({ rewardLevel: "" });

        setInitialValues({});
      }
    }
  }, [cardUserDetails, form]);

  useEffect(() => {
    if (cardUserDetails) {
      form.setFieldsValue({
        rewardLevel: cardUserDetails?.rewardLevel?.id,
      });
    }
  }, [cardUserDetails, initialValue, form]);
  const [options, setOptions] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const fetchLevelsRewards = async () => {
    try {
      const apiResponse = await fetchLevelsRewardDataAndDecryptResponse();

      setOptions(apiResponse?.data);
    } catch (error) {
      message.error(error?.error);
    }
  };

  useEffect(() => {
    fetchLevelsRewards();
  }, []);
  return (
    <>
      <Collapse
        accordion
        style={{
          marginTop: "1rem",
          border: "unset",
          borderRadius: "25px",
          background: "#f5f5f5",
        }}
      >
        <Panel header=" Update Level Reward" key="1">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleEditLevelReward}
            autoComplete="off"
            initialValues={initialValue}
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              margin: " 0 auto",
            }}
          >
            <Form.Item
              name="rewardLevel"
              label="Level Reward"
              hasFeedback
              rules={
                cardUserDetails
                  ? []
                  : [
                      {
                        required: true,
                        message: "Please input your Level Reward!",
                      },
                    ]
              }
            >
              <Select
                aria-label="Level Reward"
                placeholder="Select Branch"
                value={selectedItems}
                onChange={handleChange}
                allowClear
                className="selectLevel editLevel"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                showSearch
                data-testid="select-reward-level"
              >
                {options.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="add-btn">
                Update Level Reward
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </>
  );
};

export default Edit;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchRecentTransactionsData = async () => {
  try {
    const decryptedResponse = await apiCall("admin/v1/recentTransactions", {});
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchRecentEventsData = async () => {
  try {
    const decryptedResponse = await apiCall("admin/recentEvents", {});
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const fetchCounterUsersData = async () => {
  try {
    const decryptedResponse = await apiCall("admin/v1/counter", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchCounterTransactionsData = async () => {
  try {
    const decryptedResponse = await apiCall(
      "admin/transactionCounter",
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const fetchPayTabsCommissionData = async () => {
  try {
    const decryptedResponse = await apiCall(
      "admin/v1/payTabsCommission",
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchKiweCommissionData = async () => {
  try {
    const decryptedResponse = await apiCall("admin/kiweCommission", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchTotalMoneyAddedData = async () => {
  try {
    const decryptedResponse = await apiCall(
      "admin/totalAmountAddedInWallet",
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchWithdrawalCountersData = async () => {
  try {
    const decryptedResponse = await apiCall(
      "admin/withdrawalCounters",
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

export const fetcEventCountersData = async () => {
  try {
    const decryptedResponse = await apiCall("admin/eventCounter", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

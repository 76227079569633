import { Space, Button, DatePicker, Table, message } from "antd";
import InputSearch from "../form/InputSearch";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import moment from "moment";
import visaa from "../../../assets/images/visa.svg";
import valu from "../../../assets/images/valu.svg";
import masterCard from "../../../assets/images/masterCard.svg";
import arrowRight from "../../../assets/images/icons8-right-arrow-100 1.svg";
import exporticon from "../../../assets/images/export.svg";
import exportBlue from "../../../assets/images/exportBlue.svg";
import {
  fetchAllTransactionAndDecryptResponse,
  exportTransactionsByDateOrSearchTextFromInputAndDecryptResponse,
  exportTransactionsByTransactionsIdsAndDecryptResponse,
} from "../../../KIWE-V1/Services/transactionsAPI.js";
const Transaction = () => {
  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [buttonCondition, setButtonCondition] = useState("");
  const [page, setPage] = useState(1);

  const onSearch = (value) => {
    setSearchTextFromInput(value);
    fetchAllTransactions(1, value);
  };
  const { RangePicker } = DatePicker;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys, selectedrows) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const [transactions, setTransactions] = useState([]);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [loading, setLoading] = useState(true);
  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const fetchAllTransactions = async (page, searchTextFromInput) => {
    try {
      const apiResponse = await fetchAllTransactionAndDecryptResponse(
        searchTextFromInput,
        page
      );
      setTotalPages(apiResponse.totalRecords);
      setTransactions(
        apiResponse?.data?.map((item, index) => ({
          ...item,
          key: item?.transactionId,
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllTransactions(1, searchTextFromInput);
  }, [searchTextFromInput]);
  function onChange2(dates, dateStrings) {
    if (dates) {
      setDate({ startDate: dateStrings[0], endDate: dateStrings[1] });
    }
  }
  const exportTransactionByDateOrSearchTextFromInput = async () => {
    try {
      const apiResponse =
        await exportTransactionsByDateOrSearchTextFromInputAndDecryptResponse(
          searchTextFromInput,
          date?.startDate,
          date?.endDate
        );
      if (apiResponse.data === null) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Export Transactions Succssfully but there are no data!",
        };
        message.success(configSuccess);
      } else {
        window.open(apiResponse?.data, "EXPORT TRANSACTIONS");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const exportTransactionByTransactionsIds = async () => {
    try {
      const apiResponse =
        await exportTransactionsByTransactionsIdsAndDecryptResponse(
          selectedRowKeys
        );
      if (apiResponse.data === null) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Export Transactions Succssfully but there are no data!",
        };
        message.success(configSuccess);
      } else {
        window.open(apiResponse?.data, "EXPORT TRANSACTIONS");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleExport = () => {
    if (date.startDate && date?.endDate) {
      exportTransactionByDateOrSearchTextFromInput();
    } else if (selectedRowKeys.length > 0) {
      exportTransactionByTransactionsIds();
    } else if (searchTextFromInput || (date?.startDate && date?.endDate)) {
      exportTransactionByDateOrSearchTextFromInput();
    } else if (searchTextFromInput) {
      exportTransactionByDateOrSearchTextFromInput();
    }
  };
  const transactionColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Transaction Type ",
      dataIndex: "transactionType",
      render: (text, value) =>
        value.transactionType === "out"
          ? "Out"
          : value.transactionType === "withdrawMoney"
          ? "Withdrawal"
          : value.transactionType === "addMoney"
          ? "Add Money"
          : "-",
    },
    {
      title: "From",
      dataIndex: "senderName",
      sorter: (a, b) => a.senderName?.length - b.senderName?.length,
    },

    {
      title: "To",
      dataIndex: "receiverName",
      sorter: (a, b) => a.receiverName?.length - b.receiverName?.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Fees",
      dataIndex: "txnFees",
      sorter: (a, b) => a.txnFees - b.txnFees,
      render: (text) => <>{text}%</>,
    },
    {
      title: "Charge",
      dataIndex: "txnCharges",
      sorter: (a, b) => a.txnCharges - b.txnCharges,
    },

    {
      title: "Tip",
      dataIndex: "tipAmount",
      sorter: (a, b) => a.tipAmount - b.tipAmount,
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text) => <>{moment(text).format("D MMM, yyyy h:mm a")}</>,
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
    },
    {
      title: "Status",
      dataIndex: "transaction_status",
      key: "transaction_status",

      render: (text, record) => (
        <>
          {record.transaction_status === "paid" ||
          record.transaction_status === "success" ? (
            <>
              <div
                color="green"
                className="custom-tag onboarding-status-approved"
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </div>
            </>
          ) : record.transaction_status === "declined" ||
            record.transaction_status === "fail" ? (
            <>
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </div>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",

      render: (text, record) => (
        <>
          {record?.paymentMethod === "ValU" ? (
            <img src={valu} style={{ height: "70px", width: "70px" }} alt="-" />
          ) : record?.paymentMethod === "Visa" ? (
            <img
              src={visaa}
              style={{ height: "60px", width: "60px" }}
              alt="-"
            />
          ) : record?.paymentMethod === "MasterCard" ? (
            <img
              src={masterCard}
              style={{ height: "60px", width: "60px" }}
              alt="-"
            />
          ) : (
            record?.paymentMethod
          )}
        </>
      ),
    },
  ];
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Transaction List</h2>
          <Space className="filterButtonTrans">
            {" "}
            <Button
              type="primary"
              size="middle"
              onClick={() => setButtonCondition("addMoney")}
            >
              Add Money
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={() => setButtonCondition("out")}
            >
              Out
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={() => setButtonCondition("withdrawal")}
            >
              Withdrawal
            </Button>
          </Space>
          <Space>
            <InputSearch onSearch={onSearch} />
            <RangePicker
              separator={
                <img
                  src={arrowRight}
                  alt="-"
                  style={{ width: "15px", height: "15px" }}
                />
              }
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              onChange={onChange2}
            />
            <Button
              type="primary"
              size="middle"
              onClick={handleExport}
              style={{ cursor: "pointer" }}
              className="btnExport"
            >
              <img src={exporticon} alt="" className="imageExport" />
              <img src={exportBlue} alt="" className="exportBlue" />
            </Button>
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={transactionColumns}
            dataSource={transactions}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
            rowSelection={rowSelection}
            pagination={{
              current: page,
              pageSize: 10,
              total: totalPages,
              onChange: (page) => {
                setPage(page);
                fetchAllTransactions(page, searchTextFromInput);
              },
            }}
          />
          <Totop />
        </div>
      </div>
    </>
  );
};

export default Transaction;

import { Card } from 'antd';

const Widget = ({ title, count, src }) => {
  return (
    <>
      <Card>
        <div className='card__header'>
          <span className='card__header__title head'>{title}</span>
          <span className='card__header__count number'>{count}</span>
        </div>
        <div className='card__body'>
          <img src={src} alt='' />
        </div>
      </Card>
    </>
  );
};

export default Widget;

import React, { useState } from "react";
import { Layout } from "antd";

import NavBar from "../../components/shared/NavBar";
import SideBar from "../../components/shared/SideBar";
import TopicMenu from "../../container/TopicMenu";
import Dashboard from "../../components/front/dashboard/Dashboard";
import Admin from "../../components/front/admin/Index";
import Insights from "../../components/front/insights";
import IndividualUser from "../../components/front/individual_user";
import BusinessUser from "../../components/front/business_user";
import Transaction from "../../components/front/transaction";
import WithdrawalType from "../../components/front/withdrawal_type";
import TransactionFees from "../../components/front/transaction_fees";
import WithdrawalList from "../../components/front/withdrawal_list";
import OfferList from "../../components/front/offer_list";
import Event from "../../components/front/events";
import Content from "../../components/front/content";
import Notification from "../../components/front/notification";
import Industry from "../../components/front/industry";
import SellingType from "../../components/front/selling-type";
import IndustryType from "../../components/front/industry-types";
import BusinessOffer from "../../components/front/business_offer";
import Promotions from "../../components/front/promotions";
import GiftCard from "../../components/front/gift-card";
import CompanyList from "../../components/front/company";
import ServiceType from "../../components/front/service_type";
import ServiceCode from "../../components/front/service_code";
import BusinessOwner from "../../components/front/business-owner/index";
import { useHistory } from "react-router-dom";
// import Edit from '../../components/front/transaction_fees/Edit';
import NotificationBusiness from "../../components/front/business-notification";
import FinanceBusinessOwner from "../../components/front/FinanceBusiness_owner";
import PromotionValue from "../../components/front/promotionsValue";
import OTP from "../../components/front/otp";
import Article from "../../components/kiwe-v2/article/index";
import Topics from "../../components/kiwe-v2/FAQSTopic/index";
import PayHub from "../../components/kiwe-v2/payHub";

const MainPage = ({ children }) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  let userIdRole = localStorage.getItem("typeRoleAdmin");
  let roleAdminV2 = userIdRole === "newCMS";
  const sections = {
    Individuals: "Individuals",
    Rewards: "Rewards",
    PayHub: "Pay Hub",
    Business: "Business",
    Disputes: "Disputes",
    Support: "Support",
    Transactions: "Transactions",
    Marketing: "Marketing",
    Bills: "Bills",
  };
  let superAdmin = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "MARKETING"
  );
  let admin = roles.find((itm) => itm === "SUPER_ADMIN" || itm === "ADMIN");
  let financW = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "ADMIN" || itm === "FINANCES"
  );
  let indivdual = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "CUSTOMER_SUPPORT"
  );
  let transaction = roles.find(
    (itm) =>
      itm === "SUPER_ADMIN" || itm === "CUSTOMER_SUPPORT" || itm === "FINANCES"
  );
  let busin = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "OPERATIONS" || itm === "BUSINESS"
  );
  let financ = roles.find((itm) => itm === "SUPER_ADMIN" || itm === "FINANCES");
  let financc = roles.find((itm) => itm === "SUPER_ADMIN");

  let notify = roles.find(
    (itm) =>
      itm === "SUPER_ADMIN" ||
      itm === "BUSINESS" ||
      itm === "MARKETING" ||
      itm === "ADMIN"
  );
  let indust = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "OPERATIONS"
  );
  let gift = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "MARKETING" || itm === "ADMIN"
  );
  let businessOwner = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "ADMIN" || itm === "OPERATIONS"
  );

  const topics = [
    "<Dashboard />",
    admin ? <Insights /> : null,
    // admin ? "<Admin />" : null,

    roleAdminV2
      ? null
      : roles.find((itm) => itm === "SUPER_ADMIN")
      ? "<Event />"
      : roles.find((itm) => itm === "FINANCES")
      ? null
      : "<Event />",

    roleAdminV2 ? null : financc ? "<WithdrawalType />" : null,
    financc || indivdual ? sections.Individuals : null,
    roleAdminV2 ? null : indivdual ? "<IndividualUser />" : null,
    roleAdminV2 ? "<CardUser/>" : null,

    roleAdminV2 ? sections.Rewards : null,
    roleAdminV2 ? "<LevelReward/>" : null,
    roleAdminV2 ? "<CreateMerchant/>" : null,
    roleAdminV2 ? null : admin ? "<Promotions />" : null,
    roleAdminV2 ? null : admin ? "<PromotionValue />" : null,
    roleAdminV2 ? sections.PayHub : null,
    roleAdminV2 ? "<PayHub/>" : null,
    roleAdminV2
      ? null
      : busin || businessOwner || financW || indust || admin
      ? sections.Business
      : null,
    roleAdminV2 ? null : busin ? "<BusinessUser />" : null,
    roleAdminV2 ? null : businessOwner ? "<BusinessOwner />" : null,
    roleAdminV2 ? null : financW ? "<FinanceBusinessOwner />" : null,
    // indust ? '<Industry />' : null,
    roleAdminV2 ? null : admin ? "<IndustryType />" : null,
    // indust ? '<SellingType />' : null,
    roleAdminV2 ? sections.Disputes : null,
    roleAdminV2 ? "<Disputes/>" : null,
    roleAdminV2 ? sections.Support : null,
    roleAdminV2 ? <Article /> : null,
    roleAdminV2 ? <Topics /> : null,
    roleAdminV2
      ? null
      : transaction || financ || financW
      ? sections.Transactions
      : null,
    roleAdminV2 ? null : transaction ? "<Transaction />" : null,
    roleAdminV2 ? null : financ ? "<TransactionFees />" : null,
    roleAdminV2 ? null : financW ? "<WithdrawalList />" : null,

    notify || admin || gift ? sections.Marketing : null,
    roleAdminV2 ? null : "<Fairs/>",
    roleAdminV2 ? "<Annoucements />" : null,

    roleAdminV2 ? null : notify ? "<Notification />" : null,
    roleAdminV2 ? null : notify ? "<OldAnnoucements />" : null,
    roleAdminV2 ? null : notify ? "<BusinessOffer />" : null,
    roleAdminV2 ? null : notify ? "<OfferList />" : null,
    roleAdminV2 ? null : admin ? "<NotificationBusiness />" : null,
    roleAdminV2 ? null : gift ? "<GiftCard />" : null,
    roleAdminV2 ? null : superAdmin ? "<Quiz/>" : null,
    roleAdminV2 ? null : superAdmin ? "<Blog/>" : null,
    roleAdminV2 ? null : superAdmin ? "<MoneyLearn/>" : null,
    roleAdminV2 ? null : gift ? "<Savings/>" : null,
    roleAdminV2 ? null : admin ? sections.Bills : null,
    roleAdminV2 ? null : businessOwner ? "<OTP/>" : null,
    roleAdminV2 ? null : admin ? "<CompanyList />" : null,
    roleAdminV2 ? null : admin ? "<ServiceType />" : null,
    roleAdminV2 ? null : admin ? "<ServiceCode />" : null,
  ];

  const Menu = <TopicMenu topics={topics} sections={sections} />;

  return (
    <>
      {localStorage.getItem("accessToken") ? (
        <div className="App">
          <NavBar
            menu={Menu}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Layout>
            <SideBar
              menu={Menu}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              style={{ width: "unset !important" }}
            />
            <Layout.Content className="content">{children}</Layout.Content>
          </Layout>
        </div>
      ) : (
        history.push("/")
      )}
    </>
  );
};

export default MainPage;

import { Input, Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { OFFER_LIST_ALBUM } from "../../../constant";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import DragImage from "../form/DragImage";
import { addAndEditOfferAPI } from "../../../KIWE-V1/Services/offerApi.js";
const AddOffer = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(
    record?.id ? { image: record?.image, name: record?.name } : null
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      console.log(length);
      if (length !== 0) {
        setInitialValues({
          image: record?.image,
          name: record?.name,
        });
      } else {
        form.setFieldsValue({ image: "" });
        form.setFieldsValue({ name: "" });

        setInitialValues({});
      }
    }
  }, [record, form]);
  const handleAddandEditOffer = async (values) => {
    try {
      setLoading(true);
      let randomName = "";
      if (record) {
        if (!(typeof values.image === "string")) {
          randomName = generateRandomFileName(values?.image?.file);
          await handleImageUpload(
            values?.image?.file?.originFileObj,
            randomName,
            OFFER_LIST_ALBUM
          );
        } else {
          randomName = values.image;
        }
      } else {
        randomName = generateRandomFileName(values?.image?.file);
        await handleImageUpload(
          values?.image?.file?.originFileObj,
          randomName,
          OFFER_LIST_ALBUM
        );
      }
      const decryptedResponse = await addAndEditOfferAPI(
        values,
        randomName,
        record
      );
      if (decryptedResponse) {
        setLoading(false);
        message.success(`Offer ${record ? "Updated" : "Added"} Successfully!`);
        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (record) {
      form.setFieldsValue({ image: record?.image });
      form.setFieldsValue({ name: record?.name });
    }
  }, [record, initialValue, form]);

  return (
    <>
      <Modal
        title={record?.id ? "Edit Offer" : "Add Offer"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddandEditOffer}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item className="offerImage">
            <DragImage
              nam="file"
              nam1="image"
              label="Offer Image"
              rules={[
                {
                  required: true,
                  message: "Please input your Offer Image!",
                },
              ]}
            />
            <span className="note">
              Note :- Please upload image in size of "1440 * 645" for better
              resolution
            </span>
          </Form.Item>
          <Form.Item
            name="name"
            label="Offer Name"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Offer Name!" },
            ]}
            className="offer-name"
          >
            <Input style={{ borderRadius: "25px", height: "2.5rem" }} />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddOffer;

import { Input, Button, Form, message, Select, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  getAdminsRoles,
  addAdminApi,
  editAdminApi,
} from "../../../../KIWE-V2/Services/adminApi";
const AddRole = ({
  isModalVisible,
  setIsModalVisible,
  record,
  admins,
  setAdmins,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    email: record?.email,
    firstName: record?.firstName,
    lastName: record?.lastName,
    groupId: record?.roles[0]?.id,
  });

  const [adminRoles, setAdminRoles] = useState([]);
  const fetchAdminRoles = async () => {
    try {
      const apiResponse = await getAdminsRoles();
      setAdminRoles(apiResponse.data);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchAdminRoles();
  }, []);
  const handleAddAndEditAdmin = async (values) => {
    if (record) {
      try {
        const apiResponse = await editAdminApi(record?.id, values);
        const updatedAdmins = admins.map((admin) => {
          if (admin.id === record.id) {
            return apiResponse?.data;
          } else {
            return admin;
          }
        });
        setAdmins(updatedAdmins);
        const configSuccess = {
          className: "messageSuccess",
          content: "Admin edited successfully!",
        };
        message.success(configSuccess);
        handleCancel();
      } catch (error) {
        const configError = {
          className: "messageError",
          content: error.message,
        };
        message.error(configError);
      }
    } else {
      try {
        const apiResponse = await addAdminApi(values);
        setAdmins([...admins, apiResponse?.data]);
        const configSuccess = {
          className: "messageSuccess",
          content: "Admin Added successfully!",
        };
        message.success(configSuccess);
        handleCancel();
        const currentMultipleOfTen = Math.ceil(admins.length / 10) * 10;
        if (admins?.length === currentMultipleOfTen) {
          window.location.reload();
        }
      } catch (error) {
        const configError = {
          className: "messageError",
          content: error.message,
        };
        message.error(configError);
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);

  const handleChange = (selectedItems) => {
    setSelectedAdminRoles(selectedItems);
  };
  useEffect(() => {
    if (isModalVisible) {
      if (record?.id) {
        form.setFieldsValue({ email: record?.email });
        form.setFieldsValue({ firstName: record?.firstName });
        form.setFieldsValue({ lastName: record?.lastName });
        form.setFieldsValue({
          groupId: record?.roles[0]?.id,
        });
      } else {
        form.resetFields();
      }
    }
  }, [record, form, isModalVisible]);
  return (
    <>
      <Modal
        title={record?.id ? "Edit Contact" : "Add Contact"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndEditAdmin}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            name="firstName"
            label="First Name :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your First Name!" },
            ]}
          >
            <Input style={{ borderRadius: "25px", height: "2.5rem" }} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Last Name!" },
            ]}
          >
            <Input style={{ borderRadius: "25px", height: "2.5rem" }} />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your E-mail!" },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input style={{ borderRadius: "25px", height: "2.5rem" }} />
          </Form.Item>
          {record ? (
            ""
          ) : (
            <>
              <Form.Item
                name="password"
                label="Password :"
                hasFeedback
                rules={
                  record
                    ? []
                    : [
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]
                }
              >
                <Input.Password
                  style={{ height: "2.5rem" }}
                  aria-label="Password :"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password :"
                dependencies={["password"]}
                hasFeedback
                rules={
                  record
                    ? []
                    : [
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]
                }
              >
                <Input.Password
                  style={{ height: "2.5rem" }}
                  aria-label="Confirm Password :"
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="groupId"
            label="Add Role"
            hasFeedback
            rules={
              record
                ? []
                : [{ required: true, message: "Please input your Role!" }]
            }
          >
            <Select
              placeholder="Select Role"
              arial-label="Add Role"
              value={selectedAdminRoles}
              onChange={handleChange}
              allowClear
              className="selectRole"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {adminRoles.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {record ? "Edit Contact" : "Add Contact"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddRole;

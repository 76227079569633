import { Modal, Avatar } from "antd";
const ParentUser = ({ selectedRowKey, isModalVisible, setIsModalVisible }) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <>
      <Modal
        title="Parent Account Info"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
        width={"45rem"}
      >
        <div className="indi">
          <div className="section_row">
            <Avatar src={selectedRowKey?.parent?.profilePicture} size={130} />
            <div>
              {selectedRowKey?.parent?.firstName?.trim()?.length !== 0 ? (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>First Name</h3>
                  <span>{selectedRowKey?.parent?.firstName}</span>
                </div>
              ) : (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>First Name</h3>
                  <span>-</span>
                </div>
              )}
              {selectedRowKey?.parent?.lastName?.trim()?.length !== 0 ? (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>Last Name</h3>
                  <span>{selectedRowKey?.parent?.lastName}</span>
                </div>
              ) : (
                <div
                  className="section indi_content"
                  style={{ width: "105px" }}
                >
                  <h3>Last Name</h3>
                  <span>-</span>
                </div>
              )}
            </div>
          </div>

          <div className="section_row ">
            <div className="section">
              <h3>Birth Date</h3>
              <span>
                {selectedRowKey?.birthDate
                  ? new Date(
                      selectedRowKey?.parent?.birthDate
                    ).toLocaleDateString()
                  : "-"}
              </span>
            </div>

            <div className="section indi_content">
              <h3>Mobile</h3>
              <span>
                {selectedRowKey?.parent?.mobile
                  ? selectedRowKey?.parent?.mobile
                  : "-"}
              </span>
            </div>
          </div>

          <div className="section_row ">
            <div className="section">
              <h3>Email</h3>
              <span>
                <span>
                  {selectedRowKey?.parent?.email
                    ? selectedRowKey?.parent?.email
                    : "-"}
                </span>
              </span>
            </div>
            <div className="section indi_content">
              <h3>Create Date</h3>
              <span>
                {new Date(
                  selectedRowKey?.parent?.createdTimeStamp
                ).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="indi_info">
            <div className="col_indi indi_info_item">
              <h3>Account Type</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey?.parent?.type === undefined
                  ? "-"
                  : selectedRowKey?.parent?.type}
              </span>
            </div>
            <div className="col_indi indi_info_item">
              <h3>Address</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey?.parent?.address !== null
                  ? selectedRowKey?.parent?.address
                  : "-"}
              </span>
            </div>
            <div className="col_indi indi_info_item">
              <h3>City</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey?.parent?.city !== null
                  ? selectedRowKey?.parent?.city
                  : "-"}
              </span>
            </div>
            <div className="col_indi indi_info_item">
              <h3>Reward Level</h3>
              <span className="qrcode_indi indi_info_item">
                {selectedRowKey?.parent?.rewardLevel?.name}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ParentUser;

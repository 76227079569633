import { Space, Button, Table, message, Avatar } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { useHistory } from "react-router-dom";
import Add from "./add";
import { fetchHubsApi } from "../../../KIWE-V2/Services/hubApi";
const PayHub = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [hubs, setHubs] = useState([]);

  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllHubs = async (page) => {
    try {
      const apiResponse = await fetchHubsApi(page);
      setLoading(false);
      setHubs(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);

      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    if (pageNum) {
      fetchAllHubs(pageNum);
    } else {
      fetchAllHubs(1);
    }
  }, [pageNum]);

  const HubsColumns = [
    {
      title: "Banner",
      dataIndex: "image",
      key: "image",
      render: (txt, record) => (
        <>
          <Avatar src={record?.image} size="large" />
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Background Color",
      dataIndex: "backgroundColor",
      key: "backgroundColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record.backgroundColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Hub Status",
      dataIndex: "visible",
      key: "visible",

      render: (txt, record) => (
        <>
          <div>
            {record?.visible === true ? (
              <div className="custom-tag onboarding-status-approved">
                Active
              </div>
            ) : record?.visible === false ? (
              <div className="custom-tag onboarding-status-rejected">
                InActive
              </div>
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
    },
  ];

  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Pay Hubs</h2>
          <Space style={{ justifyContent: "flex-end" }}>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal(null);
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {hubs.length > 0 ? (
            <Table
              className="table2"
              columns={HubsColumns}
              dataSource={hubs}
              rowKey="id"
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/hubs/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No Hubs Records Found.
            </div>
          )}
        </div>
        <Add
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          hubs={hubs}
          setHubs={setHubs}
          record={record}
        />
        <Totop />
      </div>
    </>
  );
};

export default PayHub;

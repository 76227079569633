import React from "react";
import { Button, Form, message, Modal, InputNumber } from "antd";
import { useState } from "react";
import { linkCustomerToCardApi } from "../../../KIWE-V2/Services/cardApi";
const AddKitNo = ({ isModalVisible, setIsModalVisible, record }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleLinkCustomerToCard = async (values) => {
    try {
      setLoading(true);
      const apiResponse = await linkCustomerToCardApi(values, record?.id);
      handleCancel();
      setLoading(false);
      const configSuccess = {
        className: "messageSuccess",
        content: "Link customer to card successfully!",
      };
      message.success(configSuccess);
    } catch (error) {
      setLoading(false);
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={"Link Customer To Card"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleLinkCustomerToCard}
          autoComplete="off"
        >
          <Form.Item name="cardId" label="KitNo: ">
            <InputNumber
              min={0}
              className="inputs-business"
              style={{
                width: "100%",
                borderRadius: "25px",
                height: "2.7rem",
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="add-btn"
              loading={loading}
            >
              Add KitNo
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddKitNo;

import {API_BASE_URL} from '../../constant';
import axios from 'axios';

class ReactS3 {
    static upload(file, config) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileUpload', file);

        var fileName = '';
        if(config.name){
            fileName = config.name;
        }else{
            fileName = file.name;
        }

        var path = config.albumName.replace('kiweapp/','');
        var url = API_BASE_URL+'admin/uploadFile?name='+fileName+'&path='+path+'/offer';
        return new Promise(async (resolve, reject) => {
            const errorHandler = fn => {
                return fn.then(data => [null, data]).catch(err => [err]);
            };
            const uploadResult = async () => {
                let err, result;
                [err, result] = await errorHandler(axios.post(API_BASE_URL+'admin/uploadFile?name='+fileName+'&path='+path,formData).then(function (response) { }).catch(function (error) { }) );

                if (err) reject(err);
                resolve({
                    bucket: 'kiweapp',
                    // key: 'offer',
                    // location: fileName,
                    key: `${config.albumName ? config.albumName + '/' : ''}${fileName}`,
                    location: `${url}${config.albumName ? config.albumName + '/' : ''}${fileName}`,
                    filename:fileName,
                    result: result
                });
            };
            uploadResult();
        });
    }
}
export default ReactS3;
// import {
//   AWS_ACCESS_KEY,
//   AWS_SECRET_KEY,
//   REGION,
//   BUCKET_NAME,
//   // IdentityPoolId,
//   Env,
// } from "../Utils/Constants";
import ReactS3 from "../library/ReactS3";
import UploadHub from "../../library/UploadHub";
// import S3 from "react-aws-s3";

// const config = {
//   bucketName: BUCKET_NAME,
//   dirName: "product_picture",
//   region: REGION,
//   accessKeyId: AWS_ACCESS_KEY,
//   secretAccessKey: AWS_SECRET_KEY,
// };

// export const uploadImg = (e) => {
//   ReactS3.uploadFile(e, config)
//     .then((data) => {
//       //debugger;
//       console.log(data, data.location);
//     })
//     .catch((err) => {
//       //debugger;
//       console.log(err);
//     });
// };

export const generateRandomFileName = (fileZero, length = 3) => {
  let randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  let fileExtension = "." + fileZero?.name?.split(".").pop();
  return result + new Date().getTime() + fileExtension;
};
export const generateRandomFileNameWithoutExtention = (
  fileZero,
  length = 3
) => {
  let randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  let fileNameWithoutExtension = fileZero?.name?.split(".")[0];
  return fileNameWithoutExtension + "_" + result + new Date().getTime();
};

export const handleImageUpload = async (file, xclfilename, albumName) => {
  if (file) {
    console.log(file);
    // let xclfilename = generateRandomFileName();
    let amazoneParam = Object.assign(
      {},
      {},
      { name: xclfilename, albumName: albumName }
    );
    console.log(amazoneParam);
    let promise = await ReactS3.upload(file, amazoneParam);
    console.log(promise, "url");
  }
};

export const handleHubUpload = async (file, id, setLoading, handleCancel) => {
  if (file) {
    console.log(file, id);
    // let xclfilename = generateRandomFileName();

    let promise = await UploadHub.upload(file, id, setLoading, handleCancel);
    console.log(promise, "hub");
  }
};

// export const fileChange = (file, randName) => {
//   const PRODUCT_ALBUM_KEY = `${Env}/${localStorage.getItem(
//     "userId"
//   )}/product_picture`;

//   const config = {
//     bucketName: BUCKET_NAME,
//     dirName: PRODUCT_ALBUM_KEY,
//     region: REGION,
//     accessKeyId: AWS_ACCESS_KEY,
//     secretAccessKey: AWS_SECRET_KEY,
//     s3Url: "https://s3.eu-central-1.amazonaws.com/kiweapp",
//   };

//   const ReactS3Client = new S3(config);
//   /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

//   ReactS3Client.uploadFile(file, randName)
//     .then((data) => {
//       //debugger;
//       console.log(data);
//     })
//     .catch((err) => {
//       //debugger;
//       console.error(err);
//     });
// };

import "../../assets/sass/main.scss";
import Main from "../Main";
import { KiwePromotionProvider } from "../../contextAPi/KiwePromotion/KiwePromotionContext";
import { ValuPromotionProvider } from "../../contextAPi/valuPromotion/valuPromotionContext";
import { LevelRewardProvider } from "../../contextAPi/levelReward/levelRewardContext";
import { FairProvider } from "../../contextAPi/fair/FairContext";
function App() {
  return (
    <>
      <FairProvider>
        <LevelRewardProvider>
          <KiwePromotionProvider>
            <ValuPromotionProvider>
              <Main />
            </ValuPromotionProvider>
          </KiwePromotionProvider>
        </LevelRewardProvider>
      </FairProvider>
    </>
  );
}

export default App;

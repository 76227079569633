import {
  Space,
  Button,
  Table,
  Cascader,
  Select,
  message,
  Modal,
  Card,
  Tag,
  DatePicker,
} from "antd";

import { useState, useEffect } from "react";

import { updateUser } from "../../../KIWE-V2/Services/userAPI.js";

import InputSearch from "../../front/form/InputSearch";
import Totop from "../../shared/TopUp";
import { EyeOutlined } from "@ant-design/icons";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import Infooo from "../../../assets/images/infooo.svg";
import axiosInstance from "../../../helpers/axiosInstance.js";
import { useHistory } from "react-router-dom";
import frontCard from "../../../assets/images/frontVisa.png";
import backCard from "../../../assets/images/backVisa.png";
import ModalUser from "./UserDetails";

import swal from "sweetalert2";
import moment from "moment";

import { fetchUserData } from "../../../KIWE-V2/Services/userAPI.js";

const OnBoardingUser = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const search_text = params.get("search_text");
  const history = useHistory();

  const Option = Select;
  const [selectedRowKey, setSelectedRowKey] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleInfo, setIsModalVisibleInfo] = useState(false);
  const [isModalVisibleKyc, setIsModalVisibleKyc] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [ekycStatus, setEkycStatus] = useState(null);
  const [cardRequestStatus, setCardRequestStatus] = useState(null);
  const [amlVerificationStatus, setAmlVerificationStatus] = useState(null);

  const [onBoardingUserData, setOnBoardingUserData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);

  const [page, setPage] = useState(1);
  const [record, setRecord] = useState([]);

  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedPickUpDate, setSelectedPickUpDate] = useState([]);
  const handleDateChange = (dates, dateStrings) => {
    setSelectedDate(dateStrings);
  };
  const handlePickUpDateChange = (dates, dateStrings) => {
    setSelectedPickUpDate(dateStrings);
  };
  const isApproved = true;
  const isRejected = false;

  const options = [
    {
      value: "ekycStatus",
      label: "EKYC Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "REJECTED",
          label: "REJECTED",
        },
        {
          value: "FAILED",
          label: "FAILED",
        },
        {
          value: "APPROVED",
          label: "APPROVED",
        },
      ],
    },
    {
      value: "cardRequestStatus",
      label: "Card Issuance Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "REQUESTED",
          label: "REQUESTED",
        },
        {
          value: "ISSUED",
          label: "ISSUED",
        },
        {
          value: "RECEIVED",
          label: "RECEIVED",
        },
      ],
    },
    {
      value: "amlVerificationStatus",
      label: "AML Status",
      children: [
        {
          value: "ALL",
          label: "ALL",
        },
        {
          value: "PENDING",
          label: "PENDING",
        },
        {
          value: "APPROVED",
          label: "APPROVED",
        },
        {
          value: "REJECTED",
          label: "REJECTED",
        },
      ],
    },

    {
      value: "createdTimeStamp",
      label: "Joining Date",
      children: [
        {
          value: "datepicker",

          label: <DatePicker.RangePicker onChange={handleDateChange} />,
        },
      ],
    },
    {
      value: "pickTimeStamp",
      label: "PickUp Date",
      children: [
        {
          value: "pickup",
          label: <DatePicker.RangePicker onChange={handlePickUpDateChange} />,
        },
      ],
    },
  ];
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  const onSearch = (value) => {
    setSearchText(value);
    fetchOnBoardingUser(1, "PENDING", value, null, null, null, null, null);
  };

  const fetchOnBoardingUser = async (
    page,

    cardStatus,
    searchText,
    searchTextFilter,
    ekycStatus,
    cardRequestStatus,

    amlVerificationStatus,
    dateStrings,
    pickupDate
  ) => {
    try {
      const response = await fetchUserData(
        page,

        cardStatus,
        searchText,
        searchTextFilter,
        ekycStatus,
        cardRequestStatus,
        amlVerificationStatus,
        dateStrings,
        pickupDate
      );

      setLoading(false);
      console.log(response?.data);
      setOnBoardingUserData(response?.data);
      setTotalPages(response?.totalRecords);
    } catch (error) {
      setLoading(false);
      const err = convertEncryptedToPlantext(error?.message);
      message.error(err);
    }
  };
  const setRowKey = (record) => {
    const selectedRowKey = getRowKey(record);

    setSelectedRowKey(record);
  };
  const showModalKyc = (record) => {
    setIsModalVisibleKyc(true);
    setRecord(record);
  };
  const handleCancelDocument = () => {
    setIsModalVisibleKyc(false);
  };
  const getRowKey = (record) => {
    return `${record.key}`;
  };
  const showModalInfo = (record) => {
    setIsModalVisibleInfo(true);
  };
  const showModal = (record) => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelInfo = () => {
    setIsModalVisibleInfo(false);
  };

  const onChangeFilterColumns = (value) => {
    if (!value) {
      return window.location.reload();
    }

    if (value[0] === "ekycStatus") {
      setEkycStatus(value[1]);
    }
    if (value[0] === "ekycStatus" && value[1] === "ALL") {
      setEkycStatus(null);
    }
    if (value[0] === "cardRequestStatus") {
      setCardRequestStatus(value[1]);
    }
    if (value[0] === "cardRequestStatus" && value[1] === "ALL") {
      setCardRequestStatus(null);
    }
    if (value[0] === "amlVerificationStatus") {
      setAmlVerificationStatus(value[1]);
    }
    if (value[0] === "amlVerificationStatus" && value[1] === "ALL") {
      setAmlVerificationStatus(null);
    }

    history.push(`/on-boarding-user/?pageNum=1`);
  };
  useEffect(() => {
    if (pageNum) {
      fetchOnBoardingUser(
        pageNum,

        "PENDING",
        searchText,
        searchTextFilter,

        ekycStatus,
        cardRequestStatus,

        amlVerificationStatus,
        selectedDate,
        selectedPickUpDate
      );
    } else {
      fetchOnBoardingUser(
        1,

        "PENDING",
        searchText,
        searchTextFilter,
        ekycStatus,
        cardRequestStatus,

        amlVerificationStatus,
        selectedDate,
        selectedPickUpDate
      );
    }
  }, [
    pageNum,
    searchText,
    ekycStatus,
    cardRequestStatus,
    amlVerificationStatus,

    selectedDate,
    selectedPickUpDate,
  ]);

  const handleAML = (status, id) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text:
          status === "APPROVED"
            ? "Are you sure want to Approve this AML?"
            : "Are you sure want to Reject this AML?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          const userData = { amlVerificationStatus: status };
          async function updateUserAsync() {
            try {
              const updated = await updateUser(id, userData);
              const decryptedResponse = convertEncryptedToPlantext(
                updated.data.data
              ).data;
              setOnBoardingUserData((prevOnBoardingUsers) => {
                return prevOnBoardingUsers.map((user) =>
                  user.id !== id ? user : decryptedResponse
                );
              });
            } catch (error) {
              message.error(error.message);
            }
          }
          updateUserAsync();
        }
      });
  };

  const onBoardingColumns = [
    {
      title: "Info",
      dataIndex: "firstName",
      key: "firstName",

      render: (txt, record) => (
        <>
          <img
            style={{ cursor: "pointer" }}
            src={Infooo}
            onClick={() => {
              showModalInfo();
              setRowKey(record);
            }}
            alt=""
          />
        </>
      ),
    },
    {
      title: "User  Name",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
      render: (text, record) => <>{text}</>,
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a?.mobile - b?.mobile,

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.mobile ? record?.mobile : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Joining Date",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {new Date(txt)?.toLocaleDateString()}
          </span>
        </>
      ),
      sorter: (a, b) =>
        new Date(a?.createdTimeStamp) - new Date(b?.createdTimeStamp),
    },
    {
      title: "KYC Data",
      dataIndex: "KYCdata",
      key: "KYCdata",

      render: (txt, record) => (
        <div>
          <EyeOutlined onClick={() => showModalKyc(record)} />
        </div>
      ),
    },
    {
      title: "EKYC Status",
      dataIndex: "ekycStatus",
      key: "ekycStatus",

      render: (txt, record) => (
        <>
          {txt === "PENDING" ? (
            <div className="custom-tag onboarding-status-pending">Pending</div>
          ) : txt === "REJECTED" ? (
            <div color="red" className="custom-tag onboarding-status-rejected">
              Rejected
            </div>
          ) : (
            <div
              color="green"
              className="custom-tag onboarding-status-approved"
            >
              Approved
            </div>
          )}
        </>
      ),
    },
    {
      title: "Card Issuance Status",
      dataIndex: "cards",
      key: "cards",
      render: (text, record) => (
        <span>
          {record?.cards[0]?.cardRequestStatus === "PENDING" ? (
            <Tag
              color="orange"
              className="custom-tag onboarding-status-pending"
            >
              Pending
            </Tag>
          ) : record?.cards[0]?.cardRequestStatus === "REQUESTED" ? (
            <Tag
              color="blue"
              style={{ padding: "0.1rem 2.4rem" }}
              className="custom-tag"
            >
              Requested
            </Tag>
          ) : (
            <Tag color="green" className="custom-tag">
              Issued
            </Tag>
          )}
        </span>
      ),
    },

    {
      title: "Card Issuance Date",
      dataIndex: "cardsIssuance",
      key: "cardsIssuance",

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.cards[0]?.cardIssuanceDate === null
              ? "-"
              : new Date(
                  record?.cards[0]?.cardIssuanceDate
                ).toLocaleDateString()}
          </span>
        </>
      ),
      sorter: (a, b) =>
        new Date(a?.cards[0]?.cardIssuanceDate) -
        new Date(b?.cards[0]?.cardIssuanceDate),
    },
    {
      title: "Card Request Date",
      dataIndex: "cards",
      key: "cardRequestDate",

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.cards[0]?.cardRequestDate === null
              ? "-"
              : new Date(
                  record?.cards[0]?.cardRequestDate
                ).toLocaleDateString()}
          </span>
        </>
      ),
      sorter: (a, b) =>
        new Date(a?.cards[0]?.cardRequestDate) -
        new Date(b?.cards[0]?.cardRequestDate),
    },

    {
      title: "Card Status",
      dataIndex: "cards",
      key: "cards",

      render: (txt, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record?.cards[0]?.cardStatus &&
          record?.cards[0]?.cardStatus === "PENDING" ? (
            <div className="custom-tag onboarding-status-pending">Pending</div>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "AML Status",
      dataIndex: "amlVerificationStatus",
      key: "amlVerificationStatus",

      render: (text, record) => (
        <>
          {record?.amlVerificationStatus === "APPROVED" ? (
            <>
              <div
                color="green"
                className="custom-tag onboarding-status-approved"
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </div>
            </>
          ) : record?.amlVerificationStatus === "REJECTED" ? (
            <>
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </div>
            </>
          ) : record?.ekycStatus === "APPROVED" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="primary"
                className="btn paid approve"
                size="middle"
                onClick={() => handleAML("APPROVED", record?.id)}
              >
                Approve
              </Button>
              <Button
                type="danger"
                size="small"
                className="declined rejectBtn"
                onClick={() => handleAML("REJECTED", record?.id)}
              >
                Reject
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="primary"
                className="btn paid approve"
                size="middle"
                onClick={() => handleAML("APPROVED", record?.id)}
                disabled={true}
              >
                Approve
              </Button>
              <Button
                type="danger"
                size="small"
                className="declined rejectBtn"
                onClick={() => handleAML("REJECTED", record?.id)}
                disabled={true}
              >
                Reject
              </Button>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Pick Up Date",
      dataIndex: "cards",
      key: "cards",

      render: (txt, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              showModal();
              setRowKey(record);
            }}
          >
            {record?.cards[0]?.cardPickupDate === null
              ? "-"
              : new Date(record?.cards[0]?.cardPickupDate).toLocaleDateString()}
          </span>
        </>
      ),
      sorter: (a, b) => new Date(a.joiningdate) - new Date(b.joiningdate),
    },
  ];

  const onChangeFilter = (value) => {
    setSearchTextFilter(value);
  };

  const onSearchFilter = () => {};

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader-1">
          <h2>On Boarding Users</h2>
          <Space>
            <div className="onboarding_filter">
              <InputSearch
                onSearch={onSearch}
                searchTextt={search_text ? search_text : searchText}
              />

              <Select
                showSearch
                className="onboarding_filter_select"
                optionFilterProp="children"
                onChange={onChangeFilter}
                onSearch={onSearchFilter}
                style={{ marginLeft: "0.5rem" }}
                defaultValue={null}
              >
                <Option value={null}>Filter</Option>
                <Option value="username">Username</Option>
                <Option value="mobile">Mobile</Option>
                <Option value="email">Email</Option>
              </Select>
            </div>

            <Cascader
              options={options}
              onChange={onChangeFilterColumns}
              className="custom-cascader"
              placeholder="Filter"
              dropdownMenuColumnStyle={{
                height: "2rem",

                overflow: "hidden",
              }}
            />
          </Space>
        </Space>
        <div className="container">
          {onBoardingUserData?.length > 0 ? (
            <Table
              className="table2"
              columns={onBoardingColumns}
              dataSource={onBoardingUserData}
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/on-boarding-user/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No On Boarding User Records Found.
            </div>
          )}
        </div>
        {isModalVisibleKyc && (
          <Modal
            title={
              <div className="custom-modal-kyc-title">
                <h3 style={{ fontWeight: "bold" }}>KYC Data</h3>
              </div>
            }
            visible={isModalVisibleKyc}
            onCancel={handleCancelDocument}
            footer={null}
            width={600}
            className="modal"
          >
            <div className="creditContainer">
              <Card className="creditCard">
                <div className="creditCardImage">
                  {isFlipped ? (
                    <img src={backCard} alt="back-card" />
                  ) : (
                    <img src={frontCard} alt="front-card" />
                  )}
                </div>
              </Card>
              <div className="creditBtns">
                <Button
                  type="primary"
                  className="creditBtn "
                  size="middle"
                  onClick={handleClick}
                  style={{ marginRight: "1rem" }}
                  disabled={isFlipped}
                >
                  Forward
                </Button>
                <Button
                  type="primary"
                  size="small"
                  className="creditBtn "
                  onClick={handleClick}
                  disabled={!isFlipped}
                >
                  Backward
                </Button>
              </div>
            </div>
          </Modal>
        )}
        <ModalUser
          selectedRowKey={selectedRowKey}
          handleCancel={handleCancelInfo}
          isModalVisible={isModalVisibleInfo}
        />
        <Totop />
      </div>
    </>
  );
};

export default OnBoardingUser;

import { useState, useEffect } from "react";
import {
  Form,
  message,
  Select,
  Button,
  Input,
  Radio,
  Col,
  Row,
  Switch,
  Modal,
  Tag,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Totop from "../../shared/TopUp";
import DragImage from "../../front/form/DragImage";
import { normFile } from "../../shared/dragdropFun";
import {
  uploadImageS3,
  convertImageToBase64,
} from "../../../library/UploadImage";
import { generateRandomFileName } from "../../shared/uploadfun";

import {
  fetchPageNavigationApi,
  fetchTagsApi,
  addAnnouncementApi,
  addTagApi,
} from "../../../KIWE-V2/Services/announcementApi";
import { useHistory } from "react-router-dom";
const AddAnnouncement = () => {
  const [formAddAnnouncement] = Form.useForm();
  const [formAddTag] = Form.useForm();
  const accountTypes = ["All", "Individual", "Business"];

  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [pageNavigations, setPageNavigations] = useState([]);
  const [selectedPageNavigation, setSelectedPageNavigation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();
  const handleCancel = () => {
    setIsModalVisible(false);
    formAddTag.setFieldsValue({ name: "" });
    formAddTag.setFieldsValue({ nameColor: "" });
    formAddTag.setFieldsValue({ backgroundColor: "" });
  };
  const handleShowModal = () => {
    setSelectedTag(null);
    setIsModalVisible(true);
  };
  const handleAddTag = async (values) => {
    try {
      setLoading(true);
      const apiResponse = await addTagApi(values);
      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Tag Added Succssfully!",
        };
        message.success(configSuccess);
        formAddTag.resetFields();
        handleCancel();
        const newData = { ...apiResponse.data };
        setTags((prevData) => [...prevData, newData]);
        setSelectedTag(apiResponse?.data?.id);
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleChangeTag = (selectedItems) => {
    setSelectedTag(selectedItems);
  };
  const handleChangePageNavigation = (selectedItems) => {
    setSelectedPageNavigation(selectedItems);
  };
  const fetchPageNavigation = async () => {
    try {
      const apiResponse = await fetchPageNavigationApi();
      setPageNavigations(apiResponse.data);
    } catch (error) {
      message.error(error.message);
    }
  };
  const fetchTags = async () => {
    try {
      const apiResponse = await fetchTagsApi();
      setTags(apiResponse.data);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchPageNavigation();
    fetchTags();
  }, []);
  const handleUploadBannerAnnouncement = async (values) => {
    const file =
      values?.photo && values?.photo?.length > 0
        ? values?.photo[0]?.originFileObj
        : null;

    const base64Image = await convertImageToBase64(file);
    let randomName = generateRandomFileName(values?.photo[0]);
    const imageUrl = await uploadImageS3(
      randomName,
      "announcement",
      base64Image
    );
    return imageUrl;
  };
  const handleAddAnnouncement = async (values) => {
    try {
      setLoading(true);
      const imageUrl = await handleUploadBannerAnnouncement(values);
      const apiResponse = await addAnnouncementApi(values, imageUrl?.fileUrl);

      setLoading(false);
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Announcement Added Succssfully!",
        };
        message.success(configSuccess);
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const tagPlusStyle = {
    background: "#ffffff",
    borderStyle: "dashed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: "50%",
    height: "2.5rem",
    padding: ".5rem",
    borderRadius: "10px",
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Add Announcement</h2>
        <div className="container">
          <Form
            form={formAddAnnouncement}
            layout="vertical"
            onFinish={handleAddAnnouncement}
            autoComplete="off"
            className="notificationFrom"
          >
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="targetAudience"
                  label="Target Audience"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Target Audience!",
                    },
                  ]}
                  className="notificationFrom__userType"
                >
                  <Radio.Group aria-label="Target Audience">
                    {accountTypes.map((item, index) => (
                      <Radio value={item.toUpperCase()} key={index}>
                        {item}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <DragImage
                  normFile={normFile}
                  nam1="photo"
                  label="Banner Image"
                  accept=".jpg,.jpeg,image/jpeg,.png,image/png,.webp,image/webp"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Banner Image!",
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="enabled"
                  label="Activate Announcement"
                  valuePropName="checked"
                >
                  <Switch size="middle" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="text"
                  label="Text"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your Text Announcement!",
                    },
                  ]}
                >
                  <Input
                    aria-label="Text"
                    placeholder="Enter Text Announcement"
                    className="input-border"
                  />
                </Form.Item>
              </Col>{" "}
              <Col span={12}>
                <Form.Item
                  name="textColor"
                  label="Text Color"
                  hasFeedback
                  className="offer-name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Text Color!",
                    },
                  ]}
                >
                  <Input
                    type="color"
                    className="input-border"
                    defaultValue={"#0e4bfb"}
                  />
                </Form.Item>{" "}
              </Col>{" "}
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="tagId"
                  label="Tag"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your Tag!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Tag"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                    }}
                    className="selectLevel"
                    value={selectedTag}
                    onChange={handleChangeTag}
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.props.children.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    showSearch
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Tag onClick={handleShowModal} style={tagPlusStyle}>
                          <PlusOutlined /> New Tag
                        </Tag>
                      </>
                    )}
                  >
                    {tags.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        <div
                          style={{
                            backgroundColor: item.backgroundColor,
                            color: item.nameColor,
                            padding: "3px 6px",
                            borderRadius: "10px",
                            width: "50%",
                            height: "2.5rem",
                            display: "flex",
                            margin: "0 auto",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.name}
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pageNavigationId"
                  label="Page navigation"
                  hasFeedback
                >
                  <Select
                    placeholder="Select page navigation"
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                    }}
                    className="selectLevel"
                    value={selectedPageNavigation}
                    onChange={handleChangePageNavigation}
                    allowClear
                    dropdownMatchSelectWidth={true}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    showSearch
                  >
                    {pageNavigations.map((item) => (
                      <Select.Option value={item.id}>
                        {item.pageName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="add-btn"
                loading={loading}
              >
                Add Announcement
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Totop />
        <Modal
          title="Add New Tag"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="modal"
        >
          <Form
            form={formAddTag}
            layout="vertical"
            autoComplete="off"
            onFinish={handleAddTag}
          >
            <Form.Item
              name="name"
              label="Tag Name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your Tag Name!",
                },
              ]}
            >
              <Input placeholder="Enter Tag Name" className="input-border" />
            </Form.Item>
            <Form.Item
              name="nameColor"
              label="Tag Color"
              hasFeedback
              className="offer-name"
              rules={[
                {
                  required: true,
                  message: "Please input your Tag Color!",
                },
              ]}
            >
              <Input
                type="color"
                className="input-border"
                defaultValue={"#F2f2f2"}
              />
            </Form.Item>
            <Form.Item
              name="backgroundColor"
              label="Tag Background Color"
              hasFeedback
              className="offer-name"
              rules={[
                {
                  required: true,
                  message: "Please input your Tag Background Color!",
                },
              ]}
            >
              <Input
                type="color"
                className="input-border"
                defaultValue={"#0e4bfb"}
              />
            </Form.Item>
            <Form.Item className="btns">
              <Button
                size="middle"
                htmlType="submit"
                className="btns__offer-save"
                loading={loading}
              >
                Save
              </Button>
              <Button
                size="middle"
                className="btns__offer-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default AddAnnouncement;

import React from "react";
import { Switch, Route } from "react-router-dom";
import MainPage from "./MainPage/index";
import Dashboard from "../components/front/dashboard/Dashboard";
import Insights from "../components/front/insights";
import Admin from "../components/front/admin/Index";
import IndividualUser from "../components/front/individual_user";
import BusinessUser from "../components/front/business_user";
import Transaction from "../components/front/transaction";
import WithdrawalType from "../components/front/withdrawal_type";
import TransactionFees from "../components/front/transaction_fees";
import WithdrawalList from "../components/front/withdrawal_list";
import OfferList from "../components/front/offer_list";
import Event from "../components/front/events";
import Content from "../components/front/content";
import Notification from "../components/front/notification";
import JointAccounts from "../components/front/individual_user/Children";
import TransactionUser from "../components/front/individual_user/Transaction";
import PromotionUser from "../components/front/individual_user/Promotion";
import EventUser from "../components/front/individual_user/Event";
import EventDetail from "../components/front/events/EventDetail";
import PageNotFound from "../components/shared/PageNotFound";
// import Login from '../components/front/login/Login';
import AddNotification from "../components/front/notification/Add";
import Profile from "../components/front/admin/ProfileAdmin/Profile";
import Industry from "../components/front/industry";
import SellingType from "../components/front/selling-type";
import IndustryType from "../components/front/industry-types";
import Logs from "../components/front/industry-types/logs";
import BusinessOffer from "../components/front/business_offer/index";
import AddBusinessOffer from "../components/front/business_offer/add";
import EditBusinessOffer from "../components/front/business_offer/edit";
import Promotions from "../components/front/promotions";
import GiftCard from "../components/front/gift-card";
import EditBusinessUser from "../components/front/business_user/edit";
import CompanyList from "../components/front/company";
import ServiceType from "../components/front/service_type";
import ServiceCode from "../components/front/service_code";
import BusinessOwner from "../components/front/business-owner/index";
import NotificationBusiness from "../components/front/business-notification";
import Payout from "../components/front/withdrawal_list/payout";
import NewLogin from "../components/front/login/newLogin.jsx";
import CashBack from "../components/front/business_user/cashBack";
import Add from "../components/front/business_user/AddCashBack";
import Edit from "../components/front/business_user/EditCashBack";
import PromotionValue from "../components/front/promotionsValue";
import AddPromotionValue from "../components/front/promotionsValue/addPromotionValue";
import FinanceBusinessOwner from "../components/front/FinanceBusiness_owner";
import Quiz from "../components/front/quiz/Quiz";
import Add_Quiz from "../components/front/quiz/Add_Quiz";
import Blog from "../components/front/blog";
import AddBlog from "../components/front/blog/Add";
import MoneyLearn from "../components/front/money_learn";
import AddMoneyLearn from "../components/front/money_learn/add";
import Tickets from "../components/front/individual_user/tickets";
import OTP from "../components/front/otp";
import Savings from "../components/front/savings";
import AddSavings from "../components/front/savings/add.jsx";
import EditSavings from "../components/front/savings/edit.jsx";
import OnBoardingUser from "../components/kiwe-v2/onBoardingUser";

import ActiveUser from "../components/kiwe-v2/activeUser";
import Reasons from "../components/kiwe-v2/activeUser/reason";
import LevelReward from "../components/kiwe-v2/levelReward";
import CreateMerchant from "../components/kiwe-v2/createMerchant";
import MerchantCashback from "../components/kiwe-v2/levelReward/merchantCashback";
import Merchant from "../components/kiwe-v2/levelReward/merchant";
import SubAccount from "../components/kiwe-v2/activeUser/subAccount";
import Disputes from "../components/kiwe-v2/disputes/index.jsx";
import AddRewardLevel from "../components/kiwe-v2/levelReward/addLevel.jsx";
import CardUser from "../components/kiwe-v2/cardUser/index.jsx";
import CardDetails from "../components/kiwe-v2/cardUser/cardDetails.jsx";
import UpdateRewardLevel from "../components/kiwe-v2/levelReward/updateLevel.jsx";
import Fairs from "../components/kiwe-v2/fair/index.jsx";
import AddFair from "../components/kiwe-v2/fair/add.jsx";
import Announcements from "../components/kiwe-v2/announcements/index.jsx";
import EditAnnouncement from "../components/kiwe-v2/announcements/edit.jsx";
import AddAnnouncement from "../components/kiwe-v2/announcements/add.jsx";
import Article from "../components/kiwe-v2/article/index.jsx";
import Topics from "../components/kiwe-v2/FAQSTopic/index.jsx";
import FAQsTopic from "../components/kiwe-v2/FAQs/index.jsx";
import Home from "../components/kiwe-v2/home/index.jsx";
import OldAnnouncements from "../components/front/announcement-V1/index.jsx";
import AddOldAnnouncement from "../components/front/announcement-V1/add.jsx";
import EditOldAnnouncement from "../components/front/announcement-V1/edit.jsx";
import NewAdmins from "../components/kiwe-v2/adminUser/index.jsx";
import NewProfile from "../components/kiwe-v2/adminUser/ProfileAdmin/Profile.jsx";
import PayHub from "../components/kiwe-v2/payHub/index.jsx";
const Main = () => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <Home {...props} />} />
      {/* <Route exact path='/login' render={(props) => <Login {...props} />} /> */}
      <Route exact path="/login" render={(props) => <NewLogin {...props} />} />
      <Route
        exact
        path="/dashboard"
        render={(props) => (
          <MainPage>
            <Dashboard {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/insights"
        render={(props) => (
          <MainPage>
            <Insights {...props} />
          </MainPage>
        )}
      />

      <Route
        exact
        path="/view_promotions/"
        render={(props) => (
          <MainPage>
            <Promotions {...props} />
          </MainPage>
        )}
      />

      <Route
        exact
        path="/promotion-user"
        render={(props) => (
          <MainPage>
            <PromotionUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/card-details"
        render={(props) => (
          <MainPage>
            <CardDetails {...props} />
          </MainPage>
        )}
      />

      <Route
        exact
        path="/quiz"
        render={(props) => (
          <MainPage>
            <Quiz />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/money-learn"
        render={(props) => (
          <MainPage>
            <MoneyLearn />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/money-learn-add/:pageNo"
        render={(props) => (
          <MainPage>
            <AddMoneyLearn {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/blog"
        render={(props) => (
          <MainPage>
            <Blog />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-blog/:pageNo"
        render={(props) => (
          <MainPage>
            <AddBlog {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-quiz"
        render={(props) => (
          <MainPage>
            <Add_Quiz />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/admin/:pageNo"
        render={(props) => (
          <MainPage>
            <Admin {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/new-admins/:pageNo"
        render={(props) => (
          <MainPage>
            <NewAdmins {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/business-owner/:pageNo"
        render={(props) => (
          <MainPage>
            <BusinessOwner {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/finance-business-owner"
        render={(props) => (
          <MainPage>
            <FinanceBusinessOwner {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/indivdual-user/"
        render={(props) => (
          <MainPage>
            <IndividualUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/disputes/"
        render={(props) => (
          <MainPage>
            <Disputes {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/articles/"
        render={(props) => (
          <MainPage>
            <Article {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/hubs/"
        render={(props) => (
          <MainPage>
            <PayHub {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/topics/"
        render={(props) => (
          <MainPage>
            <Topics {...props} />
          </MainPage>
        )}
      />

      <Route
        exact
        path="/view-FAQs/"
        render={(props) => (
          <MainPage>
            <FAQsTopic {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/fairs/"
        render={(props) => (
          <MainPage>
            <Fairs {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-fair"
        render={(props) => (
          <MainPage>
            <AddFair />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/on-boarding-user/"
        render={(props) => (
          <MainPage>
            <OnBoardingUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/active-user/"
        render={(props) => (
          <MainPage>
            <ActiveUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/card-user/"
        render={(props) => (
          <MainPage>
            <CardUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/sub-account/"
        render={(props) => (
          <MainPage>
            <SubAccount {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/level-rewards"
        render={(props) => (
          <MainPage>
            <LevelReward />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-level-reward"
        render={(props) => (
          <MainPage>
            <AddRewardLevel />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/edit-level-reward"
        render={(props) => (
          <MainPage>
            <UpdateRewardLevel />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/merchant-cashback"
        render={(props) => (
          <MainPage>
            <MerchantCashback />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/merchant"
        render={(props) => (
          <MainPage>
            <Merchant />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/merchant-reward"
        render={(props) => (
          <MainPage>
            <CreateMerchant />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/reasons"
        render={(props) => (
          <MainPage>
            <Reasons {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/business-user/"
        render={(props) => (
          <MainPage>
            <BusinessUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/transactions"
        render={(props) => (
          <MainPage>
            <Transaction {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/withdrawal-type"
        render={(props) => (
          <MainPage>
            <WithdrawalType {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/transaction-fees"
        render={(props) => (
          <MainPage>
            <TransactionFees {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/withdrawal-list"
        render={(props) => (
          <MainPage>
            <WithdrawalList {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/offer-list"
        render={(props) => (
          <MainPage>
            <OfferList {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/announcement/"
        render={(props) => (
          <MainPage>
            <Announcements {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/old-announcement/"
        render={(props) => (
          <MainPage>
            <OldAnnouncements {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/edit-announcement"
        render={(props) => (
          <MainPage>
            <EditAnnouncement {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-announcement"
        render={(props) => (
          <MainPage>
            <AddAnnouncement {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-old-announcement"
        render={(props) => (
          <MainPage>
            <AddOldAnnouncement {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/edit-old-announcement"
        render={(props) => (
          <MainPage>
            <EditOldAnnouncement {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/events/:pageNo"
        render={(props) => (
          <MainPage>
            <Event {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/content"
        render={(props) => (
          <MainPage>
            <Content {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/notification/:pageNo"
        render={(props) => (
          <MainPage>
            <Notification {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/joint-accounts"
        render={(props) => (
          <MainPage>
            <JointAccounts {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/transaction-user"
        render={(props) => (
          <MainPage>
            <TransactionUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/tickets"
        render={(props) => (
          <MainPage>
            <Tickets {...props} />
          </MainPage>
        )}
      />

      <Route
        exact
        path="/event-user"
        render={(props) => (
          <MainPage>
            <EventUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/event-detail/:id"
        render={(props) => (
          <MainPage>
            <EventDetail {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-notification/:pageNo"
        render={(props) => (
          <MainPage>
            <AddNotification {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/profile"
        render={(props) => (
          <MainPage>
            <Profile {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/new-profile"
        render={(props) => (
          <MainPage>
            <NewProfile {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/industry"
        render={(props) => (
          <MainPage>
            <Industry {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/selling-type"
        render={(props) => (
          <MainPage>
            <SellingType {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/industry-type"
        render={(props) => (
          <MainPage>
            <IndustryType {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/logs/:id"
        render={(props) => (
          <MainPage>
            <Logs {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/business-offer/:pageNo"
        render={(props) => (
          <MainPage>
            <BusinessOffer {...props} />
          </MainPage>
        )}
      />

      <Route
        exact
        path="/business-offer-add/:pageNo"
        render={(props) => (
          <MainPage>
            <AddBusinessOffer {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/business-offer-edit/:pageNo"
        render={(props) => (
          <MainPage>
            <EditBusinessOffer {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/gift-card"
        render={(props) => (
          <MainPage>
            <GiftCard {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/savings/:pageNo"
        render={(props) => (
          <MainPage>
            <Savings {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/savings-add/:pageNo"
        render={(props) => (
          <MainPage>
            <AddSavings {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/savings-edit/:pageNo"
        render={(props) => (
          <MainPage>
            <EditSavings {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/business-user-edit"
        render={(props) => (
          <MainPage>
            <EditBusinessUser {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/promotion-value/:pageNo"
        render={(props) => (
          <MainPage>
            <PromotionValue {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/promotion-value-add/:pageNo"
        render={(props) => (
          <MainPage>
            <AddPromotionValue {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/cash-back/:userId"
        render={(props) => (
          <MainPage>
            <CashBack {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/add-cash-back"
        render={(props) => (
          <MainPage>
            <Add {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/edit-cash-back"
        render={(props) => (
          <MainPage>
            <Edit {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/company"
        render={(props) => (
          <MainPage>
            <CompanyList {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/service-type"
        render={(props) => (
          <MainPage>
            <ServiceType {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/service-code"
        render={(props) => (
          <MainPage>
            <ServiceCode {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/notification-business"
        render={(props) => (
          <MainPage>
            <NotificationBusiness {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/payout"
        render={(props) => (
          <MainPage>
            <Payout {...props} />
          </MainPage>
        )}
      />
      <Route
        exact
        path="/otp"
        render={(props) => (
          <MainPage>
            <OTP {...props} />
          </MainPage>
        )}
      />
      <Route exact path="*" render={() => <PageNotFound />} />
    </Switch>
  );
};

export default Main;

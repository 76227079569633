import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let response;
    if (Object.keys(requestData).length !== 0) {
      response = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      response = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(response?.data?.data);
    }
    return response;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
const fetchBusinessAndBusnessOwnerData = async (url) => {
  try {
    const response = await apiCall(url, {}, "get");
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchBusinessOwnerData = async (page, searchText) => {
  const response = await fetchBusinessAndBusnessOwnerData(
    `owners?page=${page}&name=${searchText}&email=${searchText}`
  );
  return response;
};
export const fetchBusinessData = async () => {
  const response = await fetchBusinessAndBusnessOwnerData(
    "owners/fetch/business"
  );
  return response;
};

const buildAddOrEditBusinessOwnerApiRequestBody = (
  values,
  record = undefined
) => {
  return {
    ...values,
    notificationFlag: true,
    accountType: "businessOwner",
    ...(record && { expiryTime: getCurrentTimeStemp(), id: record.id }),
  };
};


export const addAndUpdateBusinessOwnerApi = async (values, record) => {

  try {
    const data = buildAddOrEditBusinessOwnerApiRequestBody(values, record);
    const response = await apiCall("owners", data);
    return response;
  } catch (error) {
    throw error;
  }
};

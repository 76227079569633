import { Table, Button, message, Avatar } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { useHistory } from "react-router";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { useLocation } from "react-router-dom";
import { fetchMerchantsCashbacksDataAndDecryptResponse } from "../../../KIWE-V2/Services/merchantCashbackApi";
const Merchant = () => {
  const location = useLocation();
  const { level } = location.state || {};
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const fetchMerchantCashback = async (levelId) => {
    try {
      const apiResponse = await fetchMerchantsCashbacksDataAndDecryptResponse(
        levelId
      );

      setMerchants(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const errorData = convertEncryptedToPlantext(error?.response?.data?.data);
      const configError = {
        className: "messageError",
        content: errorData.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchMerchantCashback(level?.id);
  }, [level?.id]);
  const columns = [
    {
      title: "Merchant Avatar",
      dataIndex: "avatar",
      key: "avatar",

      render: (txt, record) => (
        <>
          <Avatar src={record?.avatar} size="large" />
        </>
      ),
    },
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
      render: (txt, record) => <>{record?.name}</>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Merchant Code",
      dataIndex: "code",
      key: "code",
      render: (txt, record) => <>{record?.code}</>,
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (val, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btnCardsActivate"
              style={{ margin: ".5rem" }}
              onClick={() => {
                history.push({
                  pathname: "/merchant-cashback",
                  state: { merchantCashback: record },
                });
              }}
            >
              Merchant Cash-back
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Merchants</h2>
        <div className="container">
          {merchants.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={merchants}
              scroll={{ x: 900 }}
              loading={loading}
            />
          ) : (
            <div className="messageNoData">No Merchants Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Merchant;

import { Modal, Avatar } from "antd";
import { S3_URL } from "../../../constant";

const ModalUser = ({ selectedRowKey, handleCancel, isModalVisible }) => {
  return (
    <>
      <Modal
        title="Account Info"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
        width={"40rem"}
      >
        <div className="onboarding_info_col">
          <div className="onboarding_info_row ">
            <div className="onboarding_section">
              <Avatar
                src={
                  S3_URL +
                  selectedRowKey.id +
                  "/profile_picture/" +
                  selectedRowKey.profilePicture
                }
                size={85}
              />
            </div>
            <div className="onboarding_section">
              <div>
                <h3>Full Name</h3>
                <span>
                  {selectedRowKey?.firstName?.trim()?.length !== 0 ||
                  selectedRowKey?.lastName?.trim()?.length !== 0
                    ? selectedRowKey?.firstName + selectedRowKey?.lastName
                    : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="onboarding_info_row ">
            <div className="onboarding_mr onboarding_section">
              <h3>Email</h3>
              <span>
                <span>
                  {selectedRowKey?.email ? selectedRowKey?.email : "-"}
                </span>
              </span>
            </div>
            <div className="onboarding_section">
              <h3>DOB</h3>
              <span>
                {selectedRowKey?.birthDate
                  ? new Date(selectedRowKey?.birthDate).toLocaleDateString()
                  : "-"}
              </span>
            </div>
          </div>

          <div className="onboarding_info_row ">
            <div className="onboarding_mr onboarding_section">
              <h3>City</h3>
              <span>
                <span>{selectedRowKey?.city ? selectedRowKey?.city : "-"}</span>
              </span>
            </div>
            <div className="onboarding_section">
              <h3>Address</h3>
              <span>
                <span>
                  {selectedRowKey?.address ? selectedRowKey?.address : "-"}
                </span>
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalUser;

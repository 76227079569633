import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestData).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(encryptedResponse?.data?.data);
    }
    return encryptedResponse;
  } catch (error) {
    if(error.response&&error.response.data){
        const decryptedError = convertEncryptedToPlantext(error?.response?.data?.data);
        throw decryptedError;
    }else{
        throw error;
    }

  }
};
export const fetchFinanceBusinessOwnersData = async () => {
    try {
      const decryptedResponse = await apiCall("owners?page=0&size=2147483647", {}, "get");
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  };


const buildTransactionSummaryRequestBody=(businessOwnerIDs,businessOwnerStatus)=>{
    return{
        userId: businessOwnerIDs,
        isBusinessOwner: businessOwnerStatus,
    }
}

export const fetchTransactionSummaryData = async (businessOwnerIDs,businessOwnerStatus) => {
    try {
      const data=buildTransactionSummaryRequestBody(businessOwnerIDs,businessOwnerStatus)

      const decryptedResponse = await apiCall("transaction/summary", data);
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  };
const buildExportTransactionRequestBody=(id,startDate,endDate)=>{
    return{
        userId: id,
        startDate: startDate,
        endDate: endDate,
    }
}
export const exportTransactionApi = async (id,startDate,endDate) => {
    try {
      const data=buildExportTransactionRequestBody(id,startDate,endDate)
      const decryptedResponse = await apiCall("transaction/download-export", data);
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  };
export const getBusinessOwnerApi = async (page, userId) => {
    try {
      const decryptedResponse = await apiCall(`transaction/businessOwner?page=${page}`, { userId });
      return decryptedResponse;
    } catch (error) {
      throw error;
    }
  };

  
import { Upload, Button, Form, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
const DragImage = ({
  nam1,
  label,
  rules,
  normFile,
  filelist = [],
  accept,
  maxCount,
  initialValue,
}) => {
  const [fileList, setFileList] = useState(filelist ? filelist : []);
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (!initialValue) {
      setFileList([]);
      setFlag(true);
    } else {
      if (filelist && filelist.length > 0) {
        setFlag(false);
      }
    }
  }, [initialValue, filelist]);
  const handleChange = ({ fileList: newFileList }) => {
    if (accept) {
      const updatedFileList = newFileList.map((file) => {
        const isCorrectType = accept
          .split(",")
          .some((type) => file.type.includes(type));
        if (isCorrectType) {
          file.status = "done";
        }
        return file;
      });

      setFileList(updatedFileList);
    } else {
      newFileList.length < 1 ? setFlag(true) : setFlag(false);
      setFileList(newFileList);
    }
  };

  return (
    <>
      <Form.Item
        name={nam1}
        getValueFromEvent={normFile}
        label={label}
        rules={rules}
        // style={{ alignItems: "center" }}
      >
        <Upload
          action="https://www.mocky.io"
          fileList={fileList}
          onChange={handleChange}
          accept={accept}
          defaultFileList={fileList}
          maxCount={maxCount}
          className="newDrag"
        >
          {flag && fileList.length < 1 && (
            <Button icon={<UploadOutlined />} className="drag-image ">
              Click To Upload
            </Button>
          )}
        </Upload>
      </Form.Item>
    </>
  );
};

export default DragImage;

import React, { useState, useEffect } from "react";
import swal from "sweetalert2";
import moment from "moment";
import Totop from "../../shared/TopUp";
import InputSearch from "../form/InputSearch";
import axiosInstance from "../../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { Space, Button, Table, message } from "antd";
import { useHistory } from "react-router";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DownOutlined,
  CheckCircleTwoTone,
  UpOutlined,
} from "@ant-design/icons";
function Quiz() {
  //data
  // const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const data = [
    {
      title: "Quiz1",
      startDate: "1/1/2002",
      endDate: "1/1/2002",
      scope: "30 Sec",
      qusetions: [
        { q: "how are you1?", wrong: 122, correct: 100 },
        { q: "how are you2?", wrong: 122, correct: 100 },
        { q: "how are you3?", wrong: 122, correct: 100 },
      ],
    },
  ];
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Prize",
      dataIndex: "prize",
      key: "prize",
      render: (text) => <span>50 EGP</span>,
    },
    {
      title: "No.User Correct Answer",
      dataIndex: "blogBody",
      key: "blogBody",
      sorter: (a, b) => a.blogBody?.localeCompare(b?.blogBody),
      render: (text) => (
        <span style={{ textAlign: "center", display: "block" }}>{175}</span>
      ),
    },
    {
      title: "No.User Wrong Answer",
      dataIndex: "blogBody",
      key: "blogBody",
      sorter: (a, b) => a.blogBody?.localeCompare(b?.blogBody),
      render: (text) => (
        <span style={{ textAlign: "center", display: "block" }}>{175}</span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <span>{moment(text).format("D MMM, yyyy h:mm a")}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <span>{moment(text).format("D MMM, yyyy h:mm a")}</span>
      ),
    },
    {
      title: "Time",
      dataIndex: "scope",
      key: "scope",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{
              backgroundColor: "#FD5394",
              color: "white",
              border: "none",
            }}
            onClick={() => DeleteProm(record)}
            size="middle"
          >
            Delete
          </Button>
          <Button
            style={{
              backgroundColor: "#002984",
              color: "white",
              border: "none",
              marginLeft: "1rem",
            }}
            onClick={() =>
              history.push("/edit-quiz", {
                edit: true,
                id: record.id,
                record,
              })
            }
            size="middle"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  //functions
  const DeleteProm = (record) => {
    const id = record.id;
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure you want to delete this Promotion ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          axiosInstance()
            .delete(`promotions/${id}`)
            .then((res) => {
              const msg = convertEncryptedToPlantext(res.data.data).message;
              if (res.status === 200) {
                window.location.reload();
              } else {
                message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              // const msg=convertEncryptedToPlantext(err.response.data.data)
              // message.error(msg)

              message.error("something went wrong please try again");
            });
        }
      });
  };
  const onSearch = (value) => setSearchText(value);
  const fetchData = (page) => {
    axiosInstance()
      .get(`quiz?page=${page}&code=${searchText}`)
      .then((res) => {
        const __data = convertEncryptedToPlantext(res.data.data);
        setTotalPages(__data.totalRecords);
        // setData(__data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, [searchText]);
  const ExpandedRowRender = ({ qusetions }) => (
    <div>
      <div style={{ marginLeft: "8rem" }}>
        {" "}
        <CloseCircleOutlined
          style={{ marginLeft: "10px", color: "red" }}
          className="dynamic-delete-button"
        />
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ marginLeft: "20px" }}
        />
      </div>
      <div>
        {" "}
        {qusetions?.map((item, index) => (
          <>
            <div style={{ display: "flex" }}>
              <p key={index}>
                Q{index + 1}:{item.q}
              </p>

              <div style={{ display: "flex", marginLeft: "1rem" }}>
                <span style={{ marginRight: "5px" }}>{item.wrong}</span>
                <span>{item.correct}</span>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
  const ExpandIcon = ({ expanded, onExpand, record }) => {
    if (expanded) {
      return <UpOutlined onClick={(e) => onExpand(record, e)} />;
      // return <Icon type="minus-square" onClick={(e) => onExpand(record, e)} />;
    }
    return <DownOutlined onClick={(e) => onExpand(record, e)} />;
    // return <Icon type="plus-square" onClick={(e) => onExpand(record, e)} />;
  };
  return (
    <div style={{ margin: "1rem" }}>
      <Space direction="horizontal" className="topHeader">
        <h2>Quiz</h2>
        <Space>
          <InputSearch onSearch={onSearch} />
          <Button
            type="primary"
            size="middle"
            onClick={() => history.push("/add-quiz", { edit: false })}
          >
            ADD
          </Button>
        </Space>
      </Space>
      <div className="container">
        {data.length > 0 ? (
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 900 }}
            loading={loading}
            expandable={{
              expandedRowRender: ExpandedRowRender,
              expandIcon: ExpandIcon,
              rowExpandable: (record) => record.title !== "Not Expandable",
            }}
            pagination={{
              pageSize: 10,
              total: totalPages,
              onChange: (page) => {
                setPage(page);
                fetchData(page);
              },
            }}
          />
        ) : (
          <div className="messageNoData">No Quiz Records Found.</div>
        )}
      </div>
      <Totop />
    </div>
  );
}

export default Quiz;

import { Input, Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { addSellingTypeAPI } from "../../../KIWE-V1/Services/sellingTypeAPI";
const Add = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(
    record?.id ? { type: record?.type } : null
  );
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      if (length !== 0) {
        setInitialValues({
          type: record?.type,
        });
      } else {
        form.setFieldsValue({ type: "" });

        setInitialValues({});
      }
    }
  }, [record]);
  const handleAddSellingTypeAPI = async (values) => {
    try {
      if (record?.id) {
        await addSellingTypeAPI(values.type, record.id);
        message.success("Updated selling type successfully!");
      } else {
        await addSellingTypeAPI(values.type, record.id);
        message.success("Added selling type successfully!");
      }
      handleCancel();
      window.location.reload();
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (record?.id) {
      form.setFieldsValue({ type: record.type });
    }
  }, [record, initialValue]);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={record?.id ? "Edit Selling Type" : "Add Selling Type"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddSellingTypeAPI}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item
            name="type"
            label="Selling Type"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Selling Type!" },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

const Organizations = ({ organizations }) => {
    return (
      <div style={{textAlign:'center'}}>
        {organizations.map((item) => (
            <h3 key={item.id}>{item.industryTypeName}</h3>
        ))}
      </div>
    );
  };
  
  export default Organizations;
  
import { GET_ALL_INDUSTRY_TYPE } from '../actionTypes';
import { getAllIndustryTypeAsync } from './api';

export const getIndustryType = () => {
  return async (dispatch) => {
    try {
      const result = await getAllIndustryTypeAsync();

      dispatch(getIndustryTypeSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getIndustryTypeSuccess = (industryType) => {
  return {
    type: GET_ALL_INDUSTRY_TYPE,
    payload: industryType,
  };
};

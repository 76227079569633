import { Form, message, Button, Input, Radio } from "antd";
// import { useState } from "react";
import Totop from "../../shared/TopUp";
import DragImage from "../form/DragImage";

// import RadionButton from "../form/RadionButton";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { useHistory } from "react-router-dom";
import { Env } from "../../../constant";
import { useParams } from "react-router-dom";
const Add = () => {
  const [form] = Form.useForm();
  const plainOptions1 = ["All", "Individual", "Business"];
  const plainOptions2 = ["All", "IOS", "Android"];

  const history = useHistory();

  const { pageNo } = useParams();
  console.log(pageNo);
  const onFinish = (values) => {
    console.log(values);
    // debugger;
    const randNameImage =
      // 'https://kiweapp.s3.eu-central-1.amazonaws.com/offer/' +
      generateRandomFileName(values?.notificationImage[0]);
    // debugger;
    handleImageUpload(
      values?.notificationImage[0].originFileObj,
      randNameImage,
      "kiweapp/" + Env + "/notification_picture_path"
    );

    console.log(
      values.notificationText,
      values.notificationTitle,
      values.deviceType,
      values.userType,
      randNameImage
    );
    axiosInstance()
      .post("admin/send_bulk_notification", {
        data: convertPlantextToEncrypted({
          notificationText: values?.notificationText,
          notificationTitle: values?.notificationTitle,
          deviceType: values?.deviceType,
          userType: values?.userType,
          notificationImage: randNameImage,

          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response.data.data);
          console.log("api_response", api_res);
          // handleCancel();
          message.success("Submit success!");

          history.push(`/notification/${+pageNo}`);
        }
      })
      .catch((error) => {
        const err = convertEncryptedToPlantext(error?.response?.data?.data);
        message.error(err.message);
        console.log(error);
      });
  };

  // const onFinishFailed = () => {
  //   message.error("Submit failed!");
  // };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Add Blog</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="notificationFrom"
          >
            <Form.Item>
              <DragImage
                normFile={normFile}
                nam1="notificationImage"
                label="Blog Image"
                rules={[
                  {
                    required: true,
                    message: "Please input your Notfication Image!",
                  },
                ]}
              />
              <span className="note">
                Note :- Please upload image in size of "1440 * 645" for better
                resolution
              </span>
            </Form.Item>
            <Form.Item
              name="blogTitle"
              label="Blog Title"
              hasFeedback
              rules={[
                { required: true, message: "Please input your Blog Title!" },
              ]}
            >
              <Input placeholder="Enter Title Text" className="input-border" />
            </Form.Item>
            <Form.Item
              name="blogBody"
              label="Blog Body"
              rules={[
                { required: true, message: "Please input your Body Blog!" },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Body Blog" className="input-border" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="add-btn">
                Add Blog
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Add;

import { Space, Button, Table, message } from "antd";
import InputSearch from "../form/InputSearch";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Totop from "../../shared/TopUp";
import { S3_URL } from "../../../constant";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  fetchEventsData,
  exportAllEvents,
} from "../../../KIWE-V1/Services/eventApi";
const Event = () => {
  const onSearch = (value) => {
    setSearchTextFromInput(value);
    fetchEvents(1, value);
  };
  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pageNo } = useParams();

  const fetchEvents = async (page, searchTextFromInput) => {
    try {
      const apiResponse = await fetchEventsData(page, searchTextFromInput);
      setTotalPages(apiResponse?.totalRecords);
      setEvents(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchEvents(pageNo, searchTextFromInput);
  }, []);

  const columns = [
    {
      title: "Event ID",
      dataIndex: "eventId",
      sorter: (a, b) => a.eventId - b.eventId,
    },
    {
      title: "Users",
      dataIndex: "ownerName",
      key: "ownerName",
      render: (txt, value) => (
        <>
          <img
            width={40}
            style={{ borderRadius: "50%", height: "40px" }}
            src={
              S3_URL +
              value.ownerId +
              "/profile_picture/" +
              value.profilePicturePath
            }
            alt="img"
          />
          <span style={{ marginLeft: "0.5rem" }}>{txt}</span>
        </>
      ),
    },
    {
      title: "Owner",
      dataIndex: "ownerName",
      key: "ownerName",
      sorter: (a, b) => a.ownerName?.localeCompare(b.ownerName),
    },
    {
      title: "Event Name",
      dataIndex: "walletName",
      key: "walletName",
      sorter: (a, b) => a.walletName?.localeCompare(b.walletName),
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate),
      render: (text, record) => <>{new Date(text).toLocaleDateString()}</>,
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Contributors",
      dataIndex: "contributors",
      key: "contributors",
      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: `/event-detail/${record?.eventId}`,
              state: record,
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{
                marginLeft: "-1rem",
                height: "2rem",
                background: "#20ce24ff",
              }}
            >
              <span className="icon-Adminw"></span>
              Contributors
            </Button>
          </Link>
        </>
      ),
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",
      render: (txt, record) => (
        <>
          <Link
            to={{
              pathname: "/transaction-user",
              state: record,
              place: "event",
            }}
          >
            <Button
              className="btn-child"
              size="small"
              style={{
                marginLeft: "-1rem",
                height: "2rem",
                background: "#20ce24ff",
              }}
            >
              <span className="icon-TransactionFeesw"></span>
              Transactions
            </Button>
          </Link>
        </>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      render: (text, record) => (
        <>{moment(record?.createdDate).format("D MMM, yyyy h:mm a")}</>
      ),
    },
  ];
  const handleExportAllEvents = async () => {
    try {
      setLoading(true);
      const apiResponse = await exportAllEvents(page, searchTextFromInput);
      if (apiResponse) {
        window.open(apiResponse?.data, "noopener,noreferrer");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  const history = useHistory();
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Events List</h2>
          <Space>
            <InputSearch onSearch={onSearch} />
            <Button
              type="primary"
              size="middle"
              onClick={handleExportAllEvents}
              loading={loading}
            >
              Export
            </Button>
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={events}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +pageNo,
              defaultCurrent: +pageNo,
              onChange: (page) => {
                setPage(page);
                history.push(`/events/${page}`);
                fetchEvents(page, searchTextFromInput);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Event;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestData).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(encryptedResponse?.data?.data);
    }
    return encryptedResponse;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
export const fetchBusinessNotificationData = async () => {
  try {
    const decryptedResponse = await apiCall("notifications", {}, "get");
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

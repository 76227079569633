import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildRequestBody = (page, searchText) => {
  return {
    accountType: "business",
    search: searchText,
    pageNo: page,
    orderBy: "id",
    sortOrder: "desc",
    expiryTime: getCurrentTimeStemp(),
  };
};
export const fetchEventsData = async (page, searchText) => {
  try {
    const requestData = buildRequestBody(page, searchText);
    const decryptedResponse = await apiCall("admin/allEvents", requestData);
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

const buildExportAllEventsAndContributorListRequestBody = (
  addtionalRequestBodyData,
  page,
  searchText
) => {
  return {
    ...addtionalRequestBodyData,
    search: searchText,
    pageNo: page,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const exportAllEvents = async (page, searchText) => {
  try {
    const requestData = buildExportAllEventsAndContributorListRequestBody(
      page,
      searchText
    );
    const decryptedResponse = await apiCall(
      "transaction/export_all_events",
      requestData
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const fetchAllContributorListForEvent = async (
  eventId,
  page,
  searchText
) => {
  try {
    const addtionalRequestBodyData = {
      eventId: eventId,
    };
    const requestData = buildExportAllEventsAndContributorListRequestBody(
      addtionalRequestBodyData,
      page,
      searchText
    );
    const decryptedResponse = await apiCall(
      "event/contributorList",
      requestData
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

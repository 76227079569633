import React, { createContext, useState, useContext } from "react";

const FairContext = createContext();

export function FairProvider({ children }) {
  const [fairsData, setFairsData] = useState([]);

  return (
    <FairContext.Provider value={{ fairsData, setFairsData }}>
      {children}
    </FairContext.Provider>
  );
}

export function useFair() {
  return useContext(FairContext);
}

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../library/Helper";
const apiCall = async (url, requestData, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestData).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestData),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      return convertEncryptedToPlantext(encryptedResponse?.data?.data);
    }
    return encryptedResponse;
  } catch (error) {
    const err = convertEncryptedToPlantext(error?.response?.data?.data);
    throw err;
  }
};
const buildAddOrEditBusinessOfferApiRequestBody = (
  values,
  randNameImage,
  randNameCoverImage,
  id = undefined
) => {
  return {
    ...values,
    image: randNameImage,
    picturePath: randNameCoverImage,
    ...(id && { id }),

    expiryTime: getCurrentTimeStemp(),
  };
};

export const addOrEditBusinessOfferApi = async (
  values,
  randNameImage,
  randNameCoverImage,
  id
) => {
  try {
    const requestBody = buildAddOrEditBusinessOfferApiRequestBody(
      values,
      randNameImage,
      randNameCoverImage,
      id
    );
    const decryptedResponse = await apiCall(
      "offer/businessOffer/addUpdate",
      requestBody
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
const buildGetAllBusinessOffersApiRequestBody = (page) => {
  return {
    pageNo: page,
    expiryTime: getCurrentTimeStemp(),
  };
};
export const getAllBusinessOffersApi = async (page) => {
  try {
    const requestBody = buildGetAllBusinessOffersApiRequestBody(page);
    const decryptedResponse = await apiCall(
      "offer/businessOffer/offers",
      requestBody
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const deleteBusinessOfferApi = async (record) => {
  try {
    const decryptedResponse = await apiCall(
      "offer/businessOffer/delete/" + record.id,
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};


import { Space, Button } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosInstance from "../../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import moment from "moment";

const CashBack = () => {
  const { state } = useLocation();
  // const [record, setRecord] = useState([]);
  const { userId } = useParams();
  localStorage.setItem("userIdCashBack", userId);
  console.log(state);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  console.log(loading);
  const userIdCashBack = localStorage.getItem("userIdCashBack");
  const fetchData = async (userIdCashBack) => {
    console.log(userId);
    axiosInstance()
      .get("admin/v1/cashback/user/" + userIdCashBack)
      .then((response) => {
        // debugger;
        if (response?.status === 200) {
          let api_res = convertEncryptedToPlantext(response?.data?.data);
          setData(api_res ?? {});
          console.log("cashback", api_res);

          setLoading(false);
        }
      })
      .catch((error) => {
        // debugger;
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData(userIdCashBack);
  }, []);

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Cash-Back</h2>
        <div className="container">
          <Space
            direction="horizontal"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <Link
              to={{
                pathname: "/add-cash-back",
                state: state,
              }}
            >
              <Button
                type="primary"
                size="middle"
                disabled={state?.cashBackId ? state?.cashBackId : null}
              >
                Add Cash-Back
              </Button>
            </Link>
            <Link
              to={{
                pathname: "/edit-cash-back",
                state: data,
              }}
            >
              <Button type="primary" size="middle">
                Edit Cash-Back
              </Button>
            </Link>
          </Space>
          {Object.keys(data).length > 0 ? (
            <div
              className="modal-above"
              style={{
                // width: '70%',
                marginTop: "1rem",
                border: "0.5px solid rgb(231 231 241)",
                borderRadius: "10px ",
                boxShadow: "5px",
              }}
            >
              <div className="column1">
                <div className="column1__column">
                  <span className="column1__column__header">Up To Limit</span>
                  <span className="column1__column__footer">
                    {data?.upToLimit ? data?.upToLimit : 0}
                  </span>
                </div>
                <div className="column1__column">
                  <span className="column1__column__header">
                    Created Cash-Back
                  </span>
                  <span className="column1__column__footer">
                    {moment(data?.startAt)?.format("YYYY-MM-DD")
                      ? moment(data?.startAt)?.format("YYYY-MM-DD")
                      : "-"}
                  </span>
                  <span className="column1__column__header">
                    Active Cash-Back
                  </span>
                  <span className="column1__column__footer">
                    {data?.activeCashBack === true ? "Active" : "Inactive"}
                  </span>
                </div>
                <div className="column1__column"></div>
              </div>

              <div className="column2">
                <div className="column2__column">
                  <span className="column2__column__header">Percentage</span>
                  <span className="column2__column__footer">
                    {data?.percentage ? data?.percentage : 0}
                  </span>
                </div>

                <div className="column2__column">
                  <span className="column2__column__header">End Cash-Back</span>
                  <span className="column2__column__footer">
                    {" "}
                    {moment(data?.endAt)?.format("YYYY-MM-DD")
                      ? moment(data?.endAt)?.format("YYYY-MM-DD")
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default CashBack;

import {
  Button,
  Form,
  message,
  Input,
  Avatar,
  Row,
  Col,
  Checkbox,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import DragImage from "../form/DragImage";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { useDispatch, useSelector } from "react-redux";
import { getIndustry } from "../../../redux/Industry/actions";
import { getIndustryType } from "../../../redux/Industry-type/actions";
import { getSellingType } from "../../../redux/selling-type/actions";
import { Env, S3_URL } from "../../../constant";
import { updateBusinessUser } from "../../../KIWE-V1/Services/userAPI";
import "./editBusinessUser.css";
const EditBusinessUser = () => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const history = useHistory();
  const { state, pageNo } = useLocation();

  const {
    isAutoWithdrawal,
    studentCodeRequired,
    qrRequired,
    businessTransactionFees,
    website,
    taxIdNumber,
    typeOfBusiness,
    mobile,
    firstName,
    email,
    countryCode,
    businessRegistrationNumber,
    address,
    businessName,
    profilePicturePath,
    userId,
    industryTypeId,
    industryId,
    sellingTypeId,
  } = state;

  const dispatch = useDispatch();
  const sellingTypes = useSelector(
    (state) => state.sellingTypeReducer.allSellingType
  );
  const industry = useSelector((state) => state.industryReducer.allIndustry);
  console.log(sellingTypes, "sellingTypes", sellingTypeId);
  const industryType = useSelector(
    (state) => state.industryTypeReducer.allIndustryType
  );
  useEffect(() => {
    dispatch(getIndustry());
    dispatch(getIndustryType());
    dispatch(getSellingType());
  }, []);
  var sellingType;
  var industryTypeName;
  var industryName;
  if (industryTypeId) {
    industryTypeName = industryType?.filter((itm) => itm.id === industryTypeId);
  }
  if (industryId) {
    industryName = industry?.filter((itm) => itm.id === industryId);
  }

  if (sellingTypeId) {
    sellingType = sellingTypes?.filter((itm) => +itm?.id === sellingTypeId);
  }

  const buildUpdateBusinessUserData = (values) => {
    let industId, industTypeId, sellId;
    if (state) {
      industId = industry.find(
        (itm) => itm.industryName === values?.industryId
      )?.id;
      industTypeId = industryType.find(
        (itm) => itm.industryTypeName === values?.industryTypeId
      )?.id;
      sellId = sellingTypes.find((itm) => itm.id === values?.sellingTypeId)?.id;
    }
    let randNameImage = "";
    if (!(typeof values.profilePicturePath === "string")) {
      randNameImage = generateRandomFileName(values.profilePicturePath[0]);
      handleImageUpload(
        values.profilePicturePath[0].originFileObj,
        randNameImage,
        "kiweapp/" + Env + "/" + userId + "/profile_picture"
      );
    } else {
      randNameImage = values.profilePicturePath;
    }
    return { industId, industTypeId, sellId, randNameImage };
  };
  const handleUpdateBusinessUser = async (values) => {
    try {
      const { industId, industTypeId, sellId, randNameImage } =
        buildUpdateBusinessUserData(values);
      await updateBusinessUser(
        values,
        industId,
        industTypeId,
        sellId,
        randNameImage,
        userId
      );
      const messageSuccess = {
        className: "messageSuccess",
        content: "Business user updated successfully!",
      };
      message.success(messageSuccess);

      history.goBack();
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    if (userId) {
      form.setFieldsValue({ isAutoWithdrawal: isAutoWithdrawal });
      form.setFieldsValue({ studentCodeRequired: studentCodeRequired });
      form.setFieldsValue({ qrRequired: qrRequired });

      form.setFieldsValue({ businessTransactionFees: businessTransactionFees });
      form.setFieldsValue({ website: website });
      form.setFieldsValue({ taxIdNumber: taxIdNumber });
      form.setFieldsValue({ typeOfBusiness: typeOfBusiness });
      form.setFieldsValue({ firstName: firstName });
      form.setFieldsValue({ mobile: mobile });
      form.setFieldsValue({ email: email });
      form.setFieldsValue({ countryCode: countryCode });
      form.setFieldsValue({
        businessRegistrationNumber: businessRegistrationNumber,
      });
      form.setFieldsValue({ address: address });
      form.setFieldsValue({ businessName: businessName });
      form.setFieldsValue({
        industryId: industryName ? industryName[0]?.id : null,
      });
      form.setFieldsValue({
        industryTypeId: industryTypeName ? industryTypeName[0]?.id : null,
      });
      form.setFieldsValue({
        sellingTypeId: sellingType ? sellingType[0]?.id : null,
      });
      form.setFieldsValue({ profilePicturePath: profilePicturePath });
    }
  }, [userId, sellingType, industryTypeName, industryName]);

  const handleCancel = () => {
    form.resetFields();
    history.goBack();
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="profile__form" style={{ padding: "0rem" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpdateBusinessUser}
            autoComplete="off"
            initialValues={{
              industryId: industryName ? industryName[0]?.industryName : null,
              industryTypeId: industryTypeName
                ? industryTypeName[0]?.industryTypeName
                : null,
              sellingTypeId: sellingType ? sellingType[0]?.type : null,
            }}
          >
            <div className="container__topheader">
              <h2>Business Details</h2>
              <div style={{ display: "flex", marginTop: "1rem" }}>
                <Form.Item>
                  <Button
                    size="middle"
                    htmlType="submit"
                    className="profile__btns__save"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    size="middle"
                    className="profile__btns__cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </div>{" "}
            </div>
            <div className="container" style={{ padding: "2.2rem" }}>
              <Form.Item className="profile__form__image">
                <div className="header">
                  <Avatar
                    src={
                      S3_URL + userId + "/profile_picture/" + profilePicturePath
                    }
                  />

                  <DragImage nam1="profilePicturePath" normFile={normFile} />
                </div>
                <span style={{ color: "red" }}>
                  Note :- Please upload image in size of "512 * 512" for better
                  resolution
                </span>
              </Form.Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name={"email"} label="Email">
                    <Input className="inputs-business" disabled />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      { required: true, message: "Please input your Address!" },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>
                  <Form.Item
                    name="firstName"
                    label="Username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!",
                      },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>
                  <Form.Item
                    name="businessName"
                    label="Business Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Business Name!",
                      },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>
                  <Form.Item
                    name="industryTypeId"
                    label="Industry Type"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Industry Type!",
                      },
                    ]}
                    className="inputs-business heightSelect"
                  >
                    <Select
                      placeholder="Select Industry-Type Name"
                      style={{
                        width: "100%",
                      }}
                      className="inputs-business heightSelect select-p valueSelect"
                    >
                      {industryType.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.industryTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Industry"
                    name="industryId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Industry!",
                      },
                    ]}
                    className="inputs-business heightSelect"
                  >
                    <Select
                      placeholder="Select Industry Name"
                      style={{
                        width: "100%",
                      }}
                      className="inputs-business heightSelect select-p valueSelect"
                    >
                      {industry.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.industryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>{" "}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="taxIdNumber"
                    label="Tax ID Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Tax ID Number!",
                      },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>
                  <Form.Item
                    label="Business Transation Number"
                    name="businessRegistrationNumber"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your Business Transaction Number!",
                      },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>

                  <Form.Item
                    name="businessTransactionFees"
                    label="Business Transaction Fees%"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input your Business Transaction Fees%!",
                      },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>
                  <div style={{ display: "flex" }}>
                    <Form.Item
                      name="countryCode"
                      label="Country Code"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Enter Country Code!',
                      //   },
                      // ]}
                    >
                      <Input
                        className="inputs-business"
                        style={{ width: "70%" }}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      name="mobile"
                      label="Mobile"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Enter Mobile!',
                      //   },
                      // ]}
                      style={{ marginLeft: "-3rem" }}
                    >
                      <Input
                        className="inputs-business"
                        style={{ width: "235%" }}
                        disabled
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="typeOfBusiness"
                    label="Business Type"
                    className="heightSelect"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Business Type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Industry-Type Name"
                      style={{
                        width: "100%",
                      }}
                      //   onChange={handleChange}
                      className="inputs-business select-p valueSelect"
                    >
                      <Option value="freelancer">Freelancer</Option>
                      <Option value="company">Company</Option>
                      <Option value="startup">Startup</Option>
                    </Select>
                  </Form.Item>
                  {typeOfBusiness === "freelancer" ||
                  typeOfBusiness === "startup" ? (
                    <Form.Item
                      name="sellingTypeId"
                      label="Selling Type"
                      className="heightSelect"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Please input your Business Type!',
                      //   },
                      // ]}
                    >
                      <Select
                        placeholder="Select Selling Type"
                        style={{
                          width: "100%",
                        }}
                        //   onChange={handleChange1}
                        className="inputs-business select-p valueSelect"
                      >
                        {sellingTypes.map((item) => (
                          <Option
                            //  key={item.id}
                            value={item.id}
                          >
                            {item.type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  <Form.Item
                    name="website"
                    label="Website"
                    rules={[
                      { required: true, message: "Please input your Website!" },
                    ]}
                  >
                    <Input className="inputs-business" />
                  </Form.Item>
                  <Form.Item name="isAutoWithdrawal" valuePropName="checked">
                    <Checkbox className="remember">
                      Allow Auto Withdrawal
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="studentCodeRequired" valuePropName="checked">
                    <Checkbox className="remember">
                      Require student code to pay
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="qrRequired" valuePropName="checked">
                    <Checkbox className="remember">
                      Payable only via QR Code
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditBusinessUser;

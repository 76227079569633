import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchKiwePromotionData = async (page, searchText) => {
  try {
    const decryptedResponse = await apiCall(
      `promotions?page=${page}&code=${searchText}`,
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const deleteKiwePromotionAPI = async (record) => {
  try {
    const decryptedResponse = await apiCall(
      `promotions/${record?.id}`,
      {},
      "delete"
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildAddAndEditKiwePromotionRequestBody = (
  addtionalRequestBodyData,
  values,
  startDate,
  endDate,
  scope
) => {
  return {
    ...addtionalRequestBodyData,
    startDate: startDate,
    endDate: endDate,
    scope: scope,
    description: values?.description,
    amount: values?.amount,
    active: values?.active,
  };
};
export const addKiwePromotionAPI = async (
  values,
  startDate,
  endDate,
  code,
  scope
) => {
  try {
    const addtionalRequestBodyData = { code: code };
    const requestBody = buildAddAndEditKiwePromotionRequestBody(
      addtionalRequestBodyData,
      values,
      startDate,
      endDate,
      scope
    );

    const decryptedResponse = await apiCall("promotions", requestBody);

    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const editKiwePromotionAPI = async (
  promotionId,
  values,
  startDate,
  endDate,
  scope
) => {
  try {
    const addtionalRequestBodyData = { id: promotionId };
    const requestBody = buildAddAndEditKiwePromotionRequestBody(
      addtionalRequestBodyData,
      values,
      startDate,
      endDate,
      scope
    );

    const decryptedResponse = await apiCall("promotions", requestBody, "put");

    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

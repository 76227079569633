import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  dashboard,
  insights,
  busniessUsers,
  content,
  events,
  individualUsers,
  notification,
  offerList,
  transactionFees,
  withdrawalList,
  withdrawalType,
  admin,
  // arrow,
  dashboardWhite,
  insightsWhite,
  adminWhite,
  individualWhite,
  transactionWhite,
  businessWhite,
  withdrawalWhite,
  withdrawalListWhite,
  offerListWhite,
  eventWhite,
  contentWhite,
  contentwWhite,
} from "../components/shared/images";
import businessOwnerBlack from "../assets/images/businessOwner.svg";
import businessOwnerWhite from "../assets/images/businessOwnerWhite.svg";
import transactionFeesBlack from "../assets/images/transactionFees.svg";
import transactionFeesWhite from "../assets/images/transactionFeesWhite.svg";
import adminBlack from "../assets/images/adminBlack.svg";
import adminWhiteS from "../assets/images/adminWhiteS.svg";
import businessRequest from "../assets/images/businessRequest.svg";
import businessRequestWhite from "../assets/images/businessRequestWhite.svg";
import giftCardBlue from "../assets/images/gift-cardBlue.svg";
import giftCardWhite from "../assets/images/gift-cardWhite.svg";
import industryBlue from "../assets/images/IndustryBlue.svg";
import industryWhite from "../assets/images/industryWhite.svg";
import contentBlue from "../assets/images/contentBlue.svg";
import contentBWhite from "../assets/images/contentBWhite.svg";
import homeBannerBlue from "../assets/images/homeBannerBlue.svg";
import homeBannerWhite from "../assets/images/homeBannerWhite.svg";
import perksListBlue from "../assets/images/perksListBlue.svg";
import perksListWhite from "../assets/images/perksListWhite.svg";
import financeBOBlue from "../assets/images/financeBOBlue.svg";
import financeBOWhite from "../assets/images/financeBOWhite.svg";
import kiwePromBlue from "../assets/images/kiwePromBlue.svg";
import kiwePromWhite from "../assets/images/kiwePromWhite.svg";
import valuPromBlue from "../assets/images/valuePromBlue.svg";
import valuPromWhite from "../assets/images/valuePromWhite.svg";
import sellingTypeBlue from "../assets/images/sellingTypeBlue.svg";
import sellingTypeWhite from "../assets/images/sellingTypeWhite.svg";
import industryTypeBlue from "../assets/images/industryTypeBlue.svg";
import industryTypeWhite from "../assets/images/industryTypeWhite.svg";
import arrowblue from "../assets/images/arrowblue.svg";
import arrow from "../assets/images/arrow.svg";
import moneyTips from "../assets/images/moneyTips.svg";
import moneyTipsBlue from "../assets/images/moneyTipsBlue.svg";
import blog from "../assets/images/blog.svg";
import blogBlue from "../assets/images/blogBlue.svg";
import quiz from "../assets/images/quiz.svg";
import quizBlue from "../assets/images/quizBlue.svg";
import activeUser from "../assets/images/activeUser.svg";
import activeUserBlue from "../assets/images/activeUserBlue.svg";
import onBoardingUser from "../assets/images/onBoardingUser.svg";
import onBoardingUserBlue from "../assets/images/onBoardingUserBlue.svg";
import level from "../assets/images/levelBlack.svg";
import levelBlue from "../assets/images/levelBlue.svg";
import merchantBuildingBlack from "../assets/images/merchantBuildingBlack.svg";
import merchantBuildingBlue from "../assets/images/merchantBuildingBlue.svg";
import disputeBlack from "../assets/images/disputeBlack.svg";
import disputeBlue from "../assets/images/disputeBlue.svg";
import fairBlack from "../assets/images/fairBlack.svg";
import fairBlue from "../assets/images/fairBlue.svg";
import topicBlack from "../assets/images/topicBlack.svg";
import topicBlue from "../assets/images/topicBlue.svg";
import payHubBlack from "../assets/images/payHubBlack.svg";
import payHubBlue from "../assets/images/payHubBlue.svg";

import { useLocation } from "react-router-dom";
const TopicMenu = ({ topics, sections }) => {
  const location = useLocation();
  let userIdRole = localStorage.getItem("typeRoleAdmin");
  let roleAdminV2 = userIdRole === "newCMS";
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  let superAdmin = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "MARKETING"
  );
  let adminn = roles.find((itm) => itm === "SUPER_ADMIN" || itm === "ADMIN");
  let businessOwner = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "ADMIN" || itm === "OPERATIONS"
  );

  let indivdual = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "CUSTOMER_SUPPORT"
  );
  let transaction = roles.find(
    (itm) =>
      itm === "SUPER_ADMIN" || itm === "CUSTOMER_SUPPORT" || itm === "FINANCES"
  );
  let busin = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "OPERATIONS" || itm === "BUSINESS"
  );
  let financ = roles.find((itm) => itm === "SUPER_ADMIN" || itm === "FINANCES");
  let financc = roles.find((itm) => itm === "SUPER_ADMIN");

  let financW = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "ADMIN" || itm === "FINANCES"
  );

  let notify = roles.find(
    (itm) =>
      itm === "SUPER_ADMIN" ||
      itm === "BUSINESS" ||
      itm === "MARKETING" ||
      itm === "ADMIN"
  );
  let indust = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "OPERATIONS"
  );
  let gift = roles.find(
    (itm) => itm === "SUPER_ADMIN" || itm === "MARKETING" || itm === "ADMIN"
  );
  const styledTopics = [];
  const iconss = [
    dashboard,
    adminn ? insights : null,

    roleAdminV2
      ? null
      : roles.find((itm) => itm === "SUPER_ADMIN")
      ? events
      : roles.find((itm) => itm === "FINANCES")
      ? null
      : events,

    roleAdminV2 ? null : financc ? withdrawalType : null,
    financc || indivdual ? sections.Individuals : null,

    roleAdminV2 ? null : indivdual ? individualUsers : null,

    roleAdminV2 ? activeUser : null,

    roleAdminV2 ? sections.Rewards : null,
    roleAdminV2 ? level : null,
    roleAdminV2 ? merchantBuildingBlack : null,
    roleAdminV2 ? null : adminn ? kiwePromBlue : null,
    roleAdminV2 ? null : adminn ? valuPromBlue : null,
    roleAdminV2 ? sections.PayHub : null,
    roleAdminV2 ? payHubBlack : null,
    roleAdminV2
      ? null
      : busin || businessOwner || financW || indust || adminn
      ? sections.Business
      : null,
    roleAdminV2 ? null : busin ? busniessUsers : null,
    roleAdminV2 ? null : businessOwner ? businessOwnerBlack : null,
    roleAdminV2 ? null : financW ? financeBOBlue : null,
    // indust ? industryBlue : null,
    roleAdminV2 ? null : adminn ? industryTypeBlue : null,
    // indust ? sellingTypeBlue : null,
    roleAdminV2 ? sections.Disputes : null,
    roleAdminV2 ? disputeBlack : null,
    roleAdminV2 ? sections.Support : null,
    roleAdminV2 ? blog : null,
    roleAdminV2 ? topicBlack : null,
    roleAdminV2
      ? null
      : transaction || financW || financ
      ? sections.Transactions
      : null,
    roleAdminV2 ? null : transaction ? transactionFees : null,
    roleAdminV2 ? null : financ ? transactionFeesBlack : null,
    roleAdminV2 ? null : financW ? withdrawalList : null,

    notify || adminn || gift ? sections.Marketing : null,
    roleAdminV2 ? null : fairBlack,
    roleAdminV2 ? homeBannerBlue : null,
    roleAdminV2 ? null : notify ? notification : null,
    roleAdminV2 ? null : notify ? homeBannerBlue : null,

    roleAdminV2 ? null : notify ? perksListBlue : null,
    roleAdminV2 ? null : notify ? homeBannerBlue : null,
    roleAdminV2 ? null : adminn ? businessRequest : null,
    roleAdminV2 ? null : gift ? giftCardBlue : null,
    roleAdminV2 ? null : superAdmin ? quiz : null,
    roleAdminV2 ? null : superAdmin ? blog : null,
    roleAdminV2 ? null : superAdmin ? moneyTips : null,
    roleAdminV2 ? null : gift ? moneyTips : null,

    roleAdminV2 ? null : adminn ? sections.Bills : null,
    roleAdminV2 ? null : businessOwner ? withdrawalList : null,
    roleAdminV2 ? null : adminn ? individualUsers : null,
    roleAdminV2 ? null : adminn ? admin : null,
    roleAdminV2 ? null : adminn ? admin : null,

  ];
  //----------------------
  const whiteIconss = [
    dashboardWhite,
    adminn ? insightsWhite : null,

    roleAdminV2
      ? null
      : roles.find((itm) => itm === "SUPER_ADMIN")
      ? eventWhite
      : roles.find((itm) => itm === "FINANCES")
      ? null
      : eventWhite,

    roleAdminV2 ? null : financc ? withdrawalWhite : null,
    financc || indivdual ? sections.Individuals : null,

    roleAdminV2 ? null : indivdual ? individualWhite : null,

    roleAdminV2 ? activeUserBlue : null,

    roleAdminV2 ? sections.Rewards : null,
    roleAdminV2 ? levelBlue : null,
    roleAdminV2 ? merchantBuildingBlue : null,
    roleAdminV2 ? null : adminn ? kiwePromWhite : null,
    roleAdminV2 ? null : adminn ? valuPromWhite : null,
    roleAdminV2 ? sections.PayHub : null,
    roleAdminV2 ? payHubBlue : null,
    roleAdminV2
      ? null
      : busin || businessOwner || financW || indust || adminn
      ? sections.Business
      : null,

    roleAdminV2 ? null : busin ? businessWhite : null,
    roleAdminV2 ? null : businessOwner ? businessOwnerWhite : null,
    roleAdminV2 ? null : financW ? financeBOWhite : null,
    // indust ? industryWhite : null,
    roleAdminV2 ? null : adminn ? industryTypeWhite : null,
    // indust ? sellingTypeWhite : null,
    roleAdminV2 ? sections.Disputes : null,
    roleAdminV2 ? disputeBlue : null,
    roleAdminV2 ? sections.Support : null,
    roleAdminV2 ? blogBlue : null,
    roleAdminV2 ? topicBlue : null,
    roleAdminV2
      ? null
      : transaction || financW || financ
      ? sections.Transactions
      : null,
    roleAdminV2 ? null : transaction ? transactionWhite : null,
    roleAdminV2 ? null : financ ? transactionFeesWhite : null,
    roleAdminV2 ? null : financW ? withdrawalListWhite : null,

    notify || adminn || gift ? sections.Marketing : null,
    roleAdminV2 ? null : fairBlue,
    roleAdminV2 ? homeBannerWhite : null,

    roleAdminV2 ? null : notify ? contentWhite : null,
    roleAdminV2 ? null : notify ? homeBannerWhite : null,
    roleAdminV2 ? null : notify ? perksListWhite : null,
    roleAdminV2 ? null : notify ? homeBannerWhite : null,
    roleAdminV2 ? null : adminn ? businessRequestWhite : null,
    roleAdminV2 ? null : gift ? giftCardWhite : null,
    roleAdminV2 ? null : superAdmin ? quizBlue : null,
    roleAdminV2 ? null : superAdmin ? blogBlue : null,
    roleAdminV2 ? null : superAdmin ? moneyTipsBlue : null,
    roleAdminV2 ? null : gift ? moneyTipsBlue : null,

    roleAdminV2 ? null : adminn ? sections.Bills : null,
    roleAdminV2 ? null : businessOwner ? withdrawalListWhite : null,
    roleAdminV2 ? null : adminn ? individualWhite : null,
    roleAdminV2 ? null : adminn ? adminWhite : null,
    roleAdminV2 ? null : adminn ? adminWhite : null,
  ];

  const listt = [
    " Dashboard",
    adminn ? " Insights" : null,

    roleAdminV2
      ? null
      : roles.find((itm) => itm === "SUPER_ADMIN")
      ? "Events"
      : roles.find((itm) => itm === "FINANCES")
      ? null
      : "Events",

    roleAdminV2 ? null : financc ? "Withdrawal Type" : null,
    financc || indivdual ? sections.Individuals : null,

    roleAdminV2 ? null : indivdual ? " Individual Users" : null,
    roleAdminV2 ? "Card Users" : null,

    roleAdminV2 ? sections.Rewards : null,
    roleAdminV2 ? "Level Rewards" : null,
    roleAdminV2 ? "Merchant Reward" : null,
    roleAdminV2 ? null : adminn ? "Kiwe Promotions" : null,
    roleAdminV2 ? null : adminn ? "Valu Promotion" : null,
    roleAdminV2 ? sections.PayHub : null,
    roleAdminV2 ? "Pay Hubs" : null,
    roleAdminV2
      ? null
      : busin || businessOwner || financW || indust || adminn
      ? sections.Business
      : null,
    roleAdminV2 ? null : busin ? " Business Users" : null,
    roleAdminV2 ? null : businessOwner ? " Business Owner" : null,
    roleAdminV2 ? null : financW ? "Finance Business" : null,
    // indust ? "Industry" : null,
    roleAdminV2 ? null : adminn ? "Industry Types" : null,
    // indust ? "Selling Type" : null,
    roleAdminV2 ? sections.Disputes : null,
    roleAdminV2 ? "Disputes" : null,
    roleAdminV2 ? sections.Support : null,
    roleAdminV2 ? "Articles" : null,
    roleAdminV2 ? "FAQ Topics" : null,
    roleAdminV2
      ? null
      : transaction || financW || financ
      ? sections.Transactions
      : null,
    roleAdminV2 ? null : transaction ? "Transaction List" : null,
    roleAdminV2 ? null : financ ? "Transaction Fees" : null,
    roleAdminV2 ? null : financW ? "Withdrawal List" : null,
    notify || adminn || gift ? sections.Marketing : null,
    roleAdminV2 ? null : "Fairs",
    roleAdminV2 ? "Announcements" : null,

    roleAdminV2 ? null : notify ? "Notification" : null,
    roleAdminV2 ? null : notify ? "Announcements" : null,
    roleAdminV2 ? null : notify ? "Perks List" : null,
    roleAdminV2 ? null : notify ? "Home Banner" : null,
    roleAdminV2 ? null : adminn ? "Business Requests" : null,
    roleAdminV2 ? null : gift ? "Gift Card" : null,
    roleAdminV2 ? null : superAdmin ? "Quiz" : null,
    roleAdminV2 ? null : superAdmin ? "Blog" : null,
    roleAdminV2 ? null : superAdmin ? "Money Tips" : null,
    roleAdminV2 ? null : gift ? "Savings" : null,

    roleAdminV2 ? null : adminn ? sections.Bills : null,
    roleAdminV2 ? null : businessOwner ? "OTP" : null,
    roleAdminV2 ? null : adminn ? "Company" : null,
    roleAdminV2 ? null : adminn ? "Service Type" : null,
    roleAdminV2 ? null : adminn ? "Service Code" : null,
  ];
  const pathh = [
    "/dashboard",
    adminn ? "/insights" : null,
    roleAdminV2
      ? null
      : roles.find((itm) => itm === "SUPER_ADMIN")
      ? "/events/1"
      : roles.find((itm) => itm === "FINANCES")
      ? null
      : "/events/1",

    roleAdminV2 ? null : financc ? "/withdrawal-type" : null,
    financc || indivdual ? sections.Individuals : null,

    roleAdminV2 ? null : indivdual ? "/indivdual-user/" : null,
    roleAdminV2 ? "/card-user/" : null,

    roleAdminV2 ? sections.Rewards : null,

    roleAdminV2 ? "/level-rewards" : null,
    roleAdminV2 ? "/merchant-reward" : null,
    roleAdminV2 ? null : adminn ? "/view_promotions/" : null,
    roleAdminV2 ? null : adminn ? "/promotion-value/1" : null,
    roleAdminV2 ? sections.PayHub : null,
    roleAdminV2 ? "/hubs/" : null,
    roleAdminV2
      ? null
      : busin || businessOwner || financW || indust || adminn
      ? sections.Business
      : null,
    roleAdminV2 ? null : busin ? "/business-user/" : null,
    roleAdminV2 ? null : businessOwner ? "/business-owner/1" : null,
    roleAdminV2 ? null : financW ? "/finance-business-owner" : null,
    // indust ? "/industry" : null,
    roleAdminV2 ? null : adminn ? "/industry-type" : null,
    // indust ? "/selling-type" : null,
    roleAdminV2 ? sections.Disputes : null,
    roleAdminV2 ? "/disputes/" : null,
    roleAdminV2 ? sections.Support : null,
    roleAdminV2 ? "/articles/" : null,
    roleAdminV2 ? "/topics/" : null,
    roleAdminV2
      ? null
      : transaction || financ || financW
      ? sections.Transactions
      : null,
    roleAdminV2 ? null : transaction ? "/transactions" : null,
    roleAdminV2 ? null : financ ? "/transaction-fees" : null,
    roleAdminV2 ? null : financW ? "/withdrawal-list" : null,

    notify || adminn || gift ? sections.Marketing : null,
    roleAdminV2 ? null : "/fairs/",
    roleAdminV2 ? "/announcement/" : null,

    roleAdminV2 ? null : notify ? "/notification/1" : null,
    roleAdminV2 ? null : notify ? "/old-announcement/" : null,
    roleAdminV2 ? null : notify ? "/business-offer/1" : null,
    roleAdminV2 ? null : notify ? "/offer-list" : null,
    roleAdminV2 ? null : adminn ? "/notification-business" : null,
    roleAdminV2 ? null : gift ? "/gift-card" : null,
    roleAdminV2 ? null : superAdmin ? "/quiz" : null,
    roleAdminV2 ? null : superAdmin ? "/blog" : null,
    roleAdminV2 ? null : superAdmin ? "/money-learn" : null,
    roleAdminV2 ? null : gift ? "/savings/1" : null,

    roleAdminV2 ? null : adminn ? sections.Bills : null,
    roleAdminV2 ? null : businessOwner ? "/otp" : null,
    roleAdminV2 ? null : adminn ? "/company" : null,
    roleAdminV2 ? null : adminn ? "/service-type" : null,
    roleAdminV2 ? null : adminn ? "/service-code" : null,
  ];
  const path = pathh.filter((itm) => itm !== null);
  const list = listt.filter((itm) => itm !== null);
  const icons = iconss.filter((itm) => itm !== null);
  const whiteicon = whiteIconss.filter((itm) => itm !== null);

  // console.log(path, list, icons);
  topics
    .filter((itm) => itm !== null)
    .forEach((topic, index) => {
      if (
        topic === sections.Rewards ||
        topic === sections.PayHub ||
        topic === sections.Individuals ||
        topic === sections.Business ||
        topic === sections.Disputes ||
        topic === sections.Support ||
        topic === sections.Marketing ||
        topic === sections.Transactions ||
        topic === sections.Bills
      ) {
        styledTopics.push(
          <Menu.Item key={topic} className="inline_text">
            <span style={{ fontWeight: "bold" }}>{topic}</span>
          </Menu.Item>
        );
      } else {
        styledTopics.push(
          <Menu.Item
            key={path[index]}
            className={`ico ${
              location.pathname !== path[index] ? "ico_bg" : "ico_color"
            }`}
          >
            <div className="wrapIco">
              <img
                src={
                  location.pathname === path[index]
                    ? whiteicon[index]
                    : icons[index]
                  // icons[index]
                }
                style={{ marginRight: ".5rem" }}
                alt="icon"
              />
              {/* <span className={icons[index]} style={{ margin: '0 .5rem' }}></span> */}
              <span style={{ fontWeight: "bold" }}> {list[index]}</span>
            </div>

            <Link to={path[index]} />
            <img
              src={location.pathname === path[index] ? arrowblue : arrow}
              alt=""
              style={{ margin: "0px 0.5rem", display: "none" }}
            />
            {/* <img src={arrow} style={{ marginLeft: '1rem' }} /> */}
          </Menu.Item>
        );
      }
    });

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[location.pathname]}
      className="color-Item"
    >
      {styledTopics}
    </Menu>
  );
};
export default TopicMenu;

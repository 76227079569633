import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Switch,
  message,
  Row,
  Col,
} from "antd";
import Totop from "../../shared/TopUp";
import arrowRight from "../../../assets/images/icons8-right-arrow-100 1.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  addKiwePromotionAPI,
  editKiwePromotionAPI,
} from "../../../KIWE-V1/Services/kiwePromotionApi";
import { useState } from "react";
import { useKiwePromotion } from "../../../contextAPi/KiwePromotion/KiwePromotionContext";

const PromotionUser = (props) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const flag = props?.location?.state?.edit;
  const promotionId = props?.location?.state?.id;
  const promotionRecord = props?.location?.state?.record;
  const { kiwePromotion, setKiwePromotion } = useKiwePromotion();

  const handleAddAndEditKiwePromotion = (values) => {
    let startDate = undefined;
    let endDate = undefined;
    if (values.Picker) {
      const pickerStartDate = values.Picker[0];
      const pickerEndDate = values.Picker[1];
      startDate = pickerStartDate.format("YYYY-MM-DD HH:mm:ss");
      endDate = pickerEndDate.format("YYYY-MM-DD HH:mm:ss");
    }
    const code = `CODE ${Math.floor(Math.random() * 1000000)}`;
    const scope = "INDIVIDUAL";

    if (flag) {
      editKiwePromotion(promotionId, values, startDate, endDate, scope);
    } else {
      addKiwePromotion(values, startDate, endDate, code, scope);
    }
  };

  const editKiwePromotion = async (
    promotionId,
    values,
    startDate,
    endDate,
    scope
  ) => {
    try {
      setLoading(true);
      const decryptedResponse = await editKiwePromotionAPI(
        promotionId,
        values,
        startDate,
        endDate,
        scope
      );
      setLoading(false);
      const newData = { ...decryptedResponse?.data };
      setKiwePromotion((prevData) => [...prevData, newData]);
      const configSuccess = {
        className: "messageSuccess",
        content: "Edited Kiwe promotion Successfully!",
      };
      message.success(configSuccess);
      history.push("/view_promotions");
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: "Something went wrong please try again",
      };
      message.error(configError);
    }
  };
  const addKiwePromotion = async (values, startDate, endDate, code, scope) => {
    try {
      setLoading(true);
      const decryptedResponse = await addKiwePromotionAPI(
        values,
        startDate,
        endDate,
        code,
        scope
      );
      setLoading(false);
      const newData = { ...decryptedResponse?.data };
      setKiwePromotion((prevData) => [...prevData, newData]);
      const configSuccess = {
        className: "messageSuccess",
        content: "Added Kiwe promotion Successfully!",
      };
      message.success(configSuccess);
      history.push("/view_promotions");
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: "Something went wrong please try again",
      };
      message.error(configError);
    }
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Promotions</h2>
        <div className="container">
          <div
            className="container"
            style={{ border: "none", padding: "2rem" }}
          >
            <Form
              form={form}
              name="prom"
              onFinish={handleAddAndEditKiwePromotion}
              layout="vertical"
              initialValues={{
                amount: flag ? promotionRecord.amount : 50,
                active: flag ? promotionRecord.active : false,
                description: flag ? promotionRecord.description : "",
                Picker: flag
                  ? [
                      moment(promotionRecord.startDate),
                      moment(promotionRecord.endDate),
                    ]
                  : null,
              }}
            >
              <Form.Item
                label="Program Desc"
                name="description"
                rules={[
                  {
                    required: flag ? false : true,
                    message: "Please input the Description",
                  },
                ]}
              >
                <TextArea style={{ marginTop: "20px" }} rows={2} />
              </Form.Item>
              <Row gutter={16} className="rowMoneyLearn">
                <Col span={12}>
                  <Form.Item
                    name="Picker"
                    label="Select Date"
                    rules={[
                      {
                        required: flag ? false : true,
                        message: "Please input the Date",
                      },
                    ]}
                  >
                    <RangePicker
                      showTime
                      separator={
                        <img
                          src={arrowRight}
                          alt="-"
                          style={{ width: "15px", height: "15px" }}
                        />
                      }
                      style={{
                        height: "2.8rem",
                        marginTop: "20px",
                        width: "90%",
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total amount to preload"
                    name="amount"
                    rules={[
                      {
                        required: flag ? false : true,
                        message: "Please input an amount",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        marginTop: "20px",
                        borderRadius: "25px",
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Activate the promotion"
                valuePropName="checked"
                name="active"
              >
                <Switch style={{ marginTop: "20px" }} />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    marginTop: "1rem",
                    padding: "1.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Send
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Totop />
      </div>
    </>
  );
};

export default PromotionUser;

import { Input, Button, Form, message, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import DragImage from "../form/DragImage";

import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { BUSINESS_OFFER_ALBUM_NAME } from "../../../constant";

import { useParams } from "react-router-dom";

const Add = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const history = useHistory();
  const { pageNo } = useParams();
  const onFinish = (values) => {
    console.log(values);
    const randNameImage = generateRandomFileName(values?.image[0]);
    const randNameCoverImage = generateRandomFileName(values?.picturePath[0]);

    handleImageUpload(
      values?.image[0].originFileObj,
      randNameImage,
      BUSINESS_OFFER_ALBUM_NAME
    );
    handleImageUpload(
      values?.picturePath[0].originFileObj,
      randNameCoverImage,
      BUSINESS_OFFER_ALBUM_NAME
    );
    axiosInstance()
      .post("offer/businessOffer/addUpdate", {
        data: convertPlantextToEncrypted({
          code: values.code,
          discount: values.discount,
          description: values.description,
          website: values.website,
          name: values.name,
          image: randNameImage,
          picturePath: randNameCoverImage,

          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response.data.data);
          console.log("api_response", api_res);
          message.success("Submit success!");

          history.push(`/business-offer/${+pageNo}`);
        }
      })
      .catch((error) => {
        const err = convertEncryptedToPlantext(error?.response?.data?.data);
        message.error(err.message);
      });
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleCancel = () => {
    form.resetFields();
    history.push(`/money-learn`);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="container__topheader">
          <h2>Add Masterclasses</h2>
          <div
            className="profile__btns"
            style={{ display: "flex", marginTop: "1rem" }}
          >
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="profile__btns__save"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                size="middle"
                className="profile__btns__cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="container profile">
          <div className="profile__form modal" style={{ padding: "1rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={16} className="rowMoneyLearn">
                <Col span={12}>
                  {" "}
                  <Form.Item className="offerImage">
                    <DragImage
                      normFile={normFile}
                      className="labelImage"
                      label="Background"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Offer Image!",
                        },
                      ]}
                      hasFeedback
                      nam1="image"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className="offerImage">
                    <DragImage
                      normFile={normFile}
                      nam1="picturePath"
                      label="Logo"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Logo!",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="name"
                label="Title"
                hasFeedback
                rules={[{ required: true, message: "Please input your Name!" }]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>

              <Form.Item
                name="description"
                label="Body Text"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Body Text!" },
                ]}
                className="offer-name"
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="website"
                label="Website"
                hasFeedback
                rules={[
                  { required: true, message: "Please input your Website!" },
                ]}
                className="offer-name"
              >
                <Input className="input-border" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;

import { Input, Button, Form, message, Modal, Switch } from "antd";
import DragImage from "../form/DragImage";
import { useEffect, useState } from "react";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { INDUSTRY_TYPE_ALBUM_NAME } from "../../../constant";
import { addAndUpdateIndustryTypeAPI } from "../../../KIWE-V1/Services/industryTypeApi.js";
const Add = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState({
    industryTypeName: record?.industryTypeName,
    industryTypeNameAr: record?.industryTypeNameAr,
    industryTypeColor: record?.industryTypeColor,
    industryTypeLogo: record?.industryTypeLogo,
    isHub: record?.isHub,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        industryTypeName: record?.industryTypeName,
        industryTypeNameAr: record?.industryTypeNameAr,
        industryTypeColor: record?.industryTypeColor,
        industryTypeLogo: record?.industryTypeLogo,
        isHub: record?.isHub,
      });
    } else {
      form.setFieldsValue({ industryTypeName: "" });
      form.setFieldsValue({ industryTypeNameAr: "" });
      form.setFieldsValue({ industryTypeColor: "" });
      form.setFieldsValue({ industryTypeLogo: "" });
      form.setFieldsValue({ isHub: "" });
    }
  }, [record, form, isModalVisible]);

  const addandUpdateIndustryType = async (values) => {
    try {
      setLoading(true);
      let randName = "";

      if (record?.id) {
        if (
          Array.isArray(values?.industryTypeLogo) &&
          values.industryTypeLogo.length > 0
        ) {
          randName = generateRandomFileName(values?.industryTypeLogo[0]);
          if (!randName.includes("undefined")) {
            await handleImageUpload(
              values?.industryTypeLogo[0]?.originFileObj,
              randName,
              INDUSTRY_TYPE_ALBUM_NAME
            );
            record["industryTypeLogo"] = randName;
          } else {
            randName = null;
            record["industryTypeLogo"] = null;
          }
        }
      } else {
        if (
          Array.isArray(values?.industryTypeLogo) &&
          values.industryTypeLogo.length > 0
        ) {
          randName = generateRandomFileName(values?.industryTypeLogo[0]);
          await handleImageUpload(
            values?.industryTypeLogo[0]?.originFileObj,
            randName,
            INDUSTRY_TYPE_ALBUM_NAME
          );
        } else if (typeof values?.industryTypeLogo === "undefined") {
          randName = null;
        } else {
          randName = values?.industryTypeLogo;
        }
      }
      const apiResponse = await addAndUpdateIndustryTypeAPI(
        values,
        randName,
        record
      );

      if (apiResponse) {
        setLoading(false);
        if (record) {
          message.success("Industry Type Edited Successfully!");
        } else {
          message.success("Industry Type Added Successfully!");
        }

        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);

      message.error(error.message);
    }
  };

  useEffect(() => {
    if (record?.id) {
      form.setFieldsValue({ industryTypeName: record?.industryTypeName });
      form.setFieldsValue({ industryTypeNameAr: record?.industryTypeNameAr });
      form.setFieldsValue({ industryTypeColor: record?.industryTypeColor });
      form.setFieldsValue({ industryTypeLogo: record?.industryTypeLogo });
      form.setFieldsValue({ isHub: record?.isHub });
    }
  }, [record, form]);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const defaultFileList = record?.industryTypeLogo
    ? [
        {
          uid: record?.id,
          name: record?.industryTypeLogo,
          status: "done",
        },
      ]
    : [];

  return (
    <>
      <Modal
        title={record?.id ? "Edit Industry Type" : "Add Industry Type"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={addandUpdateIndustryType}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item className="offerImage ">
            <>
              <DragImage
                normFile={normFile}
                nam1="industryTypeLogo"
                label="Industry Logo"
                filelist={record?.id ? defaultFileList : []}
              />
            </>
          </Form.Item>
          <Form.Item
            name="industryTypeName"
            label="Industry Name"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Industry Name!" },
            ]}
            className="offer-name industry"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item
            name="industryTypeNameAr"
            label="Industry Name Arabic"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your Industry Name Arabic!",
              },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item
            name="industryTypeColor"
            label="Industry Type Color"
            hasFeedback
            className="offer-name"
          >
            <Input
              type="color"
              className="input-border"
              defaultValue={"#0032CB"}
            />
          </Form.Item>
          <Form.Item name="isHub" label="Restriction" valuePropName="checked">
            <Switch size="middle" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default Add;

import { Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { S3_URL } from "../../../constant";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import Totop from "../../shared/TopUp";
import InputSearch from "../form/InputSearch";
import EventCard from "./EventCard";
import UserCard from "./UserCard";
import CardTrans from "../CardTrans";
import {
  fetchUserTransactionAndDecryptResponse,
  exportUserTransactionsAndDecryptResponse,
  fetchUserSummeryTransactionsAndDecryptResponse,
  fetchEventTransactionAndDecryptResponse,
} from "../../../KIWE-V1/Services/transactionsAPI";
import moment from "moment";

const TransactionUser = () => {
  const [userCard, setUserCard] = useState({});

  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state, place, userId } = useLocation();

  console.log(state, place, "yes223344");
  const onSearch = (value) => {
    setSearchText(value);
    fetchUserTransactions(1, value);
  };
  const fetchUserSummeryTransactions = async (page, searchText) => {
    if (place === "event") {
      try {
        const api_res = await fetchEventTransactionAndDecryptResponse(
          state?.walletId,
          searchText,
          page
        );
        setTotalPages(api_res?.totalRecords);
        setTransactions(api_res?.data);
        setLoading(false);
        console.log(api_res, "transactionList");
      } catch (error) {
        setLoading(false);
        message.error(error.message);
      }
    }
    if (place === "business" || place === "individual") {
      try {
        const api_res = await fetchUserSummeryTransactionsAndDecryptResponse(
          place,
          userId ? userId : state?.userId
        );
        setUserCard(api_res?.data);
        setLoading(false);
        console.log(api_res, "transactionList");
      } catch (error) {
        setLoading(false);
        message.error(error.message);
      }
    }
  };
  const fetchData = async (page, searchText) => {
    if (place === "event") {
      axiosInstance()
        .post("wallet/transaction", {
          data: convertPlantextToEncrypted({
            walletId: state?.walletId,
            search: searchText,
            pageNo: page,
            expiryTime: getCurrentTimeStemp(),
          }),
        })
        .then((response) => {
          if (response.status === 200) {
            let api_res = convertEncryptedToPlantext(response?.data?.data);
            setTotalPages(api_res?.totalRecords);
            setTransactions(api_res?.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    if (place === "business" || place === "individual") {
      axiosInstance()
        .post("wallet/transactionSummary", {
          data: convertPlantextToEncrypted({
            userId: userId ? userId : state?.userId,
            accountType: place,

            expiryTime: getCurrentTimeStemp(),
          }),
        })
        .then((response) => {
          if (response.status === 200) {
            let api_res = convertEncryptedToPlantext(response?.data?.data);
            setUserCard(api_res?.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const fetchUserTransactions = async (page, searchText) => {
    try {
      const api_res = await fetchUserTransactionAndDecryptResponse(
        state?.walletId,
        searchText,
        page
      );
      setTotalPages(api_res?.totalRecords);
      setTransactions(api_res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const err = convertEncryptedToPlantext(error?.response?.data?.data);

      message.error(err.message);
    }
  };
  useEffect(() => {
    fetchUserSummeryTransactions(1, searchText);
  }, []);

  useEffect(() => {
    fetchUserTransactions(1, searchText);
  }, []);
  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (txt, value) => (
        <>
          {place === "business" && value.transactionType === "addMoney"
            ? " Invoice"
            : place === "individual" && value.transactionType === "addMoney"
            ? "Money Added"
            : value.transactionType === "in"
            ? "Money In"
            : value.transactionType === "out"
            ? "Money Out"
            : value.transactionType === "withdrawMoney"
            ? "Withdraw Money"
            : "-"}
        </>
      ),
    },
    {
      title: "From/To person",
      dataIndex: "personName",
      key: "personName",
      sorter: (a, b) => a.personName.localeCompare(b.personName),

      render: (txt, value) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Transaction Fee",
      dataIndex: "txnFees",
      sorter: (a, b) => a.txnFees - b.txnFees,
    },
    {
      title: "Transaction Charge",
      dataIndex: "txnCharges",
      sorter: (a, b) => a.txnCharges - b.txnCharges,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",

      render: (txt, value) => <>0{txt}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",

      render: (txt, value) => <>E£ {txt?.toFixed(2)}</>,
      // width: 110,
    },
    {
      title: "Tip",
      dataIndex: "tipAmount",
      sorter: (a, b) => a.tipAmount - b.tipAmount,
      // ellipsis: true,
      render: (txt) => <>{txt ? txt : "-"}</>,
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",

      render: (txt) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Status",
      dataIndex: "transaction_status",
      key: "transaction_status",

      title: "Status",
      dataIndex: "transaction_status",
      key: "transaction_status",

      render: (text, record) => (
        <>
          {record.transaction_status === "paid" ||
          record.transaction_status === "success" ? (
            <>
              <button
                style={{
                  border: "1px solid transparent",
                  borderRadius: "15px",
                  color: "rgb(51, 185, 120)",
                  background: "#ebffeb",
                  height:
                    record.transaction_status === "success" ? "2.5rem" : "2rem",
                  width:
                    record.transaction_status === "success" ? " 5rem" : "3rem",
                  fontWeight: "bold",
                }}
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </button>
            </>
          ) : record.transaction_status === "declined" ||
            record.transaction_status === "fail" ? (
            <>
              <button
                style={{
                  border: "1px solid transparent",
                  borderRadius: "25px",
                  color: "rgb(245, 80, 83)",
                  background: "rgb(254 246 246)",
                  height:
                    record.transaction_status === "fail" ? "2rem" : "2.5rem",
                  width:
                    record.transaction_status === "fail" ? "3rem" : " 5rem",
                  fontWeight: "bold",
                }}
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </button>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      title: "Created At",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),

      render: (text) => <>{moment(text).format("D MMM, yyyy h:mm a")}</>,
    },
  ];
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  function onChange2(dates, dateStrings) {
    if (dates) {
      setDate({ startDate: dateStrings[0], endDate: dateStrings[1] });
    }
  }

  const exportUserTransactions = async (page, searchText) => {
    try {
      setLoading(true);
      if (date.startDate && date?.endDate) {
        const api_res = await exportUserTransactionsAndDecryptResponse(
          "business",
          state?.walletId,
          state?.ownerId,
          date?.startDate,
          date?.endDate
        );
        setLoading(false);

        window.open(api_res?.data, "noopener,noreferrer");
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);

      if (error.response.status === 404) {
        message.error("There are no any Transactions in This Date");
      }
    }
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Transactions</h2>
        <div className="container">
          <CardTrans
            src={
              S3_URL +
              state?.userId +
              "/profile_picture/" +
              state?.profilePicturePath
            }
            nam={
              place === "individual" ? state?.firstName : state?.businessName
            }
            handleExport={exportUserTransactions}
            loading={loading}
            onChange2={onChange2}
          />

          {place === "event" ? (
            <EventCard state={state} />
          ) : (
            <UserCard state={userCard} place={place} />
          )}
          <Space
            direction="horizontal"
            className="topHeader-1"
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <InputSearch onSearch={onSearch} />
          </Space>
          <Table
            style={{ marginTop: "1rem" }}
            className="table2"
            columns={columns}
            dataSource={transactions}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              current: +page,
              onChange: (page) => {
                setPage(page);
                fetchUserTransactions(page, searchText);
              },
            }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default TransactionUser;

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import { OFFER_S3_URL } from "../../../constant";
import Totop from "../../shared/TopUp";
import AddOffer from "./AddOffer";
import swal from "sweetalert2";
import {
  fetchOffersData,
  deleteOfferAPI,
} from "../../../KIWE-V1/Services/offerApi.js";
const OfferList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [offers, setOffers] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllOffers = async () => {
    try {
      const offers = await fetchOffersData();

      setOffers(offers.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllOffers();
  }, []);
  const handleDelete = (record) => {
    console.log(record);
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Offer ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteOfferAsync() {
            try {
              const decryptedResponse = await deleteOfferAPI(record);
              if (decryptedResponse) {
                setOffers((prevOffers) =>
                  prevOffers.filter((offer) => offer.id !== record.id)
                );
                message.success("Offer deleted successfully!!");
              }
            } catch (error) {
              message.error(error.message);
            }
          }
          deleteOfferAsync();
        }
      });
  };
  const columns = [
    {
      title: "Offer Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
      ellipsis: true,
    },
    {
      title: "Offer Image",
      dataIndex: "image",
      key: "image",
      render: (T, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={OFFER_S3_URL + record?.image}
              style={{ width: "10%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
                console.log(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="danger"
              className="delete"
              onClick={() => handleDelete(record)}
            >
              Delete
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "flex-end" }}
        >
          {/* <InputSearch onSearch={onSearch} /> */}
          <h2>Home Banner</h2>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal();
                setRecord({});
              }}
            >
              ADD
            </Button>

            <AddOffer
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={offers}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default OfferList;

import { Avatar } from "antd";
import { S3_URL } from "../../../constant";

const EventCard = ({ state }) => {
  return (
    <div className="container__header">
      <div className="container__header__column1 above">
        <div className="container__header__column1__content ">
          {state?.profilePicturePath ? (
            <Avatar
              size="large"
              alt="imgProfile"
              src={
                S3_URL +
                state?.userId +
                "/profile_picture/" +
                state?.profilePicturePath
              }
            />
          ) : (
            ""
          )}
          <span style={{ marginLeft: "0.5rem" }}>{state?.ownerName}</span>
        </div>
      </div>
      <div
        className="container__header__column2"
        // style={{ maxWidth: "33%", overflow: "hidden" }}
      >
        <div className="container__header__column2__row1 above">
          <span className="container__header__span1">Event:</span>
          <span>{state?.walletName}</span>
        </div>
        <div className="container__header__column2__row2">
          <span className="container__header__span1">Owner:</span>
          <span>{state?.ownerName}</span>
        </div>
      </div>
      <div className="container__header__column3">
        <div className="container__header__column3__row1 above">
          <span className="container__header__span1">Event Date:</span>
          <span>{new Date(state?.eventDate).toLocaleDateString()}</span>
        </div>
        <div className="container__header__column3__row2">
          <span className="container__header__span1">Balance:</span>
          <span>
            {"E£ "} {state?.balance?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="container__header__column4">
        <div className="container__header__column4__row1 above">
          <span className="container__header__span1">Created At:</span>
          <span>{new Date(state?.createdDate).toLocaleString()}</span>
        </div>
        <div className="container__header__column4__row2">
          <span className="container__header__span1">Description :</span>
          <span>{state?.eventDescription}</span>
        </div>
      </div>
    </div>
  );
};

export default EventCard;

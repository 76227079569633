import { Form, message, Button, Radio, Select, Table } from "antd";
import { useState, useEffect } from "react";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { BankTransfer } from "../../shared/images";
import BankDetails from "../business_user/BankDetails";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  fetchBusinssUsersAndDecryptResponse,
  fetchBusinssOwnersAndDecryptResponse,
  fetchWithdrawalPendingBusinessAndDecryptResponse,
  payoutWithdrawalPendingForBusinessUsersAndDecryptResponse,
} from "../../../KIWE-V1/Services/payoutApi";
const Payout = () => {
  const [form] = Form.useForm();
  const busnessTypes = ["Business Owner", "Business Users"];
  const [businessUsers, setBusinessUsers] = useState([]);
  const [businessOwners, setBusinssOwners] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [selectedItemsBusinessUser, setSelectedItemsBusinessUser] =
    useState(null);
  const [selectedItemsBusinessOwner, setSelectedItemsBusinessOwner] =
    useState(null);

  const [flagBusiness, setFlagBusiness] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [withdrawalPendingBusiness, setWithdrawalPendingBusiness] = useState(
    []
  );

  const [totalPages, setTotalPages] = useState(1);

  const [transactionsIds, setTransactionsIds] = useState([]);
  const [selectedRowsOfTransactionsIds, setSelectedRowsOfTransactionsIds] =
    useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState([]);

  const history = useHistory();

  const fetchBusinessUsers = async () => {
    try {
      const apiResponse = await fetchBusinssUsersAndDecryptResponse();
      setBusinessUsers(apiResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const fetchBusinessOwners = async () => {
    try {
      const apiResponse = await fetchBusinssOwnersAndDecryptResponse();
      setBusinssOwners(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchBusinessUsers();
    fetchBusinessOwners();
  }, []);

  const fetchWithdrawalPendingBusiness = async (page, id) => {
    try {
      const apiResponse =
        await fetchWithdrawalPendingBusinessAndDecryptResponse(page, id);
      setTotalPages(apiResponse?.totalRecords);
      setWithdrawalPendingBusiness(apiResponse?.data);
      setLoading(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const decryptedError = convertEncryptedToPlantext(
        error?.response?.data?.data
      );

      if (error.response.status === 404) {
        const configError = {
          className: "messageError",
          content: "No transactions found",
        };
        message.error(configError);
      } else {
        const configError = {
          className: "messageError",
          content: decryptedError.message,
        };
        message.error(configError);
      }
    }
  };
  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const withdrawalPendingColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "User Name",
      dataIndex: "senderName",
      key: "senderName",
      sorter: (a, b) => a.senderName?.length - b.senderName?.length,
    },
    {
      title: "Bank Details",
      dataIndex: "withdrawalType",
      key: "withdrawal Type",
      render: (src, record) => (
        <>
          <span
            className="image"
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => showModal(record)}
          >
            <img src={BankTransfer} alt="" />
          </span>
        </>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "senderMobile",
      key: "senderMobile",
      sorter: (a, b) => a.senderMobile?.length - b.senderMobile?.length,
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "transaction_status",
      key: "transaction_status",
    },
    {
      title: "Action Date",
      dataIndex: "updatedTimeStamp",
      key: "updatedTimeStamp",

      render: (txt, record) => (
        <>{txt ? moment(txt).format("D MMM, yyyy h:mm a") : "-"}</>
      ),
    },
  ];

  const handleChangeBusinessUser = (selectedItems) => {
    setSelectedItemsBusinessUser(selectedItems);
    fetchWithdrawalPendingBusiness(1, selectedItems);
  };

  const handleChangeBusinessOwner = (selectedItems) => {
    setSelectedItemsBusinessOwner(selectedItems);
    fetchWithdrawalPendingBusiness(1, selectedItems);
  };

  const selectRoleUserType = (e) => {
    if (e.target.value === "Business Users") {
      setFlagBusiness(true);
      setWithdrawalPendingBusiness([]);
      form.resetFields(["businessUsers"]);
    } else {
      setFlagBusiness(false);
      setWithdrawalPendingBusiness([]);
      form.resetFields(["businessOwnerId"]);
    }
  };
  const calculateTotalSelectedRowsOfTransactionsIds = () => {
    const totalTransactionsIds = selectedRowsOfTransactionsIds
      ?.map((item) => item?.amount)
      ?.reduce((total, current) => {
        return total + current;
      }, 0)
      ?.toFixed(2);

    setTotalAmount(totalTransactionsIds);
  };
  useEffect(() => {
    calculateTotalSelectedRowsOfTransactionsIds();
  }, [selectedRowsOfTransactionsIds]);
  const payoutWithdrawalPendingForBusinessUsersAndBusinessOwners = async () => {
    if (flagBusiness === true) {
      handlePayoutWithdrawalPendingBusinessUsers();
    } else {
      handlePayoutWithdrawalPendingBusinessOwners();
    }
  };
  const handlePayoutWithdrawalPendingBusinessOwners = async () => {
    try {
      const apiResponse =
        await payoutWithdrawalPendingForBusinessUsersAndDecryptResponse(
          totalAmount,
          transactionsIds,
          selectedItemsBusinessOwner,
          undefined,
          flagBusiness
        );
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Payout Succssfully",
        };
        message.success(configSuccess);
        history.push("/withdrawal-list");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const decryptedError = convertEncryptedToPlantext(
        error?.response?.data?.data
      );

      const configError = {
        className: "messageError",
        content: decryptedError.message,
      };
      message.error(configError);
    }
  };
  const handlePayoutWithdrawalPendingBusinessUsers = async () => {
    try {
      const apiResponse =
        await payoutWithdrawalPendingForBusinessUsersAndDecryptResponse(
          totalAmount,
          transactionsIds,
          undefined,
          selectedItemsBusinessUser,
          flagBusiness
        );
      if (apiResponse) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Payout Succssfully",
        };
        message.success(configSuccess);
        history.push("/withdrawal-list");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const decryptedError = convertEncryptedToPlantext(
        error?.response?.data?.data
      );

      const configError = {
        className: "messageError",
        content: decryptedError.message,
      };
      message.error(configError);
    }
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Add Payout</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="notificationFrom"
          >
            <Form.Item
              name="user"
              label="Making Payout for:"
              className="notificationFrom__userType"
            >
              <Radio.Group
                onChange={selectRoleUserType}
                defaultValue={busnessTypes[0]}
              >
                {busnessTypes.map((item, index) => (
                  <Radio value={item} key={index}>
                    {item}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            {flagBusiness === true ? (
              <Form.Item
                name="businessUsers"
                label="Choose Business Users"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Business Users!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Business Users"
                  value={selectedItemsBusinessUser || undefined}
                  onChange={handleChangeBusinessUser}
                  className="select-p"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  showSearch
                >
                  {businessUsers.map((item) => (
                    <Select.Option value={item.id}>
                      {item.businessName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="businessOwnerId"
                label="Choose Business Owners"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your Business Owner!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Business Owners"
                  value={selectedItemsBusinessOwner || undefined}
                  onChange={handleChangeBusinessOwner}
                  allowClear
                  className="select-p"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue?.toLowerCase())
                  }
                  showSearch
                >
                  {businessOwners.map((item) => (
                    <Select.Option value={item.id}>
                      {item.firstName + " " + item.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="transactions">
              {withdrawalPendingBusiness.length > 0 && flagBusiness === true ? (
                <Table
                  className="table2"
                  columns={withdrawalPendingColumns}
                  dataSource={withdrawalPendingBusiness}
                  scroll={{ x: 900 }}
                  loading={loading}
                  rowKey={(record) => record?.transactionId}
                  rowSelection={{
                    preserveSelectedRowKeys: true,
                    transactionsIds,
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectedRowsOfTransactionsIds(selectedRows);
                      setTransactionsIds(selectedRowKeys);
                    },
                  }}
                  pagination={{
                    pageSize: 10,
                    total: totalPages,
                    onChange: (page) => {
                      setPage(page);
                      fetchWithdrawalPendingBusiness(
                        page,
                        selectedItemsBusinessUser
                      );
                    },
                  }}
                />
              ) : null}
            </Form.Item>
            <Form.Item name="transactions">
              {withdrawalPendingBusiness.length > 0 &&
              flagBusiness === false ? (
                <Table
                  className="table2"
                  columns={withdrawalPendingColumns}
                  dataSource={withdrawalPendingBusiness}
                  rowKey={(record) => record.transactionId}
                  rowSelection={{
                    preserveSelectedRowKeys: true,
                    transactionsIds,
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectedRowsOfTransactionsIds(selectedRows);
                      setTransactionsIds(selectedRowKeys);
                    },
                  }}
                  scroll={{ x: 900 }}
                  loading={loading}
                  pagination={{
                    pageSize: 10,
                    total: totalPages,
                    onChange: (page) => {
                      setPage(page);
                      fetchWithdrawalPendingBusiness(
                        page,
                        selectedItemsBusinessOwner
                      );
                    },
                  }}
                />
              ) : null}
            </Form.Item>
            <Form.Item name="amount" label="Total Amount">
              {totalAmount}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={
                  payoutWithdrawalPendingForBusinessUsersAndBusinessOwners
                }
                className="add-btn"
              >
                Add Payout
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <BankDetails
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        record={record}
      />
    </>
  );
};

export default Payout;

import crypto from 'crypto'

//const key = 'bbC2H19lkVbQDfakxcrtNMQdd0FloLyw';
//const iv = 'gqLOHUioQ0QjhuvI';
const key = 'abC2H19lkVbQDfkkxcrtNMQdd0FloLyp';
const iv = 'pqLOHUioK0QjhuvA';

function convertPlantextToEncrypted(text) {
	// console.log("Plantext: ", text);
	const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encrypted = cipher.update(JSON.stringify(text), 'utf8', 'base64')
    encrypted += cipher.final('base64');
	return encrypted;
}

function convertEncryptedToPlantext(text) {
	const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
    let decrypted = decipher.update(text, 'base64');
    decrypted += decipher.final();
    return JSON.parse(decrypted);
}

function getCurrentTimeStemp() {
	var time = new Date().toISOString();
	return parseInt((new Date(time).getTime()) + 300000);
}

export {
    convertPlantextToEncrypted,
	convertEncryptedToPlantext,
	getCurrentTimeStemp
}
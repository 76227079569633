import { Table, Avatar, message } from "antd";

import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";

import { useHistory } from "react-router-dom";
import { fetchSubAccountsDataApiCall } from "../../../KIWE-V2/Services/userAPI";
const SubAccount = (props) => {
  const record = JSON.parse(localStorage.getItem("parentAccount"));
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const [totalPages, setTotalPages] = useState(1);

  const [subAccounts, setSubAccounts] = useState([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const history = useHistory();

  const fetchSubAccount = async (page, record) => {
    setLoading(true);
    try {
      const api_res = await fetchSubAccountsDataApiCall(page, record);
      setLoading(false);
      setSubAccounts(api_res?.data);
      setTotalPages(api_res?.totalRecords);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  useEffect(() => {
    if (pageNum) {
      fetchSubAccount(pageNum, record);
    } else {
      fetchSubAccount(1, record);
    }
  }, [pageNum]);
  const subAccountsTableColumns = [
    {
      title: "Profile Picture",
      dataIndex: "info",
      key: "info",

      render: (txt, record) => (
        <>
          <Avatar src={record?.profilePicture} size={60} />
        </>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (txt, record) => <>{txt ? txt : "-"}</>,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: (txt, record) => <>{txt ? txt : "-"}</>,
    },

    {
      title: "User Name",
      dataIndex: "username",
      key: "username",

      render: (txt, record) => <div>{txt ? txt : "-"}</div>,
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Sub Accounts</h2>
        <div className="container">
          {subAccounts?.length > 0 ? (
            <Table
              className="table2"
              columns={subAccountsTableColumns}
              dataSource={subAccounts}
              size="large"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/sub-account/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No Sub Accounts Records Found.
            </div>
          )}
        </div>

        <Totop />
      </div>
    </>
  );
};

export default SubAccount;

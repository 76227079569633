import EditBusinessUser from './editBusnessUser';
import EditBank from './editBank';

const Edit = () => {
  return (
    <>
      <EditBusinessUser />
      <EditBank />
    </>
  );
};

export default Edit;

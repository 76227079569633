import {
  Input,
  Button,
  Form,
  Select,
  message,
  Col,
  Row,
  DatePicker,
  Switch,
} from "antd";

import { useState, useEffect } from "react";
import { addFairAPI } from "../../../KIWE-V2/Services/fairsApi.js";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import DragImage from "../../front/form/DragImage";
import { normFile } from "../../shared/dragdropFun";
import { uploadOldImageS3 } from "../../../library/UploadImage";
import { generateRandomFileName } from "../../shared/uploadfun";
// import { useFair } from "../../../contextAPi/fair/FairContext.js";
import { useHistory } from "react-router";
import moment from "moment";
import { fetchBusinessData } from "../../../KIWE-V1/Services/businessOwnerApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddFair = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [busnessUsers, setBusnessUsers] = useState([]);
  const [selectedBusinessUser, setSelectedBusinessUser] = useState([]);
  const [arabicPolicy, setArabicPolicy] = useState("");
  const [englishPolicy, setEnglishPolicy] = useState("");

  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const disabledDateToday = (current) => {
    const today = moment();
    today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return current && current < today;
  };

  const disabledDateTomorrow = (current) => {
    const tomorrow = moment().add(1, "days");
    tomorrow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return current && current < tomorrow;
  };

  const history = useHistory();
  const fetchBusinessUser = async () => {
    try {
      const response = await fetchBusinessData();
      setBusnessUsers(response);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchBusinessUser();
  }, []);
  // const validateDateAndFormate = (values) => {
  //   let startDate =
  //     values?.startDate &&
  //     moment(values.startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //   let endDate =
  //     values?.endDate &&
  //     moment(values.endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //   return {
  //     startDate,
  //     endDate,
  //   };
  // };
  const validateDateAndFormate = (values) => {
    let startDate = values?.startDate?.format("YYYY-MM-DDTHH:mm:ss.SSS");
    startDate = moment(startDate).utc();
    let endDate = values?.endDate?.format("YYYY-MM-DDTHH:mm:ss.SSS");
    endDate = moment(endDate).utc();
    return {
      startDate,
      endDate,
    };
  };
  const handleUploadBannerFair = async (values) => {
    const file =
      values.banner && values.banner.length > 0
        ? values.banner[0].originFileObj
        : null;
    const base64Image = await convertImageToBase64(file);
    let randomName = generateRandomFileName(values?.banner[0]);
    const imageUrl = await uploadOldImageS3(
      randomName,
      "fair_banner",
      base64Image
    );
    return imageUrl;
  };
  const handleChangeBusinessUser = (selectedItems) => {
    setSelectedBusinessUser(selectedItems);
  };
  const addFair = async (values) => {
    try {
      setLoading(true);
      const imageUrl = await handleUploadBannerFair(values);
      const { startDate, endDate } = validateDateAndFormate(values);
      const enabled = values.enabled !== undefined ? values.enabled : false;
      const apiValues = {
        ...values,
        startDate,
        endDate,
        enabled,
        arabicPolicy,
        englishPolicy,
      };
      const apiResponse = await addFairAPI(apiValues, imageUrl.fileUrl);

      if (apiResponse) {
        setLoading(false);
        const configSuccess = {
          className: "messageSuccess",
          content: "Added Fair Successfully!",
        };
        message.success(configSuccess);

        const decryptedResponse = convertEncryptedToPlantext(
          apiResponse?.data?.data
        );

        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(null);
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(",")[1];
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["clean"],
    ],
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Add Fair</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            onFinish={addFair}
            autoComplete="off"
          >
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <DragImage
                  normFile={normFile}
                  nam1="banner"
                  label="Fair Photo"
                  filelist={[]}
                  accept=".jpg,.jpeg,image/jpeg,.png,image/png,.webp,image/webp"
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="enabled"
                  label="Activate Fair"
                  valuePropName="checked"
                >
                  <Switch size="middle" className="greenSwitch" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item
                  name="businessUserId"
                  label="Business User"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your Business User!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Branch"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    className="selectLevel"
                    value={selectedBusinessUser}
                    onChange={handleChangeBusinessUser}
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    showSearch
                  >
                    {busnessUsers.map((item) => (
                      <Select.Option value={item.id}>
                        {item.businessName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Fair Name"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please input Fair Name!" },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={12}>
                <Form.Item name="startDate" label="Start Fair">
                  <DatePicker
                    showTime={{ format: "HH:mm:ss" }}
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                    format={dateFormat}
                    disabledDate={(current) => disabledDateToday(current)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="endDate" label="End Fair">
                  <DatePicker
                    showTime={{ format: "HH:mm:ss" }}
                    style={{
                      width: "100%",
                      borderRadius: "25px",
                      height: "2.7rem",
                    }}
                    format={dateFormat}
                    disabledDate={(current) => disabledDateTomorrow(current)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="rowMoneyLearn">
              <Col span={8} lg={12} xs={24}>
                <Form.Item
                  name="arabicPolicy"
                  label="Arabic Policy"
                  hasFeedback
                  // rules={[
                  //   { required: true, message: "Please input Arabic Policy!" },
                  // ]}
                >
                  <ReactQuill
                    style={{
                      width: "100%",
                    }}
                    onChange={(content) => setArabicPolicy(content)}
                    modules={modules}
                    className="quill"
                  />
                </Form.Item>
              </Col>
              <Col span={8} lg={12} xs={24}>
                <Form.Item
                  name="englishPolicy"
                  label="English Policy"
                  hasFeedback
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input English Policy!",
                  //   },
                  // ]}
                >
                  <ReactQuill
                    style={{
                      width: "100%",
                    }}
                    modules={modules}
                    onChange={(content) => setEnglishPolicy(content)}
                    className="quill"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  // margin: "0 auto",
                  justifyContent: "center",
                  // marginTop: "5rem",
                }}
                className="add-btn btnFair"
              >
                Add Fair
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddFair;

import { Button, Form, message, Modal, Upload, Space } from "antd";
import DragImage from "../form/DragImage";
import { useEffect, useState } from "react";
import { normFile } from "../../shared/dragdropFun";
import { UploadOutlined } from "@ant-design/icons";

import axiosInstance from "../../../helpers/axiosInstance";

import { handleHubUpload } from "../../shared/uploadfun";
// import { useHistory } from "react-router-dom";
const UploadHub = ({ setIsModalVisible, isModalVisible, record }) => {
  console.log("rrrrrr->>>", record);
  const [form] = Form.useForm();

  const [initialValue, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);

  console.log(initialValue);
  // const history = useHistory();

  const onFinish = (values) => {
    console.log(values);
    setLoading(true);
    handleHubUpload(
      values?.file[0]?.originFileObj,
      record?.id,
      setLoading,
      handleCancel
    );
  };
  useEffect(() => {
    form.setFieldsValue({
      fileList: [],
    });
  }, []);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={"Upload Hub"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            file: "",
          }}
        >
          <Form.Item className="offerImage ">
            <>
              <DragImage
                normFile={normFile}
                nam1="file"
                label="Upload Hub"
                accept=".xls, .xlsx"
                maxCount={1}
              />
            </>
          </Form.Item>

          <Form.Item className="btns" style={{ marginTop: "0rem" }}>
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default UploadHub;

import { Button, Form, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { editLevelReward } from "../../../KIWE-V2/Services/userAPI.js";
import { fetchLevelsRewardDataAndDecryptResponse } from "../../../KIWE-V2/Services/rewardLevel";

const Edit = ({
  isModalVisibleLevelReward,
  setIsModalVisibleLevelReward,
  record,
  activeUsers,
  setActiveUsers,
}) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(
    record
      ? {
          rewardLevel: record?.rewardLevel?.id,
        }
      : null
  );

  const handleEditLevelReward = async (values) => {
    try {
      const apiResponse = await editLevelReward(record, values);
      if (apiResponse.status === 200) {
        const messageSuccess = {
          className: "messageSuccess",
          content: apiResponse?.message,
        };
        message.success(messageSuccess);
        handleCancel();
        setActiveUsers((prevActiveUser) =>
          prevActiveUser.map((activeUser) =>
            activeUser.id === record.id ? apiResponse?.data : activeUser
          )
        );
      }
    } catch (error) {
      const messageError = {
        className: "messageError",
        content: error.message ? error.message : error.errors[0],
      };

      if (Array.isArray(error.errors)) {
        message.error(messageError);
      } else {
        message.error(messageError);
      }
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisibleLevelReward(false);
  };
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;

      if (length !== 0) {
        setInitialValues({
          rewardLevel: record?.rewardLevel?.id,
        });
      } else {
        form.setFieldsValue({ rewardLevel: "" });

        setInitialValues({});
      }
    }
  }, [record, form]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        rewardLevel: record?.rewardLevel?.id,
      });
    }
  }, [record, initialValue, form]);
  const [options, setOptions] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const fetchLevelsRewards = async () => {
    try {
      const apiResponse = await fetchLevelsRewardDataAndDecryptResponse();

      setOptions(apiResponse?.data);
    } catch (error) {
      message.error(error?.error);
    }
  };


  useEffect(() => {
    fetchLevelsRewards();
  }, []);
  return (
    <>
      <Modal
        title={record ? "Edit Level Reward" : "Add Level Reward"}
        visible={isModalVisibleLevelReward}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEditLevelReward}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item
            name="rewardLevel"
            label="Level Reward"
            hasFeedback
            rules={
              record
                ? []
                : [
                    {
                      required: true,
                      message: "Please input your Level Reward!",
                    },
                  ]
            }
          >
            <Select
              placeholder="Select Branch"
              value={selectedItems}
              onChange={handleChange}
              allowClear
              className="select-p"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {options.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {record ? "Edit Level Reward" : "Add Level Reward"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;

import { Input, Button, Form, message, Modal } from "antd";
import DragImage from "../form/DragImage";
import { useEffect } from "react";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { Env } from "../../../constant";
import { useState } from "react";
import { addAndEditGiftCardAPI } from "../../../KIWE-V1/Services/giftCard";
const Add = ({ setIsModalVisible, isModalVisible, record }) => {
  const [form] = Form.useForm();
  const [initialValue, setInitialValues] = useState(
    record?.templateId
      ? {
          templateTitle: record?.templateTitle,
          templatePicturePath: record?.templatePicturePath,
        }
      : null
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (record) {
      let length = Object.keys(record).length;
      if (length !== 0) {
        setInitialValues({
          templateTitle: record?.templateTitle,
          templatePicturePath: record?.templatePicturePath,
        });
      } else {
        form.setFieldsValue({ templateTitle: "" });
        form.setFieldsValue({ templatePicturePath: "" });

        setInitialValues({});
      }
    }
  }, [record, form]);
  const handleAddAndUpdateGiftCard = async (values) => {
    try {
      setLoading(true);
      if (record?.templateId) {
        let randomName = "";
        if (!(typeof values.templatePicturePath === "string")) {
          randomName = generateRandomFileName(values.templatePicturePath[0]);
          await handleImageUpload(
            values.templatePicturePath[0].originFileObj,
            randomName,
            "kiweapp/" + Env + "/gift_card_templates_picture"
          );
        } else {
          randomName = values.templatePicturePath;
        }

        const decryptedResponse = await addAndEditGiftCardAPI(
          values,
          randomName,
          record
        );
        form.resetFields();
        if (decryptedResponse) {
          setLoading(false);
          message.success("Gift Card Added Successfully!");
          handleCancel();
          window.location.reload();
        }
      } else {
        const randomName = generateRandomFileName(
          values.templatePicturePath[0]
        );
        await handleImageUpload(
          values.templatePicturePath[0].originFileObj,
          randomName,
          "kiweapp/" + Env + "/gift_card_templates_picture"
        );
        const decryptedResponse = await addAndEditGiftCardAPI(
          values,
          randomName,
          record
        );
        form.resetFields();
        if (decryptedResponse) {
          setLoading(false);
          message.success("Gift Card Edited Successfully!");
          handleCancel();
          window.location.reload();
        }
      }
    } catch (error) {
      setLoading(false);

      message.error(error.message);
    }
  };
  useEffect(() => {
    if (record?.templateId) {
      form.setFieldsValue({ templateTitle: record.templateTitle });

      form.setFieldsValue({ templatePicturePath: record.templatePicturePath });
    }
  }, [record, initialValue, form]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={record?.templateId ? "Edit Gift Card" : "Add Gift Card"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndUpdateGiftCard}
          autoComplete="off"
          initialValues={initialValue}
        >
          <Form.Item className="offerImage">
            <DragImage
              nam1="templatePicturePath"
              normFile={normFile}
              label="Gift Image"
              rules={[
                { required: true, message: "Please input your Gift Image!" },
              ]}
            />
            <span className="note">
              Note :- Please upload image in size of "1440 * 645" for better
              resolution
            </span>
          </Form.Item>
          <Form.Item
            name="templateTitle"
            label="Gift Title"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Gift Title!" },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import { Space, Button, Table, Avatar } from "antd";

import Totop from "../../shared/TopUp";

import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
import { S3_URL } from "../../../constant";
import { useState, useEffect } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import swal from "sweetalert2";
const MoneyLearn = () => {
  const history = useHistory();
  // const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  console.log(page);
  const [totalPages, setTotalPages] = useState(1);
  const { pageNo } = useParams();
  // const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  // const fetchData = async (page) => {
  //   axiosInstance()
  //     .post("offer/businessOffer/offers", {
  //       data: convertPlantextToEncrypted({
  //         pageNo: page,
  //         expiryTime: getCurrentTimeStemp(),
  //       }),
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         let api_res = convertEncryptedToPlantext(response?.data?.data);
  //         //   setTotalPages(api_res?.totalRecords);
  //         //   setData(api_res?.data);
  //         setLoading(false);
  //         console.log(api_res, "business-offer");
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log(error);
  //     });
  // };
  // useEffect(() => {
  //   fetchData(pageNo);
  // }, []);
  const data = [
    {
      title: "Blog1",
      bodyText: "blogBodyblogBody blogBody blogBody",
      website: "www.kiwe.com",
    },
  ];
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title?.localeCompare(b.title),
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Body Text",
      dataIndex: "bodyText",
      key: "bodyText",
    },
    {
      title: "Number Of Views",
      dataIndex: "blogBody",
      key: "blogBody",
      sorter: (a, b) => a.blogBody?.localeCompare(b?.blogBody),
      render: (text) => (
        <span style={{ textAlign: "center", display: "block" }}>{175}</span>
      ),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    {
      title: "Background",
      dataIndex: "image",
      key: "image",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={localStorage.getItem("avatarItem")}
              style={{ width: "50%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Logo",
      dataIndex: "coverImage",
      key: "coverImage",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={localStorage.getItem("avatarItem")}
              style={{ width: "50%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (txt, record) => (
        <>
          <div>
            <Link
              to={{
                // pathname: '/business-offer-edit',
                state: record,
                pageNo: pageNo,
              }}
            >
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={handleClickEdit}
              >
                Edit
              </Button>
            </Link>
            <Button
              type="danger"
              className="delete"
              onClick={() => handleDeleteClick(record)}
            >
              Delete
            </Button>
          </div>
        </>
      ),
    },
  ];

  const handleClick = () => {
    history.push(`/money-learn-add/${pageNo}`);
  };

  const handleClickEdit = () => {
    history.push(`/business-offer-edit/${pageNo}`);
  };

  const handleDeleteClick = (data) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Business Offer ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          axiosInstance()
            .get("offer/businessOffer/delete/" + data.id)
            .then((response) => {
              if (response.status === 200) {
                window.location.reload();
                // fetchData(pageNo);
                toast.success("Success !! Business Offer deleted successfully");
                console.log("success");
              }
            })
            .catch((error) => {
              toast.error("something went error");
            });
        }
      });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Masterclasses</h2>
          <Button type="primary" size="middle" onClick={handleClick}>
            ADD
          </Button>
        </Space>
        <div className="container">
          {data.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={data}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: pageNo,
                onChange: (page) => {
                  setPage(page);
                  // history.push(`/business-offer/${page}`);
                  // fetchData(page);
                },
              }}
            />
          ) : (
            <div className="messageNoData">No Money Tips Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default MoneyLearn;

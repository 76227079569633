import { Input, Button, Form, message, Modal } from "antd";
import DragImage from "../form/DragImage";
import { useEffect, useState } from "react";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";

import { COMPANY_ALBUM_NAME } from "../../../constant";
import { addAndEditCompanyAPI } from "../../../KIWE-V1/Services/companyApi";
const Add = ({
  setIsModalVisible,
  isModalVisible,
  record,
  setCompanies,
  companies,
}) => {
  const [form] = Form.useForm();

  const [cancelState, setCancelState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkInitial, setCheckInitial] = useState(false);
  useEffect(() => {
    if (isModalVisible) {
      if (record) {
        form.setFieldsValue({
          name: record.name,
          type: record.type,
          logo: record.logo,
        });
      } else {
        form.setFieldsValue({ logo: null });
        form.resetFields();
      }
    }
  }, [isModalVisible, record, form]);
  const handleAddAndEditCompany = async (values) => {
    if (record) {
      editCompany(values);
    } else {
      addCompany(values);
    }
  };
  const addCompany = async (values) => {
    try {
      setLoading(true);
      const randomName = generateRandomFileName(values?.logo[0]);
      await handleImageUpload(
        values?.logo[0]?.originFileObj,
        randomName,
        COMPANY_ALBUM_NAME
      );
      const decryptedResponse = await addAndEditCompanyAPI(
        values,
        randomName,
        record
      );
      form.resetFields();
      form.setFieldsValue({ logo: null });
      setCheckInitial(true);
      if (decryptedResponse) {
        setLoading(false);
        const newCompanies = {
          ...values,
          logo: randomName,
        };

        setCompanies((prevCompanies) => [...prevCompanies, newCompanies]);
        message.success(decryptedResponse);
        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);

      message.error(error.message);
    }
  };
  const editCompany = async (values) => {
    try {
      setLoading(true);
      let randomName = "";
      if (!(typeof values.logo === "string")) {
        randomName = generateRandomFileName(values.logo[0]);
        await handleImageUpload(
          values.logo[0].originFileObj,
          randomName,
          COMPANY_ALBUM_NAME
        );
      } else {
        randomName = values.logo;
      }
      const decryptedResponse = await addAndEditCompanyAPI(
        values,
        randomName,
        record
      );
      if (decryptedResponse) {
        setLoading(false);
        const updatedCompanies = companies.map((company) =>
          company.id === record.id ? { ...values, logo: randomName } : company
        );
        setCompanies(updatedCompanies);

        form.setFieldsValue({ logo: null });
        setCheckInitial(true);
        message.success("Company Updated Successfully!");
        handleCancel();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    form.setFieldsValue({ logo: null });
    setCancelState(!cancelState);
    setCheckInitial(true);
  };
  return (
    <>
      <Modal
        title={record ? "Edit Company" : "Add Company"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
        initialValue={{ name: "", type: "", logo: record ? "" : null }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndEditCompany}
          autoComplete="off"
        >
          <Form.Item className="offerImage">
            <DragImage
              nam1="logo"
              label="Company Logo"
              normFile={normFile}
              initialValue={checkInitial && null}
              rules={[
                { required: true, message: "Please input your Company Logo!" },
              ]}
            />
            <span className="note">
              Note :- Please upload image in size of "1440 * 645" for better
              resolution
            </span>
          </Form.Item>

          <Form.Item
            name="name"
            label="Company Name"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Company Name!" },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Company Type"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Company Type!" },
            ]}
            className="offer-name"
          >
            <Input className="input-border" />
          </Form.Item>
          <Form.Item className="btns">
            <Button
              size="middle"
              htmlType="submit"
              className="btns__offer-save"
              loading={loading}
            >
              Save
            </Button>
            <Button
              size="middle"
              className="btns__offer-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import { useHistory, useLocation } from "react-router-dom";
import { fetchFAQsApi } from "../../../KIWE-V2/Services/FAQsApi";
import Add from "./add";
const FAQsTopic = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const pageNum = params.get("pageNum");
  const history = useHistory();
  const location = useLocation();
  const { FAQ } = location.state;
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [FAQs, setFAQs] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchAllFAQs = async (page) => {
    try {
      const apiResponse = await fetchFAQsApi(page, FAQ?.id);

      setLoading(false);
      setFAQs(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
    } catch (error) {
      setLoading(false);

      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  useEffect(() => {
    if (pageNum) {
      fetchAllFAQs(pageNum);
    } else {
      fetchAllFAQs(1);
    }
  }, [pageNum]);

  const FAQsColumns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: "FAQs Status",
      dataIndex: "visible",
      key: "visible",

      render: (txt, record) => (
        <>
          <div>
            {record?.visible === true ? (
              <div className="custom-tag onboarding-status-approved">
                Active
              </div>
            ) : record?.visible === false ? (
              <div className="custom-tag onboarding-status-rejected">
                InActive
              </div>
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>FAQs</h2>
          <Space style={{ justifyContent: "flex-end" }}>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal(null);
              }}
            >
              ADD
            </Button>
          </Space>
        </Space>
        <div className="container">
          {FAQs.length > 0 ? (
            <Table
              className="table2"
              columns={FAQsColumns}
              dataSource={FAQs}
              rowKey="id"
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNum ? +pageNum : 1,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/view-FAQs/?pageNum=${page}`);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "25%" }}>
              No FAQs Records Found.
            </div>
          )}
        </div>
        <Add
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          FAQs={FAQs}
          setFAQs={setFAQs}
          FAQ={FAQ}
          record={record}
        />
        <Totop />
      </div>
    </>
  );
};

export default FAQsTopic;

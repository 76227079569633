import { Modal } from "antd";
import { S3_URL } from "../../../constant";

const Image = ({ handleCancelImage, isModalVisibleImage, record }) => {
  console.log(record, "xxxxxxxxxxxxxxx");
  return (
    <>
      <Modal
        visible={isModalVisibleImage}
        onCancel={handleCancelImage}
        footer={null}
        className="modal"
      >
        {/* <div className='account-info'> */}
        <img
          src={
            S3_URL +
            record?.userId +
            "/profile_picture/" +
            record?.profilePicturePath
          }
          style={{ width: "100%" }}
          alt="-"
        />
        {/* </div> */}
      </Modal>
    </>
  );
};

export default Image;

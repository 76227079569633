import { Input, Button, Form, message, Select, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  editAdminApi,
  addAdminApi,
  getAdminsRoles,
} from "../../../../KIWE-V1/Services/adminApi";
const AddRole = ({ isModalVisible, setIsModalVisible, record }) => {
  console.log(record, "adminRec");
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    email: record?.email,
    firstName: record?.firstName,
    lastName: record?.lastName,
    role: record?.roles?.map((item) => item.roleName),
  });

  const [adminRoles, setAdminRoles] = useState([]);
  const fetchAdminRoles = async () => {
    try {
      const apiResponse = await getAdminsRoles();
      setAdminRoles(apiResponse.data);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchAdminRoles();
  }, []);
  const handleAddAndEditAdmin = async (values) => {
    if (record) {
      try {
        await editAdminApi(record, values);
        message.success("Admin edited successfully!");
        handleCancel();
      } catch (error) {
        message.error(error.message);
      }
    } else {
      try {
        await addAdminApi(values);
        message.success("Admin Added successfully!");
        handleCancel();
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);

  const handleChange = (selectedItems) => {
    setSelectedAdminRoles(selectedItems);
  };
  useEffect(() => {
    if (isModalVisible) {
      if (record?.userId) {
        form.setFieldsValue({ email: record?.email });
        form.setFieldsValue({ firstName: record?.firstName });
        form.setFieldsValue({ lastName: record?.lastName });
        form.setFieldsValue({
          role: record?.roles.map((item) => item.roleName),
        });
      } else {
        form.resetFields();
      }
    }
  }, [record, form, isModalVisible]);
  return (
    <>
      <Modal
        title={record?.userId ? "Edit Contact" : "Add Contact"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddAndEditAdmin}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            name="firstName"
            label="First Name :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your First Name!" },
            ]}
          >
            <Input style={{ borderRadius: "25px" }} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your Last Name!" },
            ]}
          >
            <Input style={{ borderRadius: "10px" }} />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email :"
            hasFeedback
            rules={[
              { required: true, message: "Please input your E-mail!" },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input style={{ borderRadius: "25px" }} />
          </Form.Item>
          {record ? (
            ""
          ) : (
            <>
              <Form.Item
                name="password"
                label="Password :"
                hasFeedback
                rules={
                  record
                    ? []
                    : [
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]
                }
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password :"
                dependencies={["password"]}
                hasFeedback
                rules={
                  record
                    ? []
                    : [
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]
                }
              >
                <Input.Password />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="role"
            label="Add Role"
            hasFeedback
            rules={
              record
                ? []
                : [{ required: true, message: "Please input your Role!" }]
            }
          >
            <Select
              mode="multiple"
              placeholder="Select Role"
              value={selectedAdminRoles}
              onChange={handleChange}
              allowClear
              className="select-p"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              showSearch
            >
              {adminRoles.map((item) => (
                <Select.Option key={item.id} value={item.role}>
                  {item.role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="add-btn">
              {record ? "Edit Contact" : "Add Contact"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddRole;

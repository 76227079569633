import { Modal, Form, Avatar, Button } from "antd";
import { S3_URL } from "../../../constant";
import { S3_QRCODEURL } from "../../../constant";
import { Link } from "react-router-dom";
import { FormOutlined, EyeOutlined } from "@ant-design/icons";

import Edit from "./edit";
import { useState } from "react";
import moment from "moment";
import Organizations from "./organizations";
const ModalUser = ({ selectedRowKey, handleCancel, isModalVisible }) => {
  const [form] = Form.useForm();
  const [isModalVisibleOrg, setIsModalVisibleOrg] = useState(false);
  const [isVisibleOrganizations, setIsVisibleOrganizations] = useState(false);
  const showModalOrg = (record) => {
    setIsModalVisibleOrg(true);
  };
  const showVisibleOrganizationsModal = (record) => {
    setIsVisibleOrganizations(true);
  };
  return (
    <>
      <Modal
        title="Account Info"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modal"
        width={"45rem"}
      >
        {/* <div className="account-info">
          <div className="account-info__content">
            <Avatar
              src={
                S3_URL +
                selectedRowKey.userId +
                "/profile_picture/" +
                selectedRowKey.profilePicturePath
              }
              size={130}
              // className="account-info__image"
              // alt="_"
            />

            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                firstname: selectedRowKey.firstName,
                lastname: selectedRowKey.lastName,
              }}
              className="formIndividual"

              // style={{ marginRight: "2rem" }}
            >
              <Form.Item name="firstname" label="First Name">
                <span>{selectedRowKey.firstName}</span>
              </Form.Item>
              <Form.Item name="lastname" label="Last Name">
                <span>{selectedRowKey.lastName}</span>
              </Form.Item>
            </Form>
          </div>
          <div className="account-info__secontInput">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                birthDate: new Date(
                  selectedRowKey.birthDate
                ).toLocaleDateString(),
                email: selectedRowKey.email,
              }}
              className="formIndividual"
            >
              <Form.Item name="birthDate" label="DOB">
                <span>
                  {new Date(selectedRowKey?.birthDate).toLocaleDateString()}
                </span>
              </Form.Item>
              <Form.Item name="email" label="Email">
                <span>{selectedRowKey.email}</span>
              </Form.Item>
            </Form>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                mobile: selectedRowKey?.mobile,
                createdDate: new Date(
                  selectedRowKey?.createdDate
                ).toLocaleDateString(),
              }}
              style={{ marginLeft: "-4rem" }}
              className="formIndividual"
            >
              <Form.Item name="mobile" label="Mobile">
                <span>{selectedRowKey?.mobile}</span>
              </Form.Item>
              <Form.Item name="createdDate" label="Created Date">
                <span>
                  {new Date(selectedRowKey?.createdDate).toLocaleDateString()}
                </span>
              </Form.Item>
            </Form>
          </div>
        </div> */}
        <div className="indi">
          <div className="section_row">
            <Avatar
              src={
                S3_URL +
                selectedRowKey?.userId +
                "/profile_picture/" +
                selectedRowKey?.profilePicturePath
              }
              size={130}
              // className="account-info__image"
              // alt="_"
            />
            <div>
              <div className="section indi_content">
                <h3>First Name</h3>
                <span>
                  {selectedRowKey?.firstName?.trim()?.length !== 0
                    ? selectedRowKey?.firstName
                    : "-"}
                </span>
              </div>
              <div className="section indi_content">
                <h3>Last Name</h3>
                <span>
                  {selectedRowKey?.lastName?.trim()?.length !== 0
                    ? selectedRowKey?.lastName
                    : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="section_row ">
            <div className="section">
              <h3>DOB</h3>
              <span>
                {selectedRowKey?.birthDate
                  ? new Date(selectedRowKey?.birthDate)?.toLocaleDateString()
                  : "-"}
              </span>
            </div>

            <div className="section indi_content">
              <h3>Mobile</h3>
              <span>
                {selectedRowKey?.mobile ? selectedRowKey?.mobile : "-"}
              </span>
            </div>
          </div>

          <div className="section_row ">
            <div className="section">
              <h3>Email</h3>
              <span>
                <span>
                  {selectedRowKey?.email ? selectedRowKey?.email : "-"}
                </span>
              </span>
            </div>
            <div className="section indi_content">
              <h3>Create Date</h3>
              <span>
                {new Date(selectedRowKey?.createdDate).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="border"></div>
          <div className="indi_info">
            <div className="col_indi indi_info_item">
              <h3>Qr Code</h3>
              <a
                className="qrcode_indi indi_info_item"
                href={
                  S3_QRCODEURL +
                  selectedRowKey?.userId +
                  "/qr_code_pdf/%2B20-" +
                  selectedRowKey?.mobile +
                  ".pdf"
                  // S3_QRCODEURL +
                  // selectedRowKey?.userId +
                  // "/qr_code_pdf/" +
                  // selectedRowKey?.userId +
                  // ".pdf"
                }
              >
                <Button type="primary">Download QR</Button>
              </a>
            </div>

            <div className="col_indi indi_info_item">
              <h3>Linked accounts</h3>
              <Link to="/joint-accounts">
                <Button
                  className="btn-child"
                  size="small"
                  style={{ height: "2rem" }}
                  onClick={() =>
                    localStorage.setItem(
                      "JointAccount",
                      JSON.stringify(selectedRowKey)
                    )
                  }
                >
                  <span className="icon-Adminw"></span>
                  Join Account
                </Button>
              </Link>
            </div>
            <div className="col_indi indi_info_item">
              <h3>Tickets</h3>
              <Link
                to={{
                  pathname: "/tickets",
                  state: selectedRowKey,
                  place: "individual",
                }}
              >
                <Button
                  className="btn-child"
                  size="small"
                  style={{ height: "2rem" }}
                >
                  Tickets
                </Button>
              </Link>
            </div>
            <div className="col_indi indi_info_item">
              {/* organizations industryTypeName */}
              <h3>Organization</h3>
              {selectedRowKey?.organizations?.length === 0 && (
                <FormOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalOrg()}
                />
              )}
              {selectedRowKey?.organizations?.length > 0 && (

                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>
                    {selectedRowKey?.organizations[0]?.industryTypeName}
                  </span>

                  <FormOutlined
                    style={{ marginLeft: "1rem", cursor: "pointer" }}
                    onClick={() => showModalOrg()}
                  />
                  <EyeOutlined
                    style={{ marginLeft: "1rem", cursor: "pointer" }}
                    onClick={() => showVisibleOrganizationsModal()}
                  />
                </div>
              )}
            </div>
            <Modal
              title="Organizations"
              visible={isVisibleOrganizations}
              footer={null}
              onCancel={() => setIsVisibleOrganizations(false)}
              className="modal-business"
            >
              <Organizations organizations={selectedRowKey?.organizations} />
            </Modal>
            <Edit
              isModalVisible={isModalVisibleOrg}
              setIsModalVisible={setIsModalVisibleOrg}
              record={selectedRowKey}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalUser;

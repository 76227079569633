import { Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import Totop from "../../shared/TopUp";
import Edit from "./Edit";
import { fetchTransactionFeesData } from "../../../KIWE-V1/Services/transactionFeesApi";
const TransactionFees = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const [transactionsFees, setTransactionsFees] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllTransactionsFees = async () => {
    try {
      const transactionsFees = await fetchTransactionFeesData();
      setTransactionsFees(transactionsFees?.data);
      setLoading(false);
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllTransactionsFees();
  }, []);

  const columns = [
    {
      title: "Fee Type",
      dataIndex: "feesType",
      key: "feesType",
      sorter: (a, b) => a.feesType?.length - b.feesType?.length,

      ellipsis: true,
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
      sorter: (a, b) => a.fees - b.fees,
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit-transaction"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Transaction Fees</h2>
        <div className="container">
          <Edit
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
          />
          <Table
            className="table2"
            columns={columns}
            dataSource={transactionsFees}
            onChange={onChange}
            loading={loading}
            scroll={{ x: 900 }}
          />
        </div>
        <Totop />
      </div>
    </>
  );
};

export default TransactionFees;

// import Widget from './Card';
// import { darkBlue, yellow, green, blue } from '../../shared/images';
// import TransactionChart from './TransactionChart';
// import IndividualChart from './IndividualChart';
// import Totop from '../../shared/TopUp';

// const Insights = () => {
//   return (
//     <>
//       <div className='container-cards'>
//         <div className='dark'>
//           <Widget src={darkBlue} title='Individual User' count='95K' />
//         </div>
//         <div className='yellow'>
//           <Widget src={yellow} title='Business User' count='95K' />
//         </div>
//         <div className='blue'>
//           <Widget src={blue} title='Child Accounts' count='95K' />
//         </div>
//         <div className='green'>
//           <Widget src={green} title='Events' count='95K' />
//         </div>
//       </div>
//       <div className='transaction-chart'>
//         <TransactionChart />
//       </div>
//       <div className='accept-declined'>
//         <div className='accept-declined__accept'>
//           <h2>Accept</h2>
//           <IndividualChart />
//         </div>
//         <div className='accept-declined__declined'>
//           <h2>Declined</h2>
//           <IndividualChart />
//         </div>
//       </div>
//       <Totop />
//     </>
//   );
// };

// export default Insights;
import Widget from "./Card";
import { darkBlue, yellow, green, blue } from "../../shared/images";
import TransactionChart from "./TransactionChart";
import IndividualChart from "./IndividualChart";
import Totop from "../../shared/TopUp";
import MultiLine from "./MultiLine";
import Wheel from "./Wheel";
import Bar from "./Bar";
import Circle from "./Circle";
import { Space, Button, Table, message, Modal, Avatar } from "antd";
import InputSearch from "../form/InputSearch";
import axiosInstance from "../../../helpers/axiosInstance";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import classes from "./BaseInsightsStyles.module.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
const Insights = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const { pageNo } = useParams();
  const history = useHistory();
  const [data, setData] = useState([
    {
      name: "khalifa",
      mobile: "0101111111",
      transactions: 20,
      tpv: 10,
    },
    {
      name: "khalifa",
      mobile: "0101111111",
      transactions: 20,
      tpv: 20,
    },
    {
      name: "khalifa",
      mobile: "0101111111",
      transactions: 20,
      tpv: 30,
    },
    {
      name: "khalifa",
      mobile: "0101111111",
      transactions: 20,
      tpv: 40,
    },
    {
      name: "khalifa",
      mobile: "0101111111",
      transactions: 20,
      tpv: 50,
    },
  ]);
  const columns = [
    {
      title: "Profile Image",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (text, record) => (
        <>
          <Avatar
            style={{ cursor: "pointer" }}
            src={localStorage.getItem("avatarItem")}
          />
        </>
      ),
      // ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (val) => <div>{val ? <span>val</span> : <span>Name</span>}</div>,
      ellipsis: true,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      render: (val) => (
        <div>{val ? <span>val</span> : <span>mobile</span>}</div>
      ),
      ellipsis: true,
    },
    {
      title: "Num Of Transactions",
      dataIndex: "transactions",
      key: "transactions",
      render: (val) => (
        <div>{val ? <span>{val}</span> : <span>transactions</span>}</div>
      ),
      ellipsis: true,
    },
    {
      title: "TPV",
      dataIndex: "tpv",
      key: "tpv",
      render: (val) => <div>{val ? <span>{val}</span> : <span>tpv</span>}</div>,
      ellipsis: true,
    },
  ];

  const fetchData = async (page, searchText) => {
    axiosInstance()
      .get(`url`)
      .then((response) => {
        if (response?.status === 200) {
          let api_res = convertEncryptedToPlantext(response?.data?.data);
          setData(api_res?.data);
          setTotalPages(api_res?.totalRecords);
          setLoading(false);
          console.log("leaderboard", api_res);
        }
      })
      .catch((error) => {
        setLoading(false);
        const err = convertEncryptedToPlantext(error?.response?.data?.data);
        message.error(err.message);
        console.log(error);
      });
  };
  const onSearch = (value) => {
    console.log(value);
    setSearchText(value);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Insights</h2>
      </div>
      <div className={classes.multiContain}>
        <MultiLine />
      </div>
      <div className={classes.insiteContain}>
        <Bar />

        <Wheel />
      </div>
      {/* <div className={classes.barContain}>
        {" "}
        <Circle />
      </div> */}

      <div className="container" style={{ marginTop: "2rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2 style={{ color: "#27272e", fontWeight: "700" }}>Leaderboard</h2>
          <InputSearch onSearch={onSearch} />
        </Space>
        <Table
          className="table2"
          columns={columns}
          dataSource={data}
          scroll={{ x: 900 }}
          // loading={loading}
          // pagination={{
          //   pageSize: 10,
          //   total: totalPages,
          //   current: +pageNo,
          //   onChange: (page) => {
          //     setPage(page);
          //     history.push(`/insights/${page}`);
          //     fetchData(page, searchText);
          //   },
          // }}
        />
      </div>
      <Totop />
    </>
  );
};

export default Insights;

import { Space, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import InputSearch from "../../front/form/InputSearch";
import AddRole from "./AddAdmin/AddRole";
import Totop from "../../shared/TopUp";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert2";
import { FormOutlined } from "@ant-design/icons";
import moment from "moment";
import delIco from "../../../assets/images/delete.svg";
import {
  fetchAllAdminsApi,
  deleteAdminApi,
} from "../../../KIWE-V2/Services/adminApi";
const NewAdmins = () => {
  const { pageNo } = useParams();
  const history = useHistory();
  const [record, setRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const authUserInfo = JSON.parse(localStorage.getItem("auth_user_info"));
  const roles = authUserInfo ? authUserInfo.roles : [];
  const roleSuperAdmin = roles.find((itm) => itm === "SUPER_ADMIN");
  const onSearch = (value) => {
    setSearchText(value);
    fetchNewAdmins(1, value);
  };

  const fetchNewAdmins = async (page, searchText) => {
    try {
      const apiResponse = await fetchAllAdminsApi(page, searchText);
      setAdmins(apiResponse?.data);
      setTotalPages(apiResponse?.totalRecords);
      setLoading(false);
    } catch (error) {
      //   message.error(error.message);
    }
  };
  const handleDeleteAdmin = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Admin?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(async function (isConfirm) {
        if (isConfirm.value) {
          try {
            const apiResponse = await deleteAdminApi(record?.id);
            const configSuccess = {
              className: "messageSuccess",
              content: "Admin deleted successfully!",
            };
            message.success(configSuccess);
            if (apiResponse) {
              setAdmins(admins.filter((admin) => admin.id !== record.id));
            }
          } catch (error) {
            const configError = {
              className: "messageError",
              content: error.message,
            };
            message.error(configError);
          }
        }
      });
  };

  useEffect(() => {
    fetchNewAdmins(pageNo, searchText);
  }, []);
  const adminColumns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) =>
        a.firstName?.localeCompare(b.firstName, { ignorePunctuation: true }),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) =>
        a.lastName?.localeCompare(b.lastName, { ignorePunctuation: true }),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.length - b.email?.length,
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text) => <>{moment(text).format("D MMM, yyyy h:mm a")}</>,
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role?.length - b.role?.length,
      render: (text, record) => (
        <>
          {record?.roles?.length > 0 ? (
            <>
              {record?.roles?.map((item) => (
                <span key={item?.id}>
                  {item?.name},<br />
                </span>
              ))}
            </>
          ) : (
            <div
              className="eye"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              -{" "}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (val, record) => (
        <>
          {roleSuperAdmin ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormOutlined
                style={{ margin: ".5rem", fontSize: "18px", color: "#696974" }}
                onClick={() => {
                  showModal();
                  setRecord(record);
                }}
              />

              <img
                data-testid="delete"
                src={delIco}
                style={{ width: "18px", cursor: "pointer" }}
                onClick={() => handleDeleteAdmin(record)}
                className="delete"
                alt="delete"
              />
            </div>
          ) : (
            " - "
          )}
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Admins</h2>
          <Space>
            <InputSearch onSearch={onSearch} />
            <Space>
              {roleSuperAdmin ? (
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    showModal();
                    setRecord(null);
                  }}
                >
                  Add Admin
                </Button>
              ) : (
                ""
              )}
            </Space>
            <AddRole
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
              setAdmins={setAdmins}
              admins={admins}
            />
          </Space>
        </Space>
        <div className="container">
          {admins?.length > 0 ? (
            <Table
              className="table2"
              columns={adminColumns}
              dataSource={admins}
              rowKey="id"
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: +pageNo,
                onChange: (page) => {
                  setPage(page);
                  history.push(`/new-admins/${page}`);
                  fetchNewAdmins(page, searchText);
                },
              }}
            />
          ) : (
            <div className="messageNoData" style={{ width: "24%" }}>
              No Admin User Records Found.
            </div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default NewAdmins;

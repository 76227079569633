import { Space, Button, DatePicker, Table, message } from "antd";
import InputSearch from "../form/InputSearch";
import { useEffect, useState, useMemo } from "react";
import { BankTransfer } from "../../shared/images";
import BankDetails from "../business_user/BankDetails";
import Totop from "../../shared/TopUp";
import swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import moment from "moment";
import arrowRight from "../../../assets/images/icons8-right-arrow-100 1.svg";
import exporticon from "../../../assets/images/export.svg";
import exportBlue from "../../../assets/images/exportBlue.svg";
import {
  fetchWithdrawalListAndDecryptResponse,
  exportWithdrawalByDateOrSearchTextFromInputAndDecryptResponse,
  updateWithdrawalListStatus,
} from "../../../KIWE-V1/Services/withdrawalListApi.js";
const WithdrawalList = () => {
  const [searchTextFromInput, setSearchTextFromInput] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [filteredWithdrawalList, setFilteredWithdrawalList] = useState([]);
  const [filterCondition, setFilterCondition] = useState("");
  const [record, setRecord] = useState([]);
  const history = useHistory();
  const { RangePicker } = DatePicker;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState({ startDate: "", endDate: "" });

  const onSearch = (value) => {
    setSearchTextFromInput(value);
    fetchWithdrawalLIST(1, value);
  };

  const fetchWithdrawalLIST = async (page, searchTextFromInput) => {
    try {
      const apiResponse = await fetchWithdrawalListAndDecryptResponse(
        searchTextFromInput,
        page
      );
      setTotalPages(apiResponse?.totalRecords);
      setWithdrawalList(apiResponse?.data);
      setFilteredWithdrawalList(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchWithdrawalLIST(1, searchTextFromInput);
  }, [searchTextFromInput]);

  const handleFilterClick = (filterCondition) => {
    setFilterCondition(filterCondition);
  };

  const filteredWithdrawalListMemo = useMemo(() => {
    if (!filterCondition) {
      return withdrawalList;
    }
    return withdrawalList.filter(
      (item) => item.transaction_status === filterCondition
    );
  }, [withdrawalList, filterCondition]);

  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdateStatusWithdrawal = (status, id) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text:
          status === "paid"
            ? "Are you sure want to Pay this Withdrawal Request?"
            : "Are you sure want to Decline this Withdrawal Request?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function updateWithdrawalStatusAsync() {
            try {
              const decryptedResponse = await updateWithdrawalListStatus(
                status,
                id
              );
              console.log(decryptedResponse);
              if (decryptedResponse) {
                const configSuccess = {
                  className: "messageSuccess",
                  content: "Updated withdrawl status successfully!!",
                };
                message.success(configSuccess);
                fetchWithdrawalLIST(page, searchTextFromInput);
              }
            } catch (error) {
              const configError = {
                className: "messageError",
                content: error.message,
              };
              message.error(configError);
            }
          }
          updateWithdrawalStatusAsync();
        }
      });
  };
  const withdrawalListColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Business Name",
      dataIndex: "senderName",
      key: "senderName",
      sorter: (a, b) => a.senderName?.length - b.senderName?.length,

      width: 175,
    },
    {
      title: "Bank Details",
      dataIndex: "withdrawalType",
      key: "withdrawal Type",
      render: (src, record) => (
        <>
          <span
            className="image"
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => showModal(record)}
          >
            <img src={BankTransfer} alt="" />
          </span>
        </>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Withdrawal Date",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text) => <>{moment(text).format("D MMM, yyyy h:mm a")}</>,
    },

    {
      title: "Action Date",
      dataIndex: "updatedTimeStamp",
      key: "updatedTimeStamp",
      render: (text) => <>{moment(text).format("D MMM, yyyy h:mm a")}</>,
    },
    {
      title: "Action",
      dataIndex: "transaction_status",
      key: "transaction_status",

      render: (text, record) => (
        <>
          {text === "pending" ? (
            <div>
              <Button
                type="primary"
                className="btn paid"
                size="middle"
                style={{ margin: ".5rem" }}
                onClick={() =>
                  handleUpdateStatusWithdrawal("paid", record?.transactionId)
                }
              >
                Pay
              </Button>
              <Button
                type="danger"
                size="small"
                className="declined"
                style={{ height: "2rem" }}
                onClick={() =>
                  handleUpdateStatusWithdrawal(
                    "declined",
                    record?.transactionId
                  )
                }
              >
                Decline
              </Button>
            </div>
          ) : record.transaction_status === "paid" ? (
            <>
              <div
                color="green"
                className="custom-tag onboarding-status-approved"
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </div>
            </>
          ) : record.transaction_status === "declined" ? (
            <>
              <div
                color="red"
                className="custom-tag onboarding-status-rejected"
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </div>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];
  const exportTWithdrawalByDateOrSearchTextFromInput = async (page, isDate) => {
    try {
      const apiResponse =
        await exportWithdrawalByDateOrSearchTextFromInputAndDecryptResponse(
          searchTextFromInput,
          date?.startDate,
          date?.endDate,
          isDate,
          page
        );
      if (apiResponse.data === null) {
        const configSuccess = {
          className: "messageSuccess",
          content: "Export Transactions Succssfully but there are no data!",
        };
        message.success(configSuccess);
      } else {
        window.open(apiResponse?.data, "EXPORT TRANSACTIONS");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  const handleExportAllWithdrawalList = () => {
    if (date?.startDate && date?.endDate) {
      const isDate = true;
      exportTWithdrawalByDateOrSearchTextFromInput(page, isDate);
    } else {
      const isDate = false;
      exportTWithdrawalByDateOrSearchTextFromInput(page, isDate);
    }
  };
  const handlePayout = () => {
    history?.push("/payout");
  };
  function onChange2(dates, dateStrings) {
    if (dates) {
      setDate({ startDate: dateStrings[0], endDate: dateStrings[1] });
    }
  }
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Withdrawal List</h2>
          <Space className="filterButtonTrans">
            {" "}
            <Button
              className="btnWAll"
              size="middle"
              onClick={() => handleFilterClick("")}
            >
              All
            </Button>
            <Button
              className="btnWPaid"
              size="middle"
              onClick={() => handleFilterClick("paid")}
            >
              Paid
            </Button>
            <Button
              className="btnWPending"
              size="middle"
              onClick={() => handleFilterClick("pending")}
            >
              Pending
            </Button>
            <Button
              className="btnWDecliend"
              size="middle"
              onClick={() => handleFilterClick("declined")}
            >
              Decliend
            </Button>
          </Space>
          <Space>
            <InputSearch onSearch={onSearch} />

            <Button type="primary" size="middle" onClick={handlePayout}>
              Payout
            </Button>

            <RangePicker
              separator={
                <img
                  src={arrowRight}
                  alt="-"
                  style={{ width: "15px", height: "15px" }}
                />
              }
              onChange={onChange2}
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
            <Button
              type="primary"
              size="middle"
              onClick={handleExportAllWithdrawalList}
              style={{ cursor: "pointer" }}
              className="btnExport"
              loading={loading}
            >
              <img src={exporticon} alt="" className="imageExport" />
              <img src={exportBlue} alt="" className="exportBlue" />
            </Button>
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={withdrawalListColumns}
            dataSource={filteredWithdrawalListMemo}
            scroll={{ x: 900 }}
            loading={loading}
            pagination={{
              pageSize: 10,
              total: totalPages,
              onChange: (page) => {
                setPage(page);
                fetchWithdrawalLIST(page, searchTextFromInput);
              },
            }}
          />
        </div>
        <Totop />
      </div>
      <BankDetails
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        record={record}
      />
    </>
  );
};

export default WithdrawalList;

import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
const apiCall = async (url, requestBody, method = "post", decrypt = true) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const fetchValuPromotionData = async (page) => {
  try {
    const decryptedResponse = await apiCall(
      `valu-promotion?pageNo=${page}`,
      {},
      "get"
    );
    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
const buildAddAndEditValuPromotionRequestBody = (
  values,
  valuPromotionOption,
  state
) => {
  const commonData = {
    active: values.active !== undefined ? values.active : false,
    upToLimit:
      valuPromotionOption === "Precentage"
        ? values.upToLimit === undefined
          ? 0
          : values.upToLimit
        : null,
    startDate: values.startDate,
    amount: valuPromotionOption === "Amount" ? values.amount : null,
    endDate: values.endDate,
    percentage:
      valuPromotionOption === "Precentage"
        ? values.percentage === undefined
          ? 0
          : values.percentage
        : null,
    isPercentage: valuPromotionOption === "Amount" ? false : true,
  };

  return state?.id ? { ...commonData, id: state.id } : commonData;
};

export const addValuPromotionAPI = async (values, valuPromotionOption) => {
  try {
    debugger;
    const requestBody = buildAddAndEditValuPromotionRequestBody(
      values,
      valuPromotionOption
    );

    const decryptedResponse = await apiCall("valu-promotion", requestBody);

    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};
export const editValuPromotionAPI = async (
  values,
  valuPromotionOption,
  state
) => {
  try {
    const requestBody = buildAddAndEditValuPromotionRequestBody(
      values,
      valuPromotionOption,
      state
    );

    const decryptedResponse = await apiCall(
      "valu-promotion",
      requestBody,
      "put"
    );

    return decryptedResponse;
  } catch (error) {
    throw error;
  }
};

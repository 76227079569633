import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";
export const apiCall = async (
  url,
  requestBody,
  method = "post",
  decrypt = true
) => {
  try {
    let encryptedResponse;
    if (Object.keys(requestBody).length !== 0) {
      encryptedResponse = await axiosInstance()[method](url, {
        data: convertPlantextToEncrypted(requestBody),
      });
    } else {
      encryptedResponse = await axiosInstance()[method](url);
    }
    if (decrypt) {
      const decryptedResponse = convertEncryptedToPlantext(
        encryptedResponse?.data?.data
      );

      return decryptedResponse;
    }
    return encryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

export const fetchFAQsApi = async (page, topicId, limit = 10) => {
  try {
    const encryptedResponse = await apiCall(
      `v2/admin/FAQ-topic/${topicId}/FAQ?page=${page}&limit=${limit}`,
      {},
      "get",
      false
    );
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
const buildAddAndUodateFAQRequestBody = (values) => {
  return {
    question: values.question,
    answer: values.answer,
    visible: values.visible,
  };
};
export const addFAQApi = async (values, topicId) => {
  try {
    const requestBody = buildAddAndUodateFAQRequestBody(values);

    const encryptedResponse = await apiCall(
      `v2/admin/FAQ-topic/${topicId}/FAQ`,
      requestBody,
      "post",
      false
    );
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const editFAQApi = async (values, FAQId) => {
  try {
    const requestBody = buildAddAndUodateFAQRequestBody(values);

    const encryptedResponse = await apiCall(
      `v2/admin/FAQ-topic/FAQ/${FAQId}`,
      requestBody,
      "patch",
      false
    );
    const decryptedResponse = convertEncryptedToPlantext(
      encryptedResponse.data.data
    );
    return decryptedResponse;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};

import { Form, message, Button, Input, Radio } from "antd";
import Totop from "../../shared/TopUp";
import DragImage from "../form/DragImage";
import { normFile } from "../../shared/dragdropFun";
import {
  generateRandomFileName,
  handleImageUpload,
} from "../../shared/uploadfun";
import { useHistory } from "react-router-dom";
import { Env } from "../../../constant";
import { useParams } from "react-router-dom";
import { addNotificationAPI } from "../../../KIWE-V1/Services/notificationApi";
import { useState } from "react";
const Add = () => {
  const [form] = Form.useForm();
  const accountTypes = ["All", "Individual", "Business"];
  const deviceTypes = ["All", "IOS", "Android"];
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { pageNo } = useParams();
  const handleAddNotification = async (values) => {
    try {
      setLoading(true);
      const randomNameImage = generateRandomFileName(
        values?.notificationImage[0]
      );
      await handleImageUpload(
        values?.notificationImage[0].originFileObj,
        randomNameImage,
        "kiweapp/" + Env + "/notification_picture_path"
      );
      const decryptedResponse = await addNotificationAPI(
        values,
        randomNameImage
      );

      if (decryptedResponse) {
        setLoading(false);
        const configSuccess = {
          className: "messageSuccess",
          content: "Notification Added Succssfully!",
        };
        message.success(configSuccess);
        history.push(`/notification/${+pageNo}`);
      }
    } catch (error) {
      setLoading(false);
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <h2>Add Notification</h2>
        <div className="container">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddNotification}
            autoComplete="off"
            className="notificationFrom"
          >
            <Form.Item
              name="userType"
              label="User Type:"
              rules={[
                { required: true, message: "Please input your User Type!" },
              ]}
              className="notificationFrom__userType"
            >
              <Radio.Group>
                {accountTypes.map((item, index) => (
                  <Radio value={item} key={index}>
                    {item}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="deviceType"
              label="Device Type:"
              rules={[
                { required: true, message: "Please input your Device Type!" },
              ]}
              className="notificationFrom__userDevice"
            >
              <Radio.Group>
                {deviceTypes.map((item, index) => (
                  <Radio value={item} key={index}>
                    {item}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <DragImage
                normFile={normFile}
                nam1="notificationImage"
                label="Notification Image"
                rules={[
                  {
                    required: true,
                    message: "Please input your Notfication Image!",
                  },
                ]}
              />
              <span className="note">
                Note :- Please upload image in size of "1440 * 645" for better
                resolution
              </span>
            </Form.Item>
            <Form.Item
              name="notificationTitle"
              label="Title Text"
              hasFeedback
              rules={[
                { required: true, message: "Please input your Title Text!" },
              ]}
            >
              <Input placeholder="Enter Title Text" className="input-border" />
            </Form.Item>
            <Form.Item
              name="notificationText"
              label="Body Text"
              rules={[
                { required: true, message: "Please input your Body Text!" },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Body Text" className="input-border" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="add-btn"
                loading={loading}
              >
                Add Notification
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Add;

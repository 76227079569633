import { Button, Space, Table, message, Avatar } from "antd";

import { useHistory } from "react-router-dom";
import Totop from "../../shared/TopUp";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
  getCurrentTimeStemp,
} from "../../../library/Helper";
// import { S3_URL } from '../../../constant';
import { useState, useEffect } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
// import { toast } from 'react-toastify';
// import swal from 'sweetalert2';
import { notification_picture_path } from "../../../constant";
import { useParams } from "react-router-dom";
import moment from "moment";
const Blog = () => {
  const history = useHistory();
  // const [data, setData] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  console.log(page);
  const [loading, setLoading] = useState(true);
  const { pageNo } = useParams();
  const data = [
    {
      blogTitle: "Blog1",
      blogBody: "blogBodyblogBody blogBody blogBody",
      blog_image: localStorage.getItem("avatarItem"),
      createdTimeStamp: "1/1/2023",
    },
  ];
  const fetchData = async (page) => {
    axiosInstance()
      .post("admin/offer-notifications", {
        data: convertPlantextToEncrypted({
          pageNo: page,

          expiryTime: getCurrentTimeStemp(),
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          let api_res = convertEncryptedToPlantext(response?.data?.data);
          //   setTotalPages(api_res?.totalRecords);
          //   setData(api_res?.data);
          setLoading(false);
          // console.log(api_res, "notification");
        }
      })
      .catch((error) => {
        setLoading(false);

        const err = convertEncryptedToPlantext(error?.response?.data?.data);
        message.error(err.message);
      });
  };

  useEffect(() => {
    fetchData(pageNo);
  }, []);

  const columns = [
    {
      title: "Blog Title",
      dataIndex: "blogTitle",
      key: "blogTitle",
      sorter: (a, b) => a.blogTitle?.localeCompare(b.blogTitle),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Blog Body",
      dataIndex: "blogBody",
      key: "blogBody",
      sorter: (a, b) => a.blogBody?.localeCompare(b?.blogBody),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Blog Image",
      dataIndex: "blog_image",
      key: "blog_image",
      render: (txt, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={record?.blog_image}
              style={{ width: "25%", borderRadius: "25px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "Number Of Views",
      dataIndex: "blogBody",
      key: "blogBody",
      sorter: (a, b) => a.blogBody?.localeCompare(b?.blogBody),
      render: (text) => (
        <span style={{ textAlign: "center", display: "block" }}>{175}</span>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "createdTimeStamp",
      key: "createdTimeStamp",
      render: (text, record) => (
        <>{moment(record?.createdTimeStamp).format("D MMM, yyyy h:mm a")}</>
      ),
      sorter: (a, b) =>
        new Date(a.createdTimeStamp) - new Date(b.createdTimeStamp),
    },
  ];

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const handleClick = () => {
    history.push(`/add-blog/${pageNo}`);
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Blog</h2>
          <Button type="primary" size="middle" onClick={handleClick}>
            ADD
          </Button>
        </Space>
        <div className="container">
          {data.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={data}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
              pagination={{
                pageSize: 10,
                total: totalPages,
                current: pageNo,
                onChange: (page) => {
                  setPage(page);
                },
              }}
            />
          ) : (
            <div className="messageNoData">No Blog Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default Blog;

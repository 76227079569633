import React, { useState, useEffect, useRef } from "react";
import { Badge, Popover, List, Spin } from "antd";
import { fetchNotificationsApi } from "../../../KIWE-V2/Services/notificationApi.js";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [fetching, setFetching] = useState(false);

  const containerRef = useRef(null);
  const prevTotalRecords = useRef(0);

  useEffect(() => {
    prevTotalRecords.current = 0;
    fetchNotifications(currentPage);
  }, []);

  const fetchNotifications = async (page) => {
    if (fetching) return;
    try {
      setFetching(true);
      setLoading(true);
      const apiResponse = await fetchNotificationsApi(page);
      const newNotifications = apiResponse?.data || [];
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...newNotifications,
      ]);
      setTotalRecords(apiResponse?.totalRecords || 0);
      setNumberOfPages(apiResponse?.numberOfPages || 1);
      const newUnreadCount = apiResponse?.totalRecords - (page - 1) * 8;
      if (newUnreadCount !== prevTotalRecords.current) {
        setUnreadCount(newUnreadCount);
        prevTotalRecords.current = newUnreadCount;
      }
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
      setFetching(false);
    }
  };

  const handleScroll = async () => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, clientHeight, scrollHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (currentPage < numberOfPages) {
        await fetchNotifications(currentPage + 1);
      }
    }
  };

  const handleNotificationClick = () => {
    setUnreadCount(0);
    prevTotalRecords.current = 0;
  };

  return (
    <Popover
      content={
        <div
          style={{ maxHeight: 300, overflowY: "auto" }}
          ref={containerRef}
          onScroll={handleScroll}
        >
          <List
            dataSource={notifications}
            renderItem={(notification, index) => (
              <List.Item key={`${notification.notificationId}-${index}`}>
                {notification.message}
              </List.Item>
            )}
          />
          {loading && (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          )}
        </div>
      }
      trigger="click"
      onVisibleChange={handleNotificationClick}
    >
      <Badge count={unreadCount} dot>
        <i className="fa fa-bell" />
      </Badge>
    </Popover>
  );
};

export default Notification;

import { Button, Form, message, InputNumber, DatePicker, Switch } from "antd";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { addAndEditCashBackAPI } from "../../../KIWE-V1/Services/cashAPI";
// import { useState } from "react";

const Add = () => {
  const [form] = Form.useForm();

  const { state } = useLocation();

  console.log(state);
  const history = useHistory();

  // const { userId } = state;
  // const { userId } = useParams();

  // const [id, setId] = useState();
  const dateFormat = "YYYY/MM/DD";
  const userIdCashBack = localStorage.getItem("userIdCashBack");
  const handleAddCashBack = async (values) => {
    try {
      const api_response = await addAndEditCashBackAPI(values, userIdCashBack);

      if (api_response.status === 200) {
        message.success("Submit success!");
        history.goBack();
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  const disabledDate = (current) => {
    let customDate = today;
    return current && current < moment(customDate, "YYYY-MM-DD");
  };
  const disabledDate2 = (current) => {
    let customDate = tomorrow;
    return current && current < moment(customDate, "YYYY-MM-DD");
  };
  const onChange = (value) => {
    console.log("changed", value);
  };
  const handleCancel = () => {
    form.resetFields();
    // setId(userId);
    history.goBack();
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <div className="container" style={{ padding: "2.2rem" }}>
          <div className="profile__form" style={{ padding: "0rem" }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleAddCashBack}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="container__topheader">
                <h2>Add Cash-Back</h2>
                <div style={{ display: "flex", marginTop: "1rem" }}>
                  <Form.Item>
                    <Button
                      size="middle"
                      htmlType="submit"
                      className="profile__btns__save"
                    >
                      Save
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size="middle"
                      className="profile__btns__cancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                name="percentage"
                label="Percentage Cash-Back(%)"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input your Percentage Cash-Back(%)!',
                //   },
                // ]}
              >
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={0}
                  //   onChange={onChange}
                  className="inputs-business"
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="upToLimit"
                label="Limit Cash-Back"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input your Limit Cash-Back!',
                //   },
                // ]}
              >
                <InputNumber
                  min={0}
                  //   max={100}
                  defaultValue={0}
                  onChange={onChange}
                  className="inputs-business"
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                    // margin: '5px auto',
                  }}
                />
              </Form.Item>
              <Form.Item
                name="startAt"
                label="Start Cash-Back"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input your Start Cash-Back!',
                //   },
                // ]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                  //   defaultValue={
                  //     startAt ? moment(startAt, dateFormat) : ''
                  //   }
                  format={dateFormat}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item
                name="endAt"
                label="End Cash-Back"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input your End Cash-Back!',
                //   },
                // ]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    height: "2.7rem",
                  }}
                  //   defaultValue={
                  //     endCashBack ? moment(endCashBack, dateFormat) : ''
                  //   }
                  format={dateFormat}
                  disabledDate={disabledDate2}
                />
              </Form.Item>
              <Form.Item
                name="activeCashBack"
                label="Active Cash-Back"
                valuePropName="checked"
              >
                <Switch size="middle" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;

import { GET_ALL_SELLING_TYPE } from '../actionTypes';
import { getAllSellingTypeAsync } from './api';

export const getSellingType = () => {
  return async (dispatch) => {
    try {
      const result = await getAllSellingTypeAsync();

      dispatch(getSellingTypeSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getSellingTypeSuccess = (sellingType) => {
  return {
    type: GET_ALL_SELLING_TYPE,
    payload: sellingType,
  };
};

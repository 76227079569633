import axiosInstance from "../../helpers/axiosInstance";
import {
  convertEncryptedToPlantext,
  convertPlantextToEncrypted,
} from "../../library/Helper";

const generateFetchFairsApi = (page, limit = 10) => {
  let url = `admin/fair?page=${page}&limit=${limit}`;
  return url;
};

export const fetchFairsApi = async (page) => {
  const url = generateFetchFairsApi(page, 10);
  try {
    const response = await axiosInstance().get(url);
    const decryptedResponse = convertEncryptedToPlantext(response?.data?.data);
    return decryptedResponse;
  } catch (error) {
    throw convertEncryptedToPlantext(error?.response?.data?.data);
  }
};

const buildAddFairRequestBody = (values, banner) => {
  return {
    name: values?.name,
    businessUserId: values?.businessUserId,
    banner: banner,
    startDate: values?.startDate,
    endDate: values?.endDate,
    arabicPolicy: values?.arabicPolicy,
    englishPolicy: values?.englishPolicy,
    enabled: values?.enabled,
  };
};

export const addFairAPI = async (values, banner) => {
  try {
    const requestBody = buildAddFairRequestBody(values, banner);

    const response = await axiosInstance().post("admin/fair", {
      data: convertPlantextToEncrypted(requestBody),
    });

    return response;
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptedError;
  }
};
export const updateFairStatus = async (fairId, status) => {
  try {
    const url = `admin/fair/${fairId}`;
    const data = { enabled: status };
    const decryptedResponse = await axiosInstance().patch(url, {
      data: convertPlantextToEncrypted(data),
    });
    return decryptedResponse;
  } catch (error) {
    const decryptError = convertEncryptedToPlantext(
      error?.response?.data?.data
    );
    throw decryptError;
  }
};


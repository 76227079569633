import { Space, Button, Table, message } from "antd";
import { useState, useEffect } from "react";
import Totop from "../../shared/TopUp";
import Add from "./add";
import swal from "sweetalert2";
import {
  fetchServicesTypesData,
  deleteServiceTypeAPI,
} from "../../../KIWE-V1/Services/serviceType.js";
const ServiceType = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [servicesTypes, setServicesTypes] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAllServicesTypes = async () => {
    try {
      const servicesTypes = await fetchServicesTypesData();
      setServicesTypes(servicesTypes);
      setLoading(false);
    } catch (error) {
      const configError = {
        className: "messageError",
        content: error.message,
      };
      message.error(configError);
    }
  };
  useEffect(() => {
    fetchAllServicesTypes();
  }, []);
  const handleDeleteServiceType = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Service Type ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteServiceTypeAsync() {
            try {
              const decryptedResponse = await deleteServiceTypeAPI(record);
              if (decryptedResponse) {
                setServicesTypes((prevServicesTypes) =>
                  prevServicesTypes.filter(
                    (serviceType) => serviceType.id !== record.id
                  )
                );
                message.success("Service Type deleted successfully!");
              }
            } catch (error) {
              message.error(error.message);
            }
          }
          deleteServiceTypeAsync();
        }
      });
  };
  const columns = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: (a, b) => a.serviceName?.localeCompare(b.serviceName),
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
                console.log(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="danger"
              className="delete"
              onClick={() => handleDeleteServiceType(record)}
            >
              Delete
            </Button>
          </div>
        </>
      ),
      ellipsis: true,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  return (
    <>
      {" "}
      <div style={{ margin: "1rem" }}>
        <Space direction="horizontal" className="topHeader">
          <h2>Service Type</h2>

          <Button
            type="primary"
            size="middle"
            onClick={() => {
              showModal();
              setRecord({});
            }}
          >
            ADD
          </Button>

          <Add
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            record={record}
          />
        </Space>
        <div className="container">
          {servicesTypes.length > 0 ? (
            <Table
              className="table2"
              columns={columns}
              dataSource={servicesTypes}
              onChange={onChange}
              scroll={{ x: 900 }}
              loading={loading}
            />
          ) : (
            <div className="messageNoData">No Service Type Records Found.</div>
          )}
        </div>
        <Totop />
      </div>
    </>
  );
};

export default ServiceType;

import React, { useEffect, useState } from "react";
import { Drawer, Button } from "antd";
import {
  MenuOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import avatarMale from "../../assets/images/img_avatar_male.png";
import avatarFemale from "../../assets/images/img_avatar_female.png";
import { Link } from "react-router-dom";

import { Menu, Dropdown, Avatar } from "antd";

import { logo } from "./images";
import logout from "../../assets/images/logout.svg";
import logoutWhite from "../../assets/images/logoutWhite.svg";
import adminIconWhite from "../../assets/images/adminWhiteS.svg";
import adminIcon from "../../assets/images/adminBlack.svg";
import userDetailsBlack from "../../assets/images/userDetails.svg";
import userDetailsWhite from "../../assets/images/userDetailsWhite.svg";

import { useHistory } from "react-router-dom";
import "./NavBar.css";
import Notification from "../kiwe-v2/notification/index.jsx";
const NavBar = ({ menu, collapsed, setCollapsed }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [role, setRole] = useState("");
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  let userIdRole = localStorage.getItem("typeRoleAdmin");
  let roleAdminV2 = userIdRole === "newCMS";
  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");
  let admin = roles.find((itm) => itm === "SUPER_ADMIN" || itm === "ADMIN");
  const getAdminRole = () => {
    if (roles.find((itm) => itm === "SUPER_ADMIN")) {
      setRole("SUPER_ADMIN");
    } else if (roles.find((itm) => itm === "ADMIN")) {
      setRole("ADMIN");
    } else if (roles.find((itm) => itm === "FINANCES")) {
      setRole("FINANCES");
    } else if (roles.find((itm) => itm === "OPERATIONS")) {
      setRole("OPERATIONS");
    } else if (roles.find((itm) => itm === "CUSTOMER_SUPPORT")) {
      setRole("CUSTOMER_SUPPORT");
    } else if (roles.find((itm) => itm === "BUSINESS")) {
      setRole("BUSINESS");
    } else if (roles.find((itm) => itm === "MARKETING")) {
      setRole("MARKETING");
    }
  };
  useEffect(() => {
    getAdminRole();
  }, []);
  const handleClickLogout = (path) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user_id");
    localStorage.removeItem("auth_user_info");
    localStorage.removeItem("avatarItem");
    localStorage.removeItem("JointAccount");
    localStorage.removeItem("parentAccount");
    localStorage.removeItem("typeRoleAdmin");

    history.push(path);
  };

  const headerMenu = (
    <Menu className={admin ? "menuContainer" : "menuHeight"}>
      <Menu.Item key="0" className="first_item_hover" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // flexDirection: "column",
          }}
          // className="colorLink"
        >
          <Avatar
            // icon={<UserOutlined />}
            src={localStorage.getItem("avatarItem")}
            size={40}
            // className="changeSize"
          />
          <span
            style={{
              marginLeft: ".5rem",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {firstName} {lastName}
          </span>
        </div>
      </Menu.Item>
      {admin ? (
        <Menu.Item key="1" className="item_hover">
          <Link
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="colorLink"
            to={roleAdminV2 ? "/new-admins/1" : "/admin/1"}
          >
            <img
              src={adminIcon}
              style={{ margin: "0 1rem", width: "22px", height: "22px" }}
              className="default-image"
              alt="_"
            />{" "}
            <img
              src={adminIconWhite}
              style={{ margin: "0 1rem", width: "22px", height: "22px" }}
              className="hover-image"
              alt="_"
            />
            <span style={{ fontSize: "13px", fontWeight: "bold" }}>Admin</span>
          </Link>
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key={admin ? "2" : "1"} className="item_hover">
        <Link
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="colorLink"
          to={roleAdminV2 ? "/new-profile" : "/profile"}
        >
          <img
            src={userDetailsBlack}
            style={{ margin: "0 1rem", width: "22px", height: "22px" }}
            alt="_"
            className="default-image"
          />
          <img
            src={userDetailsWhite}
            style={{ margin: "0 1rem", width: "22px", height: "22px" }}
            alt="_"
            className="hover-image"
          />
          <span style={{ fontSize: "13px", fontWeight: "bold" }}>
            Your details
          </span>
        </Link>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key={admin ? "3" : "2"} className="item_hover">
        <Link
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="colorLink2"
          to=""
          onClick={() => handleClickLogout("/")}
        >
          <img
            src={logout}
            style={{ margin: "0 1rem", width: "22px", height: "22px" }}
            className="default-image"
            alt="_"
          />{" "}
          <img
            src={logoutWhite}
            style={{ margin: "0 1rem", width: "21px", height: "21px" }}
            className="hover-image"
            alt="_"
          />{" "}
          <span style={{ fontSize: "14px", fontWeight: "bold" }}> Logout</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="navbar">
      <Button
        className="menu"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title={
          // <Link to="/">
          <>
            <img src={logo} className="logo" alt="logo" style={{ margin: 0 }} />
          </>

          // </Link>
        }
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        {menu}
      </Drawer>
      {/* <Link to='/'> */}

      <div className="logo_container">
        <img src={logo} className="logo dipImg" alt="logo" />
        {collapsed ? (
          <MenuUnfoldOutlined
            className="menu_logo"
            onClick={() => setCollapsed(!collapsed)}
          />
        ) : (
          <MenuFoldOutlined
            className="menu_logo"
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </div>
      <div className="textEnc">
        <span className="span1 ">Good things happen to those who hustle🙂</span>
      </div>
      {/* </Link> */}
      <div className="profile_section">
        {roleAdminV2 ? (
          <>
            <Notification />

            <div className="__border"></div>
          </>
        ) : (
          ""
        )}
        <Dropdown overlay={headerMenu}>
          <div className="list">
            <div className="list__image">
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  // icon={<UserOutlined />}
                  src={localStorage.getItem("avatarItem")}
                  size={30}
                  className="changeSize"
                />
                <Avatar
                  // icon={<UserOutlined />}
                  src={localStorage.getItem("avatarItem")}
                  size={50}
                  className="defaultSize"
                />
              </a>
            </div>
            <div className="list__content">
              <span className="list__content__user">
                {firstName} {lastName}
              </span>
              <span className="list__content__role">
                {role?.split("_").join(" ")}
              </span>
            </div>
          </div>
        </Dropdown>
      </div>
    </nav>
  );
};

export default NavBar;

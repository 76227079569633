import { Table, Space, Button, message, Switch } from "antd";

import Totop from "../../shared/TopUp";
import { useState, useEffect } from "react";
import Add from "./add";
import { useHistory } from "react-router-dom";
import { convertEncryptedToPlantext } from "../../../library/Helper";
import { S3_URL } from "../../../constant";
import { toast } from "react-toastify";
import swal from "sweetalert2";
import UploadHub from "./uploadHub";
import {
  fetchIndustriesTypesData,
  deleteIndustryTypeAPI,
  updateHubIndustryType,
} from "../../../KIWE-V1/Services/industryTypeApi";
const IndustryType = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleHub, setIsModalVisibleHub] = useState(false);

  const history = useHistory();
  const handleClick = (record) => {
    history.push(`/logs/${record.id}`);
  };
  const [industriesTypes, setIndustriesTypes] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  var { roles } = JSON.parse(localStorage.getItem("auth_user_info"));
  const fetchIndustriesTypes = async () => {
    try {
      const apiResponse = await fetchIndustriesTypesData();
      setIndustriesTypes(apiResponse?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const errorData = convertEncryptedToPlantext(error?.response?.data?.data);
      message.error(errorData.message);
    }
  };

  useEffect(() => {
    fetchIndustriesTypes();
  }, []);

  const columns = [
    {
      title: "Industry Name",
      dataIndex: "industryTypeName",
      key: "industryTypeName",
      sorter: (a, b) => a.industryTypeName.localeCompare(b.industryTypeName),
    },
    {
      title: "Industry Name Arabic",
      dataIndex: "industryTypeNameAr",
      key: "industryTypeNameAr",
      sorter: (a, b) =>
        a.industryTypeNameAr.localeCompare(b.industryTypeNameAr),
    },
    {
      title: "Industry Color",
      dataIndex: "industryTypeColor",
      key: "industryTypeColor",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",

              border: "1px solid transparent",

              width: "20px",
              height: "20px",
              borderRadius: "50px",
              background: `${record.industryTypeColor}`,
              margin: " 0 auto",
            }}
          ></div>
        </>
      ),
    },

    {
      title: "Industry Logo",
      dataIndex: "industryTypeLogo",
      key: "industryTypeLogo",
      render: (text, record) => (
        <>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={S3_URL + "industry_type/images/" + record.industryTypeLogo}
              style={{ width: "40%", borderRadius: "10px" }}
              alt=""
            />
          </span>
        </>
      ),
    },
    {
      title: "QR Code",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <>
          <Button type="primary" size="middle">
            <a
              href={
                S3_URL +
                "industry_type/" +
                record.id +
                "/qr_code_pdf/" +
                record.id +
                "_QR_CODE.pdf"
              }
            >
              Download
            </a>
          </Button>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem" }}
              onClick={() => {
                showModal();
                setRecord(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              className="btn edit"
              style={{ margin: ".5rem", background: "#20ce24" }}
              record={record}
              onClick={() => {
                handleClick(record);
                // setRecord(record);
              }}
            >
              View Logs
            </Button>
            {record?.industryTypeName === "Kiwe" ? (
              ""
            ) : (
              <Button
                type="danger"
                className="delete"
                onClick={() => handleDeleteIndustryTyoe(record)}
              >
                Delete
              </Button>
            )}
            {roles[0] === "SUPER_ADMIN" ? (
              <Button
                type="primary"
                className="btn edit"
                style={{ margin: ".5rem" }}
                onClick={() => {
                  showModalHub();
                  setRecord(record);
                }}
              >
                Upload Hub
              </Button>
            ) : (
              ""
            )}
          </div>
        </>
      ),
    },
    {
      title: "Restricted",
      dataIndex: "restriction",
      key: "restriction",

      render: (text, record) => (
        <>
          <div>
            <Switch
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
              size="middle"
              onChange={(value) => handleUpdateHubIndustryType(record)}
              checked={record.isHub}
            />
          </div>
        </>
      ),
    },
  ];
  const handleUpdateHubIndustryType = async (record) => {
    try {
      const apiResponse = await updateHubIndustryType(record);
      if (apiResponse) {
        let industriesType = industriesTypes.map((industryType) => {
          if (industryType.id === record?.id)
            return { ...industryType, isHub: !record.isHub };
          else return industryType;
        });
        setIndustriesTypes(industriesType);
      }
    } catch (error) {
      setLoading(false);

      const errorData = convertEncryptedToPlantext(error?.response?.data?.data);
      message.error(errorData.message);
    }
  };
  const showModal = (record) => {
    setIsModalVisible(true);
    setRecord(record);
  };
  const showModalHub = () => {
    setIsModalVisibleHub(true);
  };
  function onChange(pagination, sorter, extra) {
    console.log("params", pagination, sorter, extra);
  }
  const handleDeleteIndustryTyoe = (record) => {
    swal
      .fire({
        type: "warning",
        title: "Approval required",
        text: "Are you sure want to delete this Industry-Types ?",
        width: 500,
        height: 500,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        showCancelButton: true,
      })
      .then(function (isConfirm) {
        if (isConfirm.value) {
          async function deleteIndustryTypeAsync() {
            try {
              const decryptedResponse = await deleteIndustryTypeAPI(record);
              if (decryptedResponse) {
                setIndustriesTypes((prevIndustriesTypes) =>
                  prevIndustriesTypes.filter(
                    (industryType) => industryType.id !== record.id
                  )
                );
                message.success("Industry Type deleted successfully!");
              }
            } catch (error) {
              message.error(error.message);
            }
          }
          deleteIndustryTypeAsync();
        }
      });
  };
  return (
    <>
      <div style={{ margin: "1rem" }}>
        <Space
          direction="horizontal"
          className="topHeader"
          // style={{ justifyContent: "end" }}
        >
          <h2>Industry Types</h2>
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                showModal();
              }}
            >
              ADD
            </Button>

            <Add
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              record={record}
            />
          </Space>
        </Space>
        <div className="container">
          <Table
            className="table2"
            columns={columns}
            dataSource={industriesTypes}
            onChange={onChange}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
        <Totop />
        <UploadHub
          setIsModalVisible={setIsModalVisibleHub}
          isModalVisible={isModalVisibleHub}
          record={record}
        />
      </div>
    </>
  );
};

export default IndustryType;

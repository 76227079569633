import axiosInstance from "../../helpers/axiosInstance";
import { convertEncryptedToPlantext,getCurrentTimeStemp,convertPlantextToEncrypted } from "../../library/Helper";


export const fetchSellingTypeDataAndDecryptResponse = async () => {
  try {
    const response = await axiosInstance().get("sellingType/getSellingType");
    if (response.status === 200) {
      const decryptedResponse = convertEncryptedToPlantext(
        response?.data?.data
      );
      return decryptedResponse;
    }
  } catch (error) {
    throw error;
  }
};

const buildAddSellingTypeRequestBody=(type,id)=>{
  return{
    type,
    id,
    expiryTime: getCurrentTimeStemp(),
  }
}
export const addSellingTypeAPI = async (type,id) => {
  try {
    const data=buildAddSellingTypeRequestBody(type,id)
    const response = await axiosInstance().post("sellingType/addSellingType",{data:convertPlantextToEncrypted(data)});
    if (response.status === 200) {
      const decryptedResponse = convertEncryptedToPlantext(
        response?.data?.data
      );
      return decryptedResponse;
    }
  } catch (error) {
    const decryptedError = convertEncryptedToPlantext(error?.response?.data?.data);
    throw decryptedError;
  }
};

